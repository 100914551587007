import styled from '@emotion/styled';
import { Divider, Modal } from 'antd';
import React, { Component } from 'react';
import { TransProps, withTranslation } from 'react-i18next';

import { sendActionAnalytics } from '../../features/common/api';
import { history } from '../../services';
import { sendCredentialsToLocalServer } from '../../services/http';
import { MEMBER_TOKEN } from '../../services/http/config';

const SharedAccountContainer = styled('div')`
  cursor: pointer;
  transition: .2s linear;
  padding-left: 10px;
  border-radius: 3px;
  :hover {
    box-shadow: 0 0 0 2px var(--FFFFFF), 0 0 0 4px var(--109F63);
  }
`;

const isElectron = !!window.require;

let ipcRenderer: Electron.IpcRenderer;

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

interface IAccountManager extends TransProps {
  toggleAccountModal: () => void;
  switchAccountModalVisible: boolean;
  sharedAccounts: any;
  useMyAccount?: boolean;
  resetWorkspaceContext?: () => void;
}

class AccountManager extends Component<IAccountManager> {
  private switchAccount = (token: string, email: string): void => {
    sendActionAnalytics('visited members page');
    this.props.toggleAccountModal();
    sessionStorage.setItem(MEMBER_TOKEN, token);
    sessionStorage.setItem('selected_account', email);

    this.props.resetWorkspaceContext && this.props.resetWorkspaceContext();

    if (isElectron) {
      ipcRenderer.invoke('changed-account').catch(() => null);
      sendCredentialsToLocalServer('member', 'switchAccount');
    }

    history.push('/');
  };

  private getContainerStyle = (email: string) => {
    const selectedAccount = sessionStorage.getItem('selected_account');
    if (selectedAccount && selectedAccount === email) {
      return {
        boxShadow: '0 0 0 2px var(--FFFFFF), 0 0 0 4px var(--109F63)',
      };
    }

    return {};
  };

  private switchToUserAccount = () => {
    const selectedAccount = sessionStorage.getItem('selected_account') || this.props.useMyAccount;
    if (!selectedAccount) {
      return null;
    }

    return (
      <>
        <Divider style={{ margin: '12px 0' }} />
        <div
          style={{ cursor: 'pointer' }}
          onClick={async () => {
            this.props.toggleAccountModal();
            sessionStorage.removeItem('selected_account');
            sessionStorage.removeItem(MEMBER_TOKEN);

            if (isElectron) {
              sendCredentialsToLocalServer('access', 'switchToUserAccount');
            }

            history.push('/');
          }}
        >
          {this.props.useMyAccount
            ? this.props.t('switchAccountModal.useMyAccount')
            : this.props.t('switchAccountModal.returnToMyAccount')}
        </div>
      </>
    );
  };

  private switchAccountSettings() {
    return (
      <div>
        {this.props.sharedAccounts.map((item: any, index: number) => (
          <div
            key={index}
          >
            {index ? <Divider style={{ margin: '12px 0' }} /> : null}
            <SharedAccountContainer
              onClick={() => this.switchAccount(item.token, item.from.email)}
              key={item.from.email}
              style={this.getContainerStyle(item.from.email)}
            >
              {item.from.email}
            </SharedAccountContainer>
          </div>
        ))}
        {this.switchToUserAccount()}
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <Modal
        title={this.props.t('userMenu.switchAccount')}
        visible={this.props.switchAccountModalVisible}
        onCancel={this.props.toggleAccountModal}
        footer={null}
      >
        {this.switchAccountSettings()}
      </Modal>
    );
  }
}

export default withTranslation()(AccountManager);
