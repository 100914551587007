import { IProxy } from './components/interfaces/IProxy';
import { ProxyCheckTooltipView } from '../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { addMultipleProxies, IMultipleProxiesAddResult } from '../../state/proxy/proxy-operations/create-proxies.operations';
import { parseMultipleProxies } from '../../utils/proxy-string';
import { sendReactErrorToSentry } from '../../utils/sentry.helper';
import { sendActionAnalytics } from '../common/api';

interface IPastedProxiesSendParams {
  clipboardText: string;
  profileInEditProxyMode: string | null;
  proxyList: IProxy[];
  localProxySelectorLocation: string;
  isInDrawer: boolean;
}

export const sendPastedProxies = async ({
  clipboardText,
  profileInEditProxyMode,
  proxyList,
  localProxySelectorLocation,
  isInDrawer,
}: IPastedProxiesSendParams): Promise<IMultipleProxiesAddResult|null> => {
  const proxies = await parseMultipleProxies(clipboardText);
  if (!profileInEditProxyMode) {
    sendReactErrorToSentry({
      message: 'profile id not found in send-pasted-proxies function params',
      transactionName: 'profile-not-found-in-paste-proxies',
    });

    return null;
  }

  sendActionAnalytics('pasted proxy from buffer');
  let tooltipView: ProxyCheckTooltipView = 'selector-profile-table';
  if (isInDrawer) {
    tooltipView = localProxySelectorLocation === 'pinned-proxy' ? 'selector-profile-settings-favourite' : 'selector-profile-settings-proxy';
  }

  return addMultipleProxies({
    proxyList,
    proxies,
    selectedProfiles: [profileInEditProxyMode],
    proxyCheckTooltipView: tooltipView,
  });
};
