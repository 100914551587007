import { Checkbox } from '@material-ui/core';
import React from 'react';

import { CheckboxChecked } from './checkbox-checked';
import { CheckboxIndeterminate } from './checkbox-indeterminate';
import { IGologinCheckbox } from './interfaces';
import { CheckboxContainer, CheckboxWrapper } from './styles';

export const GologinCheckbox = (props: IGologinCheckbox): JSX.Element => {
  const { hasPadding, onChange, checked, isIndeterminate } = props;

  return (
    <CheckboxContainer hasPadding={hasPadding}>
      <Checkbox
        icon={<CheckboxWrapper />}
        checkedIcon={<CheckboxChecked />}
        indeterminateIcon={<CheckboxIndeterminate />}
        checked={checked}
        indeterminate={isIndeterminate}
        onChange={onChange}
      />
    </CheckboxContainer>
  );
};
