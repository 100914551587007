import { getBasicTableEntities } from './basic-table-entities.atom';
import { resetProfilesQueryOffset, setShouldProfilesQueryMakeRequests } from './profiles-query';
import { PAGE_SIZE } from '../../../common/constants/constants';
import { GroupHeader } from '../../interfaces/group-header.interface';
import {
  getGroupHeaderById,
  getGroupProfilesCount,
  getProfilesTableGroupHeaders,
  setProfilesTableGroupHeaders,
} from '../profiles-list.atom';

export const updateGroupHeader = (groupHeaderId: GroupHeader['id'], fieldsToUpdate: Partial<GroupHeader>): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  setProfilesTableGroupHeaders(groupHeaders.map((groupHeader) => {
    if (groupHeader.id === groupHeaderId) {
      return { ...groupHeader, ...fieldsToUpdate };
    }

    return groupHeader;
  }));
};

export const openProfilesTableGroupHeader = (groupHeaderId: GroupHeader['id']): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const groupHeaderIdx = groupHeaders.findIndex((groupHeader) => groupHeader.id === groupHeaderId);
  const groupHeader = groupHeaders[groupHeaderIdx];
  if (!groupHeader || groupHeader.isOpen) {
    return;
  }

  if (groupHeader.loadingStatus === 'loaded') {
    const groupProfilesCount = getGroupProfilesCount(groupHeaderId);
    if (groupProfilesCount === groupHeader.totalProfiles) {
      updateGroupHeader(groupHeaderId, { isOpen: true });

      return;
    }
  }

  updateGroupHeader(groupHeaderId, { isOpen: true, loadingStatus: 'loading-initiated' });
  resetProfilesQueryOffset();
};

export const closeProfilesTableGroupHeader = (groupHeaderId: GroupHeader['id']): void => {
  const groupHeader = getGroupHeaderById(groupHeaderId);
  if (!groupHeader?.isOpen) {
    return;
  }

  setShouldProfilesQueryMakeRequests(false);
  updateGroupHeader(groupHeaderId, { isOpen: false });
  if (getBasicTableEntities().length < PAGE_SIZE) {
    resetProfilesQueryOffset();
  }

  setShouldProfilesQueryMakeRequests(true);
};
