import { useSimpleSortable } from '@dnd-kit-contextless/sortable';
import { useEffect, useMemo } from 'react';

import { NEW_FEATURES } from '../../../../../feature-toggle';
import { useDraggingRowFields } from '../../../state/dragging-profiles-state.atom';

export type RelativeDropPosition = 'top'|'bottom'|null;

interface ProfileSortable extends Pick<
  ReturnType<typeof useSimpleSortable>,
  'attributes'|'listeners'|'setNodeRef'|'isDragging'
> {
  relativeDropPosition: RelativeDropPosition;
}

export const calculateRelativeDropPosition = (isOver: boolean, primaryIdx: number | null, rowIdx: number): RelativeDropPosition => {
  let dropPosition: 'top'|'bottom'|null = null;
  if (isOver && primaryIdx !== null) {
    if (primaryIdx < rowIdx) {
      dropPosition = 'bottom';
    } else if (primaryIdx > rowIdx) {
      dropPosition = 'top';
    }
  }

  return dropPosition;
};

export const useProfileSortable = (rowIdx: number): ProfileSortable => {
  const { primaryIdx, isDragging } = useDraggingRowFields(rowIdx);

  const {
    attributes,
    listeners,
    setNodeRef,
    isOver,
  } = useSimpleSortable({ id: `profile::${rowIdx}`, disabled: !NEW_FEATURES.dragAndDrop });

  const relativeDropPosition = useMemo(
    () => calculateRelativeDropPosition(isOver, primaryIdx, rowIdx),
    [isOver, primaryIdx, rowIdx],
  );

  const profileSortable = useMemo(() => ({
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    relativeDropPosition,
  }), [attributes, listeners, setNodeRef, isDragging, relativeDropPosition]);

  return profileSortable;
};
