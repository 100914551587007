import React, { FC } from 'react';
import { useTranslate } from '@tolgee/react';

import { CardList, CardListItem, CardListProfilesItem, DescriptionPlan, IconContainer } from './styles';
import IconPlanCheck from '../../../../ui/icons/IconPlanCheck';

interface IPreviewCardContent {
  isCustomPlan: boolean;
  getMaxProfilesItem: () => JSX.Element;
  planName: string;
  maxAccountShares?: number;
}

export const PreviewCardContent: FC<IPreviewCardContent> = (props) => {
  const { isCustomPlan, getMaxProfilesItem, planName, maxAccountShares } = props;

  const { t: translation } = useTranslate();

  return (
    <CardList isCustomPlan={isCustomPlan}>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <CardListProfilesItem>
          {getMaxProfilesItem()}
          {' '}
          <DescriptionPlan style={{ marginLeft: 0 }}>
            {translation(`pricing.${planName.toLowerCase()}Plan.tick1`)}
          </DescriptionPlan>
        </CardListProfilesItem>
      </CardListItem>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <DescriptionPlan>
          {translation(`pricing.${planName.toLowerCase()}Plan.tick2`)}
        </DescriptionPlan>
      </CardListItem>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <DescriptionPlan>
          {translation(`pricing.${planName.toLowerCase()}Plan.tick3`)}
        </DescriptionPlan>
      </CardListItem>
      {maxAccountShares ? (
        <CardListItem isCustomPlan={isCustomPlan}>
          <IconContainer>
            <IconPlanCheck />
          </IconContainer>
          <DescriptionPlan>
            {maxAccountShares}
            {' '}
            {translation(`pricing.${planName.toLowerCase()}Plan.tick4`)}
          </DescriptionPlan>
        </CardListItem>
      ) : null}
    </CardList>
  );
};
