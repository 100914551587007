import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconBinTrashDelete: React.FC<IIconWrapper> = (props) => (
  <IconWrapper iconType='fill' {...props}>
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path d='M2.5 6.5V13.5C2.5 14.605 3.395 15.5 4.5 15.5H12.5C13.605 15.5 14.5 14.605 14.5 13.5V6.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.5 3.5H15.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6.5 3.5V0.5H10.5V3.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 7.5V12.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5 7.5V12.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 7.5V12.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
