import React, { memo, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { IconWrapper, QuickSettingsButtonWrapper, Text } from './styles';
import ProfileSettingsBtnTooltip from './tooltip';
import { NEW_FEATURES } from '../../../../../../../feature-toggle';
import { PROXY_PAGE } from '../../../../../../common/constants/routes';
import IconProfileSettingsBtnArrow from '../../../../../ui/icons/IconProfileSettingsBtnArrow';
import TooltipCustom from '../../../../../ui/tooltip-custom';

interface IQuickSettingsButton {
  openProfileSettings: (event: React.MouseEvent) => void;
}

const QuickSettingsButton: React.FC<IQuickSettingsButton> = (props) => {
  const { openProfileSettings } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const wrapperRef = useRef<HTMLDivElement|null>(null);

  const location = useLocation();

  useEffect(() => {
    setAnchorEl(wrapperRef.current);
  }, []);

  const tooltipTranslationKey = location.pathname === PROXY_PAGE ? 'proxies.editProxy' : 'quickSettings.openQuickSettingBtnText';

  const renderTooltip = (): JSX.Element => {
    const tooltipText = <Trans i18nKey={tooltipTranslationKey} />;
    if (NEW_FEATURES.header) {
      return <TooltipCustom anchorEl={anchorElTooltip} value={tooltipText} />;
    }

    return (
      <ProfileSettingsBtnTooltip isVisible={isTooltipVisible} anchorEl={anchorEl}>
        {tooltipText}
      </ProfileSettingsBtnTooltip>
    );
  };

  return (
    <>
      <QuickSettingsButtonWrapper
        ref={wrapperRef}
        onClick={openProfileSettings}
        onMouseEnter={(event): void => {
          NEW_FEATURES.header ? setAnchorElTooltip(event.currentTarget) : setIsTooltipVisible(true);
        }}
        onMouseLeave={(): void => {
          NEW_FEATURES.header ? setAnchorElTooltip(null) : setIsTooltipVisible(false);
        }}
        newStyle={!!NEW_FEATURES.header}
        newDrawer={!!NEW_FEATURES.drawer}
      >
        <Text>
          <Trans i18nKey={NEW_FEATURES.drawer ? 'profilesTable.openQuickSettings.settings' : 'quickSettings.details'} />
        </Text>
        <IconWrapper>
          <IconProfileSettingsBtnArrow />
        </IconWrapper>
      </QuickSettingsButtonWrapper>
      {renderTooltip()}
    </>
  );
};

export default memo(QuickSettingsButton);
