import { NEW_FEATURES } from '../../../../../feature-toggle';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { IDataToCreateProfile } from '../api';

export const prepareProxyProfiles = async (proxyFromFile: IProxy[]): Promise<IDataToCreateProfile[]> => {
  if (!(proxyFromFile.length || NEW_FEATURES.importProxyFile)) {
    return [{}];
  }

  return proxyFromFile.map(proxy => ({ proxy }));
};
