import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  PROXY_TABLE_SORTER_FIELD_KEY,
  PROXY_TABLE_SORTER_ORDER_KEY,
} from '../../../../../common/constants/constants';
import { PROXY_PAGE } from '../../../../../common/constants/routes';
import { useProfilesTableSorting } from '../../../../state/profiles-table/profiles-sort.atom';
import { IconSort, IconSortDown, IconSortUp } from '../../../../ui/gologin-header/icons';

export const SorterCol = ({ sorterField = '' }: { sorterField?: string }): JSX.Element|null => {
  let { sortField, sortOrder } = useProfilesTableSorting();
  const location = useLocation();

  const isProxyPage = location.pathname === PROXY_PAGE;

  if (isProxyPage) {
    sortField = localStorage.getItem(PROXY_TABLE_SORTER_FIELD_KEY);
    sortOrder = localStorage.getItem(PROXY_TABLE_SORTER_ORDER_KEY);
  }

  const isSortingCurrentCol = sortField === sorterField;
  const iconProps = {
    padding: 4,
    iconColor: 'var(--98989F-profile-table-new-sorter)',
    iconHoveredColor: 'var(--2B2B31-profile-table-new-sorter)',
  };

  if (isSortingCurrentCol && sortOrder === 'ascend') {
    return (
      <IconSortUp {...iconProps} />
    );
  }

  if (isSortingCurrentCol && sortOrder === 'descend') {
    return (
      <IconSortDown {...iconProps} />
    );
  }

  return (
    <IconSort {...iconProps} />
  );
};
