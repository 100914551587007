import React from 'react';
import { useTranslation } from 'react-i18next';

import { BtnAdd, BtnIcon, BtnText, EmptyListButtonsEl, EmptyListEl, EmptyListTitleEl } from './styles/empty-list';
import { ProxyListContentEl } from './styles/proxy-list';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import IconPlusEmptyList from '../../../../ui/icons/IconPlusEmptyList';

interface IEmptyList {
  proxySelectorLocation: string|null;
}

const EmptyList: React.FC<IEmptyList> = (props) => {
  const { proxySelectorLocation } = props;

  const { t: translation } = useTranslation();

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    openProxyManager({
      modalView: 'proxy-add',
      currentProxy: null,
      currentProfileId: null,
      proxySelectorLocation,
    });
  };

  return (
    <ProxyListContentEl>
      <EmptyListEl>
        <EmptyListTitleEl>
          {translation('proxies.noProxyFound')}
        </EmptyListTitleEl>
        <EmptyListButtonsEl>
          <BtnAdd onClick={handleAddClick}>
            <BtnIcon>
              <IconPlusEmptyList />
            </BtnIcon>
            <BtnText>
              {translation('proxies.addProxy')}
            </BtnText>
          </BtnAdd>
        </EmptyListButtonsEl>
      </EmptyListEl>
    </ProxyListContentEl>
  );
};

export default EmptyList;
