export const PINNED_SETTINGS = [
  'name',
  'notes',
  'folders',
  'proxy',
  'language',
  'timezone',
  'geolocation',
  'resolution',
  'cookies',
  'fingerprint',
] as const;

export const OVERVIEW_SETTINGS = [
  'name',
  'notes',
  'folders',
] as const;

export const PROXY_SETTINGS = [
  'proxy',
  'timezone',
  'geolocation',
  'webRTCPublic',
  'customDns',
] as const;

export const BROWSER_SETTINGS = [
  'useragent',
  'cookies',
  'bookmarks',
  'startUrl',
  'launchArguments',
  'language',
  'extensions',
  'fonts',
] as const;

export const STORAGE_SETTINGS = [
  'activeSessionLock',
  'sessionSaving',
  'browserHistorySaving',
  'browserBookmarkSaving',
  'googleServices',
  'passwordSaving',
  'localStorageAndIndexedDB',
  'browserPlugins',
  'extensionStorage',
  'externalExtensions',
] as const;

export const HARDWARE_SETTINGS = [
  'resolution',
  'deviceMemory',
  'hardwareConcurrency',
  'mediaDevices',
  'canvas',
  'webglImage',
  'webglMetadata',
  'audioContext',
] as const;

export const ANDROID_HARDWARE_SETTINGS = [
  ...HARDWARE_SETTINGS,
] as const;
