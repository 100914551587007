import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const ProxyTitleBlockEl = styled('div')<{ isFailed?: boolean }>`
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.25px;
  line-height: 20px;
  height: 16px;

  ${(props): SerializedStyles => css`
    opacity: ${(!props.isFailed) ? '1' : '0.5'};
  `}

  .ant-drawer-body & {
    max-width: 246px;
  }

  .proxy-popper-container  & {
    min-width: 0;
  }

  .nowrap {
    line-height: normal;
    height: 16px;
  }
`;

export const ProxyItemWrapperEl = styled('div')`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProxyTitleWrapperEl = styled('div')<{ newStyle: boolean; noProxy?: boolean }>`
  ${(props): SerializedStyles => css`
    ${(props.noProxy) ? '' : `
      width: auto;
      min-width: calc(100% - 48px);
      padding-top: 2px;
    `};
  `}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    padding-top: 0;
    width: auto;
    min-width: calc(100% - 48px);
  ` : null}

  .proxy-popper & {
    padding-top: 0;
  }
`;
