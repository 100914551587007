import moment from 'moment';
import React from 'react';

import { ProxyCheckSpinnerIcon } from './styles';
import { ProxyCheckTooltipView, showProxyCheckTooltip } from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { updateProxyStatuses } from '../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IProxy } from '../components/interfaces/IProxy';
import { getProxyStatus } from '../proxy-helpers';
import { ProxyStatusDot } from '../styles/check-proxy-btn';

interface ICheckProxyStatusDot {
  proxy: IProxy;
  isSpinnerVisible: boolean;
  isChecking: boolean;
  proxyTooltipView: ProxyCheckTooltipView;
  profileId?: string;
  isSharedProxy?: boolean;
}

const CheckProxyStatusDot: React.FC<ICheckProxyStatusDot> = (props) => {
  const { proxy, isSpinnerVisible, isChecking, proxyTooltipView, profileId, isSharedProxy = false } = props;

  if (!isSpinnerVisible) {
    const status = getProxyStatus(proxy);

    return <ProxyStatusDot status={status} className='proxy-status-dot' />;
  }

  const handleClick = async (): Promise<void> => {
    const proxyChecked = await updateProxyStatuses([proxy], profileId, isSharedProxy);
    const proxyCheckedFull: IProxy = { ...proxy, ...proxyChecked, checkDate: moment().toDate() };
    if (proxyTooltipView !== 'proxy-manager-edit-form') {
      showProxyCheckTooltip({
        profileIds: [profileId || ''],
        proxies: [proxyCheckedFull],
        view: proxyTooltipView,
        timeout: 2000,
      });
    }
  };

  return (
    <ProxyCheckSpinnerIcon
      type='sync'
      spin={isChecking}
      className='proxy-status-icon'
      onClick={handleClick}
    />
  );
};

export default CheckProxyStatusDot;
