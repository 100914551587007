import React, { FC } from 'react';

import { ArrowContainer } from './styles';
import { IconArrow } from '../gologin-header/icons';

interface ICollapseArrow {
   isOpen: boolean;
   onClick: () => void;
   onMouseEnter: () => void;
   onMouseLeave: () => void;
}

const CollapseArrow: FC<ICollapseArrow> = (props) => (
  <ArrowContainer {...props}>
    <IconArrow padding='3px 5px' />
  </ArrowContainer>
);

export default CollapseArrow;
