import { Icon, Switch, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchContainer, SwitchTitle } from './styles';
import { useCurrentTheme } from '../../../../state/theme.atom';
import getSelectedThemeColor from '../../../../utils/get-selected-theme-color';

interface IProfileLanguagesInputBlock {
  autoLang: boolean;
  onChange: (state: boolean) => void;
}

const SwitchAutoLang: FC<IProfileLanguagesInputBlock> = (props) => {
  const { autoLang,  onChange } = props;

  const theme = useCurrentTheme();

  const { t: translation } = useTranslation();

  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  return (
    <SwitchContainer>
      <Switch
        checked={autoLang}
        onChange={onChange}
      />
      <SwitchTitle>
        {translation('profile.languages.autoLang')}
      </SwitchTitle>
      <Tooltip
        title={(
          <span style={{ fontSize: 12, opacity: isDarkTheme ? 1 : 0.6 }}>
            {translation('profile.languages.autoLangTooltip')}
          </span>
        )}
        overlayStyle={{ backgroundColor: 'var(--FFFFFF)', color: 'var(--000000)' }}
        placement='rightBottom'
        arrowPointAtCenter={true}
      >
        <Icon
          style={{
            marginTop: 6,
            marginLeft: 5,
            marginBottom: 6,
            opacity: 0.4,
          }}
          type='info-circle'
        />
      </Tooltip>
    </SwitchContainer>
  );
};

export default SwitchAutoLang;
