import React from 'react';
import { Trans } from 'react-i18next';

import { ProxySavedSelectorPlaceholder, ProxySavedSelectorProxy, ProxySavedSelectorUnselectButton } from './styles';
import { onProfileSettingsProxySelect, useProfileSettingsProxyId } from '../../../../state/proxy/proxy-in-profile-settings.atom';
import { getProxyByArtificialProxyId, useProxyListProxyById } from '../../../../state/proxy/proxy-list.atom';
import { ProxySelectorLocation } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconClose } from '../../../../ui/gologin-header/icons';
import { EMPTY_PROXY } from '../../proxy-helpers';
import ProxyListItem from '../../proxy-manager/proxy-list/proxy-list-item';
import { iconProps } from '../constants';

interface ISelectedProxy {
  editingProfileId: string|null;
  proxyManagerTargetRef: React.RefObject<HTMLButtonElement>;
}

const SelectedProxy: React.FC<ISelectedProxy> = (props) => {
  const { editingProfileId, proxyManagerTargetRef } = props;

  const profileSettingsProxyId = useProfileSettingsProxyId();
  let proxyToRender = useProxyListProxyById(profileSettingsProxyId);
  if (!proxyToRender && profileSettingsProxyId) {
    proxyToRender = getProxyByArtificialProxyId(profileSettingsProxyId);
  }

  if (!proxyToRender) {
    proxyToRender = { ...EMPTY_PROXY };
  }

  if (!profileSettingsProxyId) {
    return (
      <ProxySavedSelectorPlaceholder>
        <Trans i18nKey='profileSettingsPage.proxyTab.savedProxyPlaceholder' />
      </ProxySavedSelectorPlaceholder>
    );
  }

  const handleClickUnselect: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onProfileSettingsProxySelect(null);
  };

  return (
    <ProxySavedSelectorProxy>
      <ProxyListItem
        proxy={proxyToRender}
        isCurrent={false}
        currentProfileId={editingProfileId}
        localProxyElementLocation={ProxySelectorLocation.profileSettingsPage}
        isSelectProxyModeOpened={false}
        isHoverable={false}
        maxWidth={220}
        shouldShowEdit={false}
        handleRowClick={(): null => null}
        selectorContainerElement={proxyManagerTargetRef}
      />
      <ProxySavedSelectorUnselectButton onClick={handleClickUnselect}>
        <IconClose {...iconProps} />
      </ProxySavedSelectorUnselectButton>
    </ProxySavedSelectorProxy>
  );
};

export default SelectedProxy;
