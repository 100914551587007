const FORBIDDEN_CUSTOM_LAUNCH_ARGUMENTS = [
  '--user-data-dir',
  '--disable-encryption',
  '--donut-pie=undefined',
  '--webrtc-ip-handling-policy',
  '--font-masking-mode',
  '--load-extension',
  '--restore-last-session',
  '--lang',
  '--window-size',
  '--remote-debugging-port',
  '--font-masking-mode',
  '--disable-extensions-except',
  '--enable-logging',
  '--v',
  '--vmodule',
  '--no-sandbox',
  '--disable-webgl2',
  '--webgl-antialiasing-mode',
  '--restore-last-session',
  '--proxy-server',
  '--host-resolver-rules',
];


export const validateLaunchArguments = (launchArgumentsText: string) => {
  if (!launchArgumentsText) {
    return { valid: [], invalid: [] };
  }

  const separatedArgs = launchArgumentsText.split(/\r?\n/).filter(arg => arg.trim() !== '');
  return separatedArgs.reduce((acc: { valid: string[], invalid: string[] }, argument) => {
    const isValid = argument.startsWith('--') && !FORBIDDEN_CUSTOM_LAUNCH_ARGUMENTS.some((forbiddenArg) => argument.includes(forbiddenArg));
    acc[isValid ? 'valid' : 'invalid'].push(argument);

    return acc;
  }, { valid: [], invalid: [] });
}