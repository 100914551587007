import { Modal } from 'antd';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import AutoProxyForm from './components/auto-form';
import { updateProfilesTableSelectedIds, useProfilesTableSelectedIds } from '../../../../state/profiles-table-selected-ids.atom';
import { useProfilesList } from '../../../../state/profiles-list.atom';

interface IProxyManager {
  visible: boolean;
  onClose: () => any;
}

const MultipleProxyManager: FC<IProxyManager> = (props) => {
  const { t: translation } = useTranslation();

  const selectedProfileIds = useProfilesTableSelectedIds();
  const profilesList = useProfilesList();
  const selectedProfiles = profilesList.filter(profile => selectedProfileIds.includes(profile.id));

  const { visible, onClose } = props;

  return (
    <Modal
      title={translation('multipleProxyManagerModal.title')}
      footer={false}
      visible={visible}
      onCancel={onClose}
    >
      <AutoProxyForm
        closeModal={onClose}
        selectedProfiles={selectedProfiles}
        setSelectedProfilesIds={updateProfilesTableSelectedIds}
      />
    </Modal>
  );
};

export default memo(MultipleProxyManager);
