import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PriceItem from './price-item';
import { ItemRow, PriceListPopoverCustom } from './styles';
import { IGeolocationProxyPrices } from '../../../../interfaces';
import { useCurrentTheme } from '../../../../state/theme.atom';
import getSelectedThemeColor from '../../../../utils/get-selected-theme-color';

interface IAccountMenu {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  price: IGeolocationProxyPrices;
  proxyType: keyof IGeolocationProxyPrices;
  availableForPurchase: number[];
}

const PriceList: FC<IAccountMenu> = (props) => {
  const theme = useCurrentTheme();
  const { t: translation } = useTranslation();

  const { anchorEl, onClose, price, proxyType, availableForPurchase } = props;
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  return (
    <PriceListPopoverCustom
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ zIndex: 1200 }}
      isDarkTheme={isDarkTheme}
    >
      <ItemRow style={{ color: 'var(--B3B3B8-header-buy-proxy)', cursor: 'default' }}>
        {translation('trafficModal.buyTraffic')}
      </ItemRow>
      {availableForPurchase.map(count => (
        <PriceItem key={count} price={price} proxyType={proxyType} count={count} onClose={onClose} />
      ))}
    </PriceListPopoverCustom>
  );
};

export default PriceList;

