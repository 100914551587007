import styled from '@emotion/styled';

export const EmptyFlagEl = styled('div')`
  display: inline-block;
  width: 14px;
  height: 10px;
  text-align: center;
  color: var(--D9D9D9-proxy-manager-empty-flag);

  & > svg {
    vertical-align: top;
  }
`;
