import { Modal } from 'antd';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { workspaceContext } from '../../../../state';
import { logout } from '../../../../utils/logout';

interface ILogoutNotificationModalProps {
  isLogoutModalVisible: boolean;
  setIsLogoutModalVisible: (arg: boolean) => void;
}

export const LogoutNotificationModal: FC<ILogoutNotificationModalProps> = (props) => {
  const { isLogoutModalVisible, setIsLogoutModalVisible } = props;

  const workspaceCtx = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  return (
    <Modal
      title={translation('logOutModal.title.text')}
      visible={isLogoutModalVisible}
      onOk={(): Promise<void> => logout(workspaceCtx)}
      okText={translation('logOutModal.button.ok')}
      cancelText={translation('logOutModal.button.cancel')}
      onCancel={(): void => setIsLogoutModalVisible(false)}
    >
      {translation('logOutModal.subtitle.text')}
    </Modal>
  );
};
