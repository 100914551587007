import React, { useEffect, useRef, useState } from 'react';

import { Input } from './styles';
import { NEW_FEATURES } from '../../../../../../../feature-toggle';

interface INameCellEditCore {
  inputId: 'profileNameId'|'proxyNameId';
  initialName: string;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>, inputValue: string) => void;
  handleBlur: (inputValue: string) => void;
}

export const NameCellEditCore: React.FC<INameCellEditCore> = (props) => {
  const { inputId, initialName, handleKeyDown, handleBlur } = props;

  const [inputValue, setInputValue] = useState<string>(initialName);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.select();
  }, []);

  const handleChange: React.FormEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault();
    setInputValue(event.currentTarget.value);
  };

  return (
    <Input
      id={inputId}
      ref={ref}
      value={inputValue}
      onChange={handleChange}
      onKeyDown={(event): void => handleKeyDown(event, inputValue)}
      onBlur={(): void => handleBlur(inputValue)}
      onClick={(event): void => event.stopPropagation()}
      newStyle={!!NEW_FEATURES.header}
    />
  );
};
