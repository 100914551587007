import { Button, Icon, message, Modal, Select, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateFeatures, confirmEmailAddress, IUpdateFeatures } from './api';
import ChangeEmailComponent from './email';
import PasswordChangeForm from './PasswordChangeForm';
import {
  AccountEmail,
  AlignedButton,
  OverviewGrid,
  OverviewRowTitle,
  SelectCustom,
  SubtitleTransfer,
} from './styles';
import { NEW_FEATURES, setFeatures } from '../../../../../feature-toggle';
import { history } from '../../../services';
import { userContext, workspaceContext } from '../../../state';
import { switchTheme, useCurrentTheme } from '../../../state/theme.atom';
import { SelectWorkspace } from '../../../ui/workspaceSelect';
import { openWebsitePricing } from '../../../utils/open-site';
import { sendActionAnalytics, updateDefaultWorkspace } from '../../common/api';
import ImportDolphinProfilesModal from '../../modalsComponents/components/import-dolphin-profiles';
import { TabWrapper } from '../menu/tab-wrapper';
import { PageTitle } from '../styles';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const OverviewPage: FC = () => {
  const { t: translation, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  const [isEmailChangeModalOpened, setIsEmailChangeModalOpened] = useState<boolean>(false);
  const [isPasswordChangeModalOpened, setIsPasswordChangeModalOpened] = useState<boolean>(false);
  const [isShowImportDolphinModal, setIsShowImportDolphinModal] = useState<boolean>(false);
  const [isNewDesign, setIsNewDesign] = useState<boolean>(!!(NEW_FEATURES.header && NEW_FEATURES.drawer));

  const theme = useCurrentTheme();

  const {
    _id = '',
    email,
    isEmailConfirmed,
    updateUser,
    profiles: userProfiles,
    plan: userPlan,
    isQuickSettingsEnabled,
    defaultWorkspace,
  } = useContext(userContext);

  const {
    id: workspaceId,
    me,
    updateWorkspace,
    members,
  } = useContext(workspaceContext);

  const {
    maxProfiles: planMaxProfiles,
    name: namePlan,
  } = userPlan;

  useEffect(() => {
    const locale = localStorage.getItem('@gologin:selected_language');
    setLanguage(locale || i18n.language);
  }, []);

  const confirmEmail = (): void => {
    confirmEmailAddress();
    message.success(translation('personalArea.overview.AlertEmailConfirm'));
    history.push('/');
  };

  const changeLanguage = (code: string): void => {
    i18n.changeLanguage(code).then(() => {
      setLanguage(i18n.language);
      moment.locale(i18n.language);
      localStorage.setItem('@gologin:selected_language', i18n.language);
      const translationData = {
        language: i18n.language,
        closeAppTitle: translation('closeApp.title'),
        closeAppMessage: translation('closeApp.message'),
        closeAppBtnClose: translation('closeApp.btnClose'),
        closeAppBtnCancel: translation('closeApp.btnCancel'),
      };

      ipcRenderer && ipcRenderer.invoke('set-app-config', { language:  i18n.language });
      ipcRenderer && ipcRenderer.invoke('send-translation-data-to-electron', translationData);
    });
  };

  const onEmailChanged = (newEmail: string): void => {
    setIsEmailChangeModalOpened(false);

    if (me) {
      me.email = newEmail;
    }

    const newMembers = members.map(member => member.invitedByEmail === email ?
      { ...member, invitedByEmail: newEmail } :
      member,
    );

    updateWorkspace({ members: newMembers });
    updateUser({
      isEmailConfirmed: false,
      email: newEmail,
    });
  };

  const renderEmail = (emailToRender: string): JSX.Element|string => {
    const EMAIL_LENGTH = 22;

    if (emailToRender.length > EMAIL_LENGTH) {
      return (
        <Tooltip title={emailToRender}>
          {emailToRender.slice(0, EMAIL_LENGTH)}
          ...
        </Tooltip>
      );
    }

    return emailToRender;
  };

  let profilesText = `${namePlan} (${userProfiles}/${planMaxProfiles} ${translation('base.profiles')})`;
  if (!planMaxProfiles) {
    profilesText = `${namePlan} (${userProfiles} ${translation('base.profiles')})`;
  }

  const openCheckoutPage = (): void => {
    sendActionAnalytics('clicked buy plan', { actionInfo: 'overview' });
    openWebsitePricing({ workspaceId, isQuickSettingsEnabled });
  };

  const changeDefaultWorkspace = (newWorkspaceId: string): void => {
    updateDefaultWorkspace(newWorkspaceId);
    updateUser({
      defaultWorkspace: newWorkspaceId,
    });
  };

  const switchDesign = async (state: boolean): Promise<void> => {
    if (state === isNewDesign) {
      return;
    }

    setIsNewDesign(state);
    const features: IUpdateFeatures = {
      header: state,
      drawer: state,
    };

    await updateFeatures(features);
    setFeatures(features);
  };

  const renderDefaultWorkspace = (): JSX.Element|null => {
    if (!NEW_FEATURES.workspaces) {
      return null;
    }

    return (
      <>
        <OverviewRowTitle style={{ fontSize: 12, color: 'var(--767676-personal-area)' }}>
          {translation('personalArea.overview.defaultWorkspace')}
        </OverviewRowTitle>
        <SelectWorkspace
          userId={_id || ''}
          selectedWorkspaceId={defaultWorkspace || ''}
          setSelectedWorkspaceId={changeDefaultWorkspace}
          isOwnerOnly={true}
          placeholder={translation('personalArea.overview.selectDefaultWorkspace')}
          showAddWorkspace={true}
          needFoldersList={false}
        />
      </>
    );
  };

  return (
    <TabWrapper tabKey='overview'>
      <PageTitle>
        {translation('personalArea.overview.title')}
      </PageTitle>
      <OverviewGrid>
        <OverviewRowTitle>
          {translation('personalArea.overview.subTitleEmail')}
        </OverviewRowTitle>
        <div style={{ display: 'flex' }}>
          {renderEmail(email || '')}
          <AccountEmail>
            {!isEmailConfirmed && (
              <Tooltip title={translation('personalArea.overview.TooltipConfirmEmail')}>
                <Icon type='info-circle' style={{ color: 'var(--FF0000-personal-area-overview)', marginLeft: 4 }} />
              </Tooltip>
            )}
            <Tooltip title={translation('personalArea.overview.editBtnText')}>
              <Icon type='edit' style={{ marginLeft: 5 }} onClick={(): void => setIsEmailChangeModalOpened(true)} />
            </Tooltip>
          </AccountEmail>
        </div>
        {!isEmailConfirmed ? (
          <AlignedButton
            type='default'
            onClick={confirmEmail}
          >
            {translation('personalArea.overview.confirmSubmitBtnText')}
          </AlignedButton>
        ) : <div />}
        {!NEW_FEATURES.workspaces ? (
          <>
            <OverviewRowTitle>
              {translation('personalArea.overview.subTitlePlan')}
            </OverviewRowTitle>
            <span>
              {profilesText}
            </span>
            <Button
              type='primary'
              onClick={openCheckoutPage}
            >
              {translation('personalArea.overview.upgradeSubmitBtnText')}
            </Button>
          </>
        ) : null}
        <OverviewRowTitle>
          {translation('base.password')}
        </OverviewRowTitle>
        <div />
        <AlignedButton
          type='default'
          onClick={(): void => setIsPasswordChangeModalOpened(true)}
        >
          {translation('changePassInSettings.changePassword')}
        </AlignedButton>
        <OverviewRowTitle>
          {translation('base.selectLanguage')}
        </OverviewRowTitle>
        <div />
        <Select
          style={{
            marginLeft: 0,
            width: 180,
          }}
          value={language}
          onSelect={(code: string): void => {
            changeLanguage(code);
          }}
        >
          <Select.Option value='en'>
            English
          </Select.Option>
          <Select.Option value='ru-RU'>
            Русский
          </Select.Option>
          <Select.Option value='fr'>
            Français
          </Select.Option>
          <Select.Option value='pt'>
            Português
          </Select.Option>
          <Select.Option value='vi-VN'>
            Tiếng việt
          </Select.Option>
          <Select.Option value='zh-Hans-CN'>
            中文
          </Select.Option>
          <Select.Option value='ja'>
            日本
          </Select.Option>
        </Select>
        <SubtitleTransfer>
          {translation('personalArea.transfer.optionText')}
        </SubtitleTransfer>
        <div />
        <Button
          type='default'
          onClick={(): void => setIsShowImportDolphinModal(true)}
        >
          {translation('personalArea.transfer.buttonStart')}
        </Button>
        {NEW_FEATURES.header ? (
          <>
            <OverviewRowTitle>
              {translation('personalArea.overview.interfaceTheme')}
            </OverviewRowTitle>
            <div />
            <Select
              style={{ marginLeft: 0, width: 100 }}
              value={theme}
              onSelect={switchTheme}
            >
              <Select.Option value='system'>
                {translation('personalArea.overview.system')}
              </Select.Option>
              <Select.Option value='light'>
                {translation('personalArea.overview.light')}
              </Select.Option>
              <Select.Option value='dark'>
                {translation('personalArea.overview.dark')}
              </Select.Option>
            </Select>
          </>
        ) : null}
        <OverviewRowTitle>
          {translation('personalArea.overview.switchDesignTitle')}
        </OverviewRowTitle>
        <div />
        <SelectCustom
          style={{ marginLeft: 0 }}
          value={isNewDesign ? 1 : 0}
          onSelect={(value): Promise<void> => switchDesign(!!value)}
        >
          <Select.Option value={1}>
            {translation('personalArea.overview.switchDesignNew')}
          </Select.Option>
          <Select.Option value={0}>
            {translation('personalArea.overview.switchDesignOld')}
          </Select.Option>
        </SelectCustom>
      </OverviewGrid>
      <Modal
        visible={isEmailChangeModalOpened}
        onCancel={(): void => setIsEmailChangeModalOpened(false)}
        title={translation('personalArea.changeEmailModal.title')}
        footer={null}
      >
        <ChangeEmailComponent
          initialEmail={email}
          onConfirm={onEmailChanged}
        />
      </Modal>
      <Modal
        visible={isPasswordChangeModalOpened}
        onCancel={(): void => setIsPasswordChangeModalOpened(false)}
        title={translation('changePassInSettings.changePassword')}
        footer={null}
      >
        <PasswordChangeForm
          onSubmit={(): void => setIsPasswordChangeModalOpened(false)}
        />
      </Modal>
      <ImportDolphinProfilesModal
        visible={isShowImportDolphinModal}
        onClose={() => setIsShowImportDolphinModal(false)}
      />
      {renderDefaultWorkspace()}
    </TabWrapper>
  );
};

export default OverviewPage;
