import styled from '@emotion/styled';
import { Input } from 'antd';

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const InputText = styled(Input)`
  width: 350px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const SubTitle = styled('span')`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
  width: auto;
`;

export const SwitchTitle = styled(SubTitle)`
  margin-left: 10px;
  margin-top: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

export const SwitchContainer = styled('div')`
  margin-left: 10px;
  margin-top: 2px;
  white-space: nowrap;
`;
