import moment from 'moment';

import { IProxy } from './components/interfaces/IProxy';
import { IProxyCredentialsNew } from './components/interfaces/proxy-credentials-new.interface';
import { IProxyDataToSave } from './interfaces/proxy-data-to-save.interface';
import { getProxyStatusParams } from './proxy-helpers';
import { handleProxiesLimitError } from './utils/handle-proxies-limit-error';
import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { setProxyStatuses } from '../../state/proxies.context/api';
import { updateProxyItem, upsertOneProxy } from '../../state/proxy/proxy-list.atom';

export const createProxyRequest = async (proxy: IProxyCredentialsNew) => {
  const workspaceId = getCurrentWorkspaceId();
  const url = new URL(`${API_BASE_URL}/proxy`);
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  const body = JSON.stringify(proxy);

  return http(url.toString(), { method: 'POST', body }).then((response: { body: IProxyCredentialsNew }) => response.body);
};

export const createProxiesRequest = async (proxies: IProxyCredentialsNew[]) => {
  const workspaceId = getCurrentWorkspaceId();
  const url = new URL(`${API_BASE_URL}/proxy/add_proxies`);
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  const body = JSON.stringify({ proxies });

  return http(url.toString(), { method: 'POST', body }).then((response: { body: IProxyCredentialsNew[] }) => response.body);
};

export const updateProxyRequest = async (id: string, proxy: IProxyCredentialsNew, profileId = ''): Promise<IProxy> => {
  const url = new URL(`${API_BASE_URL}/proxy/${id}`);
  url.searchParams.append('profileId', profileId);
  const body = JSON.stringify(proxy);

  return http(url.toString(), { method: 'PUT', body }).then((response: { body: IProxy }) => response.body);
};

export const deleteProxiesRequest = async (proxies: string[]): Promise<IProxy> => {
  const body = JSON.stringify({ proxies });

  return http(`${API_BASE_URL}/proxy/delete_proxies`, { method: 'POST', body }).then((response: { body: IProxy }) => response.body);
};

export const getProxyRequest = async (id: string): Promise<IProxy> => http(`${API_BASE_URL}/proxy/${id}`)
  .then((response: { body: IProxy }) => response.body);

export const saveProxy = async (params: IProxyDataToSave, profileId = ''): Promise<IProxy> => {
  const { proxyId, proxyItem, checkProxy } = params;
  let newProxy: IProxy;
  if (proxyId) {
    newProxy = await updateProxyRequest(proxyId, proxyItem, profileId);
  } else {
    newProxy = await createProxyRequest(proxyItem).catch(error => handleProxiesLimitError(error));
  }

  upsertOneProxy(newProxy);
  if (!checkProxy) {
    return newProxy;
  }

  setTimeout(async (): Promise<void> => {
    const statusParams = await getProxyStatusParams(newProxy);

    updateProxyItem({
      _id: newProxy._id,
      ...statusParams,
      checkDate: moment().toDate(),
      checkInProgress: false,
    });

    await setProxyStatuses([{
      _id: newProxy._id,
      status: statusParams.status,
      country: statusParams.country || newProxy.country,
      city: statusParams.city || newProxy.city,
      error: statusParams.error || null,
      checkDate: moment().unix(),
      lastIp: statusParams.origin,
    }]).catch(() => null);
  }, 0);

  return newProxy;
};
