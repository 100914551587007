import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import React, { FC } from 'react';

import colors from './colors';
import { NEW_FEATURES } from '../../../../../feature-toggle';
import { blackMain } from '../../../ui/style-templates/colors';
import { ITag } from '../interfaces/tag.interface';

const TagItemBody = styled('div')<{
  fontColor: string;
  borderColor: string;
  isAddMode?: boolean;
  inAllProfiles?: boolean;
  newStyle: boolean;
}>`
  border: 1px solid;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  letter-spacing: 0.2px;

  ${(props): SerializedStyles => css`
    background-color: ${props.borderColor};
    border-color: ${props.borderColor};

    ${(props.isAddMode) ? `
      padding: 1px 23px 0px 6px;
      border-radius: 12px;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 21px;
      height: 24px;
    ` : `
      padding: 0 8px;
      border-radius: 10px;
      font-size: 12px;
    `}
  `}

  ${(props): SerializedStyles => props.newStyle ? css`
    color: var(--2B2B31-tags-list);
    opacity: ${props.inAllProfiles ? 1 : 0.35};
  ` : css`
    color: var(--222222-tags-list);
    opacity: ${props.inAllProfiles ? 0.7 : 0.35};
  `}

  &:hover {
    opacity: 1;
  }
`;

const TagItemContainer = styled('article')<{ newStyle: boolean }>`
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  position: relative;

  ${(props): SerializedStyles|null => props.newStyle ? null : css`
    margin-bottom: 4px;
    margin-right: 4px;
  `}
`;

const RemoveBtn = styled('div')<{  borderColor: string; fontColor: string; inAllProfiles?: boolean }>`
  position: absolute;
  width: 17px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 10;
  cursor: pointer;

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  &:before,
  &:after {
    position: absolute;
    left: 5px;
    top: 7px;
    content: ' ';
    height: 10px;
    width: 1px;
    /*transition: background-color 0.2s ease-out;*/

    ${(props): any => css`
      background-color: ${(props.inAllProfiles === false) ? 'var(--22222259-tags-list);' : 'var(--222222B3-tags-list);'}
    `}
  }
  &:hover::before,
  &:hover::after {
    background-color: var(--222222-tags-list);
  }
`;

const DEFAULT_TAG_COLOR = 'lightgrey';

interface ITagListItemProps {
  tag: ITag;
  handleClick: (e: any) => void;
  handleMouseEnter?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleMouseLeave?: () => void;
  isAddMode?: boolean;
  removeTag?: () => Promise<void>;
  innerRef?: any;
}

const TagListItem: FC<ITagListItemProps> = (props) => {
  const {
    tag,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    isAddMode,
    removeTag,
    innerRef,
  } = props;

  const { title, color, inAllProfiles = true } = tag;

  const tagColors = colors[color] || colors[DEFAULT_TAG_COLOR];

  const onRemoveClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    removeTag && removeTag();
  };

  return (
    <TagItemContainer newStyle={!!NEW_FEATURES.header}>
      <TagItemBody
        newStyle={!!NEW_FEATURES.header}
        fontColor={tagColors.fontColor}
        borderColor={tagColors.borderColor}
        inAllProfiles={inAllProfiles}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isAddMode={isAddMode}
        ref={innerRef}
      >
        {title}
      </TagItemBody>
      {(isAddMode) ? <RemoveBtn
        onClick={onRemoveClick}
        borderColor={tagColors.borderColor}
        fontColor={tagColors.fontColor}
        inAllProfiles={inAllProfiles}
      /> : null}
    </TagItemContainer>
  );
};

export default TagListItem;
