import { PROFILE_SETTINGS_PAGE_DEFAULT_PROXY } from '../../newProfile/reducer';
import { IProxy } from '../components/interfaces/IProxy';
import { getAreProxiesEqual } from '../proxy-helpers';

export const getIsDefaultProxyInProfileSettings = (proxy: IProxy|null): boolean =>
  !!proxy && !getAreProxiesEqual(proxy, { ...PROFILE_SETTINGS_PAGE_DEFAULT_PROXY, mode: 'http' });

export const getIsValidProxy = (proxy: IProxy|null): boolean => !!proxy && proxy.mode !== 'none';

export const getIsProxySelected = (proxy: IProxy|null): boolean =>
  getIsValidProxy(proxy) && getIsDefaultProxyInProfileSettings(proxy);
