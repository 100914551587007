import styled from '@emotion/styled';

export const SavedProxySearchWrapper = styled.div`
  width: 100%;
  margin: 1px 9px 0 18px;
  display: flex;
  align-items: center;
`;

export const StatusDotWrapper = styled.div`
  margin: 0 9px 0 -24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
