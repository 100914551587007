import styled from '@emotion/styled/macro';
import { Modal } from 'antd';

import { textFont } from '../../../../../ui/style-templates';

export const ModalConfirmCustom = styled(Modal)`
  .ant-modal-content {
    width: 554px;
    height: 208px;
    border-radius: 16px;
    box-shadow: 0px 1px 32px 0px rgba(0, 0, 0, 0.12), 0px 12px 24px 0px rgba(0, 0, 0, 0.12), 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
`;

export const ModalTitle = styled('div')`
  color: var(--36363D);
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ModalDescription = styled('div')`
  color: var(--36363D);
  text-align: center;
  padding: 24px;
  ${textFont()};
`;

export const ModalButton = styled('div')`
  display: inline-flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--98989F);
  height: 32px;
  cursor: pointer;
`;

export const ModalButtonConfirm = styled(ModalButton)`
  background-color: var(--00997A);
  color: var(--FFFFFF);
  border: 1px solid var(--00997A);
`;

export const ModalCloseContainer = styled('div')`
  display: flex;
  justify-content: end;
`;

export const ExampleContainer = styled('div')`
  color: var(--36363D);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PopupTitle = styled('div')`
  font-family: 'Roboto';
  padding: 16px 16px 0;
  font-size: 14px;
  color: var(--36363D);
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const PopupDescription = styled('div')`
  font-size: 12px;
  color: var(--696973);
  padding: 0 16px 8px;
  font-weight: 400;
  font-family: 'Roboto';
  line-height: 16px;
  letter-spacing: 0.2px;
`;

export const SpintaxTitle = styled(PopupTitle)`
  padding: 0;
  font-weight: 400;
  cursor: pointer;
`;

export const SpintaxDescription = styled(PopupDescription)`
  color: var(--81818A);
  padding: 0;
`;

export const InputProfileName = styled('input')`
  display: flex;
  width: 226px;
  justify-content: space-between;
  align-items: center;

  background: inherit;
  outline: none;
  letter-spacing: 0.25px;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
`;

export const ContainerExample = styled('div')`
  width: 100%;
  height: 100%;
  padding: 0 16px 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`;

export const ContainerSpintax = styled('div')`
  width: 100%;
  height: 100%;
  padding: 8px 16px;
`;
