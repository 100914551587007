import { getDefaultStore } from 'jotai';
import React, { FC, useMemo, useRef } from 'react';
import { CellMeasurer, CellMeasurerCache, List, ListRowRenderer, WindowScroller } from 'react-virtualized';

import GologinTableGroupHeader from './gologin-table-group-header';
import GologinTableHeader from './gologin-table-header';
import { IResizeProps } from './gologin-table-header-column';
import GologinTableRow from './gologin-table-row';
import GologinTableRowSelectionCell from './row-selection-cell';
import { GologinTableContainer, GologinTableItemList } from './styles';
import overscanIndicesGetter from './virtual-overscan-indices-getter';
import { IProfilesTableColumn } from '../../features/quickProfiles/components/interfaces/app-settings.interface';
import useElementWidth from '../../hooks/use-element-width.hook';
import { IBasicTableEntity, isBasicTableEntityGroupHeader } from '../../state/profiles-list.atom';
import { handleToggleProfileIsSelectedByIdx, useIsProfileSelected } from '../../state/profiles-table-selected-ids.atom';

import 'react-virtualized/styles.css';

const cellMeasurerCache = new CellMeasurerCache({
  defaultHeight: 52,
  fixedWidth: true,
});

interface IGologinTableRowSelection {
  columnWidth: number;
}

type IDataArrayItem = IBasicTableEntity;

interface IGologinTable extends IResizeProps {
  dataArray: IDataArrayItem[];
  columns: IProfilesTableColumn[];
  rowSelection: IGologinTableRowSelection;
}

const GologinTable: FC<IGologinTable> = (props) => {
  const { dataArray, columns, rowSelection, ...columnProps } = props;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerWidth = useElementWidth(tableContainerRef);

  const selectionColumn: IProfilesTableColumn = useMemo<IProfilesTableColumn>(() => ({
    colName: 'selection' as any,
    render: (_: unknown, record: IBasicTableEntity) => (
      <GologinTableRowSelectionCell
        useIsSelected={useIsProfileSelected}
        rowKey={record.idx}
        onToggle={handleToggleProfileIsSelectedByIdx}
      />
    ),
    width: rowSelection.columnWidth,
    label: '',
    minWidth: rowSelection.columnWidth,
    visible: true,
    className: 'new-selection-cell',
  }), [rowSelection]);

  const shownColumns = useMemo(() => [
    selectionColumn,
    ...columns.map((col, idx) => ({ ...col, originalColumnIndex: idx })),
  ].filter((column) => column.visible),
  [selectionColumn, columns]);

  const totalWidth = useMemo(() => {
    const columnsWidth = shownColumns.reduce<number>((acc, column) => acc + column.width, 0);

    return Math.max(columnsWidth, tableContainerWidth);
  }, [shownColumns, tableContainerWidth]);

  const rowRenderer: ListRowRenderer = (rowProps) => {
    const { index, key, style, parent } = rowProps;
    const dataItem = dataArray[index];
    if (!dataItem) {
      return null;
    }

    if (isBasicTableEntityGroupHeader(dataItem)) {
      return (
        <CellMeasurer
          key={key}
          cache={cellMeasurerCache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
        >
          {({ measure }): JSX.Element => (
            <GologinTableGroupHeader
              groupHeaderItem={dataItem}
              measure={measure}
              style={style}
              rowIdx={index}
            />
          )}
        </CellMeasurer>
      );
    }

    return (
      <CellMeasurer
        key={key}
        cache={cellMeasurerCache}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        {({ measure }): JSX.Element => (
          <GologinTableRow
            columns={shownColumns}
            dataItem={dataItem}
            useIsSelected={useIsProfileSelected}
            index={index}
            key={key}
            style={style}
            measure={measure}
          />
        )}
      </CellMeasurer>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rootElem = document.getElementById('root')!;

  return (
    <GologinTableContainer ref={tableContainerRef}>
      <GologinTableHeader columns={shownColumns} {...columnProps} />
      <GologinTableItemList
        role='treegrid'
      >
        <WindowScroller scrollElement={rootElem}>
          {({ height, isScrolling, onChildScroll, scrollTop }: any): JSX.Element => (
            <List
              tabIndex={null}
              autoHeight={true}
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              width={totalWidth}
              rowCount={dataArray.length}
              deferredMeasurementCache={cellMeasurerCache}
              rowHeight={cellMeasurerCache.rowHeight}
              rowRenderer={rowRenderer}
              scrollTop={scrollTop}
              overscanRowCount={8}
              overscanIndicesGetter={overscanIndicesGetter}
            />
          )}
        </WindowScroller>
      </GologinTableItemList>
    </GologinTableContainer>
  );
};

export default React.memo(GologinTable);
