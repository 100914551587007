import styled from '@emotion/styled';
import { Button, Dropdown, Icon, Input, Menu, message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userContext } from '../../../state';
import Modal from '../../../ui/Modal';
import { removeFolderInvite, requestFolderInvites, shareFolder, updateInviteFolderRole } from '../api';

const Col = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconSharing = styled (Icon)`
  font-size: 9px;
  margin-top: 2px;
  opacity: 0.6;
  color: var(--000000-folders-page-share-folder-modal);
  margin-left: 3px;
`;

const IconUser = styled (Icon)`
  font-size: 20px;
  opacity: 0.6;
  color: var(--000000-folders-page-share-folder-modal);
`;

const TextSharing = styled('span')`
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000-folders-page-share-folder-modal);
  opacity: 0.6;
  white-space: nowrap;
  justify-content: flex-end;
`;

const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`;

const UserIconContainer = styled('div')`
  margin-right: 5px;
`;

const CloseIconContainer = styled('div')`
  margin-left: 5px;
  cursor: pointer;
  min-width: 19px;
`;

const UserContainer = styled('div')`
  margin: 10px 0;
`;

const ButtonSharing = styled(Button)`
  font-family: Roboto;
  font-size: 11px;
  line-height: 13px;
  color: var(--00B86D-folders-page-share-folder-modal);
  border-color: var(--00B86D-folders-page-share-folder-modal);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 0.7;
  }
`;

const TextEmail = styled('div')`
  font-size: 12px;
  margin-left: 10px;
`;

declare interface IUser {
  email: string;
  role: string;
  inviteId: string;
}

declare interface IShareModal {
  shareFolderVisible: boolean;
  shareFolder?: string;
  foldersIdsToShare: [string];
  shareMultipleFolders?: boolean;
  onClose: () => void;
}

const ShareFolderModal: FC<IShareModal> = (props) => {
  const [loadingInvites, changeInvitesLoading] = useState(true);
  const [invites, setInvites] = useState<any[]>([]);
  const [selectedRole, changeSelectedRole] = useState<'guest'|'redactor'|'administrator'>('guest');
  const [insertedEmail, changeInsertedEmail] = useState<string>('');

  const { t: translation } = useTranslation();

  const getInvites = async () => {
    if (!props.shareFolder) {
      return;
    }

    const profiles = await requestFolderInvites(props.foldersIdsToShare[0]);
    setInvites(profiles);
    changeInvitesLoading(false);
  };

  useEffect(() => {
    getInvites();
  }, [props.shareFolder, props.foldersIdsToShare]);

  const removeInvite = async (id: string) => {
    changeInvitesLoading(true);
    await removeFolderInvite(id)
      .finally(() => changeInvitesLoading(false));
    getInvites();
  };

  const changeUserRole = async (id: string, role: 'administrator'|'redactor'|'guest') => {
    return updateInviteFolderRole(id, role)
      .finally(() => changeInvitesLoading(false));
  };

  const sendInvite = async () => {
    const sharedEmails = invites.find((i: any) => i.to.email === insertedEmail);

    if (!insertedEmail) {
      message.error(translation('errorMessages.insertUserEmail'));

      return;
    }

    if (sharedEmails) {
      message.error(translation('errorMessages.userAlreadyMember'));

      return;
    }

    changeInvitesLoading(true);

    await new Promise((resolve) => {
      resolve(shareFolder(props.foldersIdsToShare, selectedRole, insertedEmail));
    })
      .then(() => {
        changeInsertedEmail('');
        if (props.shareMultipleFolders) {
          props.onClose();
        } else {
          return getInvites();
        }
      })
      .finally(() => {
        changeInvitesLoading(false);
      });
  };

  const User: FC<IUser> = (userProps) => {
    const { email } = useContext(userContext);
    const showIcon = () => {
      if(userProps.role === 'owner'){
        return null;
      }

      return <IconSharing type="down" style={{ paddingTop:2 }} />;
    };

    const [GetRole, changeGetRole] = useState(userProps.role);

    const changeInviteText = () => {
      if (GetRole === 'guest') {
        return translation('roles.profile.guest.subtitle');
      }

      if (GetRole === 'owner') {
        return translation('roles.profile.owner.subtitle');
      }

      if (GetRole === 'redactor') {
        return translation('roles.profile.editor.subtitle');
      }

      return translation('roles.profile.administrator.subtitle');
    };

    const menuChangeRole = (
      <Menu>
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(userProps.inviteId, e.key).catch(() => {
              changeGetRole(GetRole);
            });
          }}
          key='guest'
        >
          {translation('roles.profile.guest.title')}
        </Menu.Item >
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(userProps.inviteId, e.key).catch(() => {
              changeGetRole(GetRole);
            });
          }}
          key='redactor'
        >
          {translation('roles.profile.editor.title')}
        </Menu.Item>
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(userProps.inviteId, e.key).catch(() => {
              changeGetRole(GetRole);
            });
          }}
          key='administrator'
        >
          {translation('roles.profile.administrator.title')}
        </Menu.Item>
      </Menu>
    );

    return (
      <UserContainer>
        <RowSpaceBetween>
          <Row>
            <UserIconContainer>
              <IconUser type='user' />
            </UserIconContainer>
            <TextEmail>
              {userProps.email}
            </TextEmail>
          </Row>
          <Dropdown overlay={menuChangeRole} trigger={['click']} disabled={userProps.role === 'owner' || userProps.email === email}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
                {changeInviteText()}
              </TextSharing>
              {showIcon()}
            </span>
          </Dropdown>
          <CloseIconContainer>
            {(userProps.role !== 'owner' && userProps.email !== email) && <Icon type='delete' onClick={() => removeInvite(userProps.inviteId)} />}
          </CloseIconContainer>
        </RowSpaceBetween>
      </UserContainer>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='guest'>
        {translation('roles.profile.guest.title')}
      </Menu.Item >
      <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='redactor'>
        {translation('roles.profile.editor.title')}
      </Menu.Item>
      <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='administrator'>
        {translation('roles.profile.administrator.title')}
      </Menu.Item>
    </Menu>
  );

  const renderInviteText = () => {
    if (selectedRole === 'guest') {
      return translation('roles.profile.guest.title');
    }

    if (selectedRole === 'redactor') {
      return translation('roles.profile.editor.title');
    }

    return translation('roles.profile.administrator.title');
  };

  const getModalTitle = () => {
    if (props.shareFolder) {
      return `${translation('base.share')} "${props.shareFolder.slice(0, 28)}${props.shareFolder.length > 28 ? '...' : ''}" ${translation('shareModal.folder')}`;
    }

    return translation('shareModal.shareMultiple');
  };

  return (
    <Modal
      destroyOnClose={true}
      visible={props.shareFolderVisible}
      onClose={() => props.onClose()}
      title={getModalTitle()}
      footer={null}
    >
      <Col>
        <RowSpaceBetween style={{ marginBottom: 16 }}>
          <Input
            style={{ height: 30 }}
            type='email'
            placeholder={translation('shareModal.form.emailInput.placeholder')}
            value={insertedEmail}
            onChange={(e: any) => changeInsertedEmail(e.target.value)}
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
                {renderInviteText()}
              </TextSharing>
              <div style={{ marginTop: -3 }}>
                <IconSharing type='down' />
              </div>
            </span>
          </Dropdown>
          <ButtonSharing style={{ marginLeft: 10 }} onClick={sendInvite}>
            {translation('shareModal.sendInvite')}
          </ButtonSharing>
        </RowSpaceBetween>
        {loadingInvites ? <div style={{ width: '100%', fontSize: 24, textAlign: 'center' }}>
          <Icon type='loading' />
        </div> : null}
        {invites.map(i => (
          <>
            <User
              email={i.to.email}
              role={i.role}
              inviteId={i.id}
            />
          </>
        ))}
      </Col>
    </Modal>
  );
};

export default ShareFolderModal;
