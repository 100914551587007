import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { hideProxyCheckTooltip } from './proxy-check/proxy-check-tooltip.atom';
import { proxyListAtom } from './proxy-list.atom';
import { closeProxyManager, IProxyManagerOpenParams, openProxyManager, ProxySelectorLocation } from './proxy-manager-modal-status.atom';
import { E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT } from '../../../common/constants/analytics';
import { sendActionAnalytics } from '../../features/common/api';
import { PROFILE_SETTINGS_PAGE_DEFAULT_PROXY } from '../../features/newProfile/reducer';
import { IProxy } from '../../features/proxy/components/interfaces/IProxy';
import { getAreProxiesEqual } from '../../features/proxy/proxy-helpers';

export type IProfileSettingsFormProxy = Omit<IProxy, 'id'|'_id'|'profilesCount'>;

const profileSettingsProxyIdAtom = atom<string | null>(null);
const profileSettingsPreviousProxyAtom = atom<IProxy | null>(null);
const profileSettingsProxyFormAtom = atom<IProfileSettingsFormProxy | null>(null);

export const onProfileSettingsProxySelect = (proxyId: string | null): void => {
  closeProxyManager();

  if (proxyId) {
    sendActionAnalytics(E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT.selectedSavedProxy);
  } else {
    hideProxyCheckTooltip();
  }

  setProfileSettingsProxyId(proxyId);
};

export const getProfileSettingsProxyId = (): string | null => getDefaultStore().get(profileSettingsProxyIdAtom);
export const useProfileSettingsProxyId = (): string | null => useAtomValue(profileSettingsProxyIdAtom);
export const setProfileSettingsProxyId = (newProxyId: string | null): void => {
  getDefaultStore().set(profileSettingsProxyIdAtom, newProxyId);
};

export const getProfileSettingsPreviousProxy = (): IProxy | null => getDefaultStore().get(profileSettingsPreviousProxyAtom);
export const setProfileSettingsPreviousProxy = (newPreviousProxy: IProxy | null): void =>
  getDefaultStore().set(profileSettingsPreviousProxyAtom, newPreviousProxy);

export const getProfileSettingsProxyForm = (): IProfileSettingsFormProxy | null => getDefaultStore().get(profileSettingsProxyFormAtom);
export const useProfileSettingsProxyForm = (): IProfileSettingsFormProxy | null => useAtomValue(profileSettingsProxyFormAtom);
export const setProfileSettingsProxyForm = (newProxyForm: IProfileSettingsFormProxy | null): void => getDefaultStore().set(profileSettingsProxyFormAtom, newProxyForm);

const proxyInProfileSettingsAtom = atom<IProxy|null>(null);

const proxyInProfileSettingsSyncedAtom = atom<IProxy|null>((get): IProxy|null => {
  const proxyInProfileSettings: IProxy|null = get(proxyInProfileSettingsAtom);
  if (
    !proxyInProfileSettings ||
    proxyInProfileSettings.mode === 'none' ||
    getAreProxiesEqual({ ...PROFILE_SETTINGS_PAGE_DEFAULT_PROXY, mode: 'http' })
  ) {
    return null;
  }

  const listedProxy = get(proxyListAtom).find((proxyInList): boolean => {
    if (proxyInProfileSettings.id && proxyInList._id === proxyInProfileSettings.id) {
      return true;
    }

    return getAreProxiesEqual(proxyInList, proxyInProfileSettings);
  });

  if (!listedProxy) {
    return null;
  }

  return { ...proxyInProfileSettings, ...listedProxy };
});

// TODO: the most mental atom the world is going to see (remove, when new profile settings page is introduced)
// This enables `handleNoProxyClick` to clear the new proxy form on the old profile settings page
const clearNewProxyFormCallbackAtom = atom<{ callback: () => void }>({ callback: () => void null });

const setProxyInProfileSettings = (proxy: IProxy|null): void => getDefaultStore().set(proxyInProfileSettingsAtom, proxy);

export const getProxyInProfileSettings = (): IProxy|null => getDefaultStore().get(proxyInProfileSettingsSyncedAtom);
export const useProxyInProfileSettings = (): IProxy|null => useAtomValue(proxyInProfileSettingsSyncedAtom);

export const selectProxyInProfileSettings = (proxy: IProxy): void => setProxyInProfileSettings(proxy);
export const unselectProxyInProfileSettings = (): void => setProxyInProfileSettings(null);

export const openProxyManagerInProfileSettings = ({
  containerElement,
  currentProxy = null,
  currentProfileId = null,
}: Pick<IProxyManagerOpenParams, 'containerElement'|'currentProxy'|'currentProfileId'>): void => openProxyManager({
  containerElement,
  currentProxy,
  currentProfileId,
  proxySelectorLocation: ProxySelectorLocation.profileSettingsPage,
  modalView: 'proxy-list',
  handleProxySelect: onProfileSettingsProxySelect,
});

export const resetProxyInProfileSettings = (): void => {
  closeProxyManager();
  unselectProxyInProfileSettings();
};

export const setClearNewProxyFormCallback = (callback: () => void): void => getDefaultStore().set(clearNewProxyFormCallbackAtom, { callback });
export const getClearNewProxyFormCallback = (): { callback: () => void } => getDefaultStore().get(clearNewProxyFormCallbackAtom);
