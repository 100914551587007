import styled from '@emotion/styled';
import { ModernModal } from '../../../../ui/modern-modal';

export const InviteModal = styled(ModernModal)`
  .ant-modal-header {
    padding-bottom: 6px;
  }

  .ant-modal-title {
    letter-spacing: 0;
  }

  .ant-modal-close{
    top: 4px;
    right: 4px;
  }
`;
