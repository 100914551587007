import { message } from 'antd';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { userContext, workspaceContext } from '../../../../state';
import { getAccessibleFolderName } from '../../../../utils/check-folders-to-add-profile';
import { openWebsitePricing } from '../../../../utils/open-site';

interface IPermissionCheckData {
  redirectToPricing(): void;
  setIsShowChangePlanMessage: Dispatch<SetStateAction<boolean>>;
  checkPermission(): boolean;
  isShowChangePlanMessage: boolean;
}

export const usePermissionCheck = (): IPermissionCheckData => {
  const [isShowChangePlanMessage, setIsShowChangePlanMessage] = useState<boolean>(false);
  const {
    hasTrial,
    isQuickSettingsEnabled,
    hasSuccessPayment,
  } = useContext(userContext);

  const {
    id: workspaceId,
    folders: workspaceFolders,
    me,
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
    permissions,
  } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const redirectToPricing = (): void => {
    setIsShowChangePlanMessage(false);
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
    });
  };

  const checkIsCreateProfilesAvailable = (): boolean => {
    if (permissions.multipleCreateProfiles) {
      return true;
    }

    const folderToCreate = getAccessibleFolderName({
      folders: workspaceFolders,
      permission: 'multipleCreateProfiles',
      limitedAccess: !!me?.limitedAccess,
      hasFirstFolder: true,
      hasSelectedFolder: true,
    });

    return !!folderToCreate;
  };

  const checkPermission = (): boolean => {
    if (NEW_FEATURES.workspaces) {
      if (workspaceIsUnpaid || workspaceIsTrial) {
        setIsShowChangePlanMessage(true);

        return false;
      }

      const isCreateMultipleProfilesAvailable = checkIsCreateProfilesAvailable();

      if (isCreateMultipleProfilesAvailable) {
        return true;
      }

      message.error(translation('notifications.error.permissionWorkspace'));

      return false;
    }

    const isDisabled = !hasSuccessPayment || hasTrial;

    if (isDisabled) {
      setIsShowChangePlanMessage(true);
    }

    return !isDisabled;
  };

  return {
    redirectToPricing,
    setIsShowChangePlanMessage,
    checkPermission,
    isShowChangePlanMessage,
  };
};
