import { makeStyles } from '@material-ui/core';
import React, { FC, useRef } from 'react';

import { IDropdownPopover } from './interfaces';
import { PopoverCustom } from './styles';
import {
  IconMeatballs,
} from '../../../ui/gologin-header/icons';
import { IconMeatballsContainer } from '../components/styles';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}));

export const DropdownPopover: FC<IDropdownPopover> = (props) => {
  const {
    children,
    isTriggerAlwaysShown,
    onClose,
    onOpen,
    isPopoverOpen,
  } = props;

  const popoverAnchor = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  return (
    <>
      <IconMeatballsContainer
        ref={popoverAnchor}
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        isVisible={isTriggerAlwaysShown || isPopoverOpen}
      >
        <IconMeatballs
          styleType={isPopoverOpen ? 'grayWithBackground' : 'activeGrayWithBackground'}
          iconColor={isPopoverOpen ? 'var(--2B2B31-header)' : 'var(--98989F-header)'}
          iconHoveredColor='var(--2B2B31-header)'
          padding={2}
        />
      </IconMeatballsContainer>
      <PopoverCustom
        open={isPopoverOpen}
        anchorEl={popoverAnchor.current}
        onClose={onClose}
        transitionDuration={300}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ zIndex: 900 }}
        disableRestoreFocus={true}
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        PaperProps={{ onMouseEnter: onOpen, onMouseLeave: onClose }}
      >
        {children}
      </PopoverCustom>
    </>
  );
};
