import moment from 'moment';

import { IProfileProxy } from './components/interfaces/IProfileProxy';
import { IProxy } from './components/interfaces/IProxy';
import { PROXY_MODES_WITH_ACTIONS_ALLOWED } from './constants/settings';
import { hideProxyCheckTooltip } from '../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import {
  getClearNewProxyFormCallback,
  getProxyInProfileSettings,
  resetProxyInProfileSettings,
} from '../../state/proxy/proxy-in-profile-settings.atom';
import { closeProxyManager } from '../../state/proxy/proxy-manager-modal-status.atom';
import { unselectProfileProxy } from '../../state/proxy/proxy-operations/select-proxies.operations';
import { updateSelectedProxies, toggleIsProxySelected } from '../../state/proxy/selected-proxies.atom';
import { getSharedProxyInfo } from '../../state/proxy/shared-proxy-info.atom';
import { getCheckProxy } from '../../utils/check-proxy';

export interface IProxyMode {
  value: string;
  label: string;
}

export const proxyModes: IProxyMode[] = [{
  value: 'http',
  label: 'http',
}, {
  value: 'socks5',
  label: 'socks 5',
}, {
  value: 'socks4',
  label: 'socks 4',
}];

// TODO: replace with `src/app/features/proxy/utils/proxy-title.ts` later
export const getProxyTitle = (proxy?: IProxy): string => {
  let title = '';
  if (proxy) {
    if (proxy.host) {
      if (proxy.customName) {
        title = proxy.customName;
      } else {
        title = proxy.host;
      }
    } else if (proxy.mode === 'gologin') {
      title = `Free ${proxy.autoProxyRegion?.toLocaleUpperCase()}`;
    } else if (proxy.mode === 'tor') {
      title = `Tor ${proxy.torProxyRegion?.toLocaleUpperCase()}`;
    }
  }

  return title;
};

export const theme = {
  spacing: (multiplier: number): number => {
    const spaceSize = 4;

    return spaceSize * multiplier;
  },
  colors: {
    primary: 'var(--00A987-proxy-manager)',
  },
};

export const getAreProxiesEqual = (proxy: IProxy, currentProxy?: IProxy, shouldCompareCustomNames = false): boolean => !!(
  currentProxy &&
  proxy.mode === currentProxy.mode &&
  (proxy.autoProxyRegion || 'us') === (currentProxy.autoProxyRegion || 'us') &&
  (proxy.torProxyRegion || 'us') === (currentProxy.torProxyRegion || 'us') &&
  proxy.host === currentProxy.host &&
  proxy.port === currentProxy.port &&
  proxy.username === currentProxy.username &&
  proxy.password === currentProxy.password &&
  (shouldCompareCustomNames ? proxy.customName === currentProxy.customName : true)
);

export interface IStatusParams {
  status: boolean;
  country?: string;
  city?: string;
  error?: string;
  origin?: string;
  timezone?: string;
  languages?: string;
}

export const getProxyStatusParams = async (proxy: IProxy): Promise<IStatusParams> => {
  const statusResponse = await getCheckProxy({ proxy });

  const statusParams: IStatusParams = {
    status: statusResponse?.status === 'success',
  };

  if (statusResponse?.country) {
    statusParams.country = `${statusResponse.country}`.toUpperCase();
  }

  if (statusResponse?.city) {
    statusParams.city = statusResponse.city;
  }

  if (statusResponse?.error) {
    statusParams.error = statusResponse.error;
  }

  if (statusResponse?.origin) {
    statusParams.origin = statusResponse.origin;
  }

  if (statusResponse?.timezone) {
    statusParams.timezone = statusResponse.timezone;
  }

  if (statusResponse?.languages) {
    statusParams.languages = statusResponse.languages;
  }

  return statusParams;
};

export const getProxyStatus = (proxy: IProxy): string | undefined => {
  const isTorOrFree = ['gologin', 'tor'].includes(proxy.mode);
  let status;
  if (isTorOrFree) {
    status = 'success';
  } else if (proxy.checkDate) {
    const daysDiff = (moment().unix() - moment(proxy.checkDate).unix()) / (60 * 60 * 24);

    if (!proxy.host || proxy.status === false) {
      status = 'fail';
    } else if (daysDiff < 1 && proxy.status === true) {
      status = 'success';
    }
  }

  return status;
};

export const copyProxies = (proxies: IProxy[]): void => {
  const lines = proxies.map((proxy): string => {
    const { host, port, username, password, customName, changeIpUrl } = proxy;
    let textClipboard = '';

    if (host !== '' && port) {
      textClipboard = host + ':' + port;
    }

    if (username !== '') {
      textClipboard = username + '@' + host + ':' + port;
    }

    if (password !== '') {
      textClipboard = username + ':' + password + '@' + host + ':' + port;
    }

    if (changeIpUrl) {
      textClipboard = `${textClipboard}[${changeIpUrl}]`;
    }

    if (customName) {
      textClipboard = `${textClipboard}:${customName}`;
    }

    return textClipboard;
  });

  navigator.clipboard.writeText(lines.join('\n'));
};

interface ISelectProxiesWithShift {
  shiftKey: boolean;
  selectedProxies: string[];
  filteredProxies: IProxy[];
  proxy: IProxy;
  lastSelectedProxy: string;
}

export const selectProxiesWithShift = ({
  shiftKey,
  selectedProxies,
  filteredProxies,
  proxy,
  lastSelectedProxy,
}: ISelectProxiesWithShift): void => {
  if (!shiftKey) {
    updateSelectedProxies({
      lastSelectedProxy: proxy._id,
    });

    return toggleIsProxySelected(proxy._id);
  }

  const lastSelectedProxyIndex = filteredProxies.findIndex(proxyEl => proxyEl._id === lastSelectedProxy);
  const currentProxyIndex = filteredProxies.findIndex(proxyEl => proxyEl._id === proxy._id);
  const startIndex = Math.min(lastSelectedProxyIndex, currentProxyIndex);
  const endIndex = Math.max(lastSelectedProxyIndex, currentProxyIndex);

  const newCheckedProxies = filteredProxies.slice(startIndex, endIndex + 1).map((proxyEl) => proxyEl._id);

  let newSelectedProxies;

  if (selectedProxies.includes(proxy._id)) {
    newSelectedProxies = selectedProxies.filter((val) => !newCheckedProxies.includes(val));
  } else {
    newSelectedProxies = [...new Set(newCheckedProxies.concat(selectedProxies))];
  }

  updateSelectedProxies({
    selectedProxies: newSelectedProxies,
    lastSelectedProxy: proxy._id,
  });
};

export const EMPTY_PROXY: IProfileProxy = {
  _id: '',
  id: '',
  mode: 'none',
  username: '',
  password: '',
  host: '',
  port: 80,
};

export const handleNoProxyClick = (event: React.SyntheticEvent, profileId: string|null = ''): void => {
  event.preventDefault();
  event.stopPropagation();

  if (!profileId) {
    return;
  }

  unselectProfileProxy(profileId);
  closeProxyManager();
  hideProxyCheckTooltip();
};

export const getIsProxyEditable = (proxy: IProxy): boolean => {
  const proxiesShared = getSharedProxyInfo();
  const isProxyShared = proxiesShared.some(({ _id }) => _id === proxy._id);

  return PROXY_MODES_WITH_ACTIONS_ALLOWED.includes(proxy.mode) && !(proxy.isInvisible || isProxyShared);
};
