import { Icon, Modal } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';

import {
  ConfirmBlock,
  ConfirmContentText,
  ConfirmTitleText,
  transferCancelButtonProps,
  transferOkButtonProps,
} from './styles';

export const transferConfirm = (
  profilesCount: number,
  sendTransfer: () => void,
  translation: (key: string, options?: any) => TFunction<any>,
): void => {
  const content = (): JSX.Element => (
    <ConfirmBlock>
      <ConfirmContentText>
        {translation('transferModal.suttitle.firstText', { count: profilesCount })}
      </ConfirmContentText>
      <ConfirmContentText style={{ marginTop: 16 }}>
        {translation('transferModal.subtitle.secondText')}
      </ConfirmContentText>
    </ConfirmBlock>
  );

  const confirmText = translation('transferModal.title.text', { count: profilesCount });
  const modal = Modal.confirm({
    title: (
      <ConfirmTitleText>
        {confirmText}
      </ConfirmTitleText>
    ),
    icon: <Icon type='question-circle' />,
    content: content(),
    okText: `${translation('modals.automationParameters.cancel')}`,
    cancelText: `${translation('transferModal.button.transfer')}`,
    okButtonProps: transferOkButtonProps,
    cancelButtonProps: transferCancelButtonProps,
    onCancel: () => {
      modal.destroy();
      sendTransfer();
    },
    keyboard: false,
    maskClosable: false,
    width: 416,
  });
};
