import styled from '@emotion/styled';
import { Button, Dropdown, Icon, Input, Menu, message, Modal, Tooltip } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userContext } from '../../../state';
import { updateProfilesTableSelectedIds } from '../../../state/profiles-table-selected-ids.atom';
import {
  removeProfileInvite,
  requestProfileInvites,
  shareMultipleProfilesToOneUser,
  shareProfile,
  updateInviteRole,
} from '../../quickProfiles/api';

const Col = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 150px;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconSharing = styled (Icon)`
  font-size: 9px;
  margin-top: 2px;
  opacity: 0.6; 
  color: var(--000000);
  margin-left: 3px;
`;

const IconUser = styled (Icon)`
  font-size: 20px;
  opacity: 0.6; 
  color: var(--000000);
`;

const TextSharing = styled('span')`
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  color: var(--000000);
  opacity: 0.6;
  white-space: nowrap;
  justify-content: flex-end;
`;

const RowSpaceBetween = styled(Row)`
  justify-content: space-between;
`;

const UserIconContainer = styled('div')`
  margin-right: 5px;
`;

const CloseIconContainer = styled('div')`
  margin-left: 5px;
  cursor: pointer;
  min-width: 19px;
`;

const UserContainer = styled('div')`
  margin: 10px 0;
`;

const ButtonSharing = styled(Button)`
  font-family: Roboto;
  font-size: 11px;
  line-height: 13px;
  color: var(--00B86D);
  border-color: var(--00B86D);
  width: 85px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover{
    opacity: 0.7
  }
`;

const TextEmail = styled('div')`
  font-size: 12px;
  margin-left: 10px;
`;

declare interface IUser {
  email: string;
  role: string;
  inviteId: string;
}

declare interface IShareModal {
  visible: boolean;
  onClose: () => void;
  profile?: any;
  inviteId?: string;
  profilesIds?: string[];
  shareMultipleProfiles?: boolean;
  afterClose?: () => void;
}

const ShareModal: FC<IShareModal> = (shareProps) => {
  const [loadingInvites, changeInvitesLoading] = useState(true);
  const [invites, setInvites] = useState<any[]>([]);
  const [selectedRole, changeSelectedRole] = useState<'guest'|'redactor'|'administrator'|'owner'>('guest');

  const { hasTrial, plan } = useContext(userContext);

  // Куда он inserted? я могу его вписать в десяток мест
  const [insertedEmail, changeInsertedEmail] = useState<string>('');

  const { t: translation } = useTranslation();

  const getInvites = async () => {
    if (shareProps.shareMultipleProfiles) {
      changeInvitesLoading(false);

      return;
    }

    const profiles = await requestProfileInvites(shareProps.profile.id);
    setInvites(profiles);
    changeInvitesLoading(false);
  };

  useEffect(() => {
    getInvites();
  }, [shareProps.profile]);

  const removeInvite = async (id: string) => {
    changeInvitesLoading(true);
    await removeProfileInvite(id)
      .then(() => updateProfilesTableSelectedIds([shareProps.profile.id]))
      .finally(() => changeInvitesLoading(false));
    getInvites();
  };

  const changeUserRole = async (id: string, role: 'administrator'|'redactor'|'guest') => {
    await updateInviteRole(id, role)
      .finally(() => changeInvitesLoading(false));
  };

  const sendInvite = async () => {
    if (!insertedEmail) {
      message.error(translation('errorMessages.insertUserEmail'));

      return;
    }

    changeInvitesLoading(true);

    await new Promise((resolve) => {
      if (shareProps.shareMultipleProfiles) {
        resolve(shareMultipleProfilesToOneUser(shareProps.profilesIds, selectedRole, insertedEmail));

        return;
      }

      resolve(shareProfile(shareProps.profile.id, selectedRole, insertedEmail));
    })
      .then(() => {
        if (shareProps.shareMultipleProfiles) {
          shareProps.onClose();
        }

        changeInsertedEmail('');
      })
      .finally(() => {
        changeInvitesLoading(false);
      });

    if (!shareProps.shareMultipleProfiles) {
      getInvites();
    }
  };

  const User: FC<IUser> = (props) => {
    const [GetRole, changeGetRole] = useState(props.role);

    const { email } = useContext(userContext);

    const showIcon = () => {
      if (props.role === 'owner') {

      } else {
        return (
          <IconSharing type='down' style={{ paddingTop:2 }} />
        );
      }
    };

    const changeInviteText = () => {
      if (GetRole === 'guest') {
        return translation('roles.profile.guest.subtitle');
      }

      if (GetRole === 'owner') {
        return translation('roles.profile.owner.subtitle');
      }

      if (GetRole === 'redactor') {
        return translation('roles.profile.editor.subtitle');
      }

      return translation('roles.profile.administrator.subtitle');
    };

    const menuChangeRole = (
      <Menu>
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(props.inviteId, e.key);
            getInvites();
          }}
          key='guest'
        >
          {translation('roles.profile.guest.title')}
        </Menu.Item >
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(props.inviteId, e.key);
            getInvites();
          }}
          key='redactor'
        >
          {translation('roles.profile.editor.title')}
        </Menu.Item>
        <Menu.Item
          onClick={(e: any) => {
            changeGetRole(e.key);
            changeUserRole(props.inviteId, e.key);
            getInvites();
          }}
          key='administrator'
        >
          {translation('roles.profile.administrator.title')}
        </Menu.Item>
      </Menu>
    );

    return (
      <UserContainer>
        <RowSpaceBetween>
          <Row>
            <UserIconContainer>
              <IconUser type='user' />
            </UserIconContainer>
            <TextEmail>
              {props.email}
            </TextEmail>
          </Row>
          <Dropdown overlay={menuChangeRole} trigger={['click']} disabled={props.role === 'owner' || props.email === email}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
                {changeInviteText()}
              </TextSharing>
              {showIcon()}
            </span>
          </Dropdown>
          <CloseIconContainer>
            {(props.role !== 'owner' && props.email !== email) && <Icon type='delete' onClick={() => removeInvite(props.inviteId)} />}
          </CloseIconContainer>
        </RowSpaceBetween>
      </UserContainer>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='guest'>
        {translation('roles.profile.guest.title')}
      </Menu.Item >
      <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='redactor'>
        {translation('roles.profile.editor.title')}
      </Menu.Item>
      <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='administrator'>
        {translation('roles.profile.administrator.title')}
      </Menu.Item>
      {(!shareProps.shareMultipleProfiles && !hasTrial && plan?.priceForMonth) ? <Menu.Item onClick={(e: any) => changeSelectedRole(e.key)} key='owner'>
        <Tooltip title={translation('roles.profile.owner.help')}>
          {translation('roles.profile.owner.title')}
        </Tooltip>
      </Menu.Item> : null}
    </Menu>
  );

  const renderInviteText = () => {
    if (selectedRole === 'guest') {
      return translation('roles.profile.guest.title');
    }

    if (selectedRole === 'redactor') {
      return translation('roles.profile.editor.title');
    }

    if (selectedRole === 'owner') {
      return translation('roles.profile.owner.title');
    }

    return translation('roles.profile.administrator.title');
  };

  return (
    <Modal
      destroyOnClose={true}
      visible={shareProps.visible}
      onCancel={() => shareProps.onClose()}
      afterClose={shareProps.afterClose}
      title={translation('shareModal.title')}
      footer={null}
    >
      <Col>
        <RowSpaceBetween style={{ marginBottom: 16 }}>
          <Input
            style={{
              height: 30,
            }}
            type='email'
            placeholder={translation('shareModal.form.emailInput.placeholder')}
            value={insertedEmail}
            onChange={(e: any) => changeInsertedEmail(e.target.value)}
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <span style={{ cursor: 'pointer', display: 'flex' }}>
              <TextSharing style={{ marginLeft: 15, marginTop: 2 }}>
                {renderInviteText()}
              </TextSharing>
              <div style={{ marginTop: -3 }}>
                <IconSharing type='down' />
              </div>
            </span>
          </Dropdown>
          <ButtonSharing style={{ marginLeft: 10 }} onClick={sendInvite}>
            {translation('shareModal.form.submitBtnText')}
          </ButtonSharing>
        </RowSpaceBetween>
        {loadingInvites ? <div style={{ width: '100%', fontSize: 24, textAlign: 'center' }}>
          <Icon type='loading' />
        </div> : null}
        {invites.map(i => (
          <>
            <User
              email={i.to.email}
              role={i.role}
              inviteId={i.id}
              key={i.id}
            />
          </>
        ))}
      </Col>
    </Modal>
  );
};

export default ShareModal;
