import { IProfile } from '../../../interfaces';
import { upsertProfileRunStatuses } from '../../../state/profile-run-statuses.atom';
import { sendUserEventToServer } from '../../../utils/send-analytics-to-server';
import { sendActionAnalytics, startContainer } from '../../common/api';

const isElectron = !!window.require;

interface ILaunchProfileWebProfile {
  id: IProfile['id'];
}

export const launchProfileWeb = async (rowInfo: ILaunchProfileWebProfile): Promise<void> => {
  sendUserEventToServer('openedBrowser');
  if (isElectron) {
    sendActionAnalytics('launched profile in cloud from app');
  }

  const { id: profileId } = rowInfo;
  upsertProfileRunStatuses([{ id: profileId, status: 'profileStatuses.downloadingProfile' }]);
  const localWorkspace = localStorage.getItem('workspace');
  let localWorkspaceId = '';
  if (localWorkspace) {
    try {
      localWorkspaceId = JSON.parse(localWorkspace).id;
    } catch (error) {
      localWorkspaceId = '';
    }
  }

  const { remoteOrbitaUrl, status, statusMessage = '' } = await startContainer(profileId, localWorkspaceId);
  upsertProfileRunStatuses([{
    id: profileId,
    status,
    statusMessage,
    isWeb: true,
    remoteOrbitaUrl,
  }]);
};
