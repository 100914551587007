import { IGeolocationProxy, IGeolocationProxyPrices } from '../../../interfaces';

export const GEOLOCATION_PROXY_TYPE = ['mobile', 'resident', 'dataCenter'] as const;
export type ProxyType = typeof GEOLOCATION_PROXY_TYPE[number];

export const proxyTrafficData: Record<keyof IGeolocationProxyPrices, keyof IGeolocationProxy> = {
  mobile: 'mobileTrafficData',
  resident: 'residentialTrafficData',
  dataCenter: 'dataCenterTrafficData',
};

export const proxyTypeToRender: Record<keyof IGeolocationProxyPrices, string> = {
  mobile: 'Mobile',
  resident: 'Residential',
  dataCenter: 'Data center',
};
