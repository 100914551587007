import styled from '@emotion/styled/macro';

export const GologinPopoverDivider = styled('div')`
  height: 0.5px;
  min-height: 0.5px;
  width: 100%;

  margin: 4.75px 0;
  background: var(--EBEBEB-header);
`;
