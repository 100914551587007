import styled from '@emotion/styled';

export const ProxySavedSelectorPlaceholder = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
`;

export const ProxySavedSelectorProxy = styled.div`
  width: 100%;
  margin: 0 9px 0 -14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProxySavedSelectorUnselectButton = styled.div`
  cursor: pointer;
`;
