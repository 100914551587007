import { IDefaultProxy } from '../components/interfaces/IDefaultProxy';
import { IProxy } from '../components/interfaces/IProxy';
import { IProxyCredentialsNew } from '../components/interfaces/proxy-credentials-new.interface';

export const PROXIES_LIST_DEFAULT = [];
export const PROXIES_HAS_MORE_DEFAULT = false;
export const PROXIES_ITERATIONS_LIMIT = 750;

export const PROXY_EMPTY: IProxyCredentialsNew = {
  mode: 'none',
  username: '',
  password: '',
  host: '',
  port: 80,
} as const;

export const FORM_VALUES_DEFAULT: IProxy = {
  _id: '',
  customName: '',
  mode: 'http',
  host: '',
  port: '',
  username: '',
  password: '',
  changeIpUrl: '',
};

type ProxyDefaultCustomField = 'mode'|'autoProxyRegion'|'torProxyRegion'|'country';

const PROXY_DEFAULT_BASE: Omit<IDefaultProxy, ProxyDefaultCustomField> = {
  port: 80,
  host: '',
  username: '',
  password: '',
  status: true,
};

const PROXIES_DEFAULT_CUSTOM_FIELDS: Pick<IDefaultProxy, ProxyDefaultCustomField>[] = [{
  mode: 'gologin',
  autoProxyRegion: 'us',
  country: 'US',
}, {
  mode: 'gologin',
  autoProxyRegion: 'ca',
  country: 'CA',
}, {
  mode: 'gologin',
  autoProxyRegion: 'uk',
  country: 'UK',
}, {
  mode: 'gologin',
  autoProxyRegion: 'de',
  country: 'DE',
}, {
  mode: 'gologin',
  autoProxyRegion: 'in',
  country: 'IN',
}, {
  mode: 'tor',
  torProxyRegion: 'us',
  country: 'US',
}, {
  mode: 'tor',
  torProxyRegion: 'uk',
  country: 'UK',
}, {
  mode: 'tor',
  torProxyRegion: 'de',
  country: 'DE',
}, {
  mode: 'tor',
  torProxyRegion: 'fr',
  country: 'FR',
}, {
  mode: 'tor',
  torProxyRegion: 'eu',
  country: 'EU',
}];

export const PROXIES_DEFAULT: IDefaultProxy[] = PROXIES_DEFAULT_CUSTOM_FIELDS.map(proxy => ({ ...PROXY_DEFAULT_BASE, ...proxy }));
