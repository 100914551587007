import React, { FC, useCallback } from 'react';

import { RawProfileRow } from './styles';
import { useProfileSortable } from './use-profile-sortable';
import { onProfilesTableRowClick } from '../../../state/profiles-table.commands';

interface IProfileRow {
  style: React.CSSProperties;
  key: any;
  tableRowIndex: number;
  className?: string;
}

const ProfileRow: FC<IProfileRow> = (props) => {
  const {
    tableRowIndex,
    children,
    key,
    style,
    className,
  } = props;

  let baseClassName = 'gologin-table-row';
  if (className) {
    baseClassName += ' ' + className;
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    relativeDropPosition,
  } = useProfileSortable(tableRowIndex);

  const handleOnClick = useCallback((): void => {
    onProfilesTableRowClick(tableRowIndex);
  }, [tableRowIndex]);

  return (
    <RawProfileRow
      key={key}
      ref={setNodeRef}
      className={baseClassName}
      data-row-key={tableRowIndex}
      {...attributes}
      {...listeners}
      dropPosition={relativeDropPosition}
      isDragging={isDragging}
      style={style}
      role='row'
      // eslint-disable-next-line no-undefined
      tabIndex={undefined}
      onClick={handleOnClick}
    >
      {children}
    </RawProfileRow>
  );
};

export default ProfileRow;
