import styled from '@emotion/styled';
import { Button, Input } from 'antd';

export const ActionButton = styled(Button)`
  font-size: 14px;
  color: var(--767676);
  border-color: var(--CDCDCD);
  min-width: 75px;
  ${props => {
    if (props.className === 'installed') {
      return `
        background-color: var(--FFFFFF) !important;
        border: none;
      `;
    }
  }}
`;

export const LoadingDiv = styled.div`
  width: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`;

export const ExtensionsButton = styled(Button)`
  width: 186px;
  height: 27px;
  font-size: 13px;
  color: var(--FFFFFF-extensions);
  background-color: var(--00A987-extensions);
  :focus {
    background-color: var(--00A987-extensions);
  }
  :hover {
    background-color: var(--00B86D);
    color: var(--FFFFFF-extensions);
  }
`;

export const ExtensionsListTable = styled.div`
  margin-top: 12px;
`;

export const ExtensionTabTable = styled.div`
  margin-top: 16px;
  width: 352px;
`;

export const StyledInput = styled(Input)`
  margin-left: -12px;
  padding-left: 7px;
  outline: none;
  height: 24px;
  input, input:focus {
    border: none;
    box-shadow: none;
  }
  input::placeholder {
    margin-left: 10px;
    color: var(--767676);
  }
`;

export const ExtensionContainerAll = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const ExtensionContainerInstalled = styled.div`
  display: flex;
  margin-top: 20px;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const IconDiv = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExtensionName = styled.span`
  margin: 0 10px;
  width: auto;
`;

export const InstalledBlockTitle = styled.div`
  color: var(--767676);
  margin-bottom: 20px;
  height: 24px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 2px;
  line-height: 12px;
`;

export const FlagContainer = styled.div`
  cursor: pointer;
  margin-right: 18.5px;
  width: 8px;
  height: 15px;
  :hover {
    path {
      stroke: var(--00A987) !important;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsContainerInstalled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ExtensionNameContainer = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
`;

export const InstalledExtensions = styled.div`
  padding-left: 24px;
  overflow-x: hidden;
  height: 470px;
  width: 51.5%;
  scrollbar-gutter: stable;
`;

export const ModalExtensionsList = styled.div`
  border-right: 1px solid var(--CDCDCD);
  height: 470px;
  overflow-x: hidden;
  width: 48.5%;
  scrollbar-gutter: stable;
`;

export const SearchRow = styled.div`
  display: flex;
  height: 24px;
`;

export const UploadIcon = styled.div`
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
  :hover {
    color: var(--00B86D);
  }
`;

export const UploadSuccessIcon = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-size: 22px;
  color: var(--00B86D);
`;

export const ErrorContainer = styled.div`
  height: 80px;
  background-color: var(--FFE9E9);
  padding: 10px 16px 8px 16px;
  border-radius: 10px;
  margin-bottom: -10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ErrorContainerMain = styled.div`
  display: flex;
`;

export const ErrorLogo = styled.div`
  width: 16px;
  height: 16px;
  color: var(--F5222D);
  margin-right: 16px;
`;

export const ErrorText = styled.div`
  font-size: 14px;
`;

export const ErrorContainerButtons = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LearnMoreButton = styled.div`
  margin-right: 16px;
  cursor: pointer;
  color: var(--767676);
`;

export const RetryButton = styled.div`
  cursor: pointer;
`;

export const CurrentExtensionsWindow = styled.div`
  margin-top: 12px;
  padding-bottom: 80px;
`;

export const DisabledUploadIcon = styled.div`
  margin-right: 10px;
  font-size: 22px;
  opacity: 0.5;
`;

export const LoaderDiv = styled.div`
  width: 100%;
  text-align: center;
`;
