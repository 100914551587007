import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import { RowKey } from './types';
import { GologinCheckbox } from '../gologin-checkbox';

interface GologinTableRowSelectionCell {
  rowKey: RowKey;
  onToggle: (rowKey: RowKey, newIsChecked: boolean, isShiftActive: boolean) => void;
  useIsSelected: (rowKey: RowKey) => boolean;
}

const GologinTableRowSelectionCell: FC<GologinTableRowSelectionCell> = (props) => {
  const { rowKey, useIsSelected, onToggle } = props;

  const isSelected = useIsSelected(rowKey);

  const [shouldMount, setShouldMount] = useState<boolean>(isSelected);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const isShiftActive = !!event.nativeEvent.shiftKey;
    const isChecked = event?.target.checked;
    onToggle(rowKey, isChecked, isShiftActive);
  }, [rowKey, onToggle]);

  useEffect(() => {
    if (shouldMount) {
      return;
    }

    window.requestIdleCallback(
      () => setShouldMount(true),
      { timeout: 400 },
    );
  }, [shouldMount]);

  if (!shouldMount) {
    return null;
  }

  return (
    <GologinCheckbox
      hasPadding={true}
      onChange={onChange}
      checked={isSelected}
    />
  );
};

export default memo(GologinTableRowSelectionCell);
