import React, { FC, useContext } from 'react';

import { DrawerBackdropContainer } from './styles';
import { tagsContext } from '../../../state';
import { closeProxyManager, useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';

const DrawerBackdrop: FC = () => {
  const { currentProfileId } = useProxyManagerState();

  const {
    setProfileInEditTagsMode,
    profileInEditTagsMode,
  } = useContext(tagsContext);

  if (!(currentProfileId || profileInEditTagsMode)) {
    return null;
  }

  return (
    <DrawerBackdropContainer onClick={(): void => {
      closeProxyManager();
      setProfileInEditTagsMode(null);
    }} />
  );
};

export default DrawerBackdrop;
