import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const ProxyCheckboxWrapperEl = styled('div')<{ isNewStyle?: boolean }>`
  margin-right: 9px;
  width: 16px;
  height: 16px;
  ${({ isNewStyle }): SerializedStyles | null => !isNewStyle ? css`
    margin-top: -4px;
  ` : null}

  .ant-checkbox-wrapper {
    line-height: 1.5;
  }

  & .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
`;
