import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconProxyDatacenter: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_13494_220474)">
        <path d="M15.5 0.5H0.5V6.5H15.5V0.5Z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 3.5H3.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5 4C12.7761 4 13 3.77614 13 3.5C13 3.22386 12.7761 3 12.5 3C12.2239 3 12 3.22386 12 3.5C12 3.77614 12.2239 4 12.5 4Z" fill="#36363D"/>
        <path d="M15.5 9.5H0.5V15.5H15.5V9.5Z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.5 12.5H3.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.5 13C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12C12.2239 12 12 12.2239 12 12.5C12 12.7761 12.2239 13 12.5 13Z" fill="#36363D"/>
      </g>
      <defs>
        <clipPath id="clip0_13494_220474">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
