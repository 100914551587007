import styled from '@emotion/styled';
import { Input, Radio } from 'antd';

import { ModernModal } from '../../../../ui/modern-modal';
import { ButtonProps } from 'antd/lib/button';

export const RadioGroup = styled(Radio.Group)`
  width: 324px;
  margin: 20px 0;
  display: flex;
`;

export const RadioButton = styled(Radio.Button)`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  width: min-content;
  flex: 1;
  white-space: nowrap;
  padding: 0 8px;
`;

export const ErrorText = styled('span')`
  color: var(--FF5F5F-modals-transfer-profile);
  font: 400 10px/16px 'Roboto';
`;

export const InputEmail = styled(Input)`
  width: 324px;
  margin-top: 4px;
  padding-left: 7px;
  font-size: 12px;
`;

export const TransferModal = styled(ModernModal)`
  .ant-modal-close{
    top: 5px;
    right: 12px;
  }

  .ant-modal-close-x{
    font-size: 14px;
  }

  .ant-modal-title {
    font: normal 20px 'Roboto';
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--222222-modals-transfer-profile);
  }

  .ant-modal-header {
    padding: 20px 28px 0;

    border-radius: 16px;
    border: none;
  }

  .ant-modal-body {
    padding: 0 28px 28px;
  }

  .ant-modal-content {
    border-radius: 16px;
  }
`;

export const ConfirmTitleText = styled('p')`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 1px;
  margin-bottom: 0;

  color: var(--222222-modals-transfer-profile);
`;

export const ConfirmContentText = styled('p')`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: var(--767676-modals-transfer-profile);
`;

export const ConfirmBlock = styled('div')`
  line-height: 1.4;
  margin-left: 4px;
  margin-top: -4px;
  padding-bottom: 20px;
`;

export const transferOkButtonProps: ButtonProps = {
  type: 'primary',
  style: { padding: '0 13px', position: 'absolute', bottom: 23, right: 29 },
};

export const transferCancelButtonProps: ButtonProps = {
  type: 'default',
  style: { padding: '0px 11px 0 14px', position: 'absolute', bottom: 23, right: 110 },
};
