import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

export const handleProxiesLimitError = (
  { body }: { body: { message: string; currentCount: number; maxCount: number } },
): void => {
  if (!Object.hasOwn(body, 'maxCount')) {
    message.error(body.message);

    return;
  }

  const { message: errorMessage, currentCount, maxCount } = body;
  const error = <Trans i18nKey={errorMessage} values={{ currentCount, maxCount }} />;
  message.error(error);
};
