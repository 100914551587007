import { Button, Icon, Modal, Popconfirm, Table, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CookiesManager from './cookies-manager';
import { ICookie } from './interfaces/interfaces';
import { ButtonImport, TableProfile, ContainerNameCol, Row } from './styles/styles';
import { sendActionAnalytics } from '../../../common/api';
import { ICookiesListModal } from '../../../common/interfaces/cookies-list-modal.interface';
import { cleanCookies, getProfileCookies } from '../../../quickProfiles/api';

const MAX_COOKIE_NAME_LENGTH = 32;

declare interface ICookiesList {
  id: string;
  name: string;
  afterClose?: () => void;
  cookiesListModalVisible: boolean;
  toggleCookiesListModal: (options: ICookiesListModal) => void;
  onCookiesRequest?: (arg: unknown[]) => void;
}

const CookiesList: FC<ICookiesList> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookiesList, setCookiesList] = useState<unknown[]>([]);
  const [cookiesManagerModalVisible, setCookiesManagerModalVisible] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  const { id, name, afterClose, cookiesListModalVisible, toggleCookiesListModal, onCookiesRequest } = props;

  const getCookiesList = async (): Promise<void> => {
    if (!id || cookiesManagerModalVisible) {
      setCookiesList([]);
      setIsLoading(true);

      return;
    }

    let getCookiesListRequest: ICookie[] = await getProfileCookies(id);
    onCookiesRequest && onCookiesRequest(getCookiesListRequest);
    getCookiesListRequest = getCookiesListRequest.map((elem: ICookie, index: number) => ({ ...elem, key: index + 1 }));
    setCookiesList(getCookiesListRequest);
    setIsLoading(false);
  };

  useEffect(() => {
    getCookiesList().catch(() => null);
  }, [id, name, cookiesManagerModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortAlphabet = (left: string, right: string): number => {
    if (left > right) {
      return 1;
    }

    if (left < right) {
      return -1;
    }

    return 0;
  };

  const modalOpenImport = (): void => {
    toggleCookiesListModal({ visible: false, id, name });
    toggleCookiesManagerModal(true);
  };

  const removeCookies = async (): Promise<void> => {
    await cleanCookies(id);
    onCookiesRequest && onCookiesRequest([]);
    setCookiesList([]);
  };

  const showButtonClear = (): JSX.Element|null => {
    if (!cookiesList.length) {
      return null;
    }

    return (
      <Button
        type='danger'
        ghost={true}
        style={{
          backgroundColor: 'var(--B70000)',
          color: 'var(--B70000)',
          borderColor: 'var(--B70000)',
          height: 30,
          marginLeft: 20,
          fontSize: 12,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {translation('base.clear')}
      </Button>
    );
  };

  const showButtonImport = (): JSX.Element|null => {
    if (!cookiesList.length) {
      return null;
    }

    return (
      <ButtonImport
        onClick={(): void => modalOpenImport()}
        style={{
          width: 70,
          height: 30,
          paddingLeft: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {translation('base.import')}
      </ButtonImport>
    );
  };

  const showTable = (): JSX.Element => (
    <Button onClick={(): void => modalOpenImport()} type='primary'>
      {translation('cookieManagerModal.importCookiesBtn.label')}
    </Button>
  );

  const toggleCookiesManagerModal = (state: boolean): void => setCookiesManagerModalVisible(state);

  const getCookiesManager = (): JSX.Element => (
    <CookiesManager
      id={id}
      name={name}
      cookiesModalVisible={cookiesManagerModalVisible}
      toggleCookiesManagerModal={toggleCookiesManagerModal}
      toggleCookiesListModal={toggleCookiesListModal}
    />
  );

  const exportCookies = async (): Promise<void> => {
    sendActionAnalytics('exported cookies');
    const fullCookies = await getProfileCookies(id, false);
    const saveFileElement = document.createElement('a');
    const outputFile = new Blob(['\ufeff', JSON.stringify(fullCookies)], { type: 'text/plain' });

    saveFileElement.href = URL.createObjectURL(outputFile);
    saveFileElement.download = `Cookies_${name}.json`;

    saveFileElement.click();
  };

  const showButtonExport = (): JSX.Element|null => {
    if (!cookiesList.length) {
      return null;
    }

    return (
      <ButtonImport
        onClick={(): Promise<void> => exportCookies()}
        style={{
          width: 70,
          height: 30,
          paddingLeft: 13,
          marginLeft: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {translation('base.export')}
      </ButtonImport>
    );
  };

  const renderTitle = (value: string): JSX.Element|string => {
    if (value.length > MAX_COOKIE_NAME_LENGTH) {
      return (
        <Tooltip title={value}>
          {value.slice(0, MAX_COOKIE_NAME_LENGTH)}
          ...
        </Tooltip>
      );
    }

    return value;
  };

  const getContent = (): JSX.Element => (
    <div>
      <Row>
        {showButtonImport()}
        {showButtonExport()}
        <Popconfirm
          placement='top'
          title={translation('cookieManagerModal.clearCookiesBtn.confirmDialog.text')}
          onConfirm={removeCookies}
          okText={translation('base.yes')}
          cancelText={translation('base.no')}
        >
          {showButtonClear()}
        </Popconfirm>
      </Row>
      <TableProfile
        pagination={false}
        rowKey='key'
        dataSource={cookiesList}
        scroll={{ y: 500 }}
        locale={{ emptyText: showTable }}
      >
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                {translation('cookieManagerModal.cookiesTable.columns.name')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='name'
          key='name'
          sorter={(left: ICookie, right: ICookie): number => sortAlphabet(left.name, right.name)}
          render={renderTitle}
        />
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                URL
              </span>
            </ContainerNameCol>
          )}
          dataIndex='url'
          key='url'
          sorter={(left: ICookie, right: ICookie): number => sortAlphabet(left.url, right.url)}
        />
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                {translation('cookieManagerModal.cookiesTable.columns.value')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='value'
          key='value'
          sorter={(left: ICookie, right: ICookie): number => sortAlphabet(left.value, right.value)}
          render={renderTitle}
        />
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                {translation('cookieManagerModal.cookiesTable.columns.expiresAt')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='expirationDate'
          key='expirationDate'
          sorter={(left: ICookie, right: ICookie): number => left.expirationDate - right.expirationDate}
        />
      </TableProfile>
    </div>
  );

  return (
    <>
      <Modal
        visible={cookiesListModalVisible}
        onCancel={(): void => toggleCookiesListModal({ visible: false })}
        wrapClassName='cookies-list-modal'
        width={780}
        title={translation('cookieManagerModal.title')}
        afterClose={afterClose}
        footer={null}
      >
        {isLoading ?
          <div style={{ fontSize: 32 }}>
            <Icon type='loading' />
          </div> :
          getContent()}
      </Modal>
      {getCookiesManager()}
    </>
  );
};

export default CookiesList;
