import { ORDER_STEP } from './constants';
import { IProfile } from '../../interfaces';
import { IGroupHeader } from '../../interfaces/group-header.interface';

interface OrderedEntity {
  id: string;
  order: number;
  targetGroupId: string | null;
}

export type IProfileForOrderList = Pick<IProfile, 'id' | 'order'>;
export type IGroupHeaderForOrderList = Pick<IGroupHeader, 'id' | 'isGroupHeader'>;
export type IBasicEntity = IProfileForOrderList | IGroupHeaderForOrderList;

export const isGroupHeader = (
  dataItem: IBasicEntity,
): dataItem is IGroupHeaderForOrderList => (dataItem as IGroupHeaderForOrderList).isGroupHeader;

interface IGenerateOrderListOpts {
  basicEntities: IBasicEntity[];
  middleIndex: number;
  step?: number;
}

const generateOrderList = <T extends IBasicEntity>({
  basicEntities,
  middleIndex,
  step = ORDER_STEP,
}: IGenerateOrderListOpts): Array<T & OrderedEntity> => {
  const [firstEntity] = basicEntities;
  const middleEntity = basicEntities[middleIndex];
  if (isGroupHeader(middleEntity)) {
    // someone tried to drag and drop group, instead of profile
    // but drag and drop for groups in between profiles
    // does not currently make any sense
    // and therefore, this shouldn't normally happen
    throw new Error('Middle entity must be a profile');
  }

  let currGroupIdx = 0;
  let currGroupId: OrderedEntity['targetGroupId'] = firstEntity.id;
  if (!isGroupHeader(firstEntity)) {
    currGroupIdx = -1;
    currGroupId = null;
  }

  const { order: middleOrder } = middleEntity;

  return basicEntities.map<T & OrderedEntity>((entity, idx) => {
    const lastGroupId = currGroupId;
    const lastGroupIdx = currGroupIdx;
    if (!isGroupHeader(entity)) {
      return { ...entity, targetGroupId: currGroupId, order: entity.order };
    }

    currGroupId = entity.id;
    currGroupIdx = idx;

    if (idx < middleIndex) {
      const targetGroupId = lastGroupId;
      const profileIdx = idx - 1;
      if (profileIdx === lastGroupIdx) {
        return { ...entity, targetGroupId, order: middleOrder };
      }

      if (basicEntities[profileIdx]) {
        const profileEntity = basicEntities[profileIdx];
        if (isGroupHeader(profileEntity)) {
          // profileIdx is index of previous item
          // if that item was a group, it would've been in last group idx
          // and since we compare the two, this should be unreachable
          // unless you screwed it up
          throw new Error('Unexpected generateOrderList failure');
        }

        return { ...entity, targetGroupId, order: profileEntity.order - step };
      }

      // if checks above fail, it means this is first group
      // it should take order from next idx instead
      // because one can't move stuff above of it
    }

    const targetGroupId = entity.id;
    const nextEntity = basicEntities[idx + 1];
    if (nextEntity && !isGroupHeader(nextEntity)) {
      return { ...entity, targetGroupId, order: nextEntity.order + step };
    }

    return { ...entity, targetGroupId, order: middleOrder };
  });
};

export default generateOrderList;
