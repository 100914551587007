import React, { useEffect, useRef, useState } from 'react';

import { iconProps, PROXY_SAVED_SELECTOR_WIDTH } from './constants';
import SavedProxySearch from './saved-proxy-search';
import SelectedProxy from './selected-proxy';
import { ProxySavedSelectorWrapper } from './styles';
import { getIsValidProxy } from './utils';
import { E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT } from '../../../../common/constants/analytics';
import { IProxy } from '../../../interfaces';
import {
  openProxyManagerInProfileSettings,
  selectProxyInProfileSettings,
  setClearNewProxyFormCallback,
  useProfileSettingsProxyId,
} from '../../../state/proxy/proxy-in-profile-settings.atom';
import { filterProxyList, getProxyByArtificialProxyId, useProxyListProxyById } from '../../../state/proxy/proxy-list.atom';
import { useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { IconArrowDown } from '../../../ui/gologin-header/icons/icon-arrow-down';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../common/api';
import PageOverlay from '../../quickProfiles/components/page-overlay';
import { EMPTY_PROXY } from '../constants';
import ProxyManager from '../proxy-manager';

interface IProxySavedSelector {
  clearNewProxyForm: () => void;
  preselectedProxy?: IProxy;
  editingProfileId?: string;
}

const ProxySavedSelector: React.FC<IProxySavedSelector> = (props) => {
  const { clearNewProxyForm, preselectedProxy = null, editingProfileId = null } = props;
  const { containerElement } = useProxyManagerState();
  const profileSettingsProxyId = useProfileSettingsProxyId();
  let profileSettingsProxy = useProxyListProxyById(profileSettingsProxyId);
  if (!profileSettingsProxy && profileSettingsProxyId) {
    profileSettingsProxy = getProxyByArtificialProxyId(profileSettingsProxyId);
  }

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const proxyManagerTargetRef = useRef<HTMLButtonElement>(null);

  const isProxyAlreadySelected = getIsValidProxy(preselectedProxy);
  if (!profileSettingsProxy) {
    profileSettingsProxy = preselectedProxy;
  }

  useEffect(() => {
    setClearNewProxyFormCallback(clearNewProxyForm);
    if (isProxyAlreadySelected && preselectedProxy) {
      selectProxyInProfileSettings(preselectedProxy);
    }
  }, []);

  useEffect(() => {
    filterProxyList(searchValue, profileSettingsProxy);
  }, [searchValue, profileSettingsProxy]);

  useEffect(() => {
    if (!containerElement) {
      setIsSearching(false);
    }
  }, [containerElement]);

  const handleClickOpenProxyManager: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    if (!proxyManagerTargetRef?.current) {
      sendReactErrorToSentry({
        message: 'Target ref not found for proxy-manager in proxy-saved-selector',
        transactionName: 'target-ref-not-found-in-proxy-saved-selector',
      });

      return;
    }

    sendActionAnalytics(E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT.openedProxyManagerInProfileSettingsPage);
    openProxyManagerInProfileSettings({
      containerElement: proxyManagerTargetRef.current,
      currentProxy: profileSettingsProxy,
      currentProfileId: editingProfileId,
    });

    setIsSearching(true);
  };

  const renderSelectorContent = (): JSX.Element => {
    if (isSearching) {
      return (
        <SavedProxySearch
          proxy={profileSettingsProxy || EMPTY_PROXY}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selectorContainerElement={proxyManagerTargetRef}
          editingProfileId={editingProfileId}
        />
      );
    }

    return (
      <>
        <SelectedProxy
          editingProfileId={editingProfileId}
          proxyManagerTargetRef={proxyManagerTargetRef}
        />
        <div className='proxy-saved-selector-arrow-down'>
          <IconArrowDown {...iconProps} />
        </div>
      </>
    );
  };

  return (
    <>
      <ProxySavedSelectorWrapper
        onClick={handleClickOpenProxyManager}
        ref={proxyManagerTargetRef}
        width={`${PROXY_SAVED_SELECTOR_WIDTH}px`}
        disabled={false}
        selectStyled={true}
        isActive={!!containerElement}
        style={{ fontSize: 14 }}
      >
        {renderSelectorContent()}
      </ProxySavedSelectorWrapper>
      <PageOverlay />
      <ProxyManager />
    </>
  );
};

export default ProxySavedSelector;
