import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ItemRowActive, PriceText } from './styles';
import { IGeolocationProxyPrices } from '../../../../interfaces';
import { history } from '../../../../services';
import { getPaymentURL } from '../api';

const isElectron = !!window.require;

interface IMenuItems {
  price: IGeolocationProxyPrices;
  proxyType: keyof IGeolocationProxyPrices;
  count: number;
  onClose: () => void;
}

const PriceItem: FC<IMenuItems> = (props): JSX.Element => {
  const { price, proxyType, count, onClose } = props;

  const calculatePrice = (): string => {
    const proxyPrice = price[proxyType];

    return (proxyPrice * count / 100).toFixed(0);
  };

  const handleMenuClick = async (): Promise<void> => {
    onClose();

    const { checkoutUrl } = await getPaymentURL({
      dataGbCount: count,
      dataType: proxyType,
      path: window.location.hash,
    });

    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutUrl);

      return;
    }

    window.location.replace(checkoutUrl);
  };

  return (
    <ItemRowActive onClick={handleMenuClick}>
      {count}
      &nbsp;GB
      <PriceText>
        $
        {calculatePrice()}
      </PriceText>
    </ItemRowActive>
  );
};

export default PriceItem;

