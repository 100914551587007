import { LOCAL_STORAGE_GROUPS } from '../../../common/constants/local-storage';
import { IGroupHeader } from '../../interfaces/group-header.interface';
import { GroupField } from '../profiles-list.atom';

interface ILocalStorageGroupsState {
  field: GroupField;
  groupHeaders: Pick<IGroupHeader, 'id' | 'isOpen'>[];
}

export const saveGroupsToLocalStorage = (groupHeaders: IGroupHeader[]): void => {
  const field = groupHeaders[0]?.filter.type || null;
  if (!field) {
    // ignore implicit resets, should use reset action
    return;
  }

  const groupHeadersToSave: ILocalStorageGroupsState['groupHeaders'] = groupHeaders.map((groupHeader) => ({
    id: groupHeader.id,
    isOpen: groupHeader.isOpen,
  }));

  const toSave = JSON.stringify({ field, groupHeaders: groupHeadersToSave });
  localStorage.setItem(LOCAL_STORAGE_GROUPS, toSave);
};

export const loadGroupsFromLocalStorage = (): ILocalStorageGroupsState | null => {
  const localStorageStateStr = localStorage.getItem(LOCAL_STORAGE_GROUPS) || '';
  let groupsState: ILocalStorageGroupsState | null = null;
  try {
    groupsState = JSON.parse(localStorageStateStr);
  } catch (err) { /* empty */ }

  if (!groupsState) {
    return null;
  }

  return groupsState;
};

export const resetLocalStorageGroups = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_GROUPS);
};
