import React, { FC } from 'react';

import ProxySelector from '../../../../features/proxy/proxy-selector';
import { IProfile } from '../../../../interfaces';
import { useProfilesSettingsProfile } from '../../../../state/profiles-settings-atom';

interface IProxyComponent {
  localProxySelectorLocation: string;
}

export const Proxy: FC<IProxyComponent> = (props) => {
  const { localProxySelectorLocation } = props;
  const profileInfo = useProfilesSettingsProfile() || {} as Partial<IProfile>;
  const { id: profileId, proxy, proxyEnabled, role } = profileInfo;

  return (
    <div style={{ cursor: 'pointer' }}>
      <ProxySelector
        proxy={proxy}
        proxyEnabled={!!proxyEnabled}
        profileId={profileId}
        isInDrawer={true}
        isSharedProxy={role !== 'owner'}
        localProxySelectorLocation={localProxySelectorLocation}
      />
    </div>
  );
};
