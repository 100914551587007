import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { theme } from '../../proxy-helpers';

export const ProxyPlaceholder = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const ProxyPlaceholderText = styled('div')<{ isInDrawer: boolean; newStyle: boolean; newDrawer: boolean }>`
  color: var(--767676-proxy-manager-placeholder);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  height: 100%;
  letter-spacing: 0.25px;
  min-width: 146px;
  padding-left: ${(props): string => props.newStyle ? props.newDrawer && props.isInDrawer ? '8px' : '16px' : '23px'};
  padding-top: 4px;

  ${(props): SerializedStyles => css`
    ${(props.isInDrawer) ? '' : `
      opacity: 0;
    `}
  `}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    padding-top: 0;
  ` : null}

  &:hover {
    color: ${theme.colors.primary};

    & i {
      color: ${theme.colors.primary};
    }
  }

  & i {
    color: var(--CDCDCD-proxy-manager-placeholder);
  }

  .row-active:hover &,
  td:hover &,
  .new-proxy-cell:hover & {
    opacity: 1;
  }
`;
