import css from '@emotion/css';
import styled from '@emotion/styled';
import Popper from '@material-ui/core/Popper';
import { Icon } from 'antd';
import React, { FC, KeyboardEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tagsContext, workspaceContext } from '../../../state';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { searchTags } from '../api';
import { IAddTag, IPopularTag, ITag, ITagBase, TagField } from '../interfaces/tag.interface';
import TagSuggestItem from './tag-suggest-item';
import { getProfilesTableGroupField } from '../../../state/profiles-list.atom';

const AddNewTagBtn = styled('button')<{ tagsSuggestsLength: number, isBulkMode?: boolean }>`
  color: var(--767676-tags-add-tag);
  cursor: pointer;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 8px 20px 8px 7px;
  border: none;
  outline: none;
  background: none;
  display: flex;
  align-items: center;

  :hover {
    color: var(--00A987-tags-add-tag);

    & i {
      color: var(--00A987-tags-add-tag);
    }
  }

  & i {
    /*transition: color 0.25s ease-out;*/
    color: var(--CDCDCD-tags-add-tag);
  }

  ${(props): any => css`
    ${(props.isBulkMode) ? `
      margin-top: -5px;
    ` : `
      margin-top: ${(props.tagsSuggestsLength) ? '-8px' : '0'};
    `}
  `}
`;

const TagInput = styled('input')`
  border: none;
  background: none;
  outline: none;
  padding: 3px 9px 4px 5px;
  vertical-align: top;
  height: 28px;
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  width: 100%;

  :focus {
    outline: none !important;
    box-shadow: none;
  }

  ::placeholder {
    color: var(--CDCDCD-tags-add-tag);
  }
`;

const SuggestContainer = styled('div')<{ tagsSuggestsLength: number; isBulkMode?: boolean; isDarkTheme: boolean }>`
  background-color: var(--FFFFFF-tags-add-tag);
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  max-height: 152px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 1px;
  position: relative;

  div[x-placement="top-start"] & {
    top: -1px;
  }

  ${(props): any => css`
    ${(props.tagsSuggestsLength) ? `
      padding: 0;
    ` : `
      padding: 6px 0 4px;
    `}
    ${(props.isBulkMode) ? `
      width: 292px;
      border-radius: 4px;
      margin-left: -11px;
      margin-top: 12px;
    ` : `
      width: 240px;
    `}
    ${(props.isDarkTheme) ? `
    box-shadow: -2px 0 8px var(--00000026-antd);
    ` : `
    box-shadow: 0px 1px 4px var(--00000003-tags-add-tag), 0px 4px 8px var(--00000005-tags-add-tag), 0px 1px 12px var(--0000001F-tags-add-tag);
    `}
  `}
`;

const NewTagTitle = styled('div')<{ isBulkMode?: boolean }>`
  /*display: inline-block;*/
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  ${(props): any => css`
    ${(props.isBulkMode) ? `
      max-width: 58px;
    ` : `
      max-width: 72px;
    `}
  `}
`;

const AllProfilesSuggestTitle = styled('div')<{ tagsSuggestsLength: number; isBulkMode?: boolean }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--767676-tags-add-tag);
  margin-left: 0px;

  width: 100%;
  ${(props): any => css`
    ${(props.tagsSuggestsLength) ? `
      margin-top: 8px;
    ` : `
      margin-top: 0px;
      margin-bottom: 10px;
    `}
    ${(props.isBulkMode) ? `
      padding: 0 8px;
    ` : `

    `}
  `}
`;

const TagsContainer = styled('div')`
  margin-top: 6px;
  margin-bottom: 4px;
`;

// TODO fix overflow
const Form = styled('form')`
  height: 28px;
  overflow: hidden;
  vertical-align: middle;
`;

const TagsSizingContainer = styled('div')`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const DEFAULT_TAG_COLOR = 'lightgrey';
const TAGS_SEARCH_DEBOUNCE_MS = 100;

interface IProps {
  profileIds: string[];
  addTag: (params: IAddTag) => void;
  updateTag: (tag: ITagBase) => Promise<void>;
  isAddMode: boolean;
  isBulkOperation?: boolean;
  currentTags: ITag[];
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  isBulkMode?: boolean;
  field: TagField;
}

const AddTag: FC<IProps> = (props: IProps) => {
  const {
    profileIds,
    addTag,
    updateTag,
    isAddMode,
    isBulkOperation,
    currentTags,
    removeProfileTag,
    removeTag,
    isBulkMode,
    field,
  } = props;

  const {
    tags: allGlobalTags,
    newAddedTag,
    setNewAddedTag,
    setProfileInEditTagsMode,
  } = React.useContext(tagsContext);

  const tags = useMemo(() => allGlobalTags.filter(tag => tag.field === field), [field, allGlobalTags]);

  const {
    id: workspace,
  } = React.useContext(workspaceContext);

  const theme = useCurrentTheme();

  const { t: translation } = useTranslation();

  const input = React.useRef<HTMLInputElement>(null);
  const containerElement = React.useRef<HTMLDivElement>(null);
  const [title, setTitle] = React.useState<string>('');
  const [color, setColor] = React.useState<string>(DEFAULT_TAG_COLOR);
  const [removeTagMode, setRemoveTagMode] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(containerElement.current);
  const [tagsSuggests, setTagsSuggests] = React.useState<IPopularTag[] | ITag[]>(tags);
  const [timerId, setTimerId] = React.useState<any>();
  const open = Boolean(anchorEl && (title || tags.length));
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  const callAddTag = (): void => {
    const newTagTitle = title;
    resetState();
    addTag({
      profileIds,
      title: newTagTitle,
      color,
      field,
    });
  };

  const onSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (!title) {
      return;
    }

    callAddTag();
  };

  const resetState = (): void => {
    setTitle('');
    setAnchorEl(null);
  };

  const closeTagsPopup = (): void => {
    setProfileInEditTagsMode(null);
    resetState();
  };

  React.useEffect(() => {
    if (input?.current) {
      setTitle('');
      input.current.focus();
    }
  }, [isAddMode]);

  React.useEffect(() => {
    if (!title) {
      setTagsSuggests(tags);
    }
  }, [title, tags, tagsSuggests]);

  React.useEffect(() => {
    if (!title) {
      return;
    }

    setTagsSuggests((currentTagsSuggests: ITag[] | IPopularTag[]) => currentTagsSuggests.map((tag) => {
      const existedTag = tags.find(globalTag => globalTag.id === tag.id);

      return existedTag || tag;
    }));
  }, [tags]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (timerId) {
      clearTimeout(timerId);
    }

    setTitle(e.target.value);
    if (newAddedTag) {
      setNewAddedTag(null);
    }

    const val = e.target.value;
    if (!val) {
      return;
    }

    setTimerId(setTimeout(async () => {
      const suggestions = await searchTags(val, workspace || '');
      if (!suggestions?.tags) {
        return;
      }

      const foundTags = suggestions.tags.filter(suggestedTag => suggestedTag.field === field);
      setTagsSuggests((currentTagsList: ITag[]) => {
        if (!val) {
          return currentTagsList;
        }

        const tagsStartsWith: ITag[] = [];
        const otherTags: ITag[] = [];
        const preparedVal = val.toLocaleLowerCase();
        for (const el of foundTags) {
          if (el.title.toLocaleLowerCase().startsWith(preparedVal)) {
            tagsStartsWith.push(el);
          } else {
            otherTags.push(el);
          }
        }

        return [
          ...tagsStartsWith,
          ...otherTags,
        ];
      });
    }, TAGS_SEARCH_DEBOUNCE_MS));
    if (!anchorEl) {
      setAnchorEl(containerElement.current);
    }
  };

  const handleKeyDown = async (event: KeyboardEvent): Promise<void> => {
    if (event.key === 'Backspace' && !title && currentTags.length) {
      setRemoveTagMode(true);
    }
  };

  const handleKeyUp = async (event: KeyboardEvent): Promise<void> => {
    const shouldRemoveTag = event.key === 'Backspace' && !title && removeTagMode;
    if (shouldRemoveTag && currentTags.length) {
      const [lastTag] = currentTags.slice(-1);
      await removeProfileTag(profileIds, lastTag.id);

      setRemoveTagMode(false);
    }
  };

  const onRemoveTagClick = async (tag: ITag): Promise<void> => {
    const groupField = getProfilesTableGroupField();
    if (groupField === field) {
      closeTagsPopup();
    }

    await removeTag(tag.id, true);
    setTagsSuggests(tagsSuggests.filter(tagSuggest => tagSuggest.id !== tag.id));
  };

  const tagsSuggestsList = (): JSX.Element | null => {
    if (!tagsSuggests.length) {
      return null;
    }

    return (
      <TagsContainer>
        {tagsSuggests.map((tag: ITag) => {
          const alreadyAdded = !!currentTags.find((el: ITag) => el.title === tag.title);

          return (
            <TagSuggestItem
              key={tag.id}
              tag={tag}
              setTitle={setTitle}
              alreadyAdded={alreadyAdded}
              removeProfileTag={removeProfileTag}
              removeTag={(): Promise<void> => onRemoveTagClick(tag)}
              addTag={addTag}
              updateTag={updateTag}
              profileIds={profileIds}
              field={field}
              closeTagsPopup={closeTagsPopup}
            />
          );
        })}
      </TagsContainer>
    );
  };

  const newTagBlock = (): JSX.Element | null => {
    const addNewTextKey = field === 'custom-status' ? 'tags.addNewStatus' : 'tags.addNewTag';
    if (!title) {
      return null;
    }

    return (
      <div>
        <AddNewTagBtn
          tagsSuggestsLength={tagsSuggests.length}
          isBulkMode={isBulkMode}
          onClick={callAddTag}
        >
          <Icon style={{ marginRight: 8, marginTop: -1 }} type='plus' />
          {translation(addNewTextKey)}
          {' '}
          “
          <NewTagTitle isBulkMode={isBulkMode}>
            {title}
          </NewTagTitle>
          ”
          {' '}
          {(isBulkOperation) ? translation('tags.toAllProfiles') : null}
        </AddNewTagBtn>
      </div>
    );
  };

  const tagMaxLength = 200;
  const addPlaceholderKey = field === 'custom-status' ? 'tags.addStatusPlaceholder' : 'tags.addTagPlaceholder';

  return (
    <div style={{ width: '100%' }}>
      <TagsSizingContainer ref={containerElement} className="tags-sizing-container" onClick={(): void => resetState()} />
      {(isAddMode) ? (
        <Form onSubmit={onSubmit} className="tags-form">
          <TagInput
            ref={input}
            value={title}
            maxLength={tagMaxLength}
            onChange={handleInputChange}
            placeholder={translation(addPlaceholderKey)}
            onFocus={(): void => setAnchorEl(containerElement.current)}
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
          />
        </Form>
      ) : null}
      <Popper
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1060 }}
        disablePortal={!!isBulkMode}
        placement='bottom-start'
        modifiers={{
          preventOverflow: {
            enabled: false,
          },
        }}
      >
        <div className="tags-popper">
          {(tagsSuggests.length || title) ? (
            <SuggestContainer tagsSuggestsLength={tagsSuggests.length} isBulkMode={isBulkMode} isDarkTheme={isDarkTheme}>
              {(isBulkOperation) ? (
                <AllProfilesSuggestTitle tagsSuggestsLength={tagsSuggests.length} isBulkMode={isBulkMode}>
                  {translation('tags.addToAllProfiles')}
                </AllProfilesSuggestTitle>
              ) : null}
              {tagsSuggestsList()}
              {newTagBlock()}
            </SuggestContainer>
          ) : null}
        </div>
      </Popper>
    </div>
  );
};

export default AddTag;
