import { calculateTargetOrder } from './calculate-orders';
import { ORDER_BASELINE, ORDER_STEP } from './constants';
import { renumerateOrders } from './renumerate-orders';

export type IOrderItem = { order: number; id: string; isPinned?: boolean; targetGroupId: string | null };
export type IDraggedItem = { idx: number; id: string };

interface IReorderItems<T extends IOrderItem, N extends IDraggedItem> {
  orderList: T[];
  fromIndex: number;
  toIndex: number;
  draggedItems: N[];
  targetGroupId: string | null;
  baseline?: number;
  step?: number;
}

export interface IReorderItemsResult<T extends IOrderItem> {
  reorderedList: T[];
  movedItems: T[];
}

interface ICutOrderList<T extends IOrderItem> {
  orderList: T[];
  targetGroupId: T['targetGroupId'];
  fromIndex: number;
  toIndex: number;
}

interface ICutOrderListResult<T extends IOrderItem> {
  trimmedOrderList: T[];
  trimmedFromIndex: number;
  trimmedToIndex: number;
}

interface ICutOrderListReduceResult<T> {
  minIndex: number | null;
  maxIndex: number | null;
  filteredList: T[];
}

const cutOrderList = <T extends IOrderItem>(opts: ICutOrderList<T>): ICutOrderListResult<T> => {
  const { orderList, targetGroupId, fromIndex, toIndex } = opts;
  const { minIndex, maxIndex, filteredList } = orderList.reduce<ICutOrderListReduceResult<T>>((acc, obj, index) => {
    if (obj.targetGroupId === targetGroupId) {
      acc.filteredList.push(obj);
      if (acc.minIndex === null || index < acc.minIndex) {
        acc.minIndex = index;
      }

      if (acc.maxIndex === null || index > acc.maxIndex) {
        acc.maxIndex = index;
      }
    }

    return acc;
  }, { minIndex: null, maxIndex: null, filteredList: [] });

  if (minIndex === null || maxIndex === null) {
    return { trimmedOrderList: orderList, trimmedFromIndex: fromIndex, trimmedToIndex: toIndex };
  }

  const clampIndex = (idx: number): number => Math.min(Math.max(idx, minIndex), maxIndex) - minIndex;

  return {
    trimmedOrderList: filteredList,
    trimmedFromIndex: fromIndex - minIndex,
    trimmedToIndex: clampIndex(toIndex),
  };
};

export const reorderItems = <T extends IOrderItem, N extends IDraggedItem>(opts: IReorderItems<T, N>): IReorderItemsResult<T> => {
  const {
    orderList,
    draggedItems,
    baseline = ORDER_BASELINE,
    step = ORDER_STEP,
  } = opts;

  const { trimmedOrderList, trimmedFromIndex, trimmedToIndex } = cutOrderList(opts);

  const [targetOrderLow, targetOrderHigh] = calculateTargetOrder({
    orderList: trimmedOrderList,
    fromIndex: trimmedFromIndex,
    toIndex: trimmedToIndex,
    movedIndexes: draggedItems.map(({ idx }) => idx),
    baseline,
    step,
  });

  const draggedIds = draggedItems.map(item => item.id);
  const [movedItems, remainingItems] = partition(orderList, (item) => draggedIds.includes(item.id));

  if (!movedItems.length) {
    return { movedItems, reorderedList: orderList };
  }

  const orderStep = Math.floor((targetOrderHigh - targetOrderLow) / (movedItems.length + 1));
  movedItems.forEach((profile, idx) => {
    profile.order = targetOrderLow + orderStep * (idx + 1);
  });

  remainingItems.push(...movedItems);
  remainingItems.sort((prev, next) => +(next.isPinned ?? 0) - +(prev.isPinned ?? 0) || next.order - prev.order);

  const newItems = renumerateOrders(remainingItems, step);

  return { movedItems, reorderedList: newItems };
};

const partition = <T>(array: T[], filter: (item: T, idx: number, arr: T[]) => boolean): [T[],T[]] => {
  const pass: T[] = [];
  const fail: T[] = [];
  array.forEach((item, idx, arr) => (filter(item, idx, arr) ? pass : fail).push(item));

  return [pass, fail];
};
