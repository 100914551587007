import * as Sentry from '@sentry/react';
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { NEW_FEATURES } from '../../../../feature-toggle';
import { PROFILE_SHARED_KEY_NAME } from '../../../common/constants/constants';
import { LOCAL_STORAGE_SELECTED_FOLDER } from '../../../common/constants/local-storage';
import { handleShareViaLinkFromStorage } from '../../features/common/deep-links';
import { IProfilesResponse, IRequestProfiles, requestProfiles } from '../../features/quickProfiles/api';
import { ALL_PROFILES_FOLDER } from '../../features/quickProfiles/components/profiles-list-page';
import { SortField, SortOrder } from '../../features/quickProfiles/constants';
import { normalizeProfilesData } from '../../features/quickProfiles/normalize-profiles-data';
import { DropFileStep } from '../../hooks';
import { IProfile } from '../../interfaces';
import { IGroupHeader } from '../../interfaces/group-header.interface';
import { IWorkspaceFolder } from '../../interfaces/workspaces';
import PerformanceObserverService from '../../services/performance-observer/performance-observer.service';
import { currentWorkspaceIdAtom } from '../current-workspace-id.atom';
import { getIsMoreProfilesAvailable, setIsMoreProfilesAvailable, useIsMoreProfilesAvailable } from '../is-more-profiles-available.atom';
import { upsertProfileRunStatuses } from '../profile-run-statuses.atom';
import {
  getBasicTableProfileIds,
  getProfilesList,
  profilesTableGroupFieldAtom,
  setProfilesList,
  updateProfilesTableGroupHeadersStatus,
} from '../profiles-list.atom';
import { profilesTableProxyIdFilterAtom } from '../profiles-proxy-filter.atom';
import { profilesSortFieldAtom, profilesSortOrderAtom } from '../profiles-table/profiles-sort.atom';
import { resetProfilesTableSelectedIds } from '../profiles-table-selected-ids.atom';
import { searchQueryAtom } from '../search-query.atom';
import { selectedTagIdAtom } from '../tags/selected-tag.atom';

interface IProfilesQuery {
  workspaceId: string;
  filter: {
    tagId: string | null;
    searchQuery: string | null;
    proxyId: string;
  };
  sort: {
    field: SortField;
    order: SortOrder;
  };
  groupField: IGroupHeader['filter']['type'] | null;
  offset: number;
}

const lastProfilesSelectorAtom = atom<IRequestProfiles | null>(null);

const getLastProfilesSelector = (): IRequestProfiles | null => getDefaultStore().get(lastProfilesSelectorAtom);
const setLastProfilesSelector = (newLastProfilesSelector: IRequestProfiles | null): void =>
  getDefaultStore().set(lastProfilesSelectorAtom, newLastProfilesSelector);

export type ProfilesQueryLoadingStatus = 'initing'|'loading'|'loaded';

const profilesQueryLoadingStatusAtom = atom<ProfilesQueryLoadingStatus>('initing');

const profilesQueryOffsetAtom = atom<number>(0);
const setProfilesQueryOffset = (newOffset: number): void => getDefaultStore().set(profilesQueryOffsetAtom, newOffset);

export const resetProfilesQueryOffset = (): void => setProfilesQueryOffset(0);

const currentProfilesQueryAtom = atom<IProfilesQuery>((get) => {
  const workspaceId = get(currentWorkspaceIdAtom) ?? '';
  const tagId: string | null = get(selectedTagIdAtom) ?? '';
  const searchQuery = get(searchQueryAtom);
  const proxyId = get(profilesTableProxyIdFilterAtom);
  const offset = get(profilesQueryOffsetAtom);
  const sortField = get(profilesSortFieldAtom);
  const sortOrder = get(profilesSortOrderAtom);
  const groupField = get(profilesTableGroupFieldAtom);

  // FIXME: this thing should work too
  // if (currentSelectedTag !== prevTag.current) {
  //   if (isTemporaryTag) {
  //     // user chose just created tag, so we do not load any profiles
  //     // until tag is actually created, and this function called again
  //     changeLoading(true);
  //     prevTag.current = currentSelectedTag;
  //
  //     return;
  //   }
  // }

  const dataSourceQuery: IProfilesQuery = {
    workspaceId,
    filter: {
      tagId,
      searchQuery,
      proxyId,
    },
    sort: {
      field: sortField,
      order: sortOrder,
    },
    groupField,
    offset,
  };

  return dataSourceQuery;
});

export const paginateProfilesQuery = (): void => {
  setProfilesQueryOffset(getProfilesList().length);
};

export const doesProfilesQueryHaveMoreProfiles = (): boolean => getIsMoreProfilesAvailable();
export const useDoesProfilesQueryHaveMoreProfiles = (): boolean => {
  const isMoreProfilesAvailable = useIsMoreProfilesAvailable();

  return isMoreProfilesAvailable;
};

export const useCurrentProfilesQuery = (): IProfilesQuery => useAtomValue(currentProfilesQueryAtom);
const getCurrentProfilesQuery = (): IProfilesQuery => getDefaultStore().get(currentProfilesQueryAtom);

export const useProfilesQueryLoadingStatus = (): ProfilesQueryLoadingStatus => useAtomValue(profilesQueryLoadingStatusAtom);
const setProfilesQueryLoadingStatus = (loadingStatus: ProfilesQueryLoadingStatus): void => {
  getDefaultStore().set(profilesQueryLoadingStatusAtom, loadingStatus);
};

const isSelectorLast = (currSelector: IRequestProfiles): boolean => currSelector === getLastProfilesSelector();

export const getProfilesQueryAsSelector = (foldersList: IWorkspaceFolder[], allProfilesFolderId: string | undefined): IRequestProfiles => {
  let folderSelector: { folderId: string } | { folder: string } | Record<string, never> = {};
  const selectedFolderName = localStorage.getItem(LOCAL_STORAGE_SELECTED_FOLDER);
  if (NEW_FEATURES.dragAndDrop && selectedFolderName && selectedFolderName !== ALL_PROFILES_FOLDER) {
    const selectedFolder = foldersList.find(folder => folder.name === selectedFolderName);

    folderSelector = { folderId: selectedFolder?.id || '' };
  } else if (NEW_FEATURES.dragAndDrop) {
    folderSelector = { folderId: allProfilesFolderId || '' };
  }

  if (!folderSelector.folderId && selectedFolderName && selectedFolderName !== ALL_PROFILES_FOLDER) {
    folderSelector = { folder: encodeURIComponent(selectedFolderName) };
  }

  const {
    workspaceId,
    filter,
    offset,
    sort,
    groupField,
  } = getCurrentProfilesQuery();

  return {
    ...folderSelector,
    workspaceId,
    search: filter.searchQuery || '',
    tag: filter.tagId || '',
    proxyId: filter.proxyId,
    offset,
    sortField: sort.field,
    sortOrder: sort.order,
    groupField,
  };
};

export type HandleOrbitaVersionsFn = (currentOrbitaMajorV: string, currentBrowserV: string) => void;
export type HandleFolderDeletedFn = () => void;
export type HandleFullProfilesMapFn = () => void;
export type HandleDropFileLoadedFn = () => void;
export type HandleLoadingDoneFn = () => void;
export type HandleCheckUaFn = (opts: { receivedProfiles: IProfile[]; currentBrowserV: string; currentOrbitaMajorV: string }) => Promise<void>;

// TODO: most (if not all) of these fields are unnecessary
// and should be applied from the state management
// but right now it is too much work :(
export interface IFetchProfilesOpts {
  foldersList: IWorkspaceFolder[];
  allProfilesFolderId: string | undefined;
  dropFileStep: DropFileStep;
  handleOrbitaVersions: HandleOrbitaVersionsFn;
  handleFolderDeleted: HandleFolderDeletedFn;
  handleFullProfilesMap: HandleFullProfilesMapFn;
  handleDropFileLoaded: HandleDropFileLoadedFn;
  handleLoadingDone: HandleLoadingDoneFn;
  handleCheckUa: HandleCheckUaFn;
}

export const fetchProfilesByCurrentQuery = async (opts: IFetchProfilesOpts): Promise<void> => {
  const {
    foldersList,
    allProfilesFolderId,
    dropFileStep,
    handleOrbitaVersions,
    handleFolderDeleted,
    handleFullProfilesMap,
    handleDropFileLoaded,
    handleLoadingDone,
    handleCheckUa,
  } = opts;

  const isIniting = !getBasicTableProfileIds().length;
  setProfilesQueryLoadingStatus(isIniting ? 'initing' : 'loading');
  const selector = getProfilesQueryAsSelector(foldersList, allProfilesFolderId);
  if (!selector.workspaceId) {
    return;
  }

  setLastProfilesSelector(selector);

  const transaction = Sentry.startTransaction({ name: 'load-profiles-table' });
  selector.transaction = transaction;

  const profilesReq = await requestProfiles(selector).catch(() => ({} as Partial<IProfilesResponse>));
  const {
    profiles: receivedProfiles = [],
    total: allProfilesCount = 0,
    currentOrbitaMajorV = '',
    currentBrowserV = '',
    isFolderDeleted = false,
    isMoreProfilesAvailable: receivedIsMoreProfilesAvailable = false,
    groupsMetadata = [],
  } = profilesReq;

  const isLastSelector = isSelectorLast(selector);
  if (!isLastSelector) {
    return;
  }

  const responseStatesSpan = transaction.startChild({ op: 'handle-response-states' });

  handleOrbitaVersions(currentOrbitaMajorV, currentBrowserV);
  if (isFolderDeleted) {
    handleFolderDeleted();
  }

  responseStatesSpan.finish();

  const checkUaSpan = transaction.startChild({ op: 'check-profiles-ua' });
  await handleCheckUa({ receivedProfiles, currentBrowserV, currentOrbitaMajorV });
  checkUaSpan.finish();

  const setProfilesSpan = transaction.startChild({ op: 'ui', description: 'set-profiles' });

  const performanceObserverService = PerformanceObserverService.getInstance();
  performanceObserverService.setProfilesMark(transaction);

  const profiles = getProfilesList();

  let updateType = 'reset';
  let resultProfilesArray = receivedProfiles;
  const isAppending = selector.offset;
  if (isAppending) {
    updateType = 'append';
    resultProfilesArray = profiles.concat(receivedProfiles);
  } else if (dropFileStep === 'loading') {
    updateType = 'drop-loading';
  }

  if (!(isAppending || dropFileStep === 'loading')) {
    resetProfilesTableSelectedIds();
  }

  const profileSharedViaLinkJson = sessionStorage.getItem(PROFILE_SHARED_KEY_NAME) || '';
  resultProfilesArray = handleShareViaLinkFromStorage(resultProfilesArray, profileSharedViaLinkJson);

  performanceObserverService.setLoadProfilesTags({ transaction, selector, prevProfiles: profiles, receivedProfiles, updateType });

  const profilesNormalized = normalizeProfilesData([...new Set(resultProfilesArray)]);

  setProfilesList(profilesNormalized);
  handleFullProfilesMap();

  updateProfilesTableGroupHeadersStatus({ groupsMetadata });
  setIsMoreProfilesAvailable(receivedIsMoreProfilesAvailable);

  setProfilesSpan.finish();

  const initRunStatusesSpan = transaction.startChild({ op: 'ui', description: 'init-run-statuses' });
  upsertProfileRunStatuses(receivedProfiles.map(profile => ({ id: profile.id, status: 'profileStatuses.ready' })));
  initRunStatusesSpan.finish();

  const removeLoadingSpan = transaction.startChild({ op: 'ui', description: 'remove-loading' });
  if (dropFileStep === 'loading') {
    handleDropFileLoaded();
  }

  handleLoadingDone();
  setProfilesQueryLoadingStatus('loaded');

  removeLoadingSpan.finish();
};
