import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ProxyItem from './proxy-item';
import { IGeolocationProxyFullData, IGeolocationProxyPrices } from '../../../../interfaces';
import { GEOLOCATION_PROXY_TYPE } from '../geolocation-proxy-type';
import PriceList from '../price-list';

interface IProxyList {
  trafficData: IGeolocationProxyFullData;
}

const ProxyList: FC<IProxyList> = (props): JSX.Element => {
  const [anchorElPriceList, setAnchorElPriceList] = React.useState<HTMLElement | null>(null);
  const [selectedType, setSelectedType] = React.useState<keyof IGeolocationProxyPrices>('mobile');
  const { t: translation } = useTranslation();

  const { trafficData } = props;

  const openPriceList = (type: keyof IGeolocationProxyPrices, currentTarget: HTMLDivElement): void => {
    setSelectedType(type);
    setAnchorElPriceList(currentTarget);
  };

  const onClose = (): void => {
    setAnchorElPriceList(null);
  };

  return (
    <>
      {GEOLOCATION_PROXY_TYPE.map(item => (
        <ProxyItem
          key={item}
          trafficData={trafficData}
          type={item}
          openPriceList={openPriceList}
        />
      ))}
      <PriceList
        anchorEl={anchorElPriceList}
        onClose={onClose}
        price={trafficData.prices}
        proxyType={selectedType}
        availableForPurchase={trafficData.availableForPurchase}
      />
    </>
  );
};

export default ProxyList;
