import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { CheckProxyButtonWrapper } from '../../check-proxy-button/styles';

export const ProxyFormContainerEl = styled('div')<{ addMaxWidth: boolean; newStyle: boolean; isEditProxy: boolean }>`
  display: flex;
  align-items: center;

  ${(props): any => css`
    ${(props.addMaxWidth) ? '' : `
      max-width: 100%;
    `};
  `}

  ${(props): SerializedStyles|null => props.newStyle ? css`
    background-color: inherit;

    ${CheckProxyButtonWrapper} {
      display: ${props.isEditProxy && 'flex'};
    }
  ` : null}
`;

export const ProxyFormEl = styled('form')`
  position: relative;
  height: 16px;
  display: block;
`;
