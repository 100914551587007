import { Icon } from 'antd';
import React from 'react';

import { Wrapper } from './styles';

export const LoadingSpinner: React.FC = () => (
  <Wrapper>
    <Icon type='loading' />
  </Wrapper>
);
