import styled from '@emotion/styled/macro';

import { textFont } from '../style-templates';

export const GologinPopoverItemRowBasic = styled('div')<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  border-radius: 4px;

  ${textFont}

  color: var(--2B2B31);
  background: ${(props): string => props.isSelected ? 'var(--F2F2F3)' : 'inherit'};
`;

export const GologinPopoverItemRowStatic = styled(GologinPopoverItemRowBasic)`
  cursor: default;
  padding: 6px 16px;
`;

export const GologinPopoverItemRow = styled(GologinPopoverItemRowBasic)`
  cursor: pointer;
  padding: 6px 16px;

  :hover {
    background: var(--EAEAEB);
  }
`;
