import { getDefaultStore, atom, useAtomValue } from 'jotai';

const isQuickPricingVisibleAtom = atom<boolean>(false);
const shouldOpenQuickPricingAtLaunchAtom = atom<boolean>(false);

export const useIsQuickPricingVisible = (): boolean => useAtomValue(isQuickPricingVisibleAtom);

export const setShouldOpenQuickPricingAtLaunch = (newShouldOpenQuickPricingAtLaunch: boolean): void =>
  getDefaultStore().set(shouldOpenQuickPricingAtLaunchAtom, newShouldOpenQuickPricingAtLaunch);

const openQuickPricing = (): void => {
  getDefaultStore().set(isQuickPricingVisibleAtom, true);
};

export const closeQuickPricing = (): void => {
  getDefaultStore().set(isQuickPricingVisibleAtom, false);
};

export const onProfileLaunched = (): void => {
  const shouldOpenQuickPricingAtLaunch = getDefaultStore().get(shouldOpenQuickPricingAtLaunchAtom);
  if (!shouldOpenQuickPricingAtLaunch) {
    return;
  }

  setShouldOpenQuickPricingAtLaunch(false);
  const delayToShowMs = 5000;
  setTimeout(openQuickPricing, delayToShowMs);
};

