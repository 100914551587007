import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { setProxiesTableList } from './proxy-table/proxies-table-list.atom';
import { IProfileProxy } from '../../features/proxy/components/interfaces/IProfileProxy';
import { IProxy } from '../../fatures/proxy/components/interfaces/IProxy';
import { filterProxies, putCurrentProxyFirstInList } from '../../utils/proxy-string';
import { IUpdateProxyItem } from '../proxies.context/interfaces/update-proxy-item.interface';

export const proxyListAtom = atom<IProxy[]>([]);
const isProxyListLoadingAtom = atom<boolean>(false);
const filteredProxyListAtom = atom<IProxy[]>([]);
const isProxyListLoadedAtom = atom(false);

const setProxyList = (data: IProxy[]): void => getDefaultStore().set(proxyListAtom, data);
export const getProxyList = (): IProxy[] => getDefaultStore().get(proxyListAtom);
export const getIsProxyListLoading = (): boolean => getDefaultStore().get(isProxyListLoadingAtom);
export const setFilteredProxyList = (data: IProxy[]): void => getDefaultStore().set(filteredProxyListAtom, data);
export const setIsProxyListLoaded = (value: boolean): void => getDefaultStore().set(isProxyListLoadedAtom, value);

export const useProxyList = (): IProxy[] => useAtomValue(proxyListAtom);

export const useFilteredProxyList = (): IProxy[] => useAtomValue(filteredProxyListAtom);
export const getFilteredProxyList = (): IProxy[] => getDefaultStore().get(filteredProxyListAtom);

export const useIsProxyListLoaded = (): boolean => useAtomValue(isProxyListLoadedAtom);

const resetProxiesInFilteredList = (): void => {
  const proxyList = getProxyList();
  const filteredProxiesList = getFilteredProxyList();

  const newFilteredProxiesList = filteredProxiesList.map(filteredProxy => {
    const newProxyValue = proxyList.find(proxy => proxy._id && proxy._id === filteredProxy._id);

    return newProxyValue || filteredProxy;
  });

  setFilteredProxyList(newFilteredProxiesList);
};

export const filterProxyList = (value: string, currentProxy: IProfileProxy|null = null): void => {
  const proxyList = getProxyList();

  if (!value) {
    const resultProxyList = putCurrentProxyFirstInList(proxyList, currentProxy);
    setFilteredProxyList(resultProxyList);

    return;
  }

  const newProxyList = filterProxies(proxyList, value.toLocaleLowerCase(), currentProxy);

  setFilteredProxyList(newProxyList);
};

export const updateProxyList = (data: IProxy[], proxyCurrent: IProxy|null = null): void => {
  setProxyList(data);
  filterProxyList('', proxyCurrent);
};

const updateProxyListInPlace = (data: IProxy[]): void => {
  setProxyList(data);
  resetProxiesInFilteredList();
};

export const removeProxiesFromList = (proxyIdsToDelete: string[]): void => {
  const proxyList = getProxyList();
  const filteredProxyList = getFilteredProxyList();
  setProxyList(proxyList.filter(proxy => !proxyIdsToDelete.includes(proxy._id)));
  setFilteredProxyList(filteredProxyList.filter(proxy => !proxyIdsToDelete.includes(proxy._id)));
  resetProxiesInFilteredList();
};

export const upsertOneProxy = (newProxy: IProxy): void => {
  const proxyList = getProxyList();
  let hasProxyChanged = false;
  const updatedProxies = proxyList.map((proxy) => {
    if (proxy._id !== newProxy._id) {
      return proxy;
    }

    hasProxyChanged = true;
    newProxy = {
      ...proxy,
      ...newProxy,
      profilesCount: proxy.profilesCount,
    };

    return newProxy;
  });

  if (hasProxyChanged) {
    return updateProxyListInPlace(updatedProxies);
  }

  updatedProxies.unshift({ ...newProxy, checkInProgress: true });

  updateProxyList(updatedProxies);
};

const updateProxyInList = (proxiesInList: IProxy[], proxyItem: IUpdateProxyItem, isUsedForManyProxies = false): void => {
  const proxyInListIndex = proxiesInList.findIndex(proxy => proxy._id === proxyItem._id);
  const isInvisible = proxyItem._id && proxyInListIndex === -1 && proxyItem.isInvisible;

  if (isInvisible) {
    const proxyToAdd: IUpdateProxyItem = { ...proxyItem, isInvisible };
    const newProxyList = [...proxiesInList];
    newProxyList.push(proxyToAdd);

    return isUsedForManyProxies ? setProxyList(newProxyList) : updateProxyList(newProxyList);
  }

  const newProxyList = [...proxiesInList];
  if (proxyInListIndex > -1) {
    newProxyList[proxyInListIndex] = { ...newProxyList[proxyInListIndex], ...proxyItem };
  } else {
    // proxy needs to be updated after check,
    // if it is not listed (for various reasons such as profile transferred with it)
    newProxyList.push({
      ...proxyItem,
      _id: proxyItem.id || '',
      id: proxyItem.id || '',
      profilesCount: 0,
      mode: proxyItem.mode || 'http',
      host: proxyItem.host || '',
      port: proxyItem.port || 80,
      isInvisible: true,
    });
  }

  isUsedForManyProxies ? setProxyList(newProxyList) : updateProxyListInPlace(newProxyList);
};

export const updateProxyItem = (proxyItem: IUpdateProxyItem): void => {
  const proxyList = getProxyList();
  updateProxyInList(proxyList, proxyItem);
};

export const updateProxyItems = (proxyItems: IUpdateProxyItem[]): void => {
  const proxyList = getProxyList();
  proxyItems.map(proxyItem => updateProxyInList(proxyList, proxyItem, true));
};

export const clearProxyLists = (): void => {
  setProxyList([]);
  setFilteredProxyList([]);
  setProxiesTableList([]);
};

export const incrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const newProxiesList = proxyList.map((proxyItem) => {
    if (proxyItem?._id === proxyId) {
      proxyItem.profilesCount ? proxyItem.profilesCount += 1 : proxyItem.profilesCount = 1;
    }

    return proxyItem;
  });

  updateProxyList(newProxiesList);
};

export const decrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const updatedProxies = proxyList.map((proxyItem) => {
    if (proxyItem._id === proxyId && proxyItem.profilesCount) {
      proxyItem.profilesCount -= 1;
    }

    return proxyItem;
  });

  updateProxyList(updatedProxies);
};

export const useProxyListProxyById = (proxyId: string | null): IProxy | null => {
  const proxyList = useProxyList();
  if (!proxyId) {
    return null;
  }

  return proxyList.find(proxyInList => proxyInList._id === proxyId) || null;
};

export const getProxyListProxyById = (proxyId: string): IProxy | null => {
  const proxyList = getProxyList();

  return proxyList.find(proxyInList => proxyInList._id === proxyId) || null;
};

export const updateProxyInListById = (
  proxyId: string,
  partialUpdate: Omit<Partial<IProxy>, '_id'>,
): void => {
  const proxyList = getProxyList();
  setProxyList(proxyList.map(proxy =>
    proxy._id === proxyId ?
      { ...proxy, ...partialUpdate } :
      proxy,
  ));
};

export const getNoIdProxyId = (proxy: IProxy): string => {
  if (!['gologin', 'tor'].includes(proxy.mode)) {
    return proxy._id;
  }

  return proxy.mode + '-' + (proxy.autoProxyRegion || proxy.torProxyRegion);
};

export const getProxyByArtificialProxyId = (artificialProxyId: string | null): IProxy | null => {
  if (!artificialProxyId) {
    return null;
  }

  const proxyList = getProxyList();
  const [mode, region] = artificialProxyId.split('-');
  const field: keyof IProxy = mode === 'gologin' ? 'autoProxyRegion' : 'torProxyRegion';

  return proxyList.find(proxy => proxy.mode === mode && proxy[field] === region) || null;
};

