import { useTranslate } from '@tolgee/react';
import React, { FC, useContext, useState } from 'react';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import { ModalUpgradeButtonContainer, ModalUpgradeCryptoButton } from './domElements';
import { ButtonPay } from './styles';
import { NEW_FEATURES } from '../../../../../feature-toggle';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IPlan } from '../../../interfaces/plan';
import { history } from '../../../services';
import { useDiscountObjById, usePriceConfig, usePromoDiscountAvaliable, userContext, workspaceContext } from '../../../state';
import { useIsUpgradeDiscountAvaliable } from '../../../state/pricing/upgrade-discount.atom';
import { getExternalCheckoutUrl } from '../../../utils/open-site';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../common/api';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../../modalsComponents/components/checkout-modal/constants';
import PriceList from '../../modalsComponents/components/checkout-modal/price-list';
import { QiwiButton } from '../../modalsComponents/components/checkout-modal/styles';
import { checkPlanMaxProfiles, isDiscountAnnual, getSelectedPlan } from '../../modalsComponents/components/checkout-modal/utils';
import { getSigmaPaymentLink } from '../api';
import { E_CURRENCY, E_PAYMENT_METHODS, E_PAYMENT_METHODS_ANALYTICS, E_PERIOD } from '../interfaces';
import { Price } from '../utils/price';
import { handlePaymentCreationError } from '../utils/sentry';

const isElectron = !!window.require;

interface ISigmaForm {
  selectedPlan: string;
  selectedDiscount: string;
  hasSuccessPayment?: boolean;
  plans: IPlan[];
  getCheckmarkLine?: () => JSX.Element;
  pricingPlan?: IPlan;
  workspaceId?: string;
  openSuccessModal: (planId: string) => void;
}

const SigmaForm: FC<ISigmaForm> = (props) => {
  const {
    selectedPlan,
    selectedDiscount,
    hasSuccessPayment,
    plans,
    getCheckmarkLine,
    pricingPlan,
    workspaceId,
  } = props;

  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const priceConfig = usePriceConfig();
  const selectedDiscountObj = useDiscountObjById(selectedDiscount);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const { profiles: userProfilesCount } = useContext(userContext);
  const { profilesCount: workspaceProfilesCount } = useContext(workspaceContext);

  const { t: translation } = useTranslate();

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const discountId = isAnnual ? ANNUAL_DISCOUNT : MONTH_DISCOUNT;
  const planPeriod = isAnnual ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;
  const profilesCount = NEW_FEATURES.workspaces ? workspaceProfilesCount : userProfilesCount;

  const selectedDiscounObj = useDiscountObjById(discountId);
  const promoDiscount = usePromoDiscountAvaliable();

  const makePayment = async (event: any): Promise<void> => {
    const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedPayNow, { actionInfo, paymentMethod: E_PAYMENT_METHODS.SIGMA }).catch(() => null);
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    if (hasSuccessPayment) {
      history.replace('/profileList');

      return;
    }

    const planToPay = getSelectedPlan(plans, selectedPlan);
    const status = planToPay && checkPlanMaxProfiles({ translation, planToPay, profiles: profilesCount, paymentMethod: E_PAYMENT_METHODS.SIGMA });
    if (!status) {
      return;
    }

    setButtonDisabled(true);
    const paymentLinkObj = await getSigmaPaymentLink({
      planId: selectedPlan,
      discountId: selectedDiscount,
      workspaceId,
      isUpgrade,
    }).catch((error: any) => {
      handlePaymentCreationError({
        error,
        selectedDiscounObj,
        planPeriod,
        method: E_PAYMENT_METHODS.SIGMA,
        paymentMethod: E_PAYMENT_METHODS_ANALYTICS.SIGMA,
        promoDiscount,
        plans,
        selectedPlan,
        priceConfig,
        tabCurrency: E_CURRENCY.RUB,
      });

      setButtonDisabled(false);
    });

    const { paymentPageUrl: checkoutUrl = '' } = paymentLinkObj || {};
    if (!checkoutUrl) {
      setButtonDisabled(false);

      return;
    }

    const checkoutPageUrl = await getExternalCheckoutUrl({ checkoutUrl });
    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutPageUrl)
        .catch((error) => {
          const errorMessage = error instanceof Error ? error.message : 'unknown';
          sendReactErrorToSentry({ message: errorMessage, transactionName: 'open-sigma-checkout-url' });
        });

      setButtonDisabled(false);

      return;
    }

    window.open(checkoutPageUrl, '_blank');
    setButtonDisabled(false);
  };

  if (NEW_FEATURES.pricing) {
    if (!pricingPlan) {
      return null;
    }

    return (
      <>
        <PriceList
          pricingPlan={pricingPlan}
          isAnnual={isAnnual}
        />
        <QiwiButton
          onClick={makePayment}
        >
          {translation('pricing.checkoutModal.submitBtn')}
          {' '}
          {new Price(pricingPlan).savePeriod(selectedDiscountObj).toLocalString(null, priceConfig)}
        </QiwiButton>
      </>
    );
  }

  return (
    <>
      {getCheckmarkLine ? getCheckmarkLine() : null}
      <ModalUpgradeButtonContainer paddingBottom={isAnnual}>
        <ButtonPay>
          <ModalUpgradeCryptoButton
            disabled={buttonDisabled}
            onClick={makePayment}
          >
            {translation('pricing.forms.stripe.submitBtnTxt')}
          </ModalUpgradeCryptoButton>
        </ButtonPay>
      </ModalUpgradeButtonContainer>
    </>
  );
};

export default SigmaForm;
