import { IProfileProxy } from '../components/interfaces/IProfileProxy';
import { IProxy } from '../components/interfaces/IProxy';

const areProxyFieldsEqual = (proxy1: IProxy|IProfileProxy, proxy2: IProxy|IProfileProxy): boolean => proxy1.mode === proxy2.mode &&
  (
    (proxy1.mode === 'gologin' && proxy1.autoProxyRegion === proxy2.autoProxyRegion) ||
    (proxy1.mode === 'tor' && proxy1.torProxyRegion === proxy2.torProxyRegion) ||
    (
      proxy1.mode !== 'gologin' && proxy1.mode !== 'tor' &&
      proxy1.host === proxy2.host &&
      proxy1.port === proxy2.port &&
      proxy1.username === proxy2.username &&
      proxy1.password === proxy2.password
    )
  );

export const findProxyInList = (proxy: IProfileProxy, proxyList: IProxy[]): IProxy|undefined => {
  let proxyFound: IProxy|undefined;
  if (proxy.id) {
    proxyFound = proxyList.find(proxyListItem => proxyListItem._id === proxy.id);
  }

  if (!proxyFound) {
    proxyFound = proxyList.find((proxyInList) => areProxyFieldsEqual(proxyInList, proxy));
  }

  return proxyFound;
};

export const getProxyForProxySelector = (proxyList: IProxy[], proxy?: IProxy): IProxy|null => {
  if (!proxy) {
    return null;
  }

  let proxyForProxySelector = findProxyInList(proxy, proxyList);
  if (!proxyForProxySelector) {
    proxyForProxySelector = proxy;
  }

  return proxyForProxySelector;
};
