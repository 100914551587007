import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { sendActionAnalytics } from '../../../../features/common/api';
import { userContext, workspaceContext } from '../../../../state';
import { openWebsitePricing } from '../../../../utils/open-site';
import { IconArrowRight } from '../../icons';
import { IconWrapperWithDescription } from '../../icons/wrapper';
import { Wrapper, Text, TextWitchIcon } from '../styles';

const TrialBar = (): JSX.Element => {
  const { trialDays: userTrialDays, isQuickSettingsEnabled, firstPlanSelected } = useContext(userContext);
  const { id: workspaceId, planExpiresAt: workspaceExpiresAt } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  let trialDays = userTrialDays;
  if (NEW_FEATURES.workspaces) {
    trialDays = (new Date(workspaceExpiresAt) > new Date())
      ? moment(workspaceExpiresAt).diff(moment(), 'days') + 1
      : 0;
  }

  const openPricingPage = async (): Promise<void> => {
    sendActionAnalytics('clicked buy plan', { actionInfo: 'trial bar' });
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: firstPlanSelected,
    });
  };

  return (
    <Wrapper background={'var(--ECF7FD-promo-bar-trial)'}>
      <Text>
        {translation('headerNotify.trialNotify.firstText')}
        {' '}
        {translation('headerNotify.trialNotify.secondTextNewKey', { count: trialDays })}
      </Text>
      <IconWrapperWithDescription
        onClick={openPricingPage}
        iconColor='var(--00A987-promo-bar-trial)'
        iconHoveredColor='var(--00997A-promo-bar-trial)'
        textColor='var(--00A987-promo-bar-trial)'
        textHoveredColor='var(--00997A-promo-bar-trial)'
        iconType='stroke'
      >
        <TextWitchIcon>
          {translation('header.payNow')}
        </TextWitchIcon>
        <IconArrowRight padding={0} />
      </IconWrapperWithDescription>
    </Wrapper>
  );
};

export default TrialBar;
