import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import IconProxyPencil from '../../../../ui/icons/IconProxyPencil';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { ProxyButtonEl } from '../../proxy-manager/proxy-list/styles/proxy-list-item';

interface IProxyEditButton {
  handleProxyInfoEditClick?: () => void;
}

const ProxyEditButton: React.FC<IProxyEditButton> = (props) => {
  const { handleProxyInfoEditClick } = props;

  return (
    <>
      <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.editProxy' />}>
        <ProxyButtonEl
          onClick={handleProxyInfoEditClick}
          className='proxy-update-btn'
        >
          <IconProxyPencil />
        </ProxyButtonEl>
      </TooltipWrapper>
    </>
  );
};

export default ProxyEditButton;
