import styled from '@emotion/styled';
import React, { FC, memo } from 'react';

const Container = styled.div`
  border: 1px solid var(--E8E8E8);
  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
  transition: border 0.3s ease;

  :hover {
    border: 1px solid var(--00B86D);

  }
`;

const Item = styled.span`
  max-width: 200px;
  min-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CloseBtnContainer = styled.span`
  color: var(--E8E8E8);
  padding: 4px;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 4px;

  transition: color 0.3s ease;

  :hover {
    color: var(--000000A6);
  }
`;

interface IProps {
  profileName: string;
  proxy: string;
  onDelete: () => any;
}

const profileProxyComponent: FC<IProps> = (props) => {
  return (
    <>
      <Container>
        <Item>{props.profileName}</Item>
        <Item>{props.proxy}</Item>
        <CloseBtnContainer onClick={props.onDelete}>X</CloseBtnContainer>
      </Container>
    </>
  )
}

export default memo(profileProxyComponent);
