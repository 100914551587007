import { IWorkspaceFolder, IWorkspaceFolderPermissions } from '../interfaces/workspaces';

export const filterFoldersForPermission = (
  folders: IWorkspaceFolder[],
  permission: keyof IWorkspaceFolderPermissions,
): string[] => folders
  .filter(folder => folder.permissions && folder.permissions[permission])
  .map(folder => folder.name);

interface IGetAccessibleFolderName {
  folders: IWorkspaceFolder[];
  permission: keyof IWorkspaceFolderPermissions;
  limitedAccess: boolean;
  hasFirstFolder?: boolean;
  hasSelectedFolder?: boolean;
}

export const getAccessibleFolderName = (props: IGetAccessibleFolderName): string => {
  const { folders, permission, limitedAccess, hasFirstFolder, hasSelectedFolder } = props;

  const foldersList = filterFoldersForPermission(folders, permission);
  const selectedFolder = foldersList.find(folder => folder === localStorage.getItem('SelectedFolder'));

  if (!foldersList.length) {
    return '';
  }

  if (hasSelectedFolder && selectedFolder) {
    return selectedFolder;
  }

  if (foldersList.length === 1 && limitedAccess) {
    return foldersList[0];
  }

  if (foldersList.length > 1 && limitedAccess && hasFirstFolder) {
    const sortedFolderList = foldersList.sort((a: string, b: string) => a.localeCompare(b));

    return sortedFolderList[0];
  }

  return '';
};
