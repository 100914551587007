import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IOrbitaModalBase, IOrbitaModalBaseFooter } from '../interfaces';
import ModalFooter from './modal-footer';
import { InformationText, Wrapper } from './styles';

const ModalBase: React.FC<IOrbitaModalBase> = (props) => {
  const {
    visible = false,
    handleCancel,
    handleAfterClose,
    titleKey = '',
    body = { key: '' },
    primaryButton,
    secondaryButton,
  } = props;

  const { t: translation } = useTranslation();
  const footerProps: IOrbitaModalBaseFooter = { secondaryButton, primaryButton };

  const handleAfterModalClose = (): void => {
    if (handleAfterClose) {
      handleAfterClose();
    }

    const [bodyElement] = document.getElementsByTagName('body');
    if (bodyElement.hasAttribute('style')) {
      bodyElement.removeAttribute('style');
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      destroyOnClose={true}
      afterClose={handleAfterModalClose}
      title={translation(titleKey)}
      footer={<ModalFooter {...footerProps} />}
    >
      <Wrapper>
        <InformationText>
          {translation(body.key, body.options || {})}
        </InformationText>
      </Wrapper>
    </Modal>
  );
};

export default ModalBase;
