import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import { INewNameProfile } from '../../utils/parse-name-formatting/interfaces';

export const requestFullProfileInfo = async (profileId: string) => {
  const data = await http(`${API_BASE_URL}/browser/${profileId}?withCookies=true`, {
    method: 'GET',
  });

  return data.body;
};

export const renameProfileNameMany = async (newNameProfiles: INewNameProfile[]) => {
  const data = await http(`${API_BASE_URL}/browser/name/many`, {
    method: 'PATCH',
    body: JSON.stringify(newNameProfiles),
  });

  return data.body;
};
