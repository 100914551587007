import { Atom, Getter, PrimitiveAtom, WritableAtom, getDefaultStore } from 'jotai';

import { IProxy } from '../../features/proxy/components/interfaces/IProxy';
import { IProfile } from '../../interfaces';

type EntityTabulated = IProfile | IProxy;

// copied from 'jotai' for proper typing, since cannot be imported
type SplitAtomAction<Item> = {
    type: 'remove';
    atom: PrimitiveAtom<Item>;
} | {
    type: 'insert';
    value: Item;
    before?: PrimitiveAtom<Item>;
} | {
    type: 'move';
    atom: PrimitiveAtom<Item>;
    before?: PrimitiveAtom<Item>;
};

interface IBasicTableEntity<Entity> {
  idx: number;
  atom: PrimitiveAtom<Entity>;
}

interface IBasicTableEntitiesParams<Entity extends EntityTabulated> {
  basicTableEntitiesAtom: Atom<IBasicTableEntity<Entity>[]>;
  listAtom: WritableAtom<PrimitiveAtom<Entity>[], [SplitAtomAction<Entity>], void>;
  get: Getter;
}

export const getBasicTableEntities = <Entity extends EntityTabulated>({
  basicTableEntitiesAtom,
  listAtom,
  get,
}: IBasicTableEntitiesParams<Entity>): IBasicTableEntity<Entity>[] => {
  let basicTableEntities: IBasicTableEntity<Entity>[] = [];
  try {
    basicTableEntities = get(basicTableEntitiesAtom);
  } catch (error: any) {
    if (error.message !== 'no atom init') {
      throw error;
    }
  }

  const newBasicTableEntities = get(listAtom).map((entityAtom, idx) => ({ idx, atom: entityAtom }));
  if (basicTableEntities.length !== newBasicTableEntities.length) {
    return newBasicTableEntities;
  }

  basicTableEntities.forEach(basicTableEntity => {
    const { idx, atom: prevAtom } = basicTableEntity;
    const newAtom = newBasicTableEntities[idx].atom;
    const prevAtomValue = getDefaultStore().get(prevAtom);
    const newAtomValue = getDefaultStore().get(newAtom);

    const prevEntityId = '_id' in prevAtomValue ? prevAtomValue._id : prevAtomValue.id;
    const nextEntityId = '_id' in newAtomValue ? newAtomValue._id : newAtomValue.id;

    if (prevEntityId !== nextEntityId) {
      getDefaultStore().set(prevAtom, newAtomValue);
    }
  });

  return basicTableEntities;
};

