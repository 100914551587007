import { useDroppable } from '@dnd-kit-contextless/core';
import { useAtomValue } from 'jotai';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CollapseArrow from './collapse-arrow';
import {
  GroupActionContainer,
  GroupHeaderCounter,
  GroupHeaderRow,
  GroupTagItemBody,
  PlainGroupHeaderText,
} from './styles';
import { NEW_FEATURES } from '../../../../feature-toggle';
import { createQuickProfile } from '../../features/quickProfiles/quick-create-profile/create-quick-profile.action';
import LoadableIconButton from '../../features/quickProfiles/quick-create-profile/loadable-icon-button';
import { RelativeDropPosition, calculateRelativeDropPosition } from '../../features/quickProfiles/table/use-profile-sortable';
import colors from '../../features/tags/components/colors';
import { tagsContext, templatesContext, userContext } from '../../state';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { useDraggingRowFields } from '../../state/dragging-profiles-state.atom';
import {
  IBasicTableGroupHeader,
  doesGroupHaveVisibleContent,
  shouldGroupBeVisibleInTable,
  toggleGroupProfilesSelection,
  updateGroupHeader,
  useProfilesTableGroupHeaders,
} from '../../state/profiles-list.atom';
import { IconMeatballs, IconPlus } from '../gologin-header/icons';
import GologinPopover from '../gologin-popover';
import { GologinPopoverItemRow } from '../gologin-popover/gologin-popover-item-row';
import TooltipCustom from '../tooltip-custom';
import { sendActionAnalytics } from '../../features/common/api';
import { E_ANALYTICS_ACTIONS } from '../../../common/constants/analytics';

export interface IGroupHeaderRow {
   dropPosition: RelativeDropPosition;
   addTopMargin: boolean;
   hasElements: boolean;
   isHovered: boolean;
   areActionsForceVisible?: boolean;
}

interface IGologinTableGroupHeader {
  groupHeaderItem: IBasicTableGroupHeader;
  measure: () => void;
  style: React.CSSProperties;
  rowIdx: number;
}

const DEFAULT_TAG_COLOR = 'lightgrey';

const GologinTableGroupHeader: FC<IGologinTableGroupHeader> = (props) => {
  const { groupHeaderItem, measure, style, rowIdx } = props;

  const groupHeader = useAtomValue(groupHeaderItem.atom);
  const { id: groupId, filter: groupFilter, isOpen, totalProfiles } = groupHeader;

  const allGroupHeaders = useProfilesTableGroupHeaders();
  const groupHeaderIdx = allGroupHeaders.findIndex((gh) => gh.id === groupId);
  const prevGroupHeader = allGroupHeaders[groupHeaderIdx - 1] || null;

  const doesHaveVisibleContent = doesGroupHaveVisibleContent(groupHeader);
  const doesPreviousGroupHaveVisibleContent = doesGroupHaveVisibleContent(prevGroupHeader);
  const shouldCurrenGroupBeVisible = shouldGroupBeVisibleInTable(allGroupHeaders, groupId);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement | null>(null);
  const [anchorElOptions, setAnchorElOptions] = useState<HTMLElement | null>(null);

  const { selectedFolder: selectedFolderName } = useContext(userContext);
  const templateCtx = useContext(templatesContext);

  const {
    setNodeRef,
    isOver,
  } = useDroppable({ id: `group::${rowIdx}`, disabled: !NEW_FEATURES.dragAndDrop });

  const { primaryIdx } = useDraggingRowFields(rowIdx);

  const relativeDropPosition = useMemo(
    () => calculateRelativeDropPosition(isOver, primaryIdx, rowIdx),
    [isOver, primaryIdx, rowIdx],
  );

  const { tags } = useContext(tagsContext);
  const customStatus = useMemo(
    () => tags.find(tag => tag.id === groupFilter.customStatusId),
    [tags, groupFilter.customStatusId],
  );

  const { t: translation } = useTranslation();

  const onCounterClick = (): void => {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.selectedProfilesViaProfilesGroupCounter);
    toggleGroupProfilesSelection(groupId);
  };

  const onSelectAllClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    sendActionAnalytics(E_ANALYTICS_ACTIONS.selectedProfilesViaProfilesGroupMenu);
    event.stopPropagation();
    setAnchorElOptions(null);
    toggleGroupProfilesSelection(groupId);
  };

  const onPlusClick = async (): Promise<void> => {
    let customStatusId = null;
    if (groupFilter.customStatusId) {
      ({ customStatusId } = groupFilter);
    }

    await createQuickProfile({
      workspaceId: getCurrentWorkspaceId(),
      templateCtx,
      folderName: selectedFolderName || '',
      customStatusId,
    });
  };

  const renderCounter = (): JSX.Element | null => {
    if (totalProfiles || totalProfiles === 0) {
      return (
        <>
          <GroupHeaderCounter
            onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
            onMouseLeave={(): void => setAnchorElTooltip(null)}
            onClick={onCounterClick}
          >
            {totalProfiles}
          </GroupHeaderCounter>
          <TooltipCustom
            anchorEl={anchorElTooltip}
            value={
              <Trans i18nKey='profiles.grouping.selectGroupProfiles' />
            }
          >
          </TooltipCustom>
        </>
      );
    }

    return null;
  };

  const hover = (): void => setIsHovered(true);
  const unhover = (): void => setIsHovered(false);

  const toggleIsGroupOpen = (): void => {
    const willBeOpened = !isOpen;
    if (willBeOpened) {
      sendActionAnalytics(E_ANALYTICS_ACTIONS.expandedProfilesGroup);
    } else {
      sendActionAnalytics(E_ANALYTICS_ACTIONS.collapsedProfilesGroup);
    }

    updateGroupHeader(groupId, { isOpen: willBeOpened });
  };

  const handleOptionsOpen = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorElOptions(event.currentTarget);
  };

  const handleOptionsClose = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setAnchorElOptions(null);
  };

  useEffect(measure, [doesPreviousGroupHaveVisibleContent, shouldCurrenGroupBeVisible]);

  const hoverAndToggleProps = {
    onMouseEnter: hover,
    onMouseLeave: unhover,
    onClick: toggleIsGroupOpen,
  };

  let groupName = translation('profiles.grouping.groupHeaderNoStatus');
  if (customStatus) {
    groupName = customStatus.title;
  }

  const renderBody = (): JSX.Element | null => {
    if (!customStatus) {
      return (
        <PlainGroupHeaderText {...hoverAndToggleProps}>
          <span>
            {groupName}
          </span>
        </PlainGroupHeaderText>
      );
    }

    const tagColors = colors[customStatus.color] || colors[DEFAULT_TAG_COLOR]

    return (
      <GroupTagItemBody {...tagColors} isAddMode={false} {...hoverAndToggleProps}>
        <b>
          {groupName}
        </b>
      </GroupTagItemBody>
    );
  };

  const areActionsForceVisible = !!anchorElOptions;

  if (!shouldCurrenGroupBeVisible) {
    return null;
  }

  return (
    <GroupHeaderRow
      style={style}
      ref={setNodeRef}
      dropPosition={relativeDropPosition}
      addTopMargin={doesPreviousGroupHaveVisibleContent}
      hasElements={doesHaveVisibleContent}
      isHovered={isHovered}
      areActionsForceVisible={!!areActionsForceVisible}
    >
      <CollapseArrow
        isOpen={isOpen}
        {...hoverAndToggleProps}
      />
      {renderBody()}
      {renderCounter()}
      <GroupActionContainer onClick={handleOptionsOpen}>
        <IconMeatballs padding={0} iconColor='var(--98989F)' iconHoveredColor='var(--36363D)' />
        <GologinPopover
          anchorEl={anchorElOptions}
          onClose={handleOptionsClose}
          extraTranslate={{ bottom: 8 }}
          width={172}
        >
          <GologinPopoverItemRow onClick={onSelectAllClick}>
            <Trans i18nKey='profiles.grouping.selectAllGroupProfiles' />
          </GologinPopoverItemRow>
        </GologinPopover>
      </GroupActionContainer>
      <GroupActionContainer onClick={onPlusClick}>
        <LoadableIconButton
          onClick={onPlusClick}
          tooltipContent={<Trans i18nKey='profiles.grouping.createProfileInGroup' values={{ groupName }} />}
          icon={<IconPlus padding={0} iconColor='var(--98989F)' iconHoveredColor='var(--36363D)' />}
        />
      </GroupActionContainer>
    </GroupHeaderRow>
  );
};

export default GologinTableGroupHeader;
