import styled from '@emotion/styled';
import { Input } from 'antd';
import React, { FC, KeyboardEvent, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { userContext, workspaceContext } from '../../../../state';
import { GreenSubmitButton, ModernModal } from '../../../../ui/modern-modal';
import { createWorkspace } from '../../../common/api';
import onWorkspaceCreated from '../../../common/on-workspace-created';

const CustomInput = styled(Input)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--222222-modals-create-workspace);

  padding: 6px 12px;
  &::placeholder {
    color: var(--767676-modals-create-workspace);
    opacity: 0.8;
  }
`;

interface ICreateWorkspaceModal {
  visible: boolean;
  onClose: () => void;
}

const CreateWorkspaceModal: FC<ICreateWorkspaceModal> = props => {
  const { visible, onClose } = props;

  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const userCtx = useContext(userContext);
  const workspaceCtx = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    setIsSubmitDisabled(!workspaceName.trim());
  }, [workspaceName]);

  const onCreateWorkspace = async (): Promise<void> => {
    if (isSubmitDisabled) {
      return;
    }

    onClose();

    const createdWorkspace = await createWorkspace(workspaceName);
    await onWorkspaceCreated(createdWorkspace.id, workspaceCtx, userCtx);
    setWorkspaceName('');
    setIsSubmitDisabled(true);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCreateWorkspace();
    }
  };

  return (
    <ModernModal
      visible={visible}
      onCancel={(): void => {
        onClose();
        setWorkspaceName('');
      }}
      width={380}
      title={translation('modals.createWorkspace.title')}
      footer={null}
    >
      <div style={{ margin: '16px 0' }}>
        <Trans i18nKey='modals.createWorkspace.description' />
      </div>
      <CustomInput
        value={workspaceName}
        onChange={(event): void => setWorkspaceName(event.currentTarget.value)}
        placeholder={translation('modals.createWorkspace.workspaceName') || ''}
        autoFocus={true}
        onKeyPress={onKeyPress}
      />
      <GreenSubmitButton style={{ margin: '32px auto 0' }} onClick={onCreateWorkspace} disabled={isSubmitDisabled}>
        {translation('modals.createWorkspace.create')}
      </GreenSubmitButton>
    </ModernModal>
  );
};

export default CreateWorkspaceModal;
