import { Icon, Table, Tooltip, Menu, message } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { history } from '../../../../services';
import { exportWorkspaceProfiles, profilesExport } from './api';
import { ButtonConfirm, ContainerNameCol, TableFooter, TableProfile } from './styles';
import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { workspaceContext } from '../../../../state';

declare interface ICookiesList {
  objectOfImportedProfiles: any;
  toggleProfileImportModalVisible: (arg: boolean) => void;
  changeCurrentWindow: (arg: 'loader'|'resultList') => void;
}

const ProfilesImportResultList: FC<ICookiesList> = (props) => {
  const [currentMenuItem, setCurrentMenuItem] = useState<'successful_profiles'|'created_profiles'|'updated_profiles'|'errors'>('created_profiles');
  const [currentProfileTable, setCurrentProfileTable] = useState(props.objectOfImportedProfiles.createdProfiles);
  const [successfulProfilesCount, setSuccessfulProfilesCount] = useState(0);
  const [currentExportButton, setCurrentExportButton] = useState<string>('Export successfully profiles');
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const { id: workspaceId } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  useEffect(() => {
    setCurrentExportButton(translation('importProfileModal.resultForm.exportBtn.successProfiles'));
  }, []);

  const responseObject = props.objectOfImportedProfiles;

  const exportProfiles = async () => {
    setIsExportLoading(true);

    let csvString: string;

    if (currentMenuItem !== 'errors') {
      const browsersIds = currentProfileTable.map((profile: any) => profile._id);

      if (NEW_FEATURES.workspaces) {
        csvString = await exportWorkspaceProfiles(workspaceId, browsersIds);
      } else {
        csvString = await profilesExport(browsersIds);
      }
    } else {
      const csvStringsArray = currentProfileTable.map((element: any) => element.csvString?.split('\n'));

      const header = csvStringsArray[0][0] + '\n';

      const values = csvStringsArray.map((profileArrayWithHeader: any[]) => profileArrayWithHeader[1] + '\n');
      const arrayWithHeader = [header, ...values];
      csvString = arrayWithHeader.join('');
    }

    const saveFileElement = document.createElement('a');
    const outputFile = new Blob([csvString], {type: 'text/plain'});
    const date = moment().utc().format('YYYYMMDD');

    saveFileElement.href = URL.createObjectURL(outputFile);
    saveFileElement.download = `gologin_profiles_export_${date}.csv`;

    setIsExportLoading(false);
    saveFileElement.click();
  };

  const getLoader = () => {
    if (!isExportLoading) {
      return null;
    }

    return (
      <Icon type='loading' />
    );
  };

  const showButtonExport = () =>
    (
      <a
        onClick={exportProfiles}
        style={{ textDecoration: 'underline' }}
      >
        {Object.keys(currentProfileTable).length ? currentExportButton : ''}
        {getLoader()}
      </a>
    );

  const handleClose = () => {
    props.toggleProfileImportModalVisible(false);
    props.changeCurrentWindow('loader');
    history.replace('/');
  };

  const showButtonConfirm = () =>
    (
      <ButtonConfirm
        onClick={handleClose}
      >
        {translation('importProfileModal.submitBtn.label')}
      </ButtonConfirm>
    );

  const getProfiles = () => {
    if (!Object.keys(props.objectOfImportedProfiles).length) {
      return;
    }

    const successfulProfiles = [...responseObject.createdProfiles, ...responseObject.updatedProfiles];

    if (successfulProfiles.length) {
      message.success(`${successfulProfiles.length} ${translation('importProfile.result.success')}`);
    }

    setSuccessfulProfilesCount(successfulProfiles.length);
    setCurrentProfileTable(successfulProfiles);
    setCurrentMenuItem('successful_profiles');
  };

  useEffect(() => {
    getProfiles();
  }, [props.objectOfImportedProfiles]);

  const getSuccessfulProfiles = () => {
    setCurrentProfileTable([...responseObject.createdProfiles, ...responseObject.updatedProfiles]);
    setCurrentMenuItem('successful_profiles');
    setCurrentExportButton(translation('importProfileModal.resultForm.exportBtn.successProfiles'));
  };

  const getCreatedProfiles = () => {
    setCurrentProfileTable(responseObject.createdProfiles);
    setCurrentMenuItem('created_profiles');
    setCurrentExportButton(translation('importProfileModal.resultForm.exportBtn.createdProfiles'));
  };

  const getUpdatedProfiles = () => {
    setCurrentProfileTable(responseObject.updatedProfiles);
    setCurrentMenuItem('updated_profiles');
    setCurrentExportButton(translation('importProfileModal.resultForm.exportBtn.updatedProfiles'));
  };

  const getErrors = () => {
    setCurrentProfileTable(responseObject.profilesWithErrors);
    setCurrentMenuItem('errors');
    setCurrentExportButton(translation('importProfileModal.resultForm.exportBtn.wrongProfiles'));
  };

  const navigatorMenu = () =>
    (
      <Menu selectedKeys={[currentMenuItem]} mode='horizontal'>
        <Menu.Item onClick={getSuccessfulProfiles} key='successful_profiles' disabled={false}>
          {translation('importProfileModal.resultForm.counter.success')}({successfulProfilesCount})
        </Menu.Item>
        <Menu.Item onClick={getCreatedProfiles} key='created_profiles' disabled={false}>
          {translation('importProfileModal.resultForm.counter.created')}({responseObject.createdProfiles?.length || 0})
        </Menu.Item>
        <Menu.Item onClick={getUpdatedProfiles} key='updated_profiles' disabled={false}>
          {translation('importProfileModal.resultForm.counter.updated')}({responseObject.updatedProfiles?.length || 0})
        </Menu.Item>
        <Menu.Item onClick={getErrors} key='errors' disabled={false}>
          {translation('importProfileModal.resultForm.counter.errors')}({responseObject.profilesWithErrors?.length || 0})
        </Menu.Item>
      </Menu>
    );

  const getColumns = () => {
    const columnsArray = [
      (
        <Table.Column
          title={(
            <ContainerNameCol>
              <span>
                {translation('importProfileModal.confirmationForm.columns.name')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='name'
          key='name'
          sorter={(a: any, b: any) => a.name.localeCompare(b.name)}
        />
      ),
      (
        <Table.Column
          title={(
            <ContainerNameCol>
              <span>
                {translation('importProfileModal.confirmationForm.columns.proxy')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='proxy'
          key='proxy'
          sorter={(a: any, b: any) => a.proxy.localeCompare(b.proxy)}
          render={((value: string) => value?.length > 32 ?  <Tooltip title={value}>{value?.slice(0, 32)}...</Tooltip>  : value)}
        />
      ),
    ];

    if (currentMenuItem !== 'errors') {
      const createdAndUpdatedColumns = [
        (
          <Table.Column
            title={(
              <ContainerNameCol>
                <span>{translation('importProfileModal.confirmationForm.columns.os')}</span>
              </ContainerNameCol>
            )}
            dataIndex='os'
            key='os'
            sorter={(a: any, b: any) => a.os.localeCompare(b.os)}
          />
        ),
        (
          <Table.Column
            title={(
              <ContainerNameCol>
                <span>{translation('importProfileModal.confirmationForm.columns._id')}</span>
              </ContainerNameCol>
            )}
            dataIndex='_id'
            key='_id'
            sorter={(a: any, b: any) => a._id.localeCompare(b._id)}
          />
        ),
      ];

      return columnsArray.concat(createdAndUpdatedColumns);
    }

    const errorsColumns = [
      (
        <Table.Column
          title={(
            <ContainerNameCol>
              <span>{translation('importProfileModal.messages.error')}</span>
            </ContainerNameCol>
          )}
          dataIndex='errorMessage'
          key='errorMessage'
          sorter={(a: any, b: any) => a.errorMessage.localeCompare(b.errorMessage)}
          render={((value: string) => value?.length > 50 ?  <Tooltip title={value}>{value?.slice(0, 50)}...</Tooltip>  : value)}
        />
      ),
    ];

    return columnsArray.concat(errorsColumns);
  };

  return (
    <>
      {navigatorMenu()}
      <TableProfile
        pagination={false}
        rowKey='name'
        dataSource={currentProfileTable}
        scroll={{ y: 500 }}
      >
        {getColumns()}
      </TableProfile>
      <TableFooter>
        {showButtonExport()}
        {showButtonConfirm()}
      </TableFooter>
    </>
  );
};

export default ProfilesImportResultList;
