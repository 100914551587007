import { API_BASE_URL } from '../../../common/constants/constants';
import { IPayment } from '../../interfaces';
import { http } from '../../services';
import { IPromoGifts } from '../pricing/interfaces';

interface IPasswords {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}
export const changePassword = async (passwords: IPasswords) => {
  const body = JSON.stringify(passwords);

  return http(`${API_BASE_URL}/user/password/manual`, {
    method: 'PATCH',
    body,
  });
};

export const requestPayments = async (): Promise<IPayment[]> => {
  try {
    const data = await http(`${API_BASE_URL}/billing/payments`);

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const requestPaymentsWorkspace = async (workspaceId: string): Promise<IPayment[]> =>
  http(`${API_BASE_URL}/billing/${workspaceId}/payments`)
    .then((res: any) => res.body);

export const sendPromo = async (promo: string, workspaceId: string): Promise<IPromoGifts> => {
  const requestBody = JSON.stringify({
    code: promo,
    workspaceId,
  });

  const req = await http(`${API_BASE_URL}/user/promo`, {
    method: 'POST',
    body: requestBody,
  });

  return req.body;
};

export const changeEmail = async (email: string, password: string) => {
  const requestData = JSON.stringify({
    email,
    password,
  });

  const changeEmailRequest = await http(`${API_BASE_URL}/user/email`, {
    method: 'PATCH',
    body: requestData,
  });
  return changeEmailRequest.body;
};

export const confirmEmailAddress = async () => {
  await http(`${API_BASE_URL}/user/email/confirm`, {
    method: 'POST',
  });
};

export const deleteBillingCard = async () => {
  try {
    await http(`${API_BASE_URL}/billing/card`, {
      method: 'DELETE'
    });

    return null;
  } catch (error) {
    throw error;
  }
};

export const deleteSigmaBillingCard = async (): Promise<void> =>
  http(`${API_BASE_URL}/billing/sigma/card`, {
    method: 'DELETE',
  });

export const deleteBillingPayPal = async (paypalSubscriptionId: string): Promise<void> => {
  await http(`${API_BASE_URL}/billing/paypal/subscriptions/${paypalSubscriptionId}`, {
    method: 'DELETE',
  });
};

export const changePlan = async (planId: string) => {
  try {
    return http(`${API_BASE_URL}/billing/plan`, {
      method: 'POST',
      body: JSON.stringify({
        planId,
      }),
    });
  } catch (error) {
    throw error;
  }
};
