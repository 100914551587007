import deepEqual from 'deep-equal';
import React, { FC, memo, useEffect, useMemo } from 'react';

import { GologinTableItemCell } from './styles';
import { RowKey } from './types';
import { IProfilesTableColumn } from '../../features/quickProfiles/components/interfaces/app-settings.interface';
import ProfileRow from '../../features/quickProfiles/table/profile-row';
import { IBasicTableEntity } from '../../state/profiles-list.atom';
import { setProfilesTableRowMeasurer } from '../../state/profiles-table-row-measurers.atom';

interface IGologinTableRow {
  columns: IProfilesTableColumn[];
  dataItem: IBasicTableEntity;
  useIsSelected: (rowKey: RowKey) => boolean;

  index: number;
  key: string;
  style: React.CSSProperties;
  measure: () => void;
}

const GologinTableRow: FC<IGologinTableRow> = (props) => {
  const { columns, dataItem, useIsSelected, index, key: elemKey, style, measure } = props;

  const isSelected = useIsSelected(dataItem.idx);

  const cells = useMemo(() => (
    <>
      {columns.map((column) => (
        <GologinTableItemCell
          key={column.colName}
          style={{
            minWidth: `${column.width}px`,
            width: `${column.width}px`,
          }}
          className={column.className}
        >
          {column.render ? column.render('', dataItem) : <div></div>}
        </GologinTableItemCell>
      ))}
    </>
  ), [columns, dataItem]);

  useEffect(() => {
    measure();
  }, [columns, dataItem, measure]);

  useEffect(() => {
    setProfilesTableRowMeasurer(index, measure);
  }, [measure]);

  return (
    <ProfileRow
      tableRowIndex={index}
      key={elemKey}
      style={style}
      className={isSelected ? 'row-selected' : ''}
    >
      {cells}
    </ProfileRow>
  );
};

export default memo(GologinTableRow, (prevProps, nextProps) => (
  prevProps.columns === nextProps.columns &&
  prevProps.dataItem.atom === nextProps.dataItem.atom &&
  prevProps.dataItem.idx === nextProps.dataItem.idx &&
  prevProps.index === nextProps.index &&
  prevProps.key === nextProps.key &&
  prevProps.style.top === nextProps.style.top &&
  prevProps.style.height === nextProps.style.height
));
