import React, { useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';

import EmptyList from './empty-list';
import ProxyRow from './proxy-row';
import { ProxyListContentEl } from './styles/proxy-list';
import { isNotNull } from '../../../../../common/typescript/predicates';
import { IProxy, IArchivedProxy } from '../../../../interfaces';
import { useFilteredProxyList } from '../../../../state/proxy/proxy-list.atom';
import { setProxyManagerCurrentProxy } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import {
  switchIsSelectProxyModeOpened,
  useConfirmBlockVisible,
  useIsSelectProxyModeOpened,
  useIsProxyContextMenuVisible, hideProxyContextMenu,
} from '../../../../state/proxy-select-menu.atom';
import ContextMenu from '../../components/context-menu';
import ProxyListMenu from '../../components/proxy-list-menu';
import RemovingConfirm from '../../components/removing-confirm';
import { getIsProxyArchived } from '../../proxy-helpers';
import { PROXY_MANAGER_SIZE } from '../styles';

export interface IProxyList {
  currentProxy?: IProxy;
  localProxyElementLocation: string | null;
}

const ProxyList: React.FC<IProxyList> = (props) => {
  const { currentProxy, localProxyElementLocation } = props;

  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const confirmBlockVisible = useConfirmBlockVisible();
  const isProxyContextMenuVisible = useIsProxyContextMenuVisible();

  const ref = useRef<HTMLDivElement>(null);

  const filteredProxies = useFilteredProxyList();
  const { selectedProxies } = useSelectedProxies();

  useEffect(() => {
    if (currentProxy?.mode !== 'none') {
      setProxyManagerCurrentProxy(currentProxy);
    }

    return () => {
      if (!getIsProxyArchived(currentProxy)) {
        setProxyManagerCurrentProxy(currentProxy);
      }
    };
  }, []);

  useEffect(() => {
    if (!selectedProxies.length) {
      switchIsSelectProxyModeOpened(false);
    }
  }, [selectedProxies]);

  useEffect(() => {
    const handleScroll = (): void => {
      hideProxyContextMenu();
    };

    if (ref?.current) {
      if (isProxyContextMenuVisible) {
        ref.current.addEventListener('scroll', handleScroll);
      } else {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    }

    return (): void => {
      if (ref?.current) {
        ref.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isProxyContextMenuVisible]);

  const proxiesVisible: (IProxy|IArchivedProxy|null)[] = filteredProxies.filter(proxy => !proxy.isInvisible);
  const isCurrentProxyNotInList = currentProxy?.id && !proxiesVisible.filter(isNotNull).some(({ id }) => id === currentProxy.id);
  if (isCurrentProxyNotInList) {
    proxiesVisible.unshift(currentProxy);
  }

  // `null` to make the last proxy in the list appear above the delete/copy menu panel
  if (isSelectProxyModeOpened) {
    proxiesVisible.push(null);
  }

  const renderConfirm = (): JSX.Element | null => {
    if (!confirmBlockVisible) {
      return null;
    }

    return <RemovingConfirm />;
  };

  if (!proxiesVisible.length) {
    return <EmptyList proxySelectorLocation={localProxyElementLocation} />;
  }

  return (
    <>
      <ProxyListContentEl>
        <div style={{ width: PROXY_MANAGER_SIZE.width }}>
          <FixedSizeList
            height={PROXY_MANAGER_SIZE.height}
            width={PROXY_MANAGER_SIZE.width}
            itemCount={proxiesVisible.length}
            itemData={{
              filteredProxies: proxiesVisible,
              currentProxy,
              localProxyElementLocation,
            }}
            itemSize={41}
            outerRef={ref}
          >
            {ProxyRow}
          </FixedSizeList>
        </div>
        <ProxyListMenu />
      </ProxyListContentEl>
      <ContextMenu />
      {renderConfirm()}
    </>
  );
};

export default ProxyList;
