import { zeroProfileBookmarks } from '../../electron/constants/zero-profile-bookmarks';
import { IFullProfile } from '../interfaces';

export const DEFAULT_PROFILE_DATA: IFullProfile = {
  id: '',
  canBeRunning: false,
  createdAt: '',
  updatedAt: '',
  audioContext: { mode: 'noise' },
  browserType: 'chrome',
  canvas: { mode: 'noise' },
  clientRects: { mode: 'noise' },
  dns: '',
  extensions: { enabled: true, names: [] },
  fonts: { families: [] },
  devicePixelRatio: 1,
  geolocation: {
    customize: true,
    enabled: true,
    fillBasedOnIp: true,
    mode: 'prompt',
    latitude: 0,
    longitude: 0,
  },
  tags: [],
  autoLang: true,
  googleServicesEnabled: false,
  lockEnabled: false,
  mediaDevices: { videoInputs: 1, audioInputs: 1, audioOutputs: 1 },
  name: '',
  notes: '',
  os: 'win',
  osSpec: '',
  isM1: false,
  plugins: { enableVulnerable: true, enableFlash: true },
  proxy: { mode: 'none', host: '', port: 80, username: '', password: '', autoProxyRegion: 'us', torProxyRegion: 'us' },
  proxyEnabled: false,
  startUrl: '',
  storage: {
    local: true,
    extensions: true,
    bookmarks: true,
    history: true,
    passwords: true,
    session: true,
    indexedDb: true,
    enableExternalExtensions: false,
  },
  timezone: {
    enabled: true,
    fillBasedOnIp: true,
    timezone: '',
  },
  webGL: { mode: 'noise' },
  webGLMetadata: { mode: 'mask', vendor: 'Google Inc.' },
  webglParams: {
    glParamValues: [],
    supportedFunctions: [],
    extensions: [],
  },
  webRTC: {
    enable: true,
    isEmptyIceList: true,
    mode: 'public',
  },
  chromeExtensions: [],
  userChromeExtensions: [],
  navigator: {
    userAgent: '',
    resolution: '',
    language: '',
    platform: '',
    hardwareConcurrency: 2,
    deviceMemory: 2,
    doNotTrack: false,
  },
  bookmarks: zeroProfileBookmarks.roots,
  launchArguments: '',
  isFullProfile: true,
};
