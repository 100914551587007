import { TFunction } from 'i18next';
import { Atom, atom, getDefaultStore, useAtomValue } from 'jotai';
import { splitAtom } from 'jotai/utils';

import { resetProfileRunStatuses } from './profile-run-statuses.atom';
import { resetProfilesTableProxyIdFilter } from './profiles-proxy-filter.atom';
import { closeProfilesSettings } from './profiles-settings-atom';
import { loadGroupsFromLocalStorage, saveGroupsToLocalStorage } from './profiles-table/groups-local-storage';
import { resetProfilesQueryOffset } from './profiles-table/profiles-query';
import { closeProfileTableModal } from './profiles-table-modal.atom';
import { resetProfilesTableSelectedIds, toggleProfilesSelected } from './profiles-table-selected-ids.atom';
import { closeQuickPricing } from './quick-pricing.atom';
import { resetToDefaultSearchState } from './search-state.atom';
import { getTagsList } from './tags/tags-list.atom';
import { NEW_FEATURES } from '../../../feature-toggle';
import { isNotNull } from '../../common/typescript/predicates';
import { IUpdateProfileStatusEvent } from '../../electron/interfaces/profile.status.manager.interfaces';
import { IProfilesResponse } from '../features/quickProfiles/api';
import { ITag } from '../features/tags/interfaces/tag.interface';
import { IProfile } from '../interfaces';
import { IGroupHeader, GroupHeaderLoadingStatus } from '../interfaces/group-header.interface';
import { IWorkspaceProfilePermissions } from '../interfaces/workspaces';
import { ProfileStatusType } from '../types';

const profilesListAtom = atom<IProfile[]>([]);
const profileAtomListAtom = splitAtom(profilesListAtom);

const profilesTableGroupHeadersAtom = atom<IGroupHeader[]>([]);
const profilesTableGroupHeadersListAtom = splitAtom(profilesTableGroupHeadersAtom);

export type GroupField = IGroupHeader['filter']['type'] | null;

export const profilesTableGroupFieldAtom = atom<GroupField>((get) => {
  const groupHeaders = get(profilesTableGroupHeadersAtom);

  return groupHeaders[0]?.filter.type || null;
});

export const useIsProfilesTableGrouped = (): boolean => {
  const groupField = useAtomValue(profilesTableGroupFieldAtom);

  return !!groupField;
};

export const getIsProfilesTableGrouped = (): boolean => {
  const groupField = getDefaultStore().get(profilesTableGroupFieldAtom);

  return !!groupField;
};

export const useProfilesTableGroupField = (): GroupField => useAtomValue(profilesTableGroupFieldAtom);
export const getProfilesTableGroupField = (): GroupField => getDefaultStore().get(profilesTableGroupFieldAtom);

export const filterProfileByGroupHeader = (groupHeader: IGroupHeader, profile: IProfile): boolean => {
  const { filter: groupFilter } = groupHeader;
  const { type, customStatusId } = groupFilter;
  switch (type) {
    case 'custom-status':
      if (!groupFilter.customStatusId) {
        return !profile.tags.find(tag => tag.field === type);
      }

      return !!profile.tags.find(tag => tag.field === type && tag.id === customStatusId);
    default:
      console.warn(`unrecognized group type: ${type}`);

      return false;
  }
};

export const NO_CUSTOM_STATUS_GROUP_ID = 'no-custom-status';

const makeGroupHeadersForCustomStatuses = (allTags: ITag[]): IGroupHeader[] => allTags
  .reduce<IGroupHeader[]>((acc, tag) => {
    if (tag.field !== 'custom-status') {
      return acc;
    }

    acc.push({
      id: tag.id,
      isGroupHeader: true,
      filter: { type: 'custom-status', customStatusId: tag.id },
      isOpen: true,
      loadingStatus: 'unloaded',
      totalProfiles: null,
    });

    return acc;
  }, [])
  .concat([{
    id: NO_CUSTOM_STATUS_GROUP_ID,
    isGroupHeader: true,
    filter: { type: 'custom-status', customStatusId: null },
    isOpen: true,
    loadingStatus: 'unloaded',
    totalProfiles: null,
  }]);

export const groupProfilesTableByCustomStatus = (): void => {
  const allTags = getTagsList();
  const groupHeaders = makeGroupHeadersForCustomStatuses(allTags);
  setProfilesTableGroupHeaders(groupHeaders);
  updateGroupHeader(groupHeaders[0]?.id, { loadingStatus: 'loading-initiated' });
};

export const reconcileCustomStatusGroups = (): void => {
  if (getProfilesTableGroupField() !== 'custom-status') {
    return;
  }

  const allTags = getTagsList();
  const prevGroupHeaders = getProfilesTableGroupHeaders();
  const generatedGroupHeaders = makeGroupHeadersForCustomStatuses(allTags);
  const newGroupHeaders = generatedGroupHeaders.map((generatedGroupHeader) => {
    const prevGroupHeader = prevGroupHeaders.find(({ id }) => id === generatedGroupHeader.id);
    let groupStatusFields = { ...prevGroupHeader };
    if (!prevGroupHeader) {
      const createdGroupProfiles = getProfilesByGroupHeaderObj(generatedGroupHeader);
      groupStatusFields = { totalProfiles: createdGroupProfiles.length, loadingStatus: 'loaded' };
    }

    return {
      ...generatedGroupHeader,
      ...groupStatusFields,
    };
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export const resetProfilesTableGroups = (): void => {
  setProfilesTableGroupHeaders([]);
};

export const reloadProfilesTableGroups = (): void => {
  const localStorageGroupsState = loadGroupsFromLocalStorage();
  switch (localStorageGroupsState?.field) {
    case 'custom-status':
      groupProfilesTableByCustomStatus();
      break;
    default:
  }
};

export const resetProfilesTableGroupStatus = (): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const unloadedStatus: GroupHeaderLoadingStatus = 'unloaded';
  const loadingInitiatedStatus: GroupHeaderLoadingStatus = 'loading-initiated';
  const newGroupHeaders = groupHeaders.map((groupHeader, groupIdx) => ({
    ...groupHeader,
    loadingStatus: groupIdx ? unloadedStatus : loadingInitiatedStatus,
    totalProfiles: null,
  }));

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export interface IProfileRunStatus {
  id: IProfile['id'];
  status: ProfileStatusType;
  statusMessage?: IUpdateProfileStatusEvent['message'];
  isWeb?: boolean;
  remoteOrbitaUrl?: string;
}

export interface IBasicTableEntity {
  idx: number;
  atom: Atom<IProfile | IGroupHeader>;
}

export interface IBasicTableGroupHeader {
  idx: number;
  atom: Atom<IGroupHeader>;
}

export const isGroupHeader = (dataItem: IProfile | IGroupHeader): dataItem is IGroupHeader => (dataItem as IGroupHeader).isGroupHeader;

export const isBasicTableEntityGroupHeader = (entity: IBasicTableEntity): entity is IBasicTableGroupHeader => {
  const dataObj = getDefaultStore().get(entity.atom);

  return isGroupHeader(dataObj);
};

const basicTableProfilesAtom: Atom<IBasicTableEntity[]> = atom<IBasicTableEntity[]>((get) => {
  let basicTableEntities: IBasicTableEntity[] = [];
  try {
    basicTableEntities = get(basicTableProfilesAtom);
  } catch (error: any) {
    if (error.message !== 'no atom init') {
      throw error;
    }
  }

  const groupHeaders = get(profilesTableGroupHeadersAtom);
  const groupHeaderAtoms = get(profilesTableGroupHeadersListAtom);
  const profiles = get(profilesListAtom);
  const profileAtoms = get(profileAtomListAtom);

  if (!groupHeaders.length) {
    const newBasicTableEntities = profileAtoms.map((profileAtom, idx) => ({ idx, atom: profileAtom }));

    if (basicTableEntities.length !== newBasicTableEntities.length) {
      return newBasicTableEntities;
    }

    basicTableEntities.forEach(basicTableEntity => {
      const { idx, atom: prevAtom } = basicTableEntity;
      const newAtom = newBasicTableEntities[idx].atom;
      const prevAtomValue = getDefaultStore().get(prevAtom);
      const newAtomValue = getDefaultStore().get(newAtom);

      if (prevAtomValue.id !== newAtomValue.id) {
        getDefaultStore().set(prevAtom, newAtomValue);
      }
    });

    return basicTableEntities;
  }

  const groups = groupHeaders.map((groupHeader) => {
    const groupProfileIndexes = profiles.reduce<number[]>((acc, profile, index) => {
      if (filterProfileByGroupHeader(groupHeader, profile)) {
        acc.push(index);
      }

      return acc;
    }, []);

    const actualProfilesCount = groupProfileIndexes.length;
    if (groupHeader.loadingStatus === 'loaded' && groupHeader.totalProfiles !== actualProfilesCount) {
      updateGroupHeader(groupHeader.id, { totalProfiles: actualProfilesCount });
    }

    if (!groupHeader.isOpen) {
      return { ...groupHeader, profileIndexes: [] };
    }

    return { ...groupHeader, profileIndexes: groupProfileIndexes };
  });

  const newBasicTableEntities = groups.reduce<IBasicTableEntity[]>((acc, group, groupIdx) => {
    const { profileIndexes } = group;

    acc.push({ idx: acc.length, atom: groupHeaderAtoms[groupIdx] });
    acc.push(...profileIndexes.map((profileIdx, arrIdx) => ({ idx: acc.length + arrIdx, atom: profileAtoms[profileIdx] })));

    return acc;
  }, []);

  // TODO: update targeted atoms and not whole array all the time
  // it is blocked by group/profiles atoms overriding each other
  // not too easy to fix right now
  return newBasicTableEntities;
});

export const useBasicTableProfiles = (): IBasicTableEntity[] => useAtomValue(basicTableProfilesAtom);
export const getBasicTableProfiles = (): IBasicTableEntity[] => getDefaultStore().get(basicTableProfilesAtom);

export const getBasicTableProfileById = (profileId: string): IBasicTableEntity | null => getBasicTableProfiles().find(
  basicTableProfile => getDefaultStore().get(basicTableProfile.atom)?.id === profileId,
) || null;

export const getProfileByBasicProfilesIdx = (idx: number): IProfile | null => {
  const basicTableProfiles = getBasicTableProfiles();
  const basicTableProfile = basicTableProfiles[idx];
  if (!basicTableProfile?.atom) {
    return null;
  }

  const entityAtom = basicTableProfile.atom;
  const entity = getDefaultStore().get(entityAtom);
  if (!entity || isGroupHeader(entity)) {
    return null;
  }

  return entity;
};

export const getBasicTableProfileIds = (): string[] => getBasicTableProfiles().map(
  basicTableProfile => {
    const basicTableEntity = getDefaultStore().get(basicTableProfile.atom);
    if (basicTableEntity && !isGroupHeader(basicTableEntity)) {
      return basicTableEntity.id;
    }

    return null;
  },
).filter(isNotNull);

export const getBasicTableEntities = (): (IProfile | IGroupHeader)[] => getBasicTableProfiles().map(
  basicTableProfile => getDefaultStore().get(basicTableProfile.atom),
).filter(isNotNull);

export const useProfilesList = (): IProfile[] => useAtomValue(profilesListAtom);
export const getProfilesList = (): IProfile[] => getDefaultStore().get(profilesListAtom);
export const setProfilesList = (newProfiles: IProfile[]): void => getDefaultStore().set(profilesListAtom, newProfiles);

export const useProfilesTableGroupHeaders = (): IGroupHeader[] => useAtomValue(profilesTableGroupHeadersAtom);
export const getProfilesTableGroupHeaders = (): IGroupHeader[] => getDefaultStore().get(profilesTableGroupHeadersAtom);
const setProfilesTableGroupHeaders = (newGroups: IGroupHeader[]): void => {
  if (!NEW_FEATURES.header) {
    getDefaultStore().set(profilesTableGroupHeadersAtom, []);

    return;
  }

  getDefaultStore().set(profilesTableGroupHeadersAtom, newGroups);
  saveGroupsToLocalStorage(newGroups);
};

export const doesGroupHaveVisibleContent = (groupHeader: IGroupHeader | null): boolean => {
  if (!groupHeader) {
    return false;
  }

  const { isOpen, totalProfiles } = groupHeader;

  return !!(isOpen && totalProfiles);
};


export const getGroupHeaderById = (groupHeaderId: IGroupHeader['id']): IGroupHeader | null => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const groupHeader = groupHeaders.find((groupHeader) => groupHeader.id === groupHeaderId);

  return groupHeader || null;
};

export const updateGroupHeader = (groupHeaderId: IGroupHeader['id'], fieldsToUpdate: Partial<IGroupHeader>): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  setProfilesTableGroupHeaders(groupHeaders.map((groupHeader) => {
    if (groupHeader.id === groupHeaderId) {
      return { ...groupHeader, ...fieldsToUpdate };
    }

    return groupHeader;
  }));
};

export const shouldGroupBeVisibleInTable = (groupHeaders: IGroupHeader[], groupHeaderId: IGroupHeader['id']): boolean => {
  const firstUnloadedOrCurrentGroup = groupHeaders.find((groupHeader) =>
    groupHeader.loadingStatus === 'unloaded'
    || groupHeader.id === groupHeaderId);

  return firstUnloadedOrCurrentGroup?.loadingStatus !== 'unloaded';
};

export const mapAndSetProfilesList = (mapProfilesList: (prevProfiles: IProfile[]) => IProfile[]): void =>
  getDefaultStore().set(profilesListAtom, mapProfilesList(getDefaultStore().get(profilesListAtom)));

export const editProfilesListFields = (profileIds: string[], newData: Partial<IProfile>): void =>
  mapAndSetProfilesList((profiles) => profiles.map((profile) => {
    if (profileIds.includes(profile.id)) {
      return { ...profile, ...newData };
    }

    return profile;
  }));

export const filterProfilesByPermission = (
  profilesIds: string[],
  permission: keyof IWorkspaceProfilePermissions,
): IProfile[] => getProfilesList()
  .filter(profile => profilesIds.find(profileId => profileId === profile.id && profile.permissions[permission]));

export const resetProfilesList = (): void => {
  setProfilesList([]);
  resetProfilesTableSelectedIds();
  resetProfileRunStatuses();
  resetProfilesQueryOffset();
  resetProfilesTableGroupStatus();
};

export const resetProfilesTable = (): void => {
  resetProfilesList();
  closeProfilesSettings();
  closeProfileTableModal();
  closeQuickPricing();
  resetToDefaultSearchState();
  resetProfilesTableProxyIdFilter();
  resetProfilesTableGroups();
};

const getProfilesByGroupHeaderObj = (groupHeader: IGroupHeader): IProfile[] => {
  const profiles = getProfilesList();

  return profiles.filter((profile) => filterProfileByGroupHeader(groupHeader, profile));
};

export const getGroupProfiles = (groupHeaderId: string): IProfile[] => {
  const groupHeader = getGroupHeaderById(groupHeaderId);
  if (!groupHeader) {
    return [];
  }

  return getProfilesByGroupHeaderObj(groupHeader);
};

export const getGroupProfilesCount = (groupHeaderId: string): number => getGroupProfiles(groupHeaderId).length;

type IUpdateGroupHeadersStatus = Pick<IProfilesResponse, 'groupsMetadata'>;

export const updateProfilesTableGroupHeadersStatus = (profilesResponse: IUpdateGroupHeadersStatus): void => {
  const { groupsMetadata } = profilesResponse;
  if (!groupsMetadata) {
    return;
  }

  const newGroupHeaders = getProfilesTableGroupHeaders().map((groupHeader) => {
    const { id: groupId } = groupHeader;
    let groupMetadata = groupsMetadata.find((gm) => (gm.groupId || NO_CUSTOM_STATUS_GROUP_ID) === groupId);
    if (!groupMetadata) {
      groupMetadata = { groupId, filteredProfilesCount: 0 };
    }

    const { filteredProfilesCount } = groupMetadata;
    const newProfilesCount = getGroupProfilesCount(groupId);
    let newStatus: GroupHeaderLoadingStatus = 'unloaded';
    if (newProfilesCount && newProfilesCount < filteredProfilesCount) {
      newStatus = 'loading';
    } else if (newProfilesCount >= filteredProfilesCount) {
      newStatus = 'loaded';
    }

    return { ...groupHeader, loadingStatus: newStatus, totalProfiles: filteredProfilesCount };
  });

  setProfilesTableGroupHeaders(newGroupHeaders);
};

export const getProfileNamesForNotifications = (profileIds: string[], translation: TFunction): string => {
  const profiles = getProfilesList().filter(profile => profileIds.includes(profile.id));
  const selectedProfilesName = profiles
    .map(profile => profile?.name.length > 15 ? profile?.name.slice(0, 12) + '...' : profile?.name);

  if (selectedProfilesName.length >= 2) {
    return selectedProfilesName.length + ' ' + translation('base.profiles');
  }

  return selectedProfilesName[0];
};

export const toggleGroupProfilesSelection = (groupHeaderId: string): void => {
  const profileIds = getGroupProfiles(groupHeaderId).map(({ id }) => id);
  toggleProfilesSelected(profileIds);
  updateGroupHeader(groupHeaderId, { isOpen: true });
};
