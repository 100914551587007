import { useState, useCallback } from 'react';

import { toggleNewUserFeature } from '../../../feature-toggle';
import { IFolder } from '../interfaces';
import { IUser, IUserCtx, USER_DEFAULT_VALUE } from '../state';

export const useUser = (): IUserCtx => {
  const [user, setUser] = useState<IUser>(USER_DEFAULT_VALUE);

  const updateUser = useCallback((userInfo: Partial<IUser>): void => {
    if (userInfo?.newFeatures) {
      toggleNewUserFeature(userInfo.newFeatures);
    }

    setUser(prev => ({
      ...prev,
      ...userInfo,
    }));
  }, []);

  const updateFolders = useCallback((newFolders: IFolder[]): void => {
    setUser(prev => ({
      ...prev,
      folders: newFolders,
    }));
  }, [user]);

  const updateSelectedFolder = useCallback((folderName: string): void => {
    setUser(prev => ({
      ...prev,
      selectedFolder: folderName,
    }));
  }, [user]);

  return {
    ...user,
    updateUser,
    updateFolders,
    updateSelectedFolder,
  };
};
