import React from 'react';
import { Trans } from 'react-i18next';

import { RemoveButtonWrapper, UnlinkButtonWrapper } from './styles';
import IconCheckCircle from '../../../../ui/icons/IconCheckCircle';
import IconCloseCurrentColor from '../../../../ui/icons/IconCloseCurrentColor';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { handleNoProxyClick } from '../../proxy-helpers';
import { ProxyButtonEl, RemoveButtonEl } from '../../proxy-manager/proxy-list/styles/proxy-list-item';
import { getProxyManagerState } from '../../../../state/proxy/proxy-manager-modal-status.atom';

interface IProxyRemoveButton {
  profileId: string;
  isVisible: boolean;
}

const ProxyRemoveButton: React.FC<IProxyRemoveButton> = (props) => {
  const { profileId, isVisible } = props;

  const onRemove = (event: React.SyntheticEvent): void => {
    const { handleProxySelect } = getProxyManagerState();
    if (!handleProxySelect) {
      handleNoProxyClick(event, profileId);

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    handleProxySelect(null);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <RemoveButtonWrapper>
      <UnlinkButtonWrapper className='proxy-unlink-button'>
        <TooltipWrapper isTransition={true} value={<Trans i18nKey='proxies.unlinkProxy' />}>
          <RemoveButtonEl onClick={onRemove}>
            <IconCloseCurrentColor />
          </RemoveButtonEl>
        </TooltipWrapper>
      </UnlinkButtonWrapper>
      <ProxyButtonEl success={true} className='proxy-circle-button'>
        <IconCheckCircle />
      </ProxyButtonEl>
    </RemoveButtonWrapper>
  );
};

export default ProxyRemoveButton;
