import { IFreeProxy } from './interfaces/IFreeProxy';
import { ISetProxyStatusParams } from './interfaces/ISetProxyStatusParams';
import { ISetSharedProxyStatusParams } from './interfaces/ISetSharedProxyStatusParams';
import { API_BASE_URL } from '../../../common/constants/constants';
import { IProxy } from '../../features/proxy/components/interfaces/IProxy';
import { ISharedProxyInfo } from '../../features/proxy/components/interfaces/ISharedProxyInfo';
import { PROXIES_LIST_DEFAULT } from '../../features/proxy/constants';
import { IProfile } from '../../interfaces';
import { http } from '../../services';

export const requestProxiesList = async (): Promise<any> => {
  const response = await http(`${API_BASE_URL}/proxy`);

  return response.body;
};

export const requestProxiesPaginated = async (page = 1): Promise<{ proxies: IProxy[]; hasMore: boolean }> => {
  const response = await http(`${API_BASE_URL}/proxy/v2?page=${page}`);

  return response.body;
};

export const requestProxiesFree = async (): Promise<IFreeProxy[]> => {
  const response = await http(`${API_BASE_URL}/proxy/free`).catch(() => ({ body: PROXIES_LIST_DEFAULT }));

  return response.body;
};

export const requestProxiesShared = async (): Promise<ISharedProxyInfo[]> => {
  const response = await http(`${API_BASE_URL}/proxy/shared`).catch(() => ({ body: PROXIES_LIST_DEFAULT }));

  return response.body;
};

export type ITableProxyConnectedProfile = Pick<IProfile, 'id'|'name'>;

interface ITableProxy {
  _id: string;
  connectedProfiles: ITableProxyConnectedProfile[];
}

interface ITableProxiesResponse {
  proxies: ITableProxy[];
}

export const requestTableProxies = async (proxiesIds: string[] = [], workspaceId = ''): Promise<ITableProxy[]> => {
  const url = new URL(`${API_BASE_URL}/proxy/table-proxies`);
  url.searchParams.append('proxiesIds', proxiesIds.join(','));
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  return http(url.toString())
    .then(({ body }: { body: ITableProxiesResponse }) => body.proxies)
    .catch(() => PROXIES_LIST_DEFAULT);
};

export const setProxyStatuses = async (proxies: ISetProxyStatusParams[]): Promise<void> => {
  const data = await http(`${API_BASE_URL}/proxy/set_proxy_statuses`, {
    method: 'POST',
    body: JSON.stringify({
      proxies,
    }),
  });

  return data.body;
};

export const setSharedProxyStatuses = async (proxies: ISetSharedProxyStatusParams[]): Promise<void> => {
  const data = await http(`${API_BASE_URL}/proxy/set_shared_proxy_statuses`, {
    method: 'POST',
    body: JSON.stringify({
      proxies,
    }),
  });

  return data.body;
};

export const getProxyRequest = async (id: string): Promise<IProxy> => {
  const response = await http(`${API_BASE_URL}/proxy/${id}`, {
    method: 'GET',
  });

  return response.body;
};
