import css from '@emotion/css';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentTheme } from '../../../state/theme.atom';
import IconRemove from '../../../ui/icons/IconRemove';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { ITag, ITagBase } from '../interfaces/tag.interface';
import colors from './colors';

const ColorContainer = styled('div')<{ allowRemove: boolean; isDarkTheme: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 168px;
  background-color: var(--FFFFFF-tags-change-colors);
  border-radius: 4px;
  position: absolute;
  z-index: 150;
  padding: 9px 8px 3px 8px;
  font-family: 'Roboto';
  font-style: normal;

  ${(props): any => css`
    top: ${(!props.allowRemove) ? '3px' : '31px;'};
    left: ${(!props.allowRemove) ? '0' : '41px'};
    ${(!props.allowRemove) ? 'margin-left: 0' : ''};
    ${props.isDarkTheme ?
    'box-shadow: -2px 0 8px var(--00000026-antd)' :
    'box-shadow: 0 1px 4px var(--00000003-tags-change-colors), 0 4px 8px var(--00000005-tags-change-colors), 0 1px 12px var(--0000001F-tags-change-colors)'
    };
  `}
`;

const ColorWrapper = styled('div')<{ allowRemove: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;

  ${(props): any => css`
    padding-bottom: ${(props.allowRemove) ? '0' : '5px'};
  `}
`;

const ColorBlock = styled('div')<{ color: string }>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  /*transition: box-shadow 0.25s ease-out;*/
  box-shadow: 0px 0px 0px 1px var(--0000001A-tags-change-colors) inset;

  &:hover {
    box-shadow: 0px 0px 0px 1px var(--0000004D-tags-change-colors) inset;
  }

  &:nth-of-type(5n) {
    margin-right: 0;
  }
  ${(props): any => css`
    background-color: ${props.color};
  `}
`;

const RemoveColorBtn = styled('div')`
  display: inline-flex;
  align-items: center;
  color: var(--000000-tags-change-colors);
  letter-spacing: 0.25px;
  cursor: pointer;

  & svg {
    color: var(--CDCDCD-tags-change-colors);
  }

  :hover {
    color: var(--F5222D-tags-change-colors);

    & svg {
      color: var(--F5222D-tags-change-colors);
    }
  }
`;

const Checkmark = styled('div')`
  left: 8px;
  top: 3px;
  width: 7px;
  height: 15px;
  border: solid var(--222222-tags-change-colors);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
`;

const TitleForm = styled('form')`
  border-bottom: 1px solid var(--EBEBEB-tags-change-colors);
  margin-bottom: 16px;
  margin-top: -4px;
  display: block;
`;

const TitleInput = styled('input')`
  color: var(--222222-tags-change-colors);
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  padding: 2px 0px;

  :focus {
    outline: none !important;
    box-shadow: none;
  }

  ::placeholder {
    color: var(--CDCDCD-tags-change-colors);
  }
`;

declare interface IProps {
  tag: ITag;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeTag?: () => Promise<void>;
  close?: () => void;
  allowRemove: boolean;
  allowChangeTitle?: boolean;
}

const TagsColors: FC<IProps> = (props) => {
  const { tag, allowRemove, removeTag, close, updateTag, allowChangeTitle } = props;
  const { color } = tag;
  const [title, setTitle] = React.useState<string>(tag.title);
  const theme = useCurrentTheme();

  const { t: translation } = useTranslation();
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  const handleClick = (newColor: string): void => {
    if (newColor === color) {
      return close && close();
    }

    setTagColor(newColor);
  };

  const setTagColor = async (newColor: string): Promise<void> => {
    await updateTag({ ...tag, color: newColor });
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setTitle(event.target.value);
  };

  const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    if (title && title.trim()) {
      await updateTag({ ...tag, title });
    } else {
      setTitle(tag.title);
    }

    close && close();
  };

  const handleBlur = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();
    if (title && title.trim()) {
      await updateTag({ ...tag, title });

      return;
    }

    setTitle(tag.title);
  };

  const tagMaxLength = 200;

  return (
    <ColorContainer allowRemove={allowRemove} className="tags-colors" isDarkTheme={isDarkTheme}>
      {(allowChangeTitle) ? (
        <TitleForm onSubmit={handleSubmit}>
          <TitleInput
            value={title}
            maxLength={tagMaxLength}
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </TitleForm>
      ) : null}
      <ColorWrapper allowRemove={allowRemove}>
        {Object.entries(colors).map(([key, colorObject]) => (
          <ColorBlock key={key} color={colorObject.fontColor} onClick={(): void => handleClick(key)}>
            {(color === key) ? <Checkmark /> : null}
          </ColorBlock>
        ))}
      </ColorWrapper>
      {(allowRemove) ? (
        <div style={{ marginTop: 16, marginLeft: 1 }}>
          <RemoveColorBtn onClick={removeTag}>
            <IconRemove />
            {translation('tags.delete')}
          </RemoveColorBtn>
        </div>
      ) : null}
    </ColorContainer>
  );
};

export default TagsColors;
