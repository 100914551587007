import styled from '@emotion/styled';
import { Icon } from 'antd';
import React, { FC } from 'react';

const Container = styled.div`
  background: var(--FFFFFF);
  height: 100%;
  font-size: 40px;
  padding-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  color: var(--222222);
  flex: 1;
`;

const LoadingPage: FC = () => (
  <Container>
    <Icon type='loading' />
  </Container>
);

export default LoadingPage;
