import * as Sentry from '@sentry/react';

import { ReactError } from './sentry-parameters/custom-errors';
import { ISendReactErrorToSentryProps, ISentryTag } from '../interfaces/sentry/sentry.interfaces';

export const sendReactErrorToSentry = (props: ISendReactErrorToSentryProps): void => {
  const {
    message,
    level = 'error',
    tags = [],
    transactionName,
  } = props;

  Sentry.captureException(new ReactError(JSON.stringify(message)), (scope) => {
    scope.setLevel(level);
    scope.setTransactionName(transactionName);
    scope.setFingerprint([transactionName]);

    tags.forEach((tagArray: ISentryTag) => {
      const [tag, value] = tagArray;
      scope.setTag(tag, value);
    });

    return scope;
  });
};

export const sendReactAnalyticsError = (error: unknown): void => {
  const message = error instanceof Error ? error.message : JSON.stringify(error);
  sendReactErrorToSentry({
    message, transactionName: 'send-analytics-error',
    tags: [['scenario', 'send-analytics']],
  });
};
