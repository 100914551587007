import styled from '@emotion/styled';
import { Button, Divider, Input, message } from 'antd';
import React, { ChangeEvent, FC, memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ProfileProxy from './profile-proxy';
import { isNotUndefined } from '../../../../../../common/typescript/predicates';
import { history } from '../../../../../services';
import { useProxyList } from '../../../../../state/proxy/proxy-list.atom';
import { addMultipleProxies } from '../../../../../state/proxy/proxy-operations/create-proxies.operations';
import { handleMultipleProxiesAddError } from '../../../../../state/proxy/proxy-operations/handle-multiple-proxies-add-error';
import { proxyFromText } from '../../../../../utils/proxy-string';
import { IProxy } from '../../../../proxy/components/interfaces/IProxy';
import { IProfileProxy, updateProxies } from '../api';

const ProfilesContainer = styled.div`
  height: 290px;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid var(--C6C6C6);
  padding: 8px 4px;

  div:first-of-type {
    margin-top: 0;
  }

  div {
    margin-top: 10px;
  }
`;

const SubmitBtn = styled(Button)`
  width: 100%;
`;

const TextArea = styled(Input.TextArea)`
  margin-top: 24px;
`;

interface IAutoProxyForm {
  selectedProfiles: any[];
  setSelectedProfilesIds: (ids: string[]) => any;
  closeModal: () => any;
}
const AutoProxyForm: FC<IAutoProxyForm> = (props) => {
  const { selectedProfiles, setSelectedProfilesIds, closeModal } = props;

  const [proxyList, setProxyList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const proxyListData = useProxyList();

  const { t: translation } = useTranslation();

  const onProxyListChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = event.target;
    if (!value) {
      setProxyList([]);

      return;
    }

    const formattedValue = value
      .replace(/[^a-z0-9.\-_/@:;!#$%^&*()+\\;',/`~={}|"<>?"№[\] ]/ig, '\n')
      .replace(/\n{2,}/g, '\n');

    if (formattedValue === '\n') {
      setProxyList([]);

      return;
    }

    const proxyArray = formattedValue.split('\n');
    setProxyList(proxyArray);
  };

  const renderBaseProxy = (index: number): string => {
    const profile = selectedProfiles[index];
    const { proxy } = profile;

    const proxyMode = proxy.mode;

    if (proxyMode === 'none') {
      return '-';
    }

    if (['http', 'socks4', 'socks5'].includes(proxyMode)) {
      if (proxy.username && !proxy.password) {
        return `${proxyMode}://${proxy.username}@${proxy.host}:${proxy.port}`;
      }

      if (proxy.username && proxy.password) {
        return `${proxyMode}://${proxy.username}:${proxy.password}@${proxy.host}:${proxy.port}`;
      }

      return `${proxyMode}://${proxy.host}:${proxy.port}`;
    }

    if (proxyMode === 'gologin') {
      const arr = ['GoLogin'];

      if (profile.proxyRegion) {
        arr.push(profile.proxyRegion.toUpperCase());
      }

      return arr.join(' • ');
    }

    if (proxyMode === 'tor') {
      const arr = ['TOR'];

      if (profile.proxyRegion) {
        arr.push(profile.proxyRegion.toUpperCase());
      }

      return arr.join(' • ');
    }

    return 'error';
  };

  const renderInsertedProxy = (proxy: string): string => {
    if (!proxy.includes('://')) {
      return `http://${proxy}`;
    }

    return proxy;
  };

  const proxyRenderer = (index: number): string => {
    if (proxyList.length === 1) {
      return renderInsertedProxy(proxyList[0]);
    }

    if (!proxyList[index]) {
      return renderBaseProxy(index);
    }

    return renderInsertedProxy(proxyList[index]);
  };

  const handleSubmit = async (): Promise<void> => {
    normalizeProxyInTextarea();
    // Textarea proxy parsing
    const parsedProxies = proxyList.map(proxy => proxyFromText(proxy));

    const multipleProxiesAddResult = await addMultipleProxies({
      proxyList: proxyListData,
      proxies: parsedProxies.filter(isNotUndefined),
      selectedProfiles,
    });

    const { error } = multipleProxiesAddResult;
    if (error) {
      return handleMultipleProxiesAddError(error);
    }

    const { proxies: addedProxies } = multipleProxiesAddResult;
    const proxies = parsedProxies.map((el) => {
      if (!el) {
        return el;
      }

      return addedProxies.find((proxy) => (
        el.mode === proxy.mode &&
        el.host.toLowerCase() === proxy.host &&
        el.port === proxy.port &&
        el.username === proxy.username &&
        el.password === proxy.password
      ));
    });

    if (!proxies) {
      return;
    }

    // Если прокси введен только один - он применяется на все профили
    if (proxies.length === 1) {
      const [proxy] = proxies;
      if (!proxy) {
        message.error(<Trans i18nKey='multipleProxyManagerModal.invalidFormat' />);

        return;
      }

      const profilesForUpdate: IProfileProxy[] = selectedProfiles.map(profile => ({
        profileId: profile.id,
        proxy,
      }));

      try {
        setIsLoading(true);

        await updateProxies(profilesForUpdate);
        closeModal();
      } finally {
        setIsLoading(false);
        history.replace('/');
      }

      return;
    }

    // valid profile to update & deleting from list
    const validProfilesProxy: IProfileProxy[] =
      proxies
        .filter((proxy) => !!proxy)
        .map((proxy, index): IProfileProxy => ({
          profileId: selectedProfiles[index]?.id,
          proxy: proxy as IProxy,
        }))
        .filter(profilesIdsWithoutProxy => !!profilesIdsWithoutProxy.profileId);

    const invalidProfilesIds: string[] = [];
    proxies.forEach((proxy, index) => {
      if (!proxy) {
        invalidProfilesIds.push(selectedProfiles[index].id);
      }
    });

    const profilesIdsWithoutProxy = selectedProfiles.slice(proxies.length - 1).map(p => p.id);

    // profiles for setting
    const profilesForSetting = invalidProfilesIds.concat(profilesIdsWithoutProxy);
    setSelectedProfilesIds(profilesForSetting);

    // deleting valid proxies from textarea
    setProxyList(proxyList.filter((_, index) => !proxies[index]));

    try {
      setIsLoading(true);

      await updateProxies(validProfilesProxy);
      closeModal();
    } finally {
      setIsLoading(false);
      history.replace('/');
    }

  };

  const normalizeProxyInTextarea = (): string|void => {
    const normalizedProxyList = proxyList
      .filter(proxy => proxy !== '')
      .map(proxy => {
        if (!proxy.includes('://')) {
          return `http://${proxy}`;
        }

        return proxy;
      });

    setProxyList(normalizedProxyList);
  };

  const onProfileDelete = (index: number): void => {
    const selectedProfilesAfterDelete = selectedProfiles
      .filter((_, i) => i !== index)
      .map(profile => profile.id);

    setSelectedProfilesIds(selectedProfilesAfterDelete);

    if (proxyList[index]) {
      const proxyListAfterProfileDelete = proxyList.filter((_, i) => i !== index);
      setProxyList(proxyListAfterProfileDelete);
    }
  };

  return (
    <>
      <ProfilesContainer>
        {selectedProfiles.map((profile, index) => (
          <>
            <ProfileProxy
              onDelete={(): void => onProfileDelete(index)}
              key={profile.id}
              profileName={profile.name}
              proxy={proxyRenderer(index)}
            />
          </>
        ))}
      </ProfilesContainer>

      <TextArea
        onBlur={normalizeProxyInTextarea}
        placeholder={translation('multipleProxyManagerModal.textField.placeholder')}
        value={proxyList.join('\n')}
        rows={4}
        onChange={onProxyListChange}
      />

      <Divider />

      <SubmitBtn type='primary' loading={isLoading} onClick={handleSubmit}>
        {translation('multipleProxyManagerModal.submitBtn.label')}
      </SubmitBtn>
    </>
  );
};

export default memo(AutoProxyForm);
