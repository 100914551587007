import { Atom, PrimitiveAtom, atom, getDefaultStore, useAtomValue } from 'jotai';
import { splitAtom } from 'jotai/utils';

import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { getBasicTableEntities } from '../../custom-table/entities-list.callback';
import { removeProxiesFromList, updateProxyItems } from '../proxy-list.atom';

const proxiesTableListAtom = atom<IProxy[]>([]);
const proxyAtomListAtom = splitAtom(proxiesTableListAtom);

export interface IBasicTableProxy {
  idx: number;
  atom: PrimitiveAtom<IProxy>;
}

const basicTableProxiesAtom: Atom<IBasicTableProxy[]> = atom<IBasicTableProxy[]>((get) => getBasicTableEntities<IProxy>({
  basicTableEntitiesAtom: basicTableProxiesAtom,
  listAtom: proxyAtomListAtom,
  get,
}));

export const useBasicTableProxies = (): IBasicTableProxy[] => useAtomValue(basicTableProxiesAtom);
export const getBasicTableProxies = (): IBasicTableProxy[] => getDefaultStore().get(basicTableProxiesAtom);

export const getProxiesTableList = (): IProxy[] => getDefaultStore().get(proxiesTableListAtom);
export const setProxiesTableList = (proxiesNew: IProxy[], proxiesIdsToDelete: string[] = [], shouldSyncProxyList = true): void => {
  getDefaultStore().set(proxiesTableListAtom, proxiesNew);
  if (!shouldSyncProxyList) {
    return;
  }

  if (proxiesIdsToDelete.length) {
    removeProxiesFromList(proxiesIdsToDelete);
  } else {
    updateProxyItems(proxiesNew);
  }
};

export const mapAndSetProxiesTableList = (
  mapProxiesList: (prevProxies: IProxy[]) => IProxy[],
  proxiesIdsToDelete: string[] = [],
  shouldSyncProxyList = true,
): void => {
  const currentProxies = getProxiesTableList();
  const mappedProxies = mapProxiesList(currentProxies);

  setProxiesTableList(mappedProxies, proxiesIdsToDelete, shouldSyncProxyList);
};
