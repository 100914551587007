import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { setProxiesTableList } from './proxy-table/proxies-table-list.atom';
import { getIsProxyArchived } from '../../features/proxy/proxy-helpers';
import { PROXY_MOCKED_ID_SEPARATOR } from '../../features/proxy/utils/proxy-id';
import { IProxy } from '../../interfaces';
import { filterProxies, putCurrentProxyFirstInList } from '../../utils/proxy-string';
import { IUpdateProxyItem } from '../proxies.context/interfaces/update-proxy-item.interface';

export const proxyListAtom = atom<IProxy[]>([]);
const isProxyListLoadingAtom = atom<boolean>(false);
const filteredProxyListAtom = atom<IProxy[]>([]);
const isProxyListLoadedAtom = atom(false);

const setProxyList = (data: IProxy[]): void =>
  getDefaultStore().set(proxyListAtom, data.filter((proxy) => !getIsProxyArchived(proxy)));

export const getProxyList = (): IProxy[] => getDefaultStore().get(proxyListAtom);
export const getIsProxyListLoading = (): boolean => getDefaultStore().get(isProxyListLoadingAtom);
export const setFilteredProxyList = (data: IProxy[]): void =>
  getDefaultStore().set(filteredProxyListAtom, data.filter((proxy) => !getIsProxyArchived(proxy)));
export const setIsProxyListLoaded = (value: boolean): void => getDefaultStore().set(isProxyListLoadedAtom, value);

export const useProxyList = (): IProxy[] => useAtomValue(proxyListAtom);

export const useFilteredProxyList = (): IProxy[] => useAtomValue(filteredProxyListAtom);
export const getFilteredProxyList = (): IProxy[] => getDefaultStore().get(filteredProxyListAtom);

export const useIsProxyListLoaded = (): boolean => useAtomValue(isProxyListLoadedAtom);

const resetProxiesInFilteredList = (): void => {
  const proxyList = getProxyList();
  const filteredProxiesList = getFilteredProxyList();

  const newFilteredProxiesList = filteredProxiesList.map(filteredProxy => {
    const newProxyValue = proxyList.find(listedProxy => listedProxy.id && listedProxy.id === filteredProxy.id);

    return newProxyValue || filteredProxy;
  });

  setFilteredProxyList(newFilteredProxiesList);
};

export const filterProxyList = (value: string, currentProxy: IProxy|null = null): void => {
  const proxyList = getProxyList();

  if (!value) {
    const resultProxyList = putCurrentProxyFirstInList(proxyList, currentProxy);
    setFilteredProxyList(resultProxyList);

    return;
  }

  const newProxyList = filterProxies(proxyList, value.toLocaleLowerCase(), currentProxy);

  setFilteredProxyList(newProxyList);
};

export const updateProxyList = (data: IProxy[], proxyCurrent: IProxy|null = null): void => {
  setProxyList(data);
  filterProxyList('', proxyCurrent);
};

const updateProxyListInPlace = (data: IProxy[]): void => {
  setProxyList(data);
  resetProxiesInFilteredList();
};

export const removeProxiesFromList = (proxyIdsToDelete: string[]): void => {
  const proxyList = getProxyList();
  const filteredProxyList = getFilteredProxyList();
  setProxyList(proxyList.filter(listedProxy => !proxyIdsToDelete.includes(listedProxy.id)));
  setFilteredProxyList(filteredProxyList.filter(filteredProxy => !proxyIdsToDelete.includes(filteredProxy.id)));
  resetProxiesInFilteredList();
};

export const addManyProxies = (newProxies: IProxy[]): void => {
  const proxyList = getProxyList();
  updateProxyList([...newProxies, ...proxyList]);
};

export const upsertOneProxy = (newProxy: IProxy): void => {
  const proxyList = getProxyList();
  let hasProxyChanged = false;
  const updatedProxies = proxyList.map((listedProxy) => {
    if (listedProxy.id !== newProxy.id) {
      return listedProxy;
    }

    hasProxyChanged = true;
    newProxy = {
      ...listedProxy,
      ...newProxy,
      profilesCount: listedProxy.profilesCount,
    };

    return newProxy;
  });

  if (hasProxyChanged) {
    return updateProxyListInPlace(updatedProxies);
  }

  updatedProxies.unshift({ ...newProxy, checkInProgress: true });

  updateProxyList(updatedProxies);
};

const updateProxyInList = (proxiesInList: IProxy[], proxyItem: IUpdateProxyItem, isUsedForManyProxies = false): IProxy[]|void => {
  const proxyInListIndex = proxiesInList.findIndex(proxy => proxy.id === proxyItem.id);
  const isInvisible = proxyItem.id && proxyInListIndex === -1 && proxyItem.isInvisible;

  if (isInvisible) {
    const proxyToAdd: IUpdateProxyItem = { ...proxyItem, isInvisible };
    const newProxyList = [...proxiesInList];
    newProxyList.push(proxyToAdd);
    if (isUsedForManyProxies) {
      return newProxyList;
    }

    return updateProxyList(newProxyList);
  }

  const newProxyList = [...proxiesInList];
  if (proxyInListIndex > -1) {
    newProxyList[proxyInListIndex] = { ...newProxyList[proxyInListIndex], ...proxyItem };
  } else {
    // proxy needs to be updated after check,
    // if it is not listed (for various reasons such as profile transferred with it)
    newProxyList.push({
      ...proxyItem,
      id: proxyItem.id || '',
      profilesCount: 0,
      mode: proxyItem.mode || 'http',
      host: proxyItem.host || '',
      port: proxyItem.port || 80,
      isInvisible: true,
    });
  }

  if (isUsedForManyProxies) {
    return newProxyList;
  }

  updateProxyListInPlace(newProxyList);
};

export const updateProxyItem = (proxyItem: IUpdateProxyItem): void => {
  if (getIsProxyArchived(proxyItem)) {
    return;
  }

  const proxyList = getProxyList();
  updateProxyInList(proxyList, proxyItem);
};

export const updateProxyItems = (proxyItems: IUpdateProxyItem[]): void => {
  const proxyList = getProxyList();
  const newProxyList = proxyItems.reduce<IProxy[]>((newProxyListAcc, proxyItem) => {
    const newList = updateProxyInList(proxyList, proxyItem, true);
    if (newList) {
      newProxyListAcc = [...newList];
    }

    return newProxyListAcc;
  }, [...proxyList]);

  setProxyList(newProxyList);
};

export const clearProxyLists = (): void => {
  setProxyList([]);
  setFilteredProxyList([]);
  setProxiesTableList([]);
};

export const incrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const newProxiesList = proxyList.map((listedProxy) => {
    if (listedProxy?.id === proxyId) {
      listedProxy.profilesCount ? listedProxy.profilesCount += 1 : listedProxy.profilesCount = 1;
    }

    return listedProxy;
  });

  updateProxyList(newProxiesList);
};

export const decrementProxyProfilesCount = (proxyId?: string): void => {
  if (!proxyId) {
    return;
  }

  const proxyList = getProxyList();

  const updatedProxies = proxyList.map((listedProxy) => {
    if (listedProxy.id === proxyId && listedProxy.profilesCount) {
      listedProxy.profilesCount -= 1;
    }

    return listedProxy;
  });

  updateProxyList(updatedProxies);
};

export const useProxyListProxyById = (proxyId: string | null): IProxy | null => {
  const proxyList = useProxyList();
  if (!proxyId) {
    return null;
  }

  return proxyList.find(listedProxy => listedProxy.id === proxyId) || null;
};

export const getProxyListProxyById = (proxyId: string): IProxy | null => {
  const proxyList = getProxyList();

  return proxyList.find(listedProxy => listedProxy.id === proxyId) || null;
};

export const updateProxyInListById = (
  proxyId: string,
  partialUpdate: Partial<IProxy>,
): void => {
  const proxyList = getProxyList();
  setProxyList(proxyList.map((listedProxy) => listedProxy.id === proxyId ?
    { ...listedProxy, ...partialUpdate } : listedProxy,
  ));
};

export const getProxyByArtificialProxyId = (artificialProxyId: string | null): IProxy | null => {
  if (!artificialProxyId) {
    return null;
  }

  const proxyList = getProxyList();
  const [mode, region] = artificialProxyId.split(PROXY_MOCKED_ID_SEPARATOR);
  if (!(mode && region)) {
    return null;
  }

  const field: keyof IProxy = mode === 'gologin' ? 'autoProxyRegion' : 'torProxyRegion';

  return proxyList.find(proxy => proxy.mode === mode && proxy[field] === region) || null;
};

