import styled from '@emotion/styled';
import { T, useTranslate } from '@tolgee/react';
import React, { FC, useContext, useState } from 'react';

import { TWO_FA_TOKEN_KEY_NAME } from '../../../../common/constants/constants';
import { IPlan } from '../../../interfaces/plan';
import { ACCESS_TOKEN } from '../../../services/http/config';
import { userContext, workspaceContext } from '../../../state';
import IconHorseHopper from '../../../ui/icons/IconHorseHopper';
import { redirectToDesktop } from '../../authentification/o-auth/utils';
import { changePlan } from '../api';

const FreePlanContainer = styled('div')`
  margin-top: 66px;
  font-family: 'Roboto';
  justify-content: space-between;
  display: flex;
  width: 1232px;
  padding: 20px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--D2D2D5-new-pricing);
`;

const FreePlanText = styled('div')`
  font-size: 16px;
  line-height: 22px;
  color: var(--222222-new-pricing);
  width: 606px;
  margin-left: 24px;
`;

const ChooseFreePlanButton = styled('button')`
  color: var(--36363D-new-pricing);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 18px;
  outline: 1px solid var(--D2D2D5-new-pricing);
  border: 0;
  background: var(--FFFFFF-new-pricing);
  cursor: pointer;

  :hover {
    background: var(--F2F2F2-new-pricing);
  }
`;

declare interface IFreePlanTable {
  updateUserContextPlan: (id: string, hasTrial: boolean) => void;
  freePlan: any;
  workspaceId?: string;
}

export const FreePlanTable: FC<IFreePlanTable> = (props) => {
  const { workspaceId, freePlan } = props;
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const { updateUser } = useContext(userContext);
  const { updateWorkspace, updateAvailableWorkspace } = useContext(workspaceContext);

  const { t: translation } = useTranslate();

  const updatePlanData = (plan: IPlan): void => {
    const { id, name, maxProfiles, maxAccountShares } = plan;
    updateUser({
      plan,
      hasTrial: false,
      firstPlanSelected: true,
    });

    if (!workspaceId) {
      return;
    }

    updateWorkspace({
      isUnpaid: false,
      paymentIsTrial: false,
      planProfilesMax: maxProfiles,
      planMembersMax: maxAccountShares,
    });

    updateAvailableWorkspace(workspaceId, {
      planId: id,
      planName: name,
      planProfilesMax: maxProfiles,
      planMembersMax: maxAccountShares,
    });
  };

  const redirectToApp = (): void => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
    const twoFaToken = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME) || '';
    redirectToDesktop({ accessToken, twoFaToken });
  };

  const setFreePlan = (): void => {
    setDisabledButton(true);
    changePlan(freePlan?.id, workspaceId)
      .then(() => {
        if (freePlan) {
          updatePlanData(freePlan);
        }

        redirectToApp();
      })
      .catch(event => {
        setDisabledButton(false);
        throw event;
      });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <FreePlanContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconHorseHopper />
          <FreePlanText>
            <T
              keyName="pricing.freePlan.text"
              params={{ strong: <strong /> }}
            />
          </FreePlanText>
        </div>
        <ChooseFreePlanButton
          disabled={disabledButton}
          onClick={setFreePlan}
        >
          {translation('pricing.freePlan.button.text')}
        </ChooseFreePlanButton>
      </FreePlanContainer>
    </div>
  );
};
