import { Icon } from 'antd';
import React, { CSSProperties, FC, useState } from 'react';

import { NEW_FEATURES } from '../../../../../feature-toggle';
import TooltipCustom from '../../../ui/tooltip-custom';

interface ILoadableIconButton {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => Promise<void>;
  icon: JSX.Element;
  tooltipContent: JSX.Element;
  style?: CSSProperties;
}

const LoadableIconButton: FC<ILoadableIconButton> = (props) => {
  const { onClick, icon, tooltipContent, style } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const onContainerClick = async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
    event.stopPropagation();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setAnchorElTooltip(null);
    await onClick(event).finally(() => setIsLoading(false));
  };

  const renderIcon = (): JSX.Element => {
    if (!isLoading) {
      return icon;
    }

    return (
      <Icon
        type='loading'
        style={{
          fontSize: 20,
          margin: NEW_FEATURES.header ? '2px 10px 0 2px' : '2px 3px 0 2px',
          color: 'var(--949393-profile-table-create-quick-profile)',
        }}
      />
    );
  };

  return (
    <>
      <div
        onMouseEnter={(event): void => {
          setAnchorElTooltip(event.currentTarget);
        }}
        onMouseLeave={(): void => setAnchorElTooltip(null)}
        onClick={onContainerClick}
        style={style}
      >
        {renderIcon()}
      </div>
      <TooltipCustom anchorEl={anchorElTooltip} value={tooltipContent} />
    </>
  );
};

export default LoadableIconButton;
