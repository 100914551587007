import React, { FC, useEffect } from 'react';

import ProfileSettingsContent from './content/profile-settings-content';
import ProfileSettingsHeader from './profile-settings-header';
import { ProfileSettingsDrawer } from './styles';
import { IProfile } from '../../interfaces';
import {
  closeProfilesSettings,
  useIsProfilesSettingsOpen,
  useProfilesSettingsProfile,
  useProfilesSettingsState,
} from '../../state/profiles-settings-atom';
import { useCurrentTheme } from '../../state/theme.atom';
import getSelectedThemeColor from '../../utils/get-selected-theme-color';
import { IProfileDrawer } from '../drawer/interfaces';
import PageOverlay from '../quickProfiles/components/page-overlay';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const ProfileSettings: FC<IProfileDrawer> = props => {
  const { renderDropDownMenu, toggleCookiesListModal } = props;

  // FIXME: consistent nullable type of profileInfo across profile settings
  const profileInfo = useProfilesSettingsProfile() || ({} as IProfile);

  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();
  const { profileIds } = useProfilesSettingsState();
  const isMultiProfilesSettings = profileIds.length > 1;

  const theme = useCurrentTheme();

  const { id: profileId, name: profileName } = profileInfo;
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';
  const isShowDrawer = profileId || isMultiProfilesSettings;

  useEffect(() => {
    if (isShowDrawer) {

      return;
    }

    closeProfilesSettings();
  }, [profileId, isMultiProfilesSettings]);

  return (
    <div onClick={(event): void => event.stopPropagation()}>
      <ProfileSettingsDrawer
        bodyStyle={{ padding: 0 }}
        visible={isProfilesSettingsOpen}
        placement='right'
        maskStyle={{ backgroundColor: 'inherit' }}
        mask={false}
        onClose={closeProfilesSettings}
        width={505}
        closable={false}
        drawerStyle={{
          backgroundColor: 'var(--F9F9F9-profile-new-settings)',
        }}
        isDarkTheme={isDarkTheme}
      >
        <ProfileSettingsHeader
          profile={profileInfo}
          changeVisibility={closeProfilesSettings}
          renderDropDownMenu={renderDropDownMenu}
        />
        <ProfileSettingsContent
          profileData={profileInfo}
          showModalCookies={(): void => toggleCookiesListModal({ visible: true, id: profileId, name: profileName })}
          selectedProfilesIds={profileIds}
          {...props}
        />
        <PageOverlay position='absolute' />
      </ProfileSettingsDrawer>
    </div>
  );
};

export default ProfileSettings;
