import { ProxyMode } from '../../app/interfaces';
import { APP_START_TRANSACTION_SPANS, AppStartTransactionSpan } from '../../app/interfaces/sentry/app-start.transaction';

export const STORAGE_KEY_NAME_PREFIX = '@gologin:';

export const TWO_FA_TOKEN_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}2fa_token`;
export const AFFILIATE_COOKIE_NAME = `${STORAGE_KEY_NAME_PREFIX}affiliate`;
export const SEARCH_HISTORY_NAME = `${STORAGE_KEY_NAME_PREFIX}searchHistory`;
export const PROFILE_SHARED_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}profile-shared`;

export const FORCE_LOAD_OLD_PRICING_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}forceOldPricing`;
export const NEW_PRICING_WORKSPACE_ID_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}newPricingWorkspaceId`;
export const IS_SEPARATED_CHECKOUT_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}isCheckoutApp`;
export const SELECTED_LANGUAGE_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}selected_language`;

export const STATE_USING_SENTRY_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}using-sentry`;
export const NEW_PROFILE_PAGE_FOLDER_NAME = `${STORAGE_KEY_NAME_PREFIX}new-profile-page-folder`;
export const NEW_PROFILE_PAGE_CUSTOM_STATUS_ID = `${STORAGE_KEY_NAME_PREFIX}new-profile-page-custom-status`;

export const APP_START_TIMESTAMP_BASE_KEY_NAME = `${STORAGE_KEY_NAME_PREFIX}app-start-timestamp`;
export const APP_START_TIMESTAMPS_KEY_NAMES_MAP =
  APP_START_TRANSACTION_SPANS.reduce<Partial<Record<AppStartTransactionSpan, string>>>((acc, spanName, index) => {
    acc[spanName] = `${APP_START_TIMESTAMP_BASE_KEY_NAME}-${index + 1}`;

    return acc;
  }, {}) as Record<AppStartTransactionSpan, string>;

export const WORKSPACE_KEY_NAME = 'workspace';

export const LOCAL_GOOGLE_CLIENT_ID_KEY_NAME = 'localGoogleClientId';
export const THEME_KEY_NAME = 'theme';

export const DEFAULT_PROFILE_CREATE_COUNT_KEY = `${STORAGE_KEY_NAME_PREFIX}defaultProfileCreateCount`;
export const PROFILES_TABLE_SORTER_FIELD_KEY = 'sorterField';
export const PROFILES_TABLE_SORTER_ORDER_KEY = 'sorterOrder';

export const PROMOCODE_UNITS = ['y', 'M', 'w', 'd'] as const;
export const FREE_PLAN_ID = '5e3aaad78481ec0b30795d1e';
export const PAGE_SIZE = 30;

// export const API_BASE_URL = 'https://api-ws.gologin.com';
export const API_BASE_URL = process.env.GOLOGIN_API_BASE_URL as string;
// export const API_BASE_URL = 'https://api-alpha-test.gologin.com';
// export const API_BASE_URL = 'http://127.0.0.1:3002';

export const API_ANALYTICS_URL = 'https://analytics.gologin.com';

export const OLD_PRICING_PAGE_URL = process.env.GOLOGIN_OLD_PRICING_PAGE_URL;

export const PAYMENT_GATEWAY_URL = process.env.GOLOGIN_PAYMENT_GATEWAY_URL;

export const MS_IN_SECOND = 1000;

export const SENTRY_IS_DEVELOPMENT = false;

export enum E_APP_TYPE {
  desktop = 'desktop',
}

export const GEOPROXY_MODE: ProxyMode = 'geolocation';
