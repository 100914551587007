import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useProfilesList } from '../../../state/profiles-list.atom';
import MultipleTagsPopper from '../../tags/components/multiple-tags-popper';
import { IAddTag, ITagBase } from '../../tags/interfaces/tag.interface';

interface IEditTagsMenuBtn {
  ButtonLine: React.ElementType;
  Row: React.ElementType;
  MultipleOperationBtnText: React.ElementType;
  selectedRows: string[];
  isProcessing: boolean;
  addTag: (params: IAddTag) => Promise<void>;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
}

const EditTagsMenuBtn: FC<IEditTagsMenuBtn> = (props: IEditTagsMenuBtn) => {
  const {
    selectedRows,
    addTag,
    updateTag,
    removeTag,
    removeProfileTag,
    isProcessing,
    ButtonLine,
    Row,
    MultipleOperationBtnText,
  } = props;

  const profilesList = useProfilesList();

  const [anchorEditTagsEl, setAnchorEditTagsEl] = useState<null | HTMLElement>(null);
  const { t: translation } = useTranslation();

  const isPopperOpen = !!(anchorEditTagsEl && selectedRows.length);

  const handleEditTagsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEditTagsEl(anchorEditTagsEl ? null : event.currentTarget);
  };

  const hideEditTags = (): void => {
    setAnchorEditTagsEl(null);
  };

  return (
    <>
      <ButtonLine
        onClick={handleEditTagsClick}
        disabled={isProcessing}
      >
        <Row>
          <MultipleOperationBtnText>
            {translation('tags.editTagsBtnText')}
          </MultipleOperationBtnText>
        </Row>
      </ButtonLine>

      <Popper
        open={isPopperOpen}
        anchorEl={anchorEditTagsEl}
        style={{ zIndex: 152 }}
      >
        <ClickAwayListener onClickAway={hideEditTags}>
          <div>
            <MultipleTagsPopper
              profileList={profilesList}
              selectedRows={selectedRows}
              updateTag={updateTag}
              addTag={addTag}
              removeTag={removeTag}
              removeProfileTag={removeProfileTag}
              field='tags'
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default EditTagsMenuBtn;
