import { Icon } from 'antd';
import React from 'react';

interface ICustomTableLoader {
  areColumnsResizing: boolean;
}

const CustomTableLoader: React.FC<ICustomTableLoader> = (props) => {
  const { areColumnsResizing } = props;

  if (areColumnsResizing) {
    return null;
  }

  return (
    <div style={{ fontSize: 32, textAlign: 'center' }}>
      <Icon type='loading' />
    </div>
  );
};

export default CustomTableLoader;
