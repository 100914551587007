import { Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProxyListItemProfilesCountEl } from '../styles/proxy-profiles-count-block';
import { TooltipEl } from '../proxy-selector/styles';

interface IProps {
  profilesCount: number;
}

const ProxyProfilesCountBlock: FC<IProps> = (props) => {
  const {
    profilesCount,
  } = props;

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const { t: translation } = useTranslation();

  const handleVisibleChange = (visible: boolean): void => {
    setTooltipVisible(visible);
  };

  return (
    <Tooltip
      title={(): JSX.Element => (
        <TooltipEl>
          <div>
            {profilesCount}
            {' '}
            {(profilesCount === 1) ? translation('proxies.profileConnected') : translation('proxies.profilesConnected')}
          </div>
          <div>
            {translation('proxies.toThisProxy')}
          </div>
        </TooltipEl>
      )}
      visible={tooltipVisible}
      mouseEnterDelay={0.8}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={(): HTMLElement => document.querySelector('.proxy-popper-container') || document.body}
    >
      <ProxyListItemProfilesCountEl>
        {profilesCount}
      </ProxyListItemProfilesCountEl>
    </Tooltip>
  );
};

export default ProxyProfilesCountBlock;
