import React, { FC } from 'react';

import { IGeolocationProxy, IGeolocationProxyPrices } from '../../../../interfaces';
import { greenMain, greenMouseover } from '../../../style-templates/colors';
import { IconPlusFill } from '../../icons';
import { proxyTypeToRender, proxyTrafficData } from '../geolocation-proxy-type';
import { ItemRow, VolumeProxyContainer } from '../styles';

interface IProxyItem {
  trafficData: IGeolocationProxy;
  type: keyof IGeolocationProxyPrices;
  openPriceList: (type: keyof IGeolocationProxyPrices, event: HTMLDivElement) => void;
}

const ProxyItem: FC<IProxyItem> = (props): JSX.Element => {

  const { trafficData, type, openPriceList } = props;

  const getTrafficLeftText = (): string => {
    const proxyData = trafficData[proxyTrafficData[type]];
    const trafficLeft = proxyData.trafficLimitBytes - proxyData.trafficUsedBytes;

    if (trafficLeft < 0) {
      return '0.0';
    }

    return (trafficLeft / 1024 / 1024 / 1024).toFixed(1);
  };

  return (
    <ItemRow>
      {proxyTypeToRender[type]}
      <VolumeProxyContainer onClick={(e): void => openPriceList(type, e.currentTarget)}>
        {getTrafficLeftText()}
        {' '}
        GB
        <IconPlusFill padding={0} margin='0 0 0 8px' iconColor='var(--00A987-header-buy-proxy)' iconHoveredColor='var(--00997A-header-buy-proxy)' />
      </VolumeProxyContainer>
    </ItemRow>
  );
};

export default ProxyItem;

