import { createContext } from 'react';

import { INewFeatures } from '../../../feature-toggle';
import { ICheckCompetitorsAppsData, IMigrationData } from '../../electron/interfaces/check-competitors.interfaces';
import { IFolder } from '../interfaces';

export interface ISharedFolder {
  id: string;
  name: string;
}

export interface IUser {
  _id?: string;
  email?: string;
  hasTrial?: boolean;
  trialDays?: number;
  createdAt?: Date;
  profiles?: number;
  profilesCountWithShares?: number;
  hasSuccessPayment?: boolean;
  isEmailConfirmed?: boolean;
  plan?: any;
  card?: any;
  subscription?: any;
  payment?: any;
  paypalSubscriptionId?: string;
  paypalSubsCount?: number;
  planExpireDate?: string;
  targetPageShown?: boolean;
  isQuickSettingsEnabled?: boolean;
  firstPlanSelected?: boolean;
  usedUpgradePlans?: string[];
  defaultWorkspace?: string;
  sharedFolders?: ISharedFolder[];
  is2faEnabled?: boolean;
  realPayments?: number;
  profileRuns?: number;
  isCorrectlyRegistered?: boolean;
  lastAppVersion?: string;
  lastBrowserVersion?: string;
  lastPaymentDate: Date;
  folders: IFolder[];
  selectedFolder?: string;
  newFeatures: INewFeatures;
  isLastPaymentTrialEnded?: boolean;
  invitedByLink: string;
  invitedByUser: string;
  migrationData?: IMigrationData;
  gologinMetadata?: ICheckCompetitorsAppsData;
}

export interface IUserCtx extends IUser {
  updateUser: (userInfo: Partial<IUser>) => void;
  updateFolders: (folders: IFolder[]) => void;
  updateSelectedFolder: (folder: string) => void;
}

export const USER_DEFAULT_VALUE: IUserCtx = {
  _id: '',
  email: '',
  hasTrial: false,
  trialDays: 0,
  createdAt: new Date(0),
  hasSuccessPayment: true,
  profiles: 0,
  profilesCountWithShares: 0,
  isEmailConfirmed: true,
  plan: {},
  card: {},
  subscription: {},
  firstPlanSelected: true,
  payment: {},
  updateUser: () => {},
  paypalSubsCount: 0,
  paypalSubscriptionId: '',
  planExpireDate: '',
  targetPageShown: false,
  usedUpgradePlans: [],
  defaultWorkspace: '',
  sharedFolders: [],
  is2faEnabled: false,
  realPayments: 0,
  profileRuns: 0,
  isCorrectlyRegistered: false,
  lastAppVersion: '3.2.7',
  lastBrowserVersion: '',
  lastPaymentDate: new Date(0),
  folders: [],
  selectedFolder: '',
  newFeatures: {},
  updateFolders: () => {},
  updateSelectedFolder: () => {},
  isLastPaymentTrialEnded: false,
  invitedByLink: '',
  invitedByUser: '',
  migrationData: {},
  gologinMetadata: {},
};

export const userContext = createContext<IUserCtx>(USER_DEFAULT_VALUE);
