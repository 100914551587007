import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { theme } from '../../../proxy-helpers';

const proxyListItemHoveredStyles = css`
  color: ${theme.colors.primary};
  outline: none;
  text-decoration: none;
  background: var(--F9F9F9-proxy-manager-list-item);

  .proxy-flag-block,
  .flag-item,
  .proxy-title {
    opacity: 1;
    color: ${theme.colors.primary};
  }

  .flag-item {
    color: var(--D9D9D9-proxy-manager-list-item);
  }

  .proxy-selected {
    display: none;
  }

  .proxy-update-btn {
    display: flex;

    &:hover {
      color: ${theme.colors.primary};
    }
  }

  .proxy-unlink-button {
    display: flex;
  }

  .proxy-circle-button {
    display: none;
  }
`;

export const ProxyListItemEl = styled.a<{ isHoverable?: boolean; maxWidth?: number }>`
  max-width: ${(props): string => props.maxWidth ? `${props.maxWidth}px` : 'none'};
  height: 41px;
  border-top: 1px solid var(--EBEBEB-proxy-manager-list-item);

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  color: var(--222222-proxy-manager-list-item);

  & .proxy-title {
    padding-right: ${theme.spacing(2)}px;
  }

  &:first-of-type {
    border-top: 1px solid transparent;
  }

  .proxy-selected {
    color: ${theme.colors.primary};
  }

  .proxy-update-btn {
    display: none;
    color: var(--767676-proxy-manager-list-item);
  }

  .proxy-unlink-button {
    display: none;
  }

  .proxy-circle-button {
    display: flex;
  }

  &:hover {
    ${(props): SerializedStyles|null => props.isHoverable ? proxyListItemHoveredStyles : null}
  }
`;

export const ProxyListItemInnerEl = styled('div')<{ newStyle: boolean; isHoverable?: boolean }>`
  display: flex;
  align-items: center;
  max-width: 278px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 16px;
  margin-left: ${(props): string => props.newStyle ? '16px' : '0'};

  ${(props): SerializedStyles|null => props.isHoverable ? null : css`
    .proxy-title {
      color: var(--222222-proxy-manager-list-item);
    }
  `}
`;

export const ProxyButtonsContainerEl = styled('div')`
  display: flex;
  align-items: flex-end;
  padding-right: 24px;
  justify-content: flex-end;
  cursor: default;
  flex-shrink: 0;

  & > * + * {
    margin-left: 14px;
  }
`;

export const ProxyButtonEl = styled('div')<{ success?: boolean }>`
  width: 18px;
  height: 18px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${(props): SerializedStyles => css`
    ${(props.success) ? `
      color: ${theme.colors.primary};
    `: ''}
  `}

  &:hover {
    color: ${theme.colors.primary};
  }
`;

export const RemoveButtonEl = styled('div')`
  width: 18px;
  height: 18px;
  padding: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }

  & > svg {
    width: 18px;
    height: 18px;
  }
`;

export const ProxyFlagBlockEl = styled('div')<{ isFailed?: boolean; newStyle: boolean }>`
  white-space: nowrap;
  display: flex;
  align-items: center;

  ${(props): SerializedStyles => css`
    opacity: ${(props.isFailed) ? '0.5' : '1'};
  `}

  tr:hover, .gologin-table-row & {
    opacity: 1;
  }

  background-color: ${(props): string|null => props.newStyle ? 'inherit' : null};
`;
