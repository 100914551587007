import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';
import { PaperProps, Popover, PopoverOrigin } from '@material-ui/core';
import React, { FC } from 'react';

interface IGologinPopoverElement {
  width?: number;
  maxHeight?: number;
  rightTranslate?: number;
  bottomTranslate?: number;
}

export const GologinPopoverElement = styled(Popover)<IGologinPopoverElement>`
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;

    gap: 1px;

    width: ${(props): string => props.width + 'px'};
    ${(props): SerializedStyles | null => props.maxHeight ? css`max-height: ${props.maxHeight}px` : null}

    padding: 4px;
    border-radius: 4px;

    background: var(--FFFFFF);
    box-shadow: var(--box-shadow-popup);

    ${(props): SerializedStyles | null => props.rightTranslate || props.bottomTranslate ? css`
      transform: translateX(${props.rightTranslate}px) translateY(${props.bottomTranslate}px) !important;
    `: null}
  }
`;

interface ITranslate {
  right: number;
  bottom: number;
}

interface IExtraTranslate extends ITranslate {
  top: number;
  left: number;
}

const calcTranslate = (extraTranslate?: Partial<IExtraTranslate>): ITranslate => {
  const { top = 0, right = 0, bottom = 0, left = 0 } = extraTranslate || {};

  return { right: right - left, bottom: bottom - top };
};

interface IGologinPopover {
  trigger?: 'hover' | 'click';
  anchorEl: HTMLElement | null;
  onOpen?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClose: (event: React.MouseEvent<HTMLDivElement>) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  extraTranslate?: Partial<IExtraTranslate>;
  width?: number;
  maxHeight?: number;
  zIndex?: number;
}

const GologinPopover: FC<IGologinPopover> = (props) => {
  const {
    trigger = 'click',
    children,
    anchorEl,
    onOpen = (): void => void 0,
    onClose,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'center',
    },
    extraTranslate,
    width = 212,
    maxHeight,
    zIndex = 900,
  } = props;

  const { right: rightTranslate, bottom: bottomTranslate } = calcTranslate(extraTranslate);

  let paperProps: Partial<PaperProps> = {};
  if (trigger === 'hover') {
    paperProps = { onMouseEnter: onOpen, onMouseLeave: onClose };
  }

  return (
    <GologinPopoverElement
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      transitionDuration={0}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      style={{ zIndex }}
      width={width}
      maxHeight={maxHeight}
      rightTranslate={rightTranslate}
      bottomTranslate={bottomTranslate}
      PaperProps={paperProps}
    >
      {children}
    </GologinPopoverElement>
  );
};

export default GologinPopover;
