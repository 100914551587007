import React, { FC, memo } from 'react';

import { GologinTableHeaderColumnContainer } from './styles';
import { SelectionStatus } from '../../state/profiles-table-selected-ids.atom';
import { GologinCheckbox } from '../gologin-checkbox';

interface IAllRowsSelection {
  className: string;
  width: number;
  useSelectionStatus: () => SelectionStatus;
  toggleAllRowsSelection: () => void;
}

const AllRowsSelection: FC<IAllRowsSelection> = (props) => {
  const { className, width, useSelectionStatus, toggleAllRowsSelection } = props;

  const allRowsSelectionStatus = useSelectionStatus();
  const isSelected = allRowsSelectionStatus === 'all';
  const isIndeterminate = allRowsSelectionStatus === 'some';

  return (
    <GologinTableHeaderColumnContainer
      style={{ width }}
      className={className}
      newStyle={true}
    >
      <GologinCheckbox
        hasPadding={true}
        onChange={toggleAllRowsSelection}
        checked={isSelected}
        isIndeterminate={isIndeterminate}
      />
    </GologinTableHeaderColumnContainer>
  );
};

export default memo(AllRowsSelection);
