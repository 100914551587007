import * as Sentry from '@sentry/react';

import { NEW_FEATURES } from '../../../../../feature-toggle';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import {
  PROXIES_DEFAULT,
  PROXIES_HAS_MORE_DEFAULT,
  PROXIES_LIST_DEFAULT,
  PROXIES_ITERATIONS_LIMIT,
  LOAD_PROXY_TRANSACTION,
  PROXY_LOAD_OPERATION,
  LOAD_PROXIES_PAGES_SPAN,
  LOAD_PROXIES_SHARED_AND_FREE_SPAN,
  FREE_PROXIES_LOADED_COUNT_TAG,
  HAS_PAGES_NOT_LOADED_TAG,
  PAGES_LOADED_COUNT_TAG,
  SHARED_PROXIES_LOADED_COUNT_TAG,
  PROXY_PAGE_LOADING_ERROR,
  PROXY_PAGE_NOT_LOADED_TAG,
  PROXIES_LOADED_COUNT_TAG,
} from '../../../features/proxy/constants';
import { ReactError } from '../../../utils/sentry-parameters/custom-errors';
import { requestProxiesFree, requestProxiesList, requestProxiesPaginated, requestProxiesShared } from '../../proxies.context/api';
import { updateFreeProxies } from '../free-proxies.atom';
import { getProxyList, setIsProxyListLoaded, updateProxyList } from '../proxy-list.atom';
import { getProxyManagerState } from '../proxy-manager-modal-status.atom';
import { setSharedProxyInfo } from '../shared-proxy-info.atom';

const loadProxiesPagesIteratively = async (transaction: Sentry.Transaction): Promise<void> => {
  const span = transaction.startChild({ op: PROXY_LOAD_OPERATION, description: LOAD_PROXIES_PAGES_SPAN });

  let hasMore = true;
  let currentPage = 1;
  while (hasMore && currentPage <= PROXIES_ITERATIONS_LIMIT) {
    let proxiesInfo: { proxies: IProxy[]; hasMore: boolean } = { proxies: PROXIES_LIST_DEFAULT, hasMore: PROXIES_HAS_MORE_DEFAULT };
    try {
      proxiesInfo = await requestProxiesPaginated(currentPage);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Proxy page loading error';
      Sentry.captureException(new ReactError(errorMessage), (scope) => {
        scope.setTransactionName(PROXY_PAGE_LOADING_ERROR);
        scope.setFingerprint([PROXY_PAGE_LOADING_ERROR]);
        scope.setLevel('error');
        scope.setTag(PROXY_PAGE_NOT_LOADED_TAG, currentPage);

        return scope;
      });

      break;
    }

    const { currentProxy } = getProxyManagerState();
    const proxiesLoaded = getProxyList();
    updateProxyList([...proxiesLoaded, ...proxiesInfo.proxies], currentProxy);
    ({ hasMore } = proxiesInfo);
    currentPage++;
  }

  const { currentProxy } = getProxyManagerState();
  const proxiesLoaded = getProxyList();

  updateProxyList([...proxiesLoaded, ...PROXIES_DEFAULT], currentProxy);

  span.setTag(PAGES_LOADED_COUNT_TAG, currentPage);
  span.setTag(PROXIES_LOADED_COUNT_TAG, proxiesLoaded.length);
  span.setTag(HAS_PAGES_NOT_LOADED_TAG, hasMore);
  span.finish();
};

const loadProxiesSharedAndFree = async (transaction: Sentry.Transaction): Promise<void> => {
  const span = transaction.startChild({ op: PROXY_LOAD_OPERATION, description: LOAD_PROXIES_SHARED_AND_FREE_SPAN });

  const [proxiesShared, proxiesFree] = await Promise.all([requestProxiesShared(), requestProxiesFree()]);
  setSharedProxyInfo(proxiesShared);
  updateFreeProxies(proxiesFree);

  span.setTag(SHARED_PROXIES_LOADED_COUNT_TAG, proxiesShared.length);
  span.setTag(FREE_PROXIES_LOADED_COUNT_TAG, proxiesFree.length);
  span.finish();
};

export const loadProxyList = async (): Promise<void> => {
  const transaction = Sentry.startTransaction({ name: LOAD_PROXY_TRANSACTION });

  if (!NEW_FEATURES.proxyPagination) {
    const proxyInfo = await requestProxiesList();

    updateProxyList([
      ...proxyInfo.proxies,
      ...PROXIES_DEFAULT,
    ]);

    if (proxyInfo.freeProxies?.length) {
      updateFreeProxies(proxyInfo.freeProxies);
    }

    if (proxyInfo.sharedProxyInfos?.length) {
      setSharedProxyInfo(proxyInfo.sharedProxyInfos);
    }

    return transaction.finish();
  }

  await Promise.all([loadProxiesPagesIteratively(transaction), loadProxiesSharedAndFree(transaction)]);
  setIsProxyListLoaded(true);

  transaction.finish();
};
