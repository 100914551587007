import { sendActionAnalytics } from '../../../features/common/api';
import { IProfileProxy } from '../../../features/proxy/components/interfaces/IProfileProxy';
import { EMPTY_PROXY } from '../../../features/proxy/proxy-helpers';
import { updateProfileProxy } from '../../../features/quickProfiles/api';
import { getProfilesList, mapAndSetProfilesList, setProfilesList } from '../../profiles-list.atom';
import { decrementProxyProfilesCount, incrementProxyProfilesCount } from '../proxy-list.atom';

// TODO: fix `any` types for proxies
export const selectProfileProxy = async (profileId: string, proxy: IProfileProxy): Promise<void> => {
  const profilesList = getProfilesList();
  const profileInfo = profilesList.find((profile) => profile.id === profileId);
  const prevProfileProxyId = profileInfo?.proxy?.id || '';
  const noNeedToUpdate = prevProfileProxyId === (proxy._id || ' ');
  if (!profileInfo || noNeedToUpdate) {
    return;
  }

  if (proxy._id) {
    incrementProxyProfilesCount(proxy?._id);
  }

  if (prevProfileProxyId) {
    decrementProxyProfilesCount(prevProfileProxyId);
  }

  mapAndSetProfilesList(prevProfilesList => prevProfilesList.map((profile) => {
    if (profile.id !== profileId) {
      return profile;
    }

    return {
      ...profile,
      proxy: {
        id: proxy._id,
        customName: proxy.customName,
        mode: proxy.mode,
        host: proxy.host,
        port: proxy.port,
        username: proxy.username,
        password: proxy.password,
        country: proxy.country,
        city: proxy.city,
        autoProxyRegion: proxy.autoProxyRegion,
        torProxyRegion: proxy.torProxyRegion,
        profiles: (proxy as any).profiles || [],
        profilesCount: proxy.profilesCount || 0,
      } as any,
      proxyType: proxy.mode,
      proxyEnabled: proxy.mode !== 'none',
    };
  }));

  await updateProfileProxy({ ...proxy, id: profileId } as any)
    .catch(() => setProfilesList(profilesList));

  sendActionAnalytics('chose proxy via proxy manager');
};

export const unselectProfileProxy = (profileId: string): Promise<void> => selectProfileProxy(profileId, EMPTY_PROXY);
