import { Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IFolder } from '../../../../../interfaces';
import { IFolderChange } from '../interfaces/folder-change.interface';
import { IFoldersSelected } from '../interfaces/folders-selected.interface';
import { Folder } from './folder';

interface IFolders extends IFoldersSelected, IFolderChange {
  folders: IFolder[];
  searchFolderName: string;
  handleSearchInputChange: React.FormEventHandler<HTMLInputElement>;
}

const Folders: React.FC<IFolders> = (props) => {
  const { folders, searchFolderName, handleSearchInputChange, foldersSelected, addProfilesToFolder, removeProfilesFromFolder } = props;
  const { t: translation } = useTranslation();

  const renderFolders = (): (JSX.Element | null)[] => folders.filter(Boolean).map((folder, index) => {
    if (folder.shared || !folder.name?.includes(searchFolderName)) {
      return null;
    }

    return (
      <Folder
        {...folder}
        foldersSelected={foldersSelected}
        addProfilesToFolder={addProfilesToFolder}
        removeProfilesFromFolder={removeProfilesFromFolder}
        key={index}
      />
    );
  });

  return (
    <>
      <Input
        onChange={handleSearchInputChange}
        placeholder={translation('foldersManagerModal.search.placeholder') || ''}
        value={searchFolderName}
        style={{ marginBottom: 7 }}
      />
      {renderFolders()}
    </>
  );
};

export default Folders;
