import React, { FC } from 'react';

import IconEmptyFlag from '../../../ui/icons/IconEmptyFlag';
import { EmptyFlagEl } from '../styles/proxy-flag';

interface IProps {
  region?: string;
}

const ProxyFlag: FC<IProps> = (props) => {
  const {
    region,
  } = props;

  if (!region || region === 'null') {
    return (
      <EmptyFlagEl style={{
        marginRight: 9,
      }} className='flag-item'>
        <IconEmptyFlag />
      </EmptyFlagEl>
    );
  }

  const regionLowercase = region.toLowerCase();

  return (
    <div className={`flag-item fi fi-${regionLowercase}`} style={{
      marginRight: 9,
    }} />
  );
};

export default ProxyFlag;
