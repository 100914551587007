import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const ProxyContextMenuEl = styled('div')<{ top: number; left: number }>`
  position: absolute;
  width: 214px;
  background: var(--FFFFFF-proxy-manager-context-menu);
  box-shadow: var(--proxy-manager-context-menu-shadow);
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  z-index: 1150;

  ${(props): SerializedStyles => css`
    top: ${props.top}px;
    left: ${props.left}px;
  `}

  ul {
    padding: 5px;
    margin: 0;
    list-style: none;

    li {
      padding: 6px 16px;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: var(--F2F2F2-proxy-manager-context-menu);
      }
    }

  }
`;

export const DividerEl = styled('div')`
  background: var(--EBEBEB-proxy-manager-context-menu);
  height: 1px;
  margin: 5px 0;
`;
