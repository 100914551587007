import styled from '@emotion/styled/macro';
import { Icon } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TagField } from '../interfaces/tag.interface';

const TagPlaceholder = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const AddTagTxt = styled('div')`
  color: var(--767676-tags-add-tag-placeholder);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 0;
  opacity: 0;
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;

  :hover {
    color: var(--00A987-tags-add-tag-placeholder);

    & i {
      color: var(--00A987-tags-add-tag-placeholder);
    }
  }

  & i {
    color: var(--CDCDCD-tags-add-tag-placeholder);
  }

  td:hover & {
    opacity: 1;
  }
`;

interface IAddTagPlaceholder {
  showPlaceholder: boolean;
  setIsAddMode: () => void;
  field: TagField;
}

const AddTagPlaceholder: FC<IAddTagPlaceholder> = (props) => {
  const {
    showPlaceholder,
    setIsAddMode,
    field,
  } = props;

  const { t: translation } = useTranslation();

  const addPlaceholderKey = field === 'custom-status' ? 'tags.addStatusPlaceholder' : 'tags.addTagPlaceholder';

  return (
    <>
      {(showPlaceholder) ? (
        <AddTagTxt>
          <Icon style={{ marginRight: 7, marginTop: -1 }} type='plus' />
          {translation(addPlaceholderKey)}
        </AddTagTxt>
      ) : <TagPlaceholder
        onClick={(): void => {
          setIsAddMode();
        }}
      />}
    </>
  );
};

export default AddTagPlaceholder;
