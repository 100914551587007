import React from 'react';
import { useLocation } from 'react-router-dom';

import ProxyEditButton from './proxy-edit-button';
import ProxyInfoCheckDate from './proxy-info-check-date';
import ProxyInfoError from './proxy-info-error';
import ProxyInfoSuccess from './proxy-info-success';
import ProxyRemoveButton from './proxy-remove-button';
import { ProxyInfoButtonsWrapper, ProxyInfoHead, ProxyInfoWrapper } from './styles';
import { PROXY_PAGE } from '../../../../../common/constants/routes';
import { IProxy } from '../../../../interfaces';
import { updateEditModeProfileId } from '../../../../state/edit-mode-profile-id.atom';
import { getProfilesList } from '../../../../state/profiles-list.atom';
import { ProxyCheckTooltipView, hideProxyCheckTooltip } from '../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { openProxySettingsModal } from '../../../../state/proxy/proxy-settings-modal.atom';
import { getIsProxyEditable, getIsProxyArchived } from '../../proxy-helpers';

interface IProxyInfo {
  proxy: IProxy;
  profileId?: string;
  proxyTooltipView: ProxyCheckTooltipView;
  selectorContainerElement: Element;
}

const ProxyInfo: React.FC<IProxyInfo> = (props) => {
  const { proxy, profileId, proxyTooltipView, selectorContainerElement } = props;

  const location = useLocation();

  const isProxyPage = location.pathname === PROXY_PAGE;
  const isProxyEditable = getIsProxyEditable(proxy) && proxyTooltipView !== 'proxy-manager-edit-form' && !getIsProxyArchived(proxy);
  const isInDrawer = ['selector-profile-settings-favourite', 'selector-profile-settings-proxy'].includes(proxyTooltipView);

  let isProxyRemovableFromProfile = false;
  if (!isProxyPage) {
    const profiles = getProfilesList();
    const profile = profiles.find(({ id }) => id === profileId);
    if (profile && profile.proxy) {
      isProxyRemovableFromProfile = proxy.id === profile.proxy.id;
    }
  }

  const handleProxyInfoEditClick = (): void => {
    hideProxyCheckTooltip();
    if (isProxyPage) {
      return openProxySettingsModal({ modalView: 'proxy-edit', currentProxy: proxy });
    }

    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: proxy,
      containerElement: selectorContainerElement,
      currentProfileId: profileId,
      modalEditingProxyId: proxy.id,
      proxySelectorLocation: proxyTooltipView,
    });

    if (profileId) {
      updateEditModeProfileId(profileId);
    }
  };

  return (
    <ProxyInfoWrapper onClick={(event): void => event.stopPropagation()}>
      <ProxyInfoHead>
        <ProxyInfoCheckDate proxy={proxy} />
        <ProxyInfoButtonsWrapper isSingleButtonVisible={!!(isProxyEditable && isProxyRemovableFromProfile)}>
          {isProxyEditable ? (
            <ProxyEditButton
              profileId={profileId}
              isInDrawer={isInDrawer}
              handleProxyInfoEditClick={handleProxyInfoEditClick}
            />
          ) : null}
          {isProxyRemovableFromProfile ? (
            <ProxyRemoveButton profileId={profileId || ''} isVisible={true} />
          ) : null}
        </ProxyInfoButtonsWrapper>
      </ProxyInfoHead>
      {proxy.status === false ? <ProxyInfoError /> : (
        <ProxyInfoSuccess
          proxy={proxy}
          proxyTooltipView={proxyTooltipView}
          profileId={profileId}
        />
      )}
    </ProxyInfoWrapper>
  );
};

export default ProxyInfo;
