import { handleUpdateProxiesRequestError } from './handle-proxies-update-error';
import { updateProfileProxyWithErrorHandling } from './update-proxies.operations';
import { getIsTorOrFreeProxy } from '../../../../common/constants/types';
import { sendActionAnalytics } from '../../../features/common/api';
import { EMPTY_PROXY } from '../../../features/proxy/constants';
import { IProxy } from '../../../interfaces';
import { getProfilesList, mapAndSetProfilesList, setProfilesList } from '../../profiles-list.atom';
import { decrementProxyProfilesCount, incrementProxyProfilesCount } from '../proxy-list.atom';

export const selectProfileProxy = async (profileId: string, proxy: IProxy): Promise<void> => {
  const profilesList = getProfilesList();
  const profileInfo = profilesList.find((profile) => profile.id === profileId);
  const { archivedProxy = null } = profileInfo || {};
  const prevProfileProxyId = profileInfo?.proxy?.id || '';
  const noNeedToUpdate = prevProfileProxyId === (proxy.id || '');
  if ((!profileInfo || noNeedToUpdate) && !archivedProxy) {
    return;
  }

  const isTorOrFreeProxy = getIsTorOrFreeProxy(proxy);
  if (!isTorOrFreeProxy) {
    if (proxy.id) {
      incrementProxyProfilesCount(proxy?.id);
    }

    if (prevProfileProxyId) {
      decrementProxyProfilesCount(prevProfileProxyId);
    }
  }

  mapAndSetProfilesList(prevProfilesList => prevProfilesList.map((profile) => {
    if (profile.id !== profileId) {
      return profile;
    }

    const { archivedProxy } = profile;
    if (archivedProxy) {
      delete profile.archivedProxy;
    }

    let profilesCount = proxy.profilesCount || 0;
    if (isTorOrFreeProxy) {
      profilesCount = 0;
    }

    return {
      ...profile,
      proxy: {
        id: proxy.id,
        customName: proxy.customName,
        mode: proxy.mode,
        host: proxy.host,
        port: proxy.port,
        username: proxy.username,
        password: proxy.password,
        country: proxy.country,
        city: proxy.city,
        autoProxyRegion: proxy.autoProxyRegion,
        torProxyRegion: proxy.torProxyRegion,
        profiles: [],
        profilesCount,
      },
      proxyEnabled: proxy.mode !== 'none',
    };
  }));

  const updateProxyResponse = await updateProfileProxyWithErrorHandling({ profileId, proxy: { ...proxy } })
    .catch(() => setProfilesList(profilesList));

  if (updateProxyResponse?.error) {
    handleUpdateProxiesRequestError(updateProxyResponse);

    return;
  }

  sendActionAnalytics('chose proxy via proxy manager');
};

export const unselectProfileProxy = async (profileId: string): Promise<void> => {
  const { id: _unusedId, ...emptyProxy } = EMPTY_PROXY;
  await selectProfileProxy(profileId, emptyProxy);
};
