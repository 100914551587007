import { Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IFolder } from '../../../../interfaces';
import Folders from './folders';
import FolderCreate from './folders/folder-create';
import { IFolderChange } from './interfaces/folder-change.interface';
import { IFoldersSelected } from './interfaces/folders-selected.interface';

interface IFoldersManager extends IFoldersSelected, IFolderChange {
  visible: boolean;
  onClose: () => any;
  afterClose?: () => void;
  folders: IFolder[];
}

const FoldersManager: React.FC<IFoldersManager> = (props) => {
  const { visible, onClose, afterClose, folders = [], foldersSelected, setFoldersSelected, addProfilesToFolder, removeProfilesFromFolder } = props;
  const [searchFolderName, setSearchFolderName] = useState('');
  const { t: translation } = useTranslation();

  const handleCancel = (): void => {
    onClose();
    setSearchFolderName('');
    setFoldersSelected([]);
  };

  const handleSearchInputChange: React.FormEventHandler<HTMLInputElement> = (event) => setSearchFolderName(event.currentTarget.value);

  return (
    <Modal
      bodyStyle={{
        height: 253,
        overflow: 'auto',
        paddingTop: 16,
        zIndex: 1001,
      }}
      afterClose={afterClose}
      onCancel={handleCancel}
      title={translation('foldersManagerModal.title')?.toUpperCase()}
      visible={visible}
      width={416}
      footer={null}
    >
      {folders.length ? (
        <Folders
          folders={folders}
          foldersSelected={foldersSelected}
          setFoldersSelected={setFoldersSelected}
          searchFolderName={searchFolderName}
          handleSearchInputChange={handleSearchInputChange}
          addProfilesToFolder={addProfilesToFolder}
          removeProfilesFromFolder={removeProfilesFromFolder}
        />
      ) : <FolderCreate />}
    </Modal>
  );
};

export default FoldersManager;
