import { IProfile } from '../../interfaces';
import { normalizeTags } from '../tags/api';

export const normalizeProfilesData = (profiles: IProfile[]): IProfile[] => profiles.map((profile) => {
  if (!profile.tags) {
    profile.tags = [];
  }

  profile.tags = normalizeTags(profile.tags);

  return {
    ...profile,
    order: profile.order ?? 1000,
  };
});
