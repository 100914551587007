import { NEW_FEATURES } from '../../../../../feature-toggle';
import { upsertProfileRunStatuses } from '../../../state/profile-run-statuses.atom';
import { getProfilesTableSelectedIds } from '../../../state/profiles-table-selected-ids.atom';
import { closeCloudBrowserUrlInSingleTab } from '../../../utils/cloud-browser';
import { stopContainer } from '../../common/api';

export const stopProfileWeb = async (profileId: string, isMultiple?: boolean): Promise<void> => {
  if (isMultiple) {
    const selectedProfileIds = getProfilesTableSelectedIds();
    upsertProfileRunStatuses(selectedProfileIds.map(selectedProfileId => ({
      id: selectedProfileId,
      status: 'profileStatuses.syncing',
      isWeb: false,
    })));
  } else {
    upsertProfileRunStatuses([{
      id: profileId,
      status: 'profileStatuses.syncing',
      isWeb: false,
    }]);
  }

  closeCloudBrowserUrlInSingleTab(profileId);
  await stopContainer(profileId, NEW_FEATURES.cloudBrowser);

  upsertProfileRunStatuses([{
    id: profileId,
    status: 'profileStatuses.ready',
  }]);
};
