import moment from 'moment';

import { restoreProxiesRequest } from './api';
import { PROXY_MODES_WITH_ACTIONS_ALLOWED } from './constants/settings';
import { LocalizationErrorMessages } from '../../../common/constants/localization-error-messages';
import { isNotNull } from '../../../common/typescript/predicates';
import { IProxy, IArchivedProxy, IArchivedProxyInBrowser } from '../../interfaces';
import { mapAndSetProfilesList, updateProfilesArchivedProxy } from '../../state/profiles-list.atom';
import { hideProxyCheckTooltip } from '../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { onProxyRestoreDone, onProxyRestoreStarted } from '../../state/proxy/proxy-check/proxy-statuses.atom';
import { setProfileSettingsProxyForm } from '../../state/proxy/proxy-in-profile-settings.atom';
import { getFilteredProxyList, removeProxiesFromList, upsertOneProxy } from '../../state/proxy/proxy-list.atom';
import { closeProxyManager, setProxyManagerCurrentProxy } from '../../state/proxy/proxy-manager-modal-status.atom';
import { MULTIPLE_PROXIES_ADD_ERRORS } from '../../state/proxy/proxy-operations/create-proxies.operations';
import { loadProxyList } from '../../state/proxy/proxy-operations/load-proxies.operations';
import { unselectProfileProxy } from '../../state/proxy/proxy-operations/select-proxies.operations';
import { updateSelectedProxies, toggleIsProxySelected } from '../../state/proxy/selected-proxies.atom';
import { getSharedProxies } from '../../state/proxy/shared-proxies.atom';
import { getCheckProxy } from '../../utils/check-proxy';

// TODO: replace with `src/app/features/proxy/utils/proxy-title.ts` later
export const getProxyTitle = (proxy?: IProxy): string => {
  let title = '';
  if (proxy) {
    if (proxy.host) {
      if (proxy.customName) {
        title = proxy.customName;
      } else {
        title = proxy.host;
      }
    } else if (proxy.mode === 'gologin') {
      title = `Free ${proxy.autoProxyRegion?.toLocaleUpperCase()}`;
    } else if (proxy.mode === 'tor') {
      title = `Tor ${proxy.torProxyRegion?.toLocaleUpperCase()}`;
    } else if (proxy?.name) {
      title = proxy.name;
    }
  }

  return title;
};

export interface IStatusParams {
  status: boolean;
  country?: string;
  city?: string;
  error?: string;
  origin?: string;
  timezone?: string;
  languages?: string;
}

export const getProxyStatusParams = async (proxy: IProxy): Promise<IStatusParams> => {
  const statusResponse = await getCheckProxy({ proxy });

  const statusParams: IStatusParams = {
    status: statusResponse?.status === 'success',
  };

  if (statusResponse?.country) {
    statusParams.country = `${statusResponse.country}`.toUpperCase();
  }

  if (statusResponse?.city) {
    statusParams.city = statusResponse.city;
  }

  if (statusResponse?.error) {
    statusParams.error = statusResponse.error;
  }

  if (statusResponse?.origin) {
    statusParams.origin = statusResponse.origin;
  }

  if (statusResponse?.timezone) {
    statusParams.timezone = statusResponse.timezone;
  }

  if (statusResponse?.languages) {
    statusParams.languages = statusResponse.languages;
  }

  return statusParams;
};

export const getProxyStatus = (proxy: IProxy): string | undefined => {
  const isTorOrFree = ['gologin', 'tor'].includes(proxy.mode);
  let status;
  if (isTorOrFree) {
    status = 'success';
  } else if (proxy.checkDate) {
    const daysDiff = (moment().unix() - moment(proxy.checkDate).unix()) / (60 * 60 * 24);

    if (!proxy.host || proxy.status === false) {
      status = 'fail';
    } else if (daysDiff < 1 && proxy.status === true) {
      status = 'success';
    }
  }

  return status;
};

export const copyProxies = (proxies: IProxy[]): void => {
  const lines = proxies.map((proxy): string => {
    const { host, port, username, password, customName, changeIpUrl } = proxy;
    let textClipboard = '';

    if (host !== '' && port) {
      textClipboard = host + ':' + port;
    }

    if (username !== '') {
      textClipboard = username + '@' + host + ':' + port;
    }

    if (password !== '') {
      textClipboard = username + ':' + password + '@' + host + ':' + port;
    }

    if (changeIpUrl) {
      textClipboard = `${textClipboard}[${changeIpUrl}]`;
    }

    if (customName) {
      textClipboard = `${textClipboard}:${customName}`;
    }

    return textClipboard;
  });

  navigator.clipboard.writeText(lines.join('\n'));
};

interface ISelectProxiesWithShift {
  shiftKey: boolean;
  selectedProxies: string[];
  proxiesToSelectFrom: IProxy[];
  proxy: IProxy;
  lastSelectedProxy: string;
}

export const selectProxiesWithShift = ({
  shiftKey,
  selectedProxies,
  proxiesToSelectFrom,
  proxy,
  lastSelectedProxy,
}: ISelectProxiesWithShift): void => {
  if (!shiftKey) {
    updateSelectedProxies({ lastSelectedProxy: proxy.id });

    return toggleIsProxySelected(proxy.id);
  }

  const lastSelectedProxyIndex = proxiesToSelectFrom.findIndex(proxyEl => proxyEl.id === lastSelectedProxy);
  const currentProxyIndex = proxiesToSelectFrom.findIndex(proxyEl => proxyEl.id === proxy.id);
  const startIndex = Math.min(lastSelectedProxyIndex, currentProxyIndex);
  const endIndex = Math.max(lastSelectedProxyIndex, currentProxyIndex);

  const newCheckedProxies = proxiesToSelectFrom.slice(startIndex, endIndex + 1).map((proxyEl) => proxyEl.id);

  let newSelectedProxies;

  if (selectedProxies.includes(proxy.id)) {
    newSelectedProxies = selectedProxies.filter((val) => !newCheckedProxies.includes(val));
  } else {
    newSelectedProxies = [...new Set(newCheckedProxies.concat(selectedProxies))];
  }

  updateSelectedProxies({
    selectedProxies: newSelectedProxies,
    lastSelectedProxy: proxy.id,
  });
};

export const handleNoProxyClick = (event: React.SyntheticEvent, profileId: string|null = ''): void => {
  event.preventDefault();
  event.stopPropagation();

  if (!profileId) {
    return;
  }

  unselectProfileProxy(profileId);
  closeProxyManager();
  hideProxyCheckTooltip();
};

export const getIsProxyEditable = (proxy: IProxy): boolean => {
  const sharedProxies = getSharedProxies();
  const isProxyShared = sharedProxies.some(({ id, profileId }) => (id === proxy.id || profileId === proxy?.profileId));
  if (getIsProxyArchived(proxy)) {
    return true;
  }

  const filteredProxies = getFilteredProxyList();
  const proxiesVisible: (IProxy|IArchivedProxy|null)[] = filteredProxies.filter(filteredProxy => !filteredProxy.isInvisible);
  const isCurrentProxyNotInList = proxy?.id && !proxiesVisible.filter(isNotNull).some(({ id }) => id === proxy.id);
  if (isCurrentProxyNotInList) {
    return false;
  }

  return PROXY_MODES_WITH_ACTIONS_ALLOWED.includes(proxy.mode) && !(proxy.isInvisible || isProxyShared);

};

export const restoreProxy = async (archivedProxy: Partial<IArchivedProxy>): Promise<IProxy> => {
  const { id: archivedProxyId = '' } = archivedProxy;
  onProxyRestoreStarted([archivedProxyId]);
  const [restoredProxy] = await restoreProxiesRequest([archivedProxyId]);
  const { isRestoredByOwner = false } = restoredProxy;
  onProxyRestoreDone([archivedProxyId]);
  if (isRestoredByOwner) {
    upsertOneProxy(restoredProxy);
  }

  setProfileSettingsProxyForm(restoredProxy);
  mapAndSetProfilesList(profiles => profiles.map(profileInList => {
    const profileArchivedProxyId = profileInList?.archivedProxy?.id;
    if (profileArchivedProxyId === restoredProxy.id) {
      delete profileInList.archivedProxy;

      return {
        ...profileInList,
        proxy: restoredProxy,
        proxyEnabled: true,
      };
    }

    return profileInList;
  }));

  setProxyManagerCurrentProxy(restoredProxy);

  return restoredProxy;
};

export const getIsProxyArchived = (proxy?: Partial<IProxy|IArchivedProxy|IArchivedProxyInBrowser>|null): boolean => {
  if (!proxy) {
    return false;
  }

  if (Object.hasOwn(proxy, 'isArchivedProxy') || Object.hasOwn(proxy, 'name')) {
    return true;
  }

  return false;
};

export const isCreateProxiesValidationError = (errorMessage: unknown): boolean => {
  if (!Array.isArray(errorMessage)) {
    return false;
  }

  const [validationError] = errorMessage;
  if (!validationError) {
    return false;
  }

  const { constraints = null } = validationError || {};
  if (!constraints) {
    return false;
  }

  return true;
};

export const getCreateProxyValidationError = (
  errorMessage: unknown,
  proxiesMultipleAddLimit?: number,
): { error: { message: string; isCustomError?: boolean; count?: number}} => {
  const createProxiesError = {
    error: {
      message: String(errorMessage) || LocalizationErrorMessages.SomethingWentWrongAgainLater,
      isCustomError: true,
    },
  };

  if (!Array.isArray(errorMessage)) {
    return createProxiesError;
  }

  const [validationError] = errorMessage;
  if (!validationError) {
    return createProxiesError;
  }

  const { constraints = null } = validationError || {};
  if (!constraints) {
    return createProxiesError;
  }

  const { arrayMaxSize = '' } = constraints || {};
  if (arrayMaxSize) {
    return {
      error: {
        message: MULTIPLE_PROXIES_ADD_ERRORS.limitReachedError,
        count: proxiesMultipleAddLimit || 30,
      },
    };
  }

  const constraintsFirstValue = getFirstConstraintValue(constraints);

  if (constraintsFirstValue) {
    return {
      error: {
        message: constraintsFirstValue,
        isCustomError: true,
      },
    };
  }

  return createProxiesError;
};

const getFirstConstraintValue = (constraints: Record<string, unknown>): string => {
  const constraintKeys = Object.keys(constraints);
  if (constraintKeys.length > 0) {
    const [firstKey] = constraintKeys;
    if (typeof constraints[firstKey] === 'string') {
      return constraints[firstKey];
    }
  }

  return '';
};

export const handleActiveProxiesAlreadyArchived = async (
  archivedProxies: IArchivedProxyInBrowser[],
  shouldLoadAllProxies?: boolean,
): Promise<void> => {
  if (!archivedProxies?.length) {
    return;
  }

  updateProfilesArchivedProxy(archivedProxies);
  archivedProxies.forEach(archivedProxy => {
    removeProxiesFromList([archivedProxy?.id]);
  });

  shouldLoadAllProxies && loadProxyList();
};

export const makeArchivedProxyFromProxy = (proxy: IProxy): IArchivedProxyInBrowser => {
  const { id, country = '', customName = '', host = '' } = proxy || {};
  const name = customName || host || '';

  return { id, country, name };
};
