import styled from '@emotion/styled';

export const ProxyCheckboxWrapperEl = styled('div')`
  margin-right: 9px;
  width: 16px;
  height: 16px;
  margin-top: -4px;

  .ant-checkbox-wrapper {
    line-height: 1.5;
  }

  & .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
`;
