import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { openProxyManagerListView } from './proxy/proxy-manager-modal-status.atom';
import { resetSelectedProxies } from './proxy/selected-proxies.atom';

const editModeProfileIdAtom = atom<string | null>(null);
const proxyElementLocationAtom = atom<string | null>(null);

const setEditModeProfileId = (value: string | null): void => getDefaultStore().set(editModeProfileIdAtom, value);
const setProxyElementLocation = (value: string | null): void => getDefaultStore().set(proxyElementLocationAtom, value);

export const useEditModeProfileId = (): string | null => useAtomValue(editModeProfileIdAtom);
export const useProxyElementLocation = (): string | null => useAtomValue(proxyElementLocationAtom);

export const updateEditModeProfileId = (id: string | null): void => {
  setEditModeProfileId(id);

  if (id) {
    return;
  }

  setProxyElementLocation(null);
  openProxyManagerListView();
  resetSelectedProxies();
};

export const updateProxyElementLocation = (id: string | null): void => {
  setProxyElementLocation(id);
};
