import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Container, EmailChip, ErrorText, InputElem, LinkToPayment } from './styles';
import { isEmailValid } from '../../../../../utils/email-validator';
import { openWebsitePricing } from '../../../../../utils/open-site';

interface IEmailInput {
  emails: string[];
  onChange: (newEmails: string[]) => void;
  errorText: string;
  onErrorTextChange: (newErrorText: string) => void;
  maxEmails: number;
  placeholder: string;
  inputFocused: boolean;
  type?: 'invite'|'sharing';
}

export const EmailInput: FC<IEmailInput> = (props) => {
  const {
    emails, onChange, errorText, onErrorTextChange,
    maxEmails, placeholder, inputFocused, type,
  } = props;

  const [emailText, setEmailText] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  const refreshErrorText = (newEmailText: string = emailText): void => {
    let newText = '';
    if ((newEmailText && emails.length >= maxEmails) ||
      (!emails.length && emails.length >= maxEmails)) {
      newText = type === 'invite' ?
        'You\'ve reached the limit of team members. Upgrade your plan to invite more.' :
        'You\'ve reached the limit of sharing. Upgrade your plan to share more.';
    }

    onErrorTextChange(newText);
  };

  const tryToPushEmailToList = (): void => {
    if (!emailText) {
      return;
    }

    const isEmailPresent = emails.find(email => email === emailText);
    if (isEmailPresent) {
      setEmailText('');

      return;
    }

    const isEmailInvalid = emailText && !isEmailValid(emailText);
    if (isEmailInvalid) {
      onErrorTextChange(translation('errorMessages.enterValidEmail'));

      return;
    }

    refreshErrorText();

    onChange(emails.concat(emailText.trim()));
    setEmailText('');
  };

  const onPaste = (event: React.ClipboardEvent): void => {
    const text = event.clipboardData.getData('text/plain');
    const emailCandidates = text.split(/\s+/);
    const validEmails = emailCandidates.filter(isEmailValid);
    const addedEmails = validEmails.filter(em => !emails.includes(em));
    const canBeConcat = !(validEmails.length === 0 && emailCandidates.length === 1);

    if (canBeConcat) {
      event.preventDefault();
      onChange(emails.concat(addedEmails));
    }

    refreshErrorText();
  };

  const onEmailInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Backspace' && !(emailText || '').trim() && emails.length) {
      event.preventDefault();
      const last = emails.length - 1;
      setEmailText(emails[last]);
      onChange(emails.slice(0, last));
    }

    if ((event.key === ' ' || event.key === 'Enter') && (emailText || '').trim()) {
      event.preventDefault();
      tryToPushEmailToList();
    }
  };

  const onBlur = (): void => {
    setIsInputFocused(false);
    tryToPushEmailToList();
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailText(event.target.value.trim());
    refreshErrorText(event.target.value.trim());
  };

  const onEmailDelete = (emailToDelete: string): void => {
    onChange(emails.filter(email => email !== emailToDelete));
    refreshErrorText();
  };

  const renderErrorText = (error: string): JSX.Element => {
    if (!error) {
      return (
        <ErrorText>
          &nbsp;
        </ErrorText>
      );
    }

    const isUpgradeError = error.toLowerCase().includes('upgrade');
    if (!isUpgradeError) {
      return (
        <ErrorText>
          {error}
        </ErrorText>
      );
    }

    let firstText = translation('newShareModal.reachedLimit.first');
    let secondText = translation('newShareModal.reachedLimit.second');
    if (type === 'invite') {
      firstText = translation('modals.inviteUserWorkspace.reachedLimit.first');
      secondText = translation('modals.inviteUserWorkspace.reachedLimit.second');
    }

    return (
      <ErrorText>
        {firstText}
        &nbsp;
        <LinkToPayment
          onClick={onClickLinkUpgrade}
          onMouseDown={(event): void => event.preventDefault()}
        >
          {translation('base.upgrade')}
        </LinkToPayment>
        &nbsp;
        {secondText}
      </ErrorText>
    );
  };

  const onClickLinkUpgrade = (): void => {
    openWebsitePricing({});
  };

  const renderInputElement = (): JSX.Element | null => {
    if (!inputFocused && emails.length) {
      return null;
    }

    return (
      <InputElem
        placeholder={!emails.length ? placeholder : ''}
        value={emailText}
        onChange={onInputChange}
        onKeyDown={onEmailInputKeyPress}
        autoFocus={true}
        onBlur={onBlur}
        onPaste={onPaste}
      />
    );
  };

  return (
    <Col>
      {renderErrorText(errorText)}
      <Container className='ant-input' isFocused={isInputFocused} isError={false}>
        {emails.map(email => (
          <EmailChip
            label={email}
            onDelete={(): void => onEmailDelete(email)}
            key={email}
            deleteIcon={(
              <span >
                ×
              </span>
            )}
          />
        ))}
        {renderInputElement()}
      </Container>
    </Col>
  );
};
