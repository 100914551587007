import { SerializedStyles } from '@emotion/core';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Chip } from '@material-ui/core';

export const InputElem = styled('input')`
  font-family: 'Roboto';
  border: none;
  outline: none;

  flex: 1;
  height: fit-content;
  padding: 0;
  margin: 0;
  min-width: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--222222-modals-invite-members);
  background: inherit;

  &::-webkit-input-placeholder{
    color: var(--767676-modals-invite-members);
    opacity: 0.5;
  }
`;

export const Col = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const EmailChip = styled(Chip)`
  &.MuiChip-root {
    padding: 0 4px;
    border-radius: 2px;
    background: var(--0000001A-modals-invite-members);

    overflow: hidden;
    color: var(--222222-modals-invite-members);
    font: normal 12px/16px 'Roboto';
    height: 24px;

    margin-bottom: 3px;
    margin-right: 3px;

    .MuiChip-label {
      padding: 0;
    }

    .MuiChip-deleteIcon {
      margin: 0 0 0 5px;
      width: 8px;
      height: 16px;
      font-size: 15px;
      color: var(--222222-modals-invite-members);
    }
  }
`;

export const Container = styled('div')<{ isFocused: boolean; isError: boolean }>`
  display: flex;
  height: auto;

  flex-wrap: wrap;
  min-height: 32px;

  margin-top: 2px;
  padding: 6px 6px 0;

  ${(props): SerializedStyles|'' => props.isFocused ? css`
    border-color: var(--1FC47D-modals-invite-members);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px var(--00B86D33-modals-invite-members);
  ` : ''}

  ${(props): SerializedStyles|'' => props.isError ? css`
    border-color: var(--F96060-modals-invite-members);
    box-shadow: none;

    &:hover {
      border-color: var(--F96060-modals-invite-members);
    }
  ` : ''}
`;

export const ErrorText = styled('span')`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: var(--EB5757-modals-invite-members);
`;

export const LinkToPayment = styled('span')`
  text-decoration-line: underline;

  :hover{
  cursor: pointer;
  }
`;
