import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { NEW_FEATURES } from '../../../../../feature-toggle';
import { IS_SEPARATED_CHECKOUT_KEY_NAME } from '../../../../common/constants/constants';
import { IPlan } from '../../../interfaces/plan';
import { history } from '../../../services';
import { FOREVER_FREE_PLAN_ID } from '../constants';
import {
  E_PAYMENT_METHODS,
  ICalcModalHeightParams,
  IDiscountShort,
  IErrorCreationPaymentLinkData,
  IIsWorkspacePaidParams,
  PaymentMethodIntentType,
  PaymentType,
} from '../interfaces';

const CENTS_IN_DOLLAR = 100;
const MIN_PLAN_PRICE_IN_DOLLAR = 0.5;

export const recalculatePrice = (promoDiscountData: IDiscountShort, price: number): number => {
  const { active, discountType, value } = promoDiscountData;
  if (!(active && value)) {
    return price;
  }

  switch (discountType) {
    case 'percent':
      return Math.max(MIN_PLAN_PRICE_IN_DOLLAR, price - price * value / CENTS_IN_DOLLAR);
    case 'fix':
      return Math.max(MIN_PLAN_PRICE_IN_DOLLAR, price - value / CENTS_IN_DOLLAR);
    default:
      return price;
  }
};

export const getAnalyticsPaymentType = (type: PaymentType, isGooglePay?: boolean|null): PaymentMethodIntentType => {
  const analyticsPaymentType: Record<PaymentType, PaymentMethodIntentType> = {
    [PaymentType.Card]: E_PAYMENT_METHODS.STRIPE,
    [PaymentType.Mobile]: isGooglePay ? E_PAYMENT_METHODS.GOOGLE_PAY : E_PAYMENT_METHODS.APPLE_PAY,
    [PaymentType.PayPal]: E_PAYMENT_METHODS.PAYPAL,
    [PaymentType.Crypto]: E_PAYMENT_METHODS.CRYPTO,
    [PaymentType.Sigma]: E_PAYMENT_METHODS.SIGMA,
  };

  return analyticsPaymentType[type];
};

export const getShouldShowUpgrade = (params: IIsWorkspacePaidParams): boolean => {
  if (!NEW_FEATURES.showUpgrade) {
    return false;
  }

  const { planId, isUnpaid, planExpiresAt, paymentIsTrial, paymentMethod } = params;
  if (planId === FOREVER_FREE_PLAN_ID || isUnpaid) {
    return false;
  }

  if (!planExpiresAt) {
    return false;
  }

  const paymentExpiresIn = moment(planExpiresAt).utc();

  return !paymentIsTrial && moment(paymentExpiresIn).isAfter(moment.utc()) && paymentMethod !== 'paypal';
};

// cannot yet be replaced with 'auto' height
// because it also affects 3ds, loading and success modals
// and the design requirement is to keep heights equal in payment flow
// it's easier and more stable to hard-code them, until design is fixed
export const calcModalHeight = (params: ICalcModalHeightParams): number => {
  const {
    paymentType,
    isNewStripeFlow,
    isTrialEnded,
    isUpgradeDiscount,
  } = params;

  const REGULAR_HEIGHT = 688;
  const PAYPAL_HEIGHT = 750;

  let cardHeight = 757;
  let regularHeight = REGULAR_HEIGHT;
  const trialEndedTextHeight = 32;
  const oldFlowFormHeight = 94;
  const upgradePlanLineHeight = 58;

  if (isTrialEnded) {
    cardHeight += trialEndedTextHeight;
  }

  if (!isNewStripeFlow) {
    cardHeight += oldFlowFormHeight;
  }

  if (isUpgradeDiscount) {
    cardHeight += upgradePlanLineHeight;
    regularHeight += upgradePlanLineHeight;
  }

  const paymentTypesHeights: Record<PaymentType, number> = {
    Card: cardHeight,
    Mobile: regularHeight,
    Crypto: regularHeight,
    PayPal: PAYPAL_HEIGHT,
    Sigma: regularHeight,
  };

  return paymentTypesHeights[paymentType];
};

export const checkSeparatedCheckoutUrl = (): void => {
  const isCheckoutApp = sessionStorage.getItem(IS_SEPARATED_CHECKOUT_KEY_NAME) === 'true';
  if (!isCheckoutApp) {
    return;
  }

  const urlObject = new URL(window.location.href);
  let path = urlObject.pathname;
  const queryParams = urlObject.search;

  if (path.startsWith('/checkout')) {
    return;
  }

  path = `/checkout${path}`;
  setTimeout(() => {
    history.replace(`${path}${queryParams}`);
  }, 100);
};

export const planByPlanId = (planId: string, plansList: IPlan[]): IPlan|null =>
  plansList.find(planObj => planObj.id === planId) || null;

export const showErrorCreationPaymentLink = ({
  message,
  errorCode,
  errorMessage,
}: IErrorCreationPaymentLinkData): void => {
  if (errorCode === 403) {
    // для ошибки по суточным лимитами попыток сделать платеж
    message.error(errorMessage);

    return;
  }

  message.error(<Trans i18nKey='notifications.error.paymentFailedContactSupport' />);
};
