import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProxySearchInputEl = styled('input')<{
  width?: number;
  truncate: boolean;
  isTransparent: boolean;
  newStyle: boolean;
  isInDrawer: boolean;
  newDrawer: boolean;
}>`
  border: none;
  background: none;
  outline: none;
  max-width: 100%;
  padding: 0;
  height: 16px;
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 1;
  color: var(--000000-proxy-manager-search-input);

  ${(props): SerializedStyles => css`
    ${(props.width) ? `
      width: ${props.width}px;
      margin-left: ${props.newStyle ? props.newDrawer && props.isInDrawer ? '8px' : '16px' : '23px'};
      margin-right: ${props.newStyle ? '7px' : '0'};
      display: ${props.newStyle ? 'flex' : 'block'};
    ` : `
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    `}

    ${(props.truncate) ? `
      text-overflow: ellipsis;
    ` : ''}

    opacity: ${(props.isTransparent) ? '0.5' : '1'};

    tr:hover, .gologin-table-row:hover & {
      opacity: 1;
    }
  `}

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--CDCDCD-proxy-manager-search-input);
  }
`;
