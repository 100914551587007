import css from '@emotion/css';
import styled from '@emotion/styled';
import Popper from '@material-ui/core/Popper';
import React, { FC } from 'react';

import { tagsContext } from '../../../state';
import IconCheck from '../../../ui/icons/IconCheck';
import IconDotsHorizontal from '../../../ui/icons/IconDotsHorizontal';
import { IAddTag, ITag, ITagBase, TagField } from '../interfaces/tag.interface';
import TagsColors from './tag-colors';
import TagListItem from './tag-list-item';
import { workspaceContext } from '../../../state';
import { NEW_FEATURES } from '../../../../../feature-toggle';

const TagSuggest = styled('div')`
  cursor: pointer;
  display: flex;

  &:hover {
    background: var(--F9F9F9-tags-add-tag);

    button {
      opacity: 1;
    }
  }
`;

const TagSuggestItemLeft = styled('div')`
  padding: 2px 8px;
  height: 24px;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;

  & article {
    max-width: 140px;
  }
`;

const EditTagBtn = styled('button')<{ isOpen: boolean }>`
  background: none;
  border: none;
  outline: none;
  height: 24px;
  width: 36px;
  padding: 4px 14px 4px 6px;
  color: var(--767676-tags-add-tag);
  cursor: pointer;

  ${(props): any => css`
    ${(props.isOpen) ? `
      opacity: 1;
    ` : `
      opacity: 0;
    `}
  `}

  &:hover {
    outline: none;
    color: var(--00A987-tags-add-tag);
  }
`;

const CheckIconBlock = styled('div')`
  color: var(--8E8E8E-tags-add-tag);
  cursor: pointer;
  padding: 0 4px;
  margin-left: 3px;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;
  vertical-align: top;
  top: -3px;
`;

const TagsColorContainer = styled('div')`
  position: relative;
  left: -111px;
  top: -31px;
`;

declare interface IProps {
  tag: ITag;
  profileIds: string[];
  addTag: (params: IAddTag) => void;
  updateTag: (tag: ITagBase) => Promise<void>;
  setTitle: (title: string) => void;
  alreadyAdded: boolean;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  removeTag: () => Promise<void>;
  field: TagField;
  closeTagsPopup: () => void;
}

const TagSuggestItem: FC<IProps> = (props) => {
  const {
    tag,
    setTitle,
    alreadyAdded,
    removeProfileTag,
    removeTag,
    addTag,
    updateTag,
    profileIds,
    field,
    closeTagsPopup,
  } = props;

  const { setTagEditorVisible } = React.useContext(tagsContext);

  const {
    permissions,
  } = React.useContext(workspaceContext);

  let allowRemove = true;
  let allowEditTags = true;

  if (NEW_FEATURES.workspaces) {
    allowRemove = permissions.removeTags;
    allowEditTags = permissions.editTags;
  }

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const editorOpen = Boolean(anchorEl);

  const handleClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    setTagEditorVisible(!anchorEl);
    setAnchorEl((anchorEl) ? null : e.currentTarget);
  };

  const handleClickOutside = (e: Event): void => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target as Node)
    ) {
      e.preventDefault();
      e.stopPropagation();

      setTagEditorVisible(false);
      setAnchorEl(null);
    }
  };

  React.useEffect(() => {
    if (anchorEl) {
      document.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [anchorEl]);

  const onSuggestClick = async (): Promise<void> => {
    setTitle('');
    if (field === 'custom-status') {
      closeTagsPopup();
    }

    if (alreadyAdded) {
      await removeProfileTag(profileIds, tag.id);

      return;
    }

    addTag({
      profileIds,
      tagId: tag.id,
      title: tag.title,
      color: tag.color,
      field,
    });
  };

  const onTagRemove = (): Promise<void> => {
    setAnchorEl(null);
    setTagEditorVisible(false);

    return removeTag();
  };

  return (
    <TagSuggest>
      <TagSuggestItemLeft onClick={onSuggestClick}>
        <TagListItem
          tag={tag}
          handleClick={() => { return; }}
        />
        {(alreadyAdded) ? (
          <CheckIconBlock>
            <IconCheck />
          </CheckIconBlock>
        ) : null}
      </TagSuggestItemLeft>

      {(allowEditTags) ? (
        <>
          <EditTagBtn onClick={handleClick} isOpen={editorOpen}>
            <IconDotsHorizontal />
          </EditTagBtn>
          <Popper
            open={editorOpen}
            anchorEl={anchorEl}
            style={{ zIndex: 1060 }}
            placement='bottom-start'
          >
            <TagsColorContainer ref={wrapperRef}>
              <TagsColors
                tag={tag}
                updateTag={updateTag}
                close={(): void => {
                  setAnchorEl(null);
                  setTagEditorVisible(false);
                }}
                removeTag={onTagRemove}
                allowChangeTitle={true}
                allowRemove={allowRemove}
              />
            </TagsColorContainer>
          </Popper>
        </>
      ) : null}
    </TagSuggest>
  );
};

export default TagSuggestItem;
