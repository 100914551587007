import React, { FC, memo } from 'react';
import { areEqual } from 'react-window';

import ProxyListItem from './proxy-list-item';
import { getIsProxyTruthy } from '../../../../../common/constants/types';
import { isNotNull } from '../../../../../common/typescript/predicates';
import { IProxy, IArchivedProxy } from '../../../../interfaces';
import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import {
  closeProxyManager,
  getProxyManagerState,
  useProxyManagerState,
} from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { selectProfileProxy } from '../../../../state/proxy/proxy-operations/select-proxies.operations';
import { toggleIsProxySelected, useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { useIsSelectProxyModeOpened } from '../../../../state/proxy-select-menu.atom';
import { getIsProxyArchived, restoreProxy, selectProxiesWithShift } from '../../proxy-helpers';
import { getNoIdProxyId } from '../../utils/proxy-id';

interface IProxyRow {
  data: {
    filteredProxies: (IProxy|IArchivedProxy|null)[];
    currentProxy?: IProxy;
    onProxySelect: (proxy: IProxy, profileId?: string) => Promise<void>;
    onSetNoProxy: (profileId: string) => Promise<void>;
    localProxyElementLocation: string | null;
  };
  index: number;
  style: any;
}

const ProxyRow: FC<IProxyRow> = (props) => {
  const { data, index, style } = props;

  const { currentProfileId } = useProxyManagerState();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const { lastSelectedProxy, selectedProxies } = useSelectedProxies();

  const {
    filteredProxies,
    currentProxy,
    localProxyElementLocation,
  } = data;

  let proxy = filteredProxies[index];
  if (getIsProxyArchived(currentProxy) && proxy?.id === currentProxy?.id) {
    proxy = currentProxy;
  }

  if (!proxy) {
    return <div></div>;
  }

  const handleRowClick: React.MouseEventHandler<HTMLAnchorElement> = async (event) => {
    let proxyToSelect = proxy;
    if (getIsProxyArchived(proxy)) {
      proxyToSelect = await restoreProxy(proxy);
    }

    const { handleProxySelect } = getProxyManagerState();
    event.preventDefault();

    if (isSelectProxyModeOpened) {
      toggleIsProxySelected(proxy.id);

      return selectProxiesWithShift({
        shiftKey: event.shiftKey,
        selectedProxies,
        proxiesToSelectFrom: filteredProxies.filter(isNotNull),
        proxy: proxyToSelect,
        lastSelectedProxy,
      });
    }

    if (handleProxySelect) {
      let proxyId = proxyToSelect?.id;
      if (!proxyId && getIsProxyTruthy(proxy)) {
        proxyId = getNoIdProxyId(proxyToSelect);
      }

      handleProxySelect(proxyId);

      return;
    }

    if (currentProfileId) {
      const performanceObserverService = PerformanceObserverService.getInstance();
      performanceObserverService.handleUserAction({ userAction: 'select-proxy-manager-proxy' });

      selectProfileProxy(currentProfileId, proxyToSelect);
      closeProxyManager();
    }
  };

  return (
    <ProxyListItem
      key={[
        proxy.mode,
        proxy.host,
        proxy.port,
        proxy.username,
        proxy.password,
        proxy.autoProxyRegion,
        proxy.torProxyRegion,
        proxy.id,
      ].join('_')}
      proxy={proxy}
      isCurrent={!!(currentProxy && proxy.id === currentProxy.id)}
      currentProfileId={currentProfileId}
      style={style}
      localProxyElementLocation={localProxyElementLocation}
      isSelectProxyModeOpened={isSelectProxyModeOpened}
      isHoverable={true}
      maxWidth={0}
      shouldShowEdit={true}
      handleRowClick={handleRowClick}
    />
  );
};

export default memo(ProxyRow, areEqual);
