import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { flagIcons } from '../../../../flag-icons';
import { CheckProxyButtonWrapper } from '../check-proxy-button/styles';
import { PROXY_MANAGER_SIZE } from '../proxy-manager/styles';
import { PROXY_SETTINGS_MODAL_CONTENT_SIZE } from '../proxy-page/proxy-settings-modal/styles';
import { ProxyWrapperSectionEl } from '../proxy-selector/styles';

export const ProxyPopper = styled.div`
  ${flagIcons}
`;

export const ProxyManagerContainer = styled('div')<{ isDarkTheme: boolean; isWrappedByModal?: boolean }>`
  background: var(--FFFFFF-proxy-manager-popup);
  border-radius: 8px;
  font-family: 'Roboto';
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
  overflow: hidden;

  ${(props): SerializedStyles => {
    if (props.isWrappedByModal) {
      return css`
        border: 0.5px solid var(--E5E5E6-proxy-settings-modal-content-border);
      `;
    }

    return props.isDarkTheme ? css`
      box-shadow: -2px 0 8px var(--00000026-antd);
    ` : css`
      box-shadow: 0px 4px 8px var(--0000001F-proxy-manager-popup), 0px 6px 12px var(--0000001F-proxy-manager-popup), 0px 1px 16px var(--0000001F-proxy-manager-popup);
    `;
  }}
`;

export const ProxyManagerContent = styled('div')<{ newStyle: boolean; isWrappedByModal?: boolean }>`
  width: ${(props): number => props.isWrappedByModal ? PROXY_SETTINGS_MODAL_CONTENT_SIZE.width : PROXY_MANAGER_SIZE.width}px;
  position: relative;

  ${(props): SerializedStyles|null => props.newStyle ? css`
    ${CheckProxyButtonWrapper} {
      display: flex;
    }
    
    ${ProxyWrapperSectionEl} {
      z-index: 104;
    }
  ` : null}
`;
