import { Input, Select } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

const { Option } = Select;

import GologinProxyForm, { DEFAULT_GOLOGIN_PROXY_REGION } from './gologin-proxy-form';
import HttpSocksProxyForm from './http-socks-proxy-form';
import { ProxyTabAddNewProxyFormWrapper, ProxyTabCustomFormTitle, ProxyTabCustomFormWrapper } from './styles';
import TorProxyForm, { DEFAULT_TOR_PROXY_REGION } from './tor-proxy-form';
import { UpdateProxyFn } from '..';
import { IProxy } from '../../../../features/proxy/components/interfaces/IProxy';
import { getProxyByArtificialProxyId } from '../../../../state/proxy/proxy-list.atom';
import { IconArrowDown } from '../../../../ui/gologin-header/icons/icon-arrow-down';
import { EMPTY_PROXY } from '../../../proxy/proxy-helpers';
import ProxySavedSelector from '../../../proxy/proxy-saved-selector';
import ClearButtonForm from '../components/clear-button-tooltip';
import { Row, Subtitle } from '../domElements';

interface IProxyTabCustomForm {
  profileId?: string;
  proxyId: string | null;
  currentProxy: IProxy;
  updateProxy: UpdateProxyFn;
  setUpdatedField: (field: string) => any;
  isCheckInterrupted: boolean;
  setIsCheckInterrupted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EMPTY_FORM_PROXY: IProxy = {
  ...EMPTY_PROXY,
  mode: 'http',
  port: '',
};

const ProxyTabCustomForm: React.FC<IProxyTabCustomForm> = (props) => {
  const { profileId, proxyId, currentProxy, updateProxy, setUpdatedField, isCheckInterrupted, setIsCheckInterrupted } = props;

  const isFormDisabled = !!getProxyByArtificialProxyId(proxyId) || currentProxy?.mode === 'geolocation';

  const updateProxySelector = (mode: IProxy['mode']): void => {
    setUpdatedField('proxy');
    updateProxy({
      mode,
      autoProxyRegion: DEFAULT_GOLOGIN_PROXY_REGION,
      torProxyRegion: DEFAULT_TOR_PROXY_REGION,
    });
  };

  const updateAutoProxyRegion = (autoProxyRegion: string, field: 'autoProxyRegion'|'torProxyRegion'): void => {
    updateProxy({ [field]: autoProxyRegion });
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>, field: 'host' | 'port'): void => {
    updateProxy({ [field]: target.value.trim() });
  };

  const clearNewProxyForm = (): void => {
    updateProxy(null);
  };

  const getProxyInputGroup = (): JSX.Element|null => {
    if (!['http', 'socks4', 'socks5'].includes(currentProxy.mode)) {
      return null;
    }

    const hostInputStyles: React.CSSProperties = { width: 150 };
    if (isFormDisabled) {
      hostInputStyles.borderRight = 'none';
    }

    const colonSeparatorStyles: React.CSSProperties = {
      width: 10,
      padding: 3,
      borderLeft: 0,
      pointerEvents: 'none',
      backgroundColor: 'var(--FFFFFF)',
    };

    if (isFormDisabled) {
      delete colonSeparatorStyles.pointerEvents;
      delete colonSeparatorStyles.backgroundColor;
      colonSeparatorStyles.borderRight = 'none';
    }

    return (
      <>
        <Input
          placeholder='IP Address'
          style={hostInputStyles}
          value={currentProxy.host}
          onChange={(event): void => handleChange(event, 'host')}
          disabled={isFormDisabled}
        />
        <Input
          style={colonSeparatorStyles}
          placeholder=":"
          disabled={true}
        />
        <Input
          placeholder='port'
          style={{ width: 65, borderLeft: 0 }}
          value={currentProxy.port}
          onChange={(event): void => handleChange(event, 'port')}
          disabled={isFormDisabled}
        />
      </>
    );
  };

  const renderClearButtonTooltip = (): JSX.Element|null => {
    if (!['http', 'socks4', 'socks5'].includes(currentProxy.mode)) {
      return null;
    }

    return (
      <ClearButtonForm
        isDisabled={isFormDisabled}
        clearNewProxyForm={clearNewProxyForm}
      />
    );
  };

  const renderProxySettingsContent = (): JSX.Element => {
    switch (currentProxy.mode) {
      case 'gologin':
        return (
          <GologinProxyForm
            proxy={currentProxy}
            updateAutoProxyRegion={updateAutoProxyRegion}
            isDisabled={isFormDisabled}
          />
        );
      case 'tor':
        return (
          <TorProxyForm
            proxy={currentProxy}
            updateAutoProxyRegion={updateAutoProxyRegion}
            isDisabled={isFormDisabled}
          />
        );
      default:
        return (
          <HttpSocksProxyForm
            proxy={currentProxy}
            updateProxy={updateProxy}
            isCheckInterrupted={isCheckInterrupted}
            setIsCheckInterrupted={setIsCheckInterrupted}
            isDisabled={isFormDisabled}
          />
        );
    }
  };

  return (
    <ProxyTabCustomFormWrapper>
      <div>
        <ProxyTabCustomFormTitle className='proxy-saved-selector-title'>
          <Trans i18nKey='profileSettingsPage.proxyTab.savedProxyTitle' />
        </ProxyTabCustomFormTitle>
        <ProxySavedSelector
          preselectedProxy={currentProxy}
          editingProfileId={profileId}
          clearNewProxyForm={clearNewProxyForm}
        />
      </div>
      <ProxyTabAddNewProxyFormWrapper isDisabled={isFormDisabled}>
        <ProxyTabCustomFormTitle>
          <Trans i18nKey='profileSettingsPage.proxyTab.addNewProxyTitle' />
        </ProxyTabCustomFormTitle>
        <div>
          <Subtitle>
            <Trans i18nKey='newProfile.proxy.proxyTypeHostAndPortLabel' />
          </Subtitle>
          <Row>
            <Input.Group compact={true} style={{ width: 500 }}>
              <Select
                value={currentProxy.mode}
                onChange={updateProxySelector}
                style={{ width: 278 }}
                disabled={isFormDisabled}
                suffixIcon={(
                  <IconArrowDown
                    padding={0}
                    margin='-2px -4px 0 0'
                    iconColor='var(--B5B5BA)'
                    iconHoveredColor='var(--222222)'
                  />
                )}
              >
                <Option key='http'>
                  HTTP Proxy
                </Option>
                <Option key='socks4'>
                  Socks 4 Proxy
                </Option>
                <Option key='socks5'>
                  Socks 5 Proxy
                </Option>
                <Option key='gologin'>
                  Free Proxy
                </Option>
                <Option key='tor'>
                  Tor Network
                </Option>
              </Select>
              {getProxyInputGroup()}
            </Input.Group>
            {renderClearButtonTooltip()}
          </Row>
        </div>
        {renderProxySettingsContent()}
      </ProxyTabAddNewProxyFormWrapper>
    </ProxyTabCustomFormWrapper>
  );
};

export default ProxyTabCustomForm;
