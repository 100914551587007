import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { ISharedProxyInfo } from '../../features/proxy/components/interfaces/ISharedProxyInfo';

const sharedProxyInfoAtom = atom<ISharedProxyInfo[]>([]);

export const getSharedProxyInfo = (): ISharedProxyInfo[] => getDefaultStore().get(sharedProxyInfoAtom);
export const setSharedProxyInfo = (data: ISharedProxyInfo[]): void => getDefaultStore().set(sharedProxyInfoAtom, data);

export const useSharedProxyInfo = (): ISharedProxyInfo[] => useAtomValue(sharedProxyInfoAtom);

interface IUpdateSharedProxyInfo extends ISharedProxyInfo {
  isInvisible?: boolean;
}

export const updateSharedProxyInfo = (proxyInfoItem: IUpdateSharedProxyInfo): void => {
  const sharedProxyInfo = getSharedProxyInfo();

  let itemUpdated = false;
  const proxyInfo = { ...proxyInfoItem };
  let updatedList = sharedProxyInfo.map((proxy) => {
    if (proxyInfo.profileId === proxy.profileId) {
      itemUpdated =  true;

      return {
        ...proxy,
        ...proxyInfo,
      };
    }

    return proxy;
  });

  if (!itemUpdated) {
    updatedList = [proxyInfo].concat(updatedList);
  }

  setSharedProxyInfo(updatedList);
};
