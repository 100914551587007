import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Icon, message, Modal } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IUserInvite } from './interfaces';
import ShareLinks from './share-links';
import {
  Row,
  RowSpaceBetween,
  ShareProfileModal,
  TextMessageWrapper,
  UserContainer,
} from './styles';
import UserRow from './user-row';
import { IProfile, ShareInvitedRole } from '../../../../interfaces';
import { userContext, workspaceContext } from '../../../../state';
import { getProfileNamesForNotifications, mapAndSetProfilesList, useProfilesList } from '../../../../state/profiles-list.atom';
import {
  SHARE_MODAL_NAME,
  startClosingProfileTableModal,
  useProfileTableModalIsVisible,
  useProfilesTableModalProfiles,
} from '../../../../state/profiles-table-modal.atom';
import { updateProfilesTableSelectedIds, useProfilesTableSelectedIds } from '../../../../state/profiles-table-selected-ids.atom';
import LabeledElement from '../../../../ui/LabeledElement';
import { GreenSubmitButton } from '../../../../ui/modern-modal';
import { filterEmailsByUser } from '../../../../utils/filterEmailsByUser';
import { SharingSelectRole } from '../../../../utils/roles-texts';
import { sendActionAnalytics } from '../../../common/api';
import { refreshCurrentWorkspaceData } from '../../../common/switch-workspace';
import RoleSelect from '../../../members/role-select';
import {
  removeMultipleInvites,
  requestProfilesInvites,
  shareMultipleProfiles,
  updateManyInviteRoles,
} from '../../../quickProfiles/api';
import { EmailInput } from '../invite-members/email-input';

const MultiShareModal: React.FC = () => {
  const isModalVisible = useProfileTableModalIsVisible(SHARE_MODAL_NAME);
  const modalProfiles = useProfilesTableModalProfiles();

  const profilesList = useProfilesList();
  const selectedProfilesIds = useProfilesTableSelectedIds();
  const selectedProfiles = profilesList.filter(profile => selectedProfilesIds.includes(profile.id));
  const profiles = modalProfiles || selectedProfiles;
  const profilesIds = profiles.map(({ id }) => id);
  const disableSharing = selectedProfiles.some(({ permissions }) => !permissions?.shareProfile);

  const isSharingMultipleProfiles = profiles.length > 1;

  const [isLoadingInvites, setIsLoadingInvites] = useState(true);
  const [emails, setEmails] = useState<string[]>([]);
  const [invites, setInvites] = useState<IUserInvite[]>([]);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<SharingSelectRole>('guest');
  const [isShowInvites, setIsShowInvites] = useState<boolean>(true);
  const [isEmailInputFocused, setIsEmailInputFocused] = useState<boolean>(true);

  const { email: userEmail } = useContext(userContext);
  const workspace = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const maxEmails = Number.POSITIVE_INFINITY;

  const changeProfilesData = (profileIds: string[], newData: Partial<IProfile>): void => {
    mapAndSetProfilesList(profilesOld => profilesOld.map(profile => {
      if (!profileIds.includes(profile.id)) {
        return profile;
      }

      return { ...profile, ...newData };
    }));
  };

  const getInvites = async (): Promise<void> => {
    const profiles = await requestProfilesInvites(profilesIds || []);
    const profileIds: string[] = profilesIds.filter(profileId => !profiles.find(pp => pp.instanceId === profileId));
    const setIds = [...new Set(profileIds)];
    if (setIds.length) {
      changeProfilesData(setIds, { isShared: false });
    }

    const dataInvites: IUserInvite[] = profiles.reduce<IUserInvite[]>((acc, share) => {
      const userIndex = acc.findIndex(user => user.email === share.to.email);

      if (userIndex !== -1) {
        acc[userIndex].role = [...new Set([...acc[userIndex].role, share.role])];
        acc[userIndex].inviteId = [...new Set([...acc[userIndex].inviteId, share.id])];
      } else {
        acc.push({
          email: share.to.email,
          role: [share.role],
          inviteId: [share.id],
        });
      }

      return acc;
    }, []);

    setInvites(dataInvites);
    setIsLoadingInvites(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      getInvites();
      sendActionAnalytics('visited share profile');
    } else {
      return () => {
        setInvites([]);
        setIsLoadingInvites(true);
      };
    }
  }, [isModalVisible]);

  const confirm = (email: string, ids: string[]): void => {
    const modal = Modal.confirm({
      title: translation('newShareModal.removeAccessConfirm.title'),
      icon: <Icon type='question-circle' />,
      content: email,
      okText: translation('base.cancel'),
      cancelText: translation('newShareModal.removeSuccessMessage.remove'),
      className: 'deleteAccessSharing',
      okButtonProps: { type: 'primary' },
      cancelButtonProps: { type: 'default' },
      onCancel: async () => {
        modal.destroy();
        await removeInvite(email, ids);
      },
      keyboard: false,
      maskClosable: false,
      width: 416,
    });
  };

  const removeInvite = async (email: string, ids: string[]): Promise<void> => {
    setIsLoadingInvites(true);
    await removeMultipleInvites(ids);
    if (isSharingMultipleProfiles) {
      updateProfilesTableSelectedIds(profilesIds || []);
    }

    const MAX_EMAIL_LENGTH = 30;
    email = email.length > MAX_EMAIL_LENGTH ? email.slice(0, MAX_EMAIL_LENGTH) + '...' : email;
    message.success(
      <TextMessageWrapper>
        <span style={{ fontWeight: 700 }}>
          {email}
        </span>
        &nbsp;
        {translation('newShareModal.removeSuccessMessage.hasBeenRemoved')}
      </TextMessageWrapper>,
    );

    await getInvites();
  };

  const changeUserRole = async (ids: string[], role: SharingSelectRole | 'remove', email: string): Promise<void> => {
    if (['varies', 'owner', 'remove'].includes(role)) {
      if (role === 'remove') {
        confirm(email, ids);
      }

      return;
    }

    await updateManyInviteRoles({ inviteIds: ids, role: role as ShareInvitedRole })
      .finally(() => setIsLoadingInvites(false));

    await getInvites();
  };

  const sendShareSucessNotification = (profilesCount: number): void => {
    message.success(
      <TextMessageWrapper>
        <Trans i18nKey='newShareModal.sharedSuccessMessage.youveShared' count={profilesCount} />
      </TextMessageWrapper>,
    );
  };

  const sendShare = async (role: SharingSelectRole): Promise<void> => {
    if (['varies', 'owner'].includes(role)) {
      return;
    }

    const emailsToShare = filterEmailsByUser(emails, userEmail, 'You can\'t share with yourself');
    if (!emailsToShare.length) {
      setEmails([]);

      return;
    }

    const sharedProfiles = await shareMultipleProfiles({
      instanceIds: profilesIds || [],
      role: role as ShareInvitedRole,
      emails: emailsToShare,
      workspaceId: workspace.id,
    });

    const profileIds = sharedProfiles.map(share => share.instanceId);
    changeProfilesData(profileIds, { isShared: true });
    sendShareSucessNotification(profileIds.length);

    refreshCurrentWorkspaceData(workspace);
    setIsShowInvites(true);
  };

  const sendInvite = async (): Promise<void> => {
    if (!emails.length) {
      message.error(translation('errorMessages.insertUserEmail'));

      return;
    }

    setIsLoadingInvites(true);
    await sendShare(selectedRole).finally(() => getInvites());
    setEmails([]);
  };

  const getModalTitle = (): string => {
    if (isSharingMultipleProfiles) {
      return translation('sharingModal.title.text', { count: profilesIds?.length });
    }

    return translation('newShareModal.titleSingle') || '';
  };

  const closeModal = (): void => {
    startClosingProfileTableModal();
    setEmails([]);
    setEmailErrorText('');
  };

  const renderInvitesHeader = (): JSX.Element => {
    if (invites.length === 1) {
      const [invite] = invites;

      return (
        <UserContainer key={invite.email}>
          <UserRow
            email={invite.email}
            role={invite.role}
            inviteId={invite.inviteId}
            changeUserRole={changeUserRole}
            disableSharing={disableSharing}
          />
        </UserContainer>
      );
    }

    return (
      <Row onClick={(): void => setIsShowInvites(!isShowInvites)} style={{ cursor: 'pointer' }}>
        <span style={{ fontSize: '14px', lineHeight: '14px', color: 'var(--767676-modals-profile-share-modal)', marginRight: '4px', fontFamily: 'Roboto' }}>
          {translation('newShareModal.sharedWith')}
        </span>
        <span style={{ opacity: 0.5, marginRight: '3px', fontFamily: 'Roboto', fontSize: '12px', lineHeight: '14px', color: 'var(--222222-modals-profile-share-modal)' }}>
          {(!isLoadingInvites && profilesIds?.length === 1) ? (invites.length - 1) : invites.length}
        </span>
        {isShowInvites ? <ExpandLess htmlColor='var(--D2D2D2-modals-profile-share-modal)' /> : <ExpandMore htmlColor='var(--D2D2D2-modals-profile-share-modal)' />}
      </Row>
    );
  };

  return (
    <ShareProfileModal
      destroyOnClose={true}
      visible={isModalVisible}
      title={getModalTitle()}
      onCancel={closeModal}
      footer={null}
      width={380}
    >
      <RowSpaceBetween>
        <div
          style={{ width: '100%', marginBottom: '24px' }}
          onClick={(): void => setIsEmailInputFocused(true)}
          onBlur={(): void => setIsEmailInputFocused(false)}
        >
          <EmailInput
            emails={emails}
            onChange={setEmails}
            errorText={emailErrorText}
            onErrorTextChange={setEmailErrorText}
            maxEmails={maxEmails}
            inputFocused={isEmailInputFocused}
            placeholder={translation('newShareModal.emailInputPlaceholder')}
            type='sharing'
          />
        </div>
      </RowSpaceBetween>
      <LabeledElement title={translation('newShareModal.selectRole')}>
        <RowSpaceBetween style={{ marginBottom: 24 }}>
          <RoleSelect
            width='100%'
            menuWidth='242px'
            type='sharing'
            role={selectedRole}
            setRole={(role): void => setSelectedRole(role as SharingSelectRole)}
            disabled={disableSharing}
          />
          <GreenSubmitButton
            disabled={disableSharing}
            onClick={sendInvite}
            style={{ marginLeft: 12 }}
          >
            {translation('newShareModal.shareButton')}
          </GreenSubmitButton>
        </RowSpaceBetween>
      </LabeledElement>
      {renderInvitesHeader()}
      {isLoadingInvites ? (
        <div style={{ width: '100%', fontSize: '24px', textAlign: 'center' }}>
          <Icon type='loading' />
        </div>
      ) : null}
      {(isShowInvites && invites.length !== 1) ?
        invites.map(invite => (
          <UserContainer key={invite.email}>
            <UserRow
              email={invite.email}
              role={invite.role}
              inviteId={invite.inviteId}
              changeUserRole={changeUserRole}
              disableSharing={disableSharing}
            />
          </UserContainer>
        )) : null}
      <ShareLinks profiles={profiles} />
    </ShareProfileModal>
  );
};

export default MultiShareModal;
