import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { blackMain, grey, greyInformers } from '../../../../ui/style-templates/colors';
import { IChangeSetting } from '../../interfaces';
import { ContainerIcon, SeparatedContainer, ContainerParamsColumn, TextArea } from '../../styles';
import { validateLaunchArguments } from '../../../../utils/validate-launch-arguments';
import { message } from 'antd';

interface ILaunchArguments extends IChangeSetting {
  launchArguments: string;
  profileId: string;
}

export const LaunchArguments: FC<ILaunchArguments> = ({ launchArguments, profileId, changeSetting }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [launchArgumentsText, setLaunchArgumentsText] = useState('');
  const [launchArgumentsCount, setLaunchArgumentsCount] = useState(0);

  const { t: translation } = useTranslation();

  useEffect(() => {
    setLaunchArgumentsText(launchArguments);
  }, [launchArguments, profileId]);

  useEffect(() => {
    const count = launchArgumentsText.split(/\r?\n/).filter(arg => arg.trim() !== '').length;
    setLaunchArgumentsCount(count);
  }, [launchArgumentsText]);

  const changeLaunchArguments = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const text = event.target.value;
    setLaunchArgumentsText(text);
  };

  const onBlur = (): void => {
    const checkArguments = validateLaunchArguments(launchArgumentsText);
    if (checkArguments.invalid.length) {
      message.error(translation('profileSettings.launchArguments.skippedForbiddenArguments', { list: checkArguments.invalid.join(', ') }));
    }

    const validLaunchArgs = checkArguments.valid.join('\n');
    changeSetting({ launchArguments: validLaunchArgs });
    setLaunchArgumentsText(validLaunchArgs);
  };

  const renderAddLaunchArguments = (): JSX.Element => {
    if (launchArgumentsCount) {
      return (
        <div>
          {translation('quickSettings.browser.launchArgumentsCountNewKey', { count: launchArgumentsCount })}
        </div>
      );
    }

    return (
      <ContainerIcon
        iconColor={greyInformers}
        iconHoveredColor={blackMain}
        textColor={grey}
        textHoveredColor={blackMain}
        iconType='stroke'
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        {translation('quickSettings.browser.addLaunchArguments')}
      </ContainerIcon>
    );
  };

  const renderLaunchArgumentsArea = (): JSX.Element|null => {
    if (!isEditing) {
      return null;
    }

    return (
      <TextArea
        style={{ marginTop: 16 }}
        minHeight={132}
        placeholder={translation('profileSettings.launchArguments.placeholder') || ''}
        value={launchArgumentsText}
        onChange={changeLaunchArguments}
        onBlur={onBlur}
      />
    );
  };

  return (
    <ContainerParamsColumn hasCursorPointer={true}>
      <SeparatedContainer onClick={(): void => setIsEditing(!isEditing)}>
        {renderAddLaunchArguments()}
      </SeparatedContainer>
      {renderLaunchArgumentsArea()}
    </ContainerParamsColumn>
  );
};
