import styled from '@emotion/styled';

import { theme } from '../proxy-helpers';

export const IconBtn = styled('button')<{ isInNewDrawer?: boolean }>`
  color: ${props => props.isInNewDrawer ? 'var(--98989F-proxy-manager-icon-buttons)' : 'var(--767676-proxy-manager-icon-buttons)'};
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  width: 16px;
  height: 16px;
  text-decoration: none;
  margin-right: ${theme.spacing(3)}px;
  padding: 0;

  &:last-child {
    margin-right: 0;
  }

  &:focus,
  &:visited {
    color: ${props => props.isInNewDrawer ? 'var(--98989F-proxy-manager-icon-buttons)' : 'var(--767676-proxy-manager-icon-buttons)'};
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.isInNewDrawer ? 'var(--2B2B31-proxy-manager-icon-buttons)' : theme.colors.primary};
    outline: none;
    text-decoration: none;
  }
`;
