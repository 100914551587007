import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { EnhancedStore } from 'redux-starter-kit';
import {
  IResponse,
  configureRefreshFetch,
  fetchJSON,
} from 'refresh-fetch';

import { MEMBER_TOKEN } from './config';
import { retrieveToken, clearToken } from './storage';
import { validateRequestAndGetProperties } from './utils';
import { history } from '..';
import { API_BASE_URL, TWO_FA_TOKEN_KEY_NAME, API_ANALYTICS_URL } from '../../../common/constants/constants';
import i18n from '../../i18n';
import { IFetchWithTokenConfig, IGetRequestProps } from '../../interfaces/fetch-with-token.interfaces';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { openWebsitePricing } from '../../utils/open-site';
import { ReactError } from '../../utils/sentry-parameters/custom-errors';
import { sendReactErrorToSentry } from '../../utils/sentry.helper';
import { getGologinMetaHeader } from '../../utils/user-os';

const isElectron = !!window.require;
let currentHistory: number[] = [];
let patchHistory: string;
let queryArray: RequestInfo[] = [];
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export async function fetchWithToken<T>(
  url: string,
  config?: IFetchWithTokenConfig,
): Promise<IResponse<T>> {
  const [urlEndpoint] = url.replace(API_BASE_URL, '').split('?') || [''];
  const method = config?.method || 'GET';

  const { memberToken, token, headers } = await validateRequestAndGetProperties({ urlEndpoint, method });
  if (API_BASE_URL === 'https://api.gologin.com' && url.includes('/analytics')) {
    url = url.replace(API_BASE_URL, API_ANALYTICS_URL);
  }

  queryArray.push(url);
  currentHistory.push(history.length);
  patchHistory = history.location.pathname;

  // Убираем одинаковые значения, так как если посылается несколько запросов с одной страницы массив забивается одинаковыми значениями
  currentHistory = [...new Set(currentHistory)];

  let configWithToken: RequestInit = config || {};
  if (memberToken && !(url.includes('share/member') || url.includes('share/my-members'))) {
    headers.Authorization = `Bearer ${memberToken}`;
  }

  if (token !== null) {
    configWithToken = { ...config, headers: { ...config?.headers, ...headers } };
  }

  function wait(delay: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  const requestError = (error: any): undefined | Promise<any> => {
    const endpointsToIgnore = [
      '/analytics',
      '/run-sync',
      '/gologin-settings/migration-data',
      '/user/metadata',
    ];

    if (endpointsToIgnore.some(endpoint => url.toString().includes(endpoint))) {
      if (url.toString().includes('/gologin-settings/migration-data')) {
        const isErrorKnown = typeof error?.body?.message === 'string';
        const errorMessage = isErrorKnown
          ? error.body.message
          : i18n.t('notifications.error.somethingWentWrongAgainLater');

        const transactionNameAndFingerprint = isErrorKnown ? 'request-error' : 'base-unknown-error';
        Sentry.captureException(new ReactError(errorMessage), (scope) => {
          const path = url.replace(/^.*\/\/[^/]+/, '');
          scope.setLevel(<Sentry.SeverityLevel>'error');
          scope.setTag('path', `${path}`);
          scope.setTag('error-status', `${error.status}`);
          scope.setTransactionName(transactionNameAndFingerprint);
          scope.setFingerprint([transactionNameAndFingerprint]);

          return scope;
        });
      }

      return;
    }

    if (error.message === 'Network request failed') {
      message.error('Network error');
      console.error(error);
    }

    if (error.status === 429) {
      message.error(i18n.t('notifications.error.tooManyRequests'));
      if (isElectron) {
        ipcRenderer && ipcRenderer.invoke('too-many-requests').catch(() => null);
      }

      Sentry.captureException(new ReactError(error.body.message), (scope) => {
        const path = url.replace(/^.*\/\/[^/]+/, '');
        scope.setLevel(<Sentry.SeverityLevel>'error');
        scope.setTag('path', `${path}`);
        scope.setTag('error-status', `${error.status}`);
        scope.setTransactionName('too-many-requests-error');
        scope.setFingerprint(['too-many-requests-error']);

        return scope;
      });

      return;
    }

    const urlData = new URL(url.toString());

    if (error.status === 401) {
      const isLoginPage = urlData.pathname === '/user/login';
      if (isLoginPage) {
        message.error(error.body.message);
      } else if (urlData.pathname !== '/user') {
        if (isElectron) {
          ipcRenderer && ipcRenderer.invoke('redirected-to-sign-up');
        }

        clearToken();
        history.replace('/sign_up');
      }

      Sentry.captureException(new ReactError(error.body.message), (scope) => {
        const path = url.replace(/^.*\/\/[^/]+/, '');
        scope.setLevel(<Sentry.SeverityLevel>'error');
        scope.setTag('path', `${path}`);
        scope.setTag('error-status', `${error.status}`);
        scope.setTransactionName('user-unauthorized-error');
        scope.setFingerprint(['user-unauthorized-error']);

        return scope;
      });
    } else if (
      error.status === 403 &&
      error?.body?.message === 'Enter two factor authentication code' &&
      patchHistory !== '/two_factor'
    ) {
      if (isElectron) {
        ipcRenderer && ipcRenderer.invoke('redirected-to-2fa');
      }

      history.replace('/two_factor');
    } else if (error.status !== 400) {
      if (error.status !== 402) {
        fetchWithToken(`${API_BASE_URL}/analytics`, {
          method: 'POST',
          body: JSON.stringify({
            type: 'request-error',
            status: 'error',
            field: url,
            message: i18n.t(error.body?.message),
            appVersion: window.gologinAppVersion,
          }),
        }).catch((errorObj: unknown) => {
          const errorMessage = errorObj instanceof Error ? errorObj.message : JSON.stringify(error);
          sendReactErrorToSentry({
            message: errorMessage, transactionName: 'send-analytics-error',
            tags: [['scenario', 'send-analytics']],
          });
        });
      }

      if (typeof error?.body?.message === 'string') {
        const [name] = error.body?.message.match(/".*"/g) || [];

        if (name) {
          const nameWithoutQuotas = name.replace(/"/g, '');

          const MAX_NAME_SIZE = 40;

          if (nameWithoutQuotas.length > MAX_NAME_SIZE) {
            error.body.message = error.body.message.replace(nameWithoutQuotas, `${nameWithoutQuotas.slice(0, MAX_NAME_SIZE)}...`);
          }
        }
      }

      const isErrorKnown = typeof error?.body?.message === 'string';
      const errorMessage = isErrorKnown
        ? i18n.t(error.body.message)
        : i18n.t('notifications.error.somethingWentWrongAgainLater');

      message.error(errorMessage);

      if (error.status === 403) {
        Sentry.captureException(new ReactError(error.body.message), (scope) => {
          const path = url.replace(/^.*\/\/[^/]+/, '');
          scope.setLevel(<Sentry.SeverityLevel>'warning');
          scope.setTag('path', `${path}`);
          scope.setTag('error-status', `${error.status}`);
          scope.setTransactionName('forbidden-request-error');
          scope.setFingerprint(['forbidden-request-error']);

          return scope;
        });
      } else {
        const transactionNameAndFingerprint = isErrorKnown ? 'request-error' : 'base-unknown-error';
        Sentry.captureException(new ReactError(errorMessage), (scope) => {
          const path = url.replace(/^.*\/\/[^/]+/, '');
          scope.setLevel(<Sentry.SeverityLevel>'error');
          scope.setTag('path', `${path}`);
          scope.setTag('error-status', `${error.status}`);
          scope.setTransactionName(transactionNameAndFingerprint);
          scope.setFingerprint([transactionNameAndFingerprint]);

          return scope;
        });
      }
    } else {
      const errorMessage = i18n.t('notifications.error.invalidInputData') + ` "${error.body.message[0].property}"`;
      message.error(errorMessage);
      console.log(errorMessage);
      Sentry.captureException(new ReactError(errorMessage), (scope) => {
        const path = url.replace(/^.*\/\/[^/]+/, '');
        scope.setLevel(<Sentry.SeverityLevel>'error');
        scope.setTag('path', `${path}`);
        scope.setTag('error-status', `${error.status}`);
        scope.setTransactionName('bad-requests-error');
        scope.setFingerprint(['bad-requests-error']);

        return scope;
      });
    }

    if (error.status === 402) {
      openWebsitePricing({ isShowPaymentMethods: true });

      Sentry.captureException(new ReactError(error.body.message), (scope) => {
        const urlPath = url.replace(/^.*\/\/[^/]+/, '');
        scope.setLevel(<Sentry.SeverityLevel>'warning');
        scope.setTag('path', `${urlPath}`);
        scope.setTag('error-status', `${error.status}`);
        scope.setTransactionName('payment-required-error');
        scope.setFingerprint(['payment-required-error']);

        return scope;
      });
    }

  };

  const request = async (): Promise<any> => {
    while (!window.navigator.onLine) {

      await wait(100);

      // 2 условия так как:
      // 1) length - не гарантирует уникальное значение при возврате на предыдущую страницу, для этого нужен patchname
      // 2) patchname - пользователь может заходить на одну и туже страницу, для этого нужно смотреть length
      if (currentHistory[currentHistory.length - 1] !== history.length || patchHistory !== history.location.pathname) {
        // Удаляем запрос из массива так как он уже не актуален
        queryArray = queryArray.filter(val => val !== url);

        // Ничего не возвращать не полчается так как все равно возвраает null и в консоль летят ошибки, посчитал это лучшим вариантом.
        return new Promise(() => Error);
      }
    }

    if (queryArray.includes(url)) {
      return fetchJSON<T>(url, configWithToken)
        .then((req: any) => req)
        .catch((error: any) => {
          requestError(error);

          throw error;
        }).finally(() => {
          // Удаляем запрос из массива после завершения
          queryArray = queryArray.filter(val => val !== url);
        });
    }
  };

  return request();
}

export async function fetchWithTimeout<T>(url: string, options: any): Promise<T> {
  const { timeout = 30 * 1000 } = options;
  const { memberToken, headers } = await getRequestProperties();

  const configWithToken: RequestInit = { ...options, headers };

  if (memberToken && !(url.includes('share/member') || url.includes('share/my-members'))) {
    headers.Authorization = `Bearer ${memberToken}`;
  }

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(url, {
    ...configWithToken,
    signal: controller.signal,
  });

  clearTimeout(id);

  return response.json() as Promise<T>;
}

export async function getRequestProperties(): Promise<IGetRequestProps> {
  const memberToken = sessionStorage.getItem(MEMBER_TOKEN) || '';
  const twoFaToken = localStorage.getItem(TWO_FA_TOKEN_KEY_NAME) || '';
  const token = await retrieveToken() || '';

  return {
    memberToken,
    twoFaToken,
    token,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-two-factor-token': twoFaToken || '',
      'workspace-id': getCurrentWorkspaceId(),
      'GoLogin-Meta-Header': await getGologinMetaHeader(),
    },
  };
}

export function configureHttp(storeConfig: EnhancedStore): any {
  return configureRefreshFetch({
    fetch: fetchWithToken,
    shouldRefreshToken: () => false,
    refreshToken: async () => null,
  });
}
