import { Icon, Modal, Tooltip } from 'antd';
import React, { FC, SetStateAction, useEffect, useState, Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getExtensionsCount, setExtensionsRequest } from './api';
import { ExtensionIcon } from './extensions-icon';
import { ExtensionsList } from './extensions-list';
import { IExtensionsObject, ISetExtensionsOptions } from './interfaces/extensions-modal.interface';
import { extensionsContext } from '../../../../state/extensions.context';
import IconExtension from '../../../../ui/icons/IconExtension';
import { sendActionAnalytics } from '../../../common/api';
import {
  addExtensionToSettings,
  removeExtensionFromSettings,
  requestExtensionList,
} from '../../../profileSettingsComponents/extensionsTab/api';
import {
  ActionButton,
  ActionsContainerInstalled,
  ExtensionContainerInstalled,
  ExtensionName,
  ExtensionNameContainer,
  FlagContainer,
  InstalledBlockTitle,
  InstalledExtensions,
  ModalExtensionsList,
  IconDiv,
  LoaderDiv,
} from '../../../profileSettingsComponents/extensionsTab/domElements';
import { CHROME_STORE_URL } from '../../../profileSettingsComponents/extensionsTab/extensions-tab';
import { getFlag } from '../../../profileSettingsComponents/extensionsTab/getFlag';
import {
  IAllExtensions,
  IExtensionFromDb,
  IExtensionTypeWithId,
} from '../../../profileSettingsComponents/extensionsTab/interfaces/extension.interface';
import SearchRowComponent from '../../../profileSettingsComponents/extensionsTab/search-row';
import { getProfilesTableSelectedProfilesCommonExtensions } from '../../../../state/profiles-table-selected-ids.atom';

export const EXTENSION_NAME_MAX_LENGTH = 23;

declare interface IExtensionsModal {
  setUserExtensionsExtensionsTab?: Dispatch<SetStateAction<IExtensionFromDb[]>>|undefined;
  userExtensionsTab?: IExtensionFromDb[];
  updateProfileInfo?: (profileInfo: any) => void;
  extensionsModalVisible: boolean;
  setExtensionsModalVisible: (arg: boolean) => void;
  profileInfo?: any;
  profileIds?: string[];
}

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const ExtensionsModal: FC<IExtensionsModal> = (props) => {
  const {
    setUserExtensionsExtensionsTab, userExtensionsTab, updateProfileInfo, extensionsModalVisible, setExtensionsModalVisible,
    profileInfo, profileIds,
  } = props;

  const [extensions, setExtensions] = useState<IExtensionFromDb[]>([]);
  const [selectedExtensions, setSelectedExtensions] = useState<IExtensionFromDb[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [batchCount, setBatchCount] = useState<number>(0);
  const [removedExtensions, setRemovedExtensions] = useState<string[]>([]);
  const [extensionsCount, setExtensionsCount] = useState<number>(0);
  const [isCustomExtLoading, setIsCustomExtLoading] = useState<boolean>(false);
  const [submittedSearchString, setSubmittedSearchString] = useState<string>('');

  const { updateExtensionsMap } = useContext(extensionsContext);

  const { t: translation } = useTranslation();

  let userExtensions = selectedExtensions;
  let setUserExtensions = setSelectedExtensions;

  if (setUserExtensionsExtensionsTab && userExtensionsTab) {
    userExtensions = userExtensionsTab;
    setUserExtensions = setUserExtensionsExtensionsTab;
  }

  useEffect(() => {
    setIsLoading(true);

    if (extensionsModalVisible) {
      getExtensionsCount().then(count => {
        setExtensionsCount(count);
        getExtensionList();
      });
    }
  }, [extensionsModalVisible]);

  const getExtensionList = (): void => {
    const chromeExtensions = profileInfo?.chromeExtensions || [];
    const userChromeExtensions = profileInfo?.userChromeExtensions || [];
    const allExtensions = [...chromeExtensions, ...userChromeExtensions];

    const installedExtensionsIds: string[] =
      userExtensionsTab?.map((extension: IExtensionFromDb) => extension.extId) || [];

    requestExtensionList({ skip: batchCount }).then((extensionsFromRequest: IExtensionFromDb[]) => {
      const formattedExtensions: IExtensionFromDb[] = extensionsFromRequest
        .filter((extension: IExtensionFromDb) => allExtensions.includes(extension.extId));

      const formattedAllExtensions: IExtensionFromDb[] = extensionsFromRequest.map((extension: IExtensionFromDb) => {
        if (installedExtensionsIds.length && installedExtensionsIds.includes(extension.extId)) {

          return (userExtensionsTab as IExtensionFromDb[]).find((installedExtension: IExtensionFromDb) =>
            extension.extId === installedExtension.extId) as IExtensionFromDb;
        }

        return extension;
      });

      setExtensions([...extensions, ...formattedAllExtensions]);

      if (!setUserExtensionsExtensionsTab) {
        setUserExtensions([...userExtensions, ...formattedExtensions]);
      }

      if (profileIds) {
        const commonExtensions = getProfilesTableSelectedProfilesCommonExtensions();
        if (commonExtensions?.length) {
          const commonExtensionsExtracted: IExtensionFromDb[] =
            formattedAllExtensions.filter(extension => commonExtensions.includes(extension.extId));

          setUserExtensions([...userExtensions, ...commonExtensionsExtracted]);
        }
      }

      setBatchCount((prevState) => ++prevState);
      setIsLoading(false);
    });
  };

  const getMoreExtensions = (): void => {
    if (submittedSearchString) {
      return;
    }

    getExtensionList();
  };

  const addExtension = (event: React.MouseEvent, extension: IExtensionFromDb): void => {
    setErrorMessage('');
    event.preventDefault();
    const { extId, type } = extension;
    const isAlreadyAdded = userExtensions.find((userExt: IExtensionFromDb) => userExt.extId === extId);
    if (isAlreadyAdded) {
      return;
    }

    const newUserExtensions: IExtensionFromDb[] = [...userExtensions];
    newUserExtensions.push({
      ...extension,
    });

    if (isElectron && type === 'chrome') {
      ipcRenderer.invoke('download-chrome-extension', [extId]);
    }

    if (isElectron && type === 'user') {
      ipcRenderer.invoke('download-user-chrome-extension', [extId]);
    }

    const allExtensions: IAllExtensions = getAllExtensions(newUserExtensions);

    if (removedExtensions.includes(extId)) {
      removedExtensions.filter(removedExtension => removedExtension !== extId);
    }

    updateProfileInfo && updateProfileInfo({
      chromeExtensions: allExtensions.chromeExtensions,
      userChromeExtensions: allExtensions.userChromeExtensions,
    });

    setUserExtensionsExtensionsTab && setUserExtensionsExtensionsTab(newUserExtensions);

    setUserExtensions(newUserExtensions);
  };

  const getAllExtensions = (newUserExtensions: IExtensionTypeWithId[]): { chromeExtensions: string[]; userChromeExtensions: string[] } => (
    newUserExtensions.reduce((result: IAllExtensions, extension: IExtensionTypeWithId) => {
      if (extension.type === 'chrome') {
        result.chromeExtensions.push(extension.extId);

        return result;
      }

      result.userChromeExtensions.push(extension.extId);

      return result;
    }, { chromeExtensions: [], userChromeExtensions: [] })
  );

  const getExtensionUrl = (extName = '', extId: string): string => {
    const searchName = extName.toLowerCase().split(' ').join('-');

    return `${CHROME_STORE_URL}/${searchName}/${extId}`;
  };

  const handleLinkClick = (extName: string, extId: string): void => {
    const url: string = getExtensionUrl(extName, extId);

    if (isElectron) {
      window.require('electron').shell.openExternal(url);

      return;
    }

    window.open(url);
  };

  const checkExtStatus = (extId: string, status: 'installed' | 'addToNewProfiles'): boolean => !!userExtensions
    .filter((userExt: IExtensionFromDb) => {
      let success = true;
      if (status === 'addToNewProfiles') {
        success = userExt.addToNewProfiles;
      }

      return userExt.extId === extId && success;
    })
    .length;

  const closeExtensionsModal = (): void => {
    setExtensionsModalVisible(false);
    setErrorMessage('');
    setBatchCount(0);
    setExtensions([]);
    setSelectedExtensions([]);

    if (isLoading) {
      return;
    }

    if (!profileIds?.length) {
      return;
    }

    sendActionAnalytics('added extension via mass operations');
    const extensionsObject: IExtensionsObject = userExtensions
      .reduce((acc: IExtensionsObject, extension: IExtensionFromDb) => {
        switch (true) {
          case extension.type === 'chrome':
            acc.chromeExtensionIds.push(extension.extId);
            break;
          default:
            acc.customExtensionIds.push(extension.extId);
            break;
        }

        return acc;
      }, { chromeExtensionIds: [], customExtensionIds: [] });

    const options: ISetExtensionsOptions = {
      chromeExtensionIds: extensionsObject.chromeExtensionIds,
      customExtensionIds: extensionsObject.customExtensionIds,
      profileIds,
      extensionsToRemove: removedExtensions,
    };

    setUserExtensions([]);
    setExtensionsRequest(options).catch(() => null);
  };

  const getExtensionIcon = (extension: IExtensionFromDb): JSX.Element => {
    if (extension.iconBinary || extension.urlPath) {
      return (
        <ExtensionIcon iconBinary={extension.iconBinary} urlPath={extension.urlPath} />
      );
    }

    return (
      <Icon component={IconExtension} style={{ width: '20px', height: '20px', stroke: 'black' }} />
    );
  };

  const setToNewProfilesFlag = (extension: IExtensionFromDb): void  => {
    setErrorMessage('');
    const processedExtensions: IExtensionFromDb[] = extensions.map((extensionTmp: IExtensionFromDb) => {
      if (extensionTmp.extId !== extension.extId) {
        return extensionTmp;
      }

      if (!extensionTmp.addToNewProfiles) {
        addExtensionToSettings(extensionTmp.extId, extensionTmp.type);
        sendActionAnalytics('added extension to all new profiles');
      }

      if (extensionTmp.addToNewProfiles) {
        removeExtensionFromSettings(extensionTmp.extId, extensionTmp.type);
      }

      return {
        ...extension,
        addToNewProfiles: !extensionTmp.addToNewProfiles,
      };
    });

    const processedSelectedExtension: IExtensionFromDb[] = userExtensions.map((extensionTmp: IExtensionFromDb) => {
      if (extensionTmp.extId !== extension.extId) {
        return extensionTmp;
      }

      return {
        ...extension,
        addToNewProfiles: !extensionTmp.addToNewProfiles,
      };
    });

    setUserExtensionsExtensionsTab && setUserExtensionsExtensionsTab(processedSelectedExtension);
    updateExtensionsMap(processedExtensions);
    setExtensions(processedExtensions);
    setUserExtensions(processedSelectedExtension);
  };

  const getFlagContainer = (extension: IExtensionFromDb): JSX.Element => (
    <Tooltip
      title={
        extension.addToNewProfiles
          ? translation('manageExtensionsModal.stopAddingToNew')
          : translation('manageExtensionsModal.startAddingToNew')
      }
    >
      <FlagContainer
        onClick={(): void|null => isCustomExtLoading ? null : setToNewProfilesFlag(extension)}
      >
        {getFlag(extension.addToNewProfiles)}
      </FlagContainer>
    </Tooltip>
  );

  const getLoader = (): JSX.Element|null => {
    if (submittedSearchString) {
      return null;
    }

    return (
      <LoaderDiv>
        <Icon type='loading' />
      </LoaderDiv>
    );
  };

  const getExtensionName = (name: string): string|JSX.Element => {
    if (name.length < EXTENSION_NAME_MAX_LENGTH) {
      return name;
    }

    return (
      <Tooltip
        title={name}
      >
        {name.slice(0, EXTENSION_NAME_MAX_LENGTH)}
        ...
      </Tooltip>
    );
  };

  const getAllExtensionsList = (): JSX.Element => {
    if (isLoading) {
      return (
        <LoaderDiv>
          <Icon type='loading' />
        </LoaderDiv>
      );
    }

    return (
      <div>
        <InfiniteScroll
          dataLength={extensions.length}
          next={getMoreExtensions}
          hasMore={extensions.length < extensionsCount}
          loader={getLoader()}
          scrollableTarget={'extensionModal'}
          style={{ overflow: 'hidden' }}
        >
          <div>
            {extensions
              .map(extension => (
                <ExtensionContainerInstalled
                  key={extension.extId}
                >
                  <ExtensionNameContainer onClick={(): void => handleLinkClick(extension.name, extension.extId)}>
                    <IconDiv>
                      {getExtensionIcon(extension)}
                    </IconDiv>
                    <ExtensionName>
                      {getExtensionName(extension.name)}
                    </ExtensionName>
                  </ExtensionNameContainer>
                  <ActionsContainerInstalled>
                    {getFlagContainer(extension)}
                    <ActionButton
                      href='#'
                      size='small'
                      disabled={checkExtStatus(extension.extId, 'installed') || isCustomExtLoading}
                      onClick={(event: React.MouseEvent): void => {
                        // tslint:disable-next-line:no-unused-expression
                        !checkExtStatus(extension.extId, 'installed') && addExtension(event, extension);
                      }}
                      className={checkExtStatus(extension.extId, 'installed') ? 'installed' : ''}
                    >
                      {checkExtStatus(extension.extId, 'installed')
                        ? translation('manageExtensionsModal.buttons.btnInstalled')
                        : translation('manageExtensionsModal.buttons.btnInstall')}
                    </ActionButton>
                  </ActionsContainerInstalled>
                </ExtensionContainerInstalled>
              ))}
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <Modal
      width={785}
      title={translation('manageExtensionsModal.title')}
      visible={extensionsModalVisible}
      onCancel={closeExtensionsModal}
      footer={false}
      bodyStyle={{ paddingRight: 0 }}
    >
      <div style={{ display: 'flex', overflowX: 'hidden' }} >
        <ModalExtensionsList id={'extensionModal'}>
          <SearchRowComponent
            profileInfo={profileInfo}
            updateProfileInfo={updateProfileInfo}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            setUserExtensions={setUserExtensions}
            userExtensions={userExtensions}
            setExtensions={setExtensions}
            extensions={extensions}
            isLoadingModal={isLoading}
            extensionsModalVisible={extensionsModalVisible}
            isCustomExtLoading={isCustomExtLoading}
            setIsCustomExtLoading={setIsCustomExtLoading}
            submittedSearchString={submittedSearchString}
            setSubmittedSearchString={setSubmittedSearchString}
          />
          {getAllExtensionsList()}
        </ModalExtensionsList>
        <InstalledExtensions>
          <InstalledBlockTitle>
            {translation('manageExtensionsModal.installedExt')}
          </InstalledBlockTitle>
          <ExtensionsList
            isLoading={isLoading}
            extensions={userExtensions}
            setUserExtensions={setUserExtensions}
            setAllExtensions={setExtensions}
            setUserExtensionsExtensionsTab={setUserExtensionsExtensionsTab}
            allExtensions={extensions}
            updateProfileInfo={updateProfileInfo}
            setErrorMessage={setErrorMessage}
            removedExtensions={removedExtensions}
            setRemovedExtensions={setRemovedExtensions}
            isCustomExtLoading={isCustomExtLoading}
            updateExtensionsMap={updateExtensionsMap}
          />
        </InstalledExtensions>
      </div>
    </Modal>
  );
};

export default ExtensionsModal;
