import { DragEndEvent, DragStartEvent } from '@dnd-kit-contextless/core';
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getCurrentWorkspaceId } from './current-workspace-id.atom';
import { getBasicTableEntities, getBasicTableProfiles, getProfilesList, isGroupHeader, mapAndSetProfilesList } from './profiles-list.atom';
import { getProfilesTableSelectedIds, reselectCurrentProfileTableIds } from './profiles-table-selected-ids.atom';
import { getSelectedFolderId } from './selected-folder.atom';
import { onProfilesGroupChange } from '../features/drag-n-drop/on-profile-group-change';
import reorderBasicEntities from '../features/drag-n-drop/reorder-basic-entities';
import { manageProfilesInWorkspaceFolder } from '../features/folders/api';
import { reorderFolderProfiles, reorderManyFolderProfiles } from '../features/quickProfiles/api';
import { checkDragAndDropStatus } from '../features/quickProfiles/dragging-profiles-overlay/check-dnd-status';
import { IProfile } from '../interfaces';
import { showProfileDroppedInFolderMessage } from '../ui/gologin-header/profile-dropped-in-folder-message';
import cloneNodeElementWithStyles from '../utils/clone-node-element-with-styles';

interface IDraggingProfile {
  id: IProfile['id'];
  name: IProfile['name'];
  idx: number;
  isPrimary: boolean;
}

interface IDraggingProfilesState {
  draggingProfiles: IDraggingProfile[];
  overlayElement: HTMLElement | null;
}

interface IDraggingRowFields {
 primaryIdx: number | null;
 isDragging: boolean;
}

const DEFAULT_DRAGGING_PROFILES_STATE: IDraggingProfilesState = { draggingProfiles: [], overlayElement: null };

const draggingProfilesStateAtom = atom<IDraggingProfilesState>(DEFAULT_DRAGGING_PROFILES_STATE);

export const useDraggingProfilesState = (): IDraggingProfilesState => useAtomValue(draggingProfilesStateAtom);
const setDraggingProfilesState = (newState: IDraggingProfilesState): void => getDefaultStore().set(draggingProfilesStateAtom, newState);

export const useDraggingRowFields = (rowIdx: number): IDraggingRowFields => {
  const { draggingProfiles } = useDraggingProfilesState();

  return draggingProfiles.reduce<IDraggingRowFields>((acc, draggingProfile) => {
    if (draggingProfile.idx === rowIdx) {
      acc.isDragging = true;
    }

    if (draggingProfile.isPrimary) {
      acc.primaryIdx = draggingProfile.idx;
    }

    return acc;
  }, { primaryIdx: null, isDragging: false });
};

const getDraggingProfiles = (): IDraggingProfile[] => getDefaultStore().get(draggingProfilesStateAtom).draggingProfiles;

const resetDraggingProfilesState = (): void => setDraggingProfilesState({ ...DEFAULT_DRAGGING_PROFILES_STATE });

export const calculateDraggingProfiles = (draggingProfileIdx: number): IDraggingProfile[] => {
  const selectedIds = getProfilesTableSelectedIds();
  const basicTableProfiles = getBasicTableProfiles();
  const tableProfiles = basicTableProfiles.map(basicTableProfile => ({
    idx: basicTableProfile.idx,
    ...getDefaultStore().get(basicTableProfile.atom),
  }));

  const draggingProfile = tableProfiles.find(profile => profile.idx === draggingProfileIdx);
  if (!draggingProfile || isGroupHeader(draggingProfile)) {
    return [];
  }

  const isDraggingOneOfSelected = selectedIds.includes(draggingProfile.id);
  if (!isDraggingOneOfSelected) {
    return [{
      id: draggingProfile.id,
      name: draggingProfile.name,
      idx: draggingProfile.idx,
      isPrimary: true,
    }];
  }

  return selectedIds.reduce<IDraggingProfile[]>((acc, selectedProfileId) => {
    const profile = tableProfiles.find(tableProfile => tableProfile.id === selectedProfileId);
    if (profile && !isGroupHeader(profile)) {
      acc.push({
        id: profile.id,
        name: profile.name,
        idx: profile.idx,
        isPrimary: profile.idx === draggingProfileIdx,
      });
    }

    return acc;
  }, []);
};

export const handleGlobalDragStart = (event: DragStartEvent): void => {
  const activeProfileIdx = +(event.active.id.toString().replace(/^profile::/, ''));
  const draggingProfiles = calculateDraggingProfiles(activeProfileIdx);

  // TODO: uncomment to get draggable row after perf testing
  // const clonedElement = cloneProfileRowAsOverlayElement(activeProfileIdx);
  setDraggingProfilesState({ draggingProfiles, overlayElement: null });
};

const cloneProfileRowAsOverlayElement = (profileIdx: number): HTMLElement | null => {
  const draggedElement = document.querySelector(`[data-row-key='${profileIdx}']`);
  const clonedElement = cloneNodeElementWithStyles(draggedElement);
  if (!clonedElement) {
    return null;
  }

  clonedElement.style.background = 'var(--F9F9F9-profile-table)';
  clonedElement.style.opacity = '0.5';

  return clonedElement;
};

export const handleGlobalDragEnd = (event: DragEndEvent): void => {
  try {
    processGlobalDragEnd(event);
  } finally {
    resetDraggingProfilesState();
  }
};

const processGlobalDragEnd = (event: DragEndEvent): void => {
  const { active, over } = event;
  if (!over) {
    return;
  }

  const isDndAllowed = checkDragAndDropStatus();
  if (!isDndAllowed) {
    return;
  }

  const activeProfileIdx = +(active.id.toString().replace(/^profile::/, ''));

  const overId = over.id.toString();
  if (overId.startsWith('folder::')) {
    const folderName = overId.replace(/^folder::/, '');
    handleDropProfileInFolder(folderName);

    return;
  }

  if (overId.startsWith('profile::')) {
    const rowIdx = +overId.replace(/^profile::/, '');
    if (activeProfileIdx !== rowIdx) {
      handleDropProfileInList({ fromIndex: activeProfileIdx, toIndex: rowIdx });
    }
  }

  if (overId.startsWith('group::')) {
    const rowIdx = +overId.replace(/^group::/, '');
    if (activeProfileIdx !== rowIdx) {
      handleDropProfileInList({ fromIndex: activeProfileIdx, toIndex: rowIdx });
    }
  }
};

const handleDropProfileInFolder = async (folderName: string): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const draggingProfiles = getDraggingProfiles();
  const profileIds = draggingProfiles.map(profile => profile.id);

  await manageProfilesInWorkspaceFolder({
    workspaceId,
    name: folderName,
    profiles: profileIds,
    action: 'add',
  });

  showProfileDroppedInFolderMessage(profileIds.length);
};

interface IHandleProfileRowDragEnd {
  fromIndex: number;
  toIndex: number;
}

const handleDropProfileInList = (opts: IHandleProfileRowDragEnd): void => {
  const workspaceId = getCurrentWorkspaceId();
  const selectedFolderId = getSelectedFolderId();
  const allDraggedProfiles = getDraggingProfiles();
  const basicEntities = getBasicTableEntities();

  const { fromIndex, toIndex } = opts;
  console.log({ fromIndex, toIndex });
  mapAndSetProfilesList(() => {
    const profilesList = [...getProfilesList()];
    const { targetGroupId, movedItems, reorderedList } = reorderBasicEntities({
      basicEntities,
      fromIndex,
      toIndex,
      allDraggedProfiles,
    });

    if (movedItems.length === 1) {
      reorderFolderProfiles({
        workspaceId,
        folderId: selectedFolderId || '',
        profileId: movedItems[0].id,
        order: movedItems[0].order,
      });
    } else {
      const profileOrders = movedItems.map(({ id, order }) => ({ profileId: id, order }));
      reorderManyFolderProfiles({
        workspaceId,
        folderId: selectedFolderId || '',
        profiles: profileOrders,
      });
    }

    reselectCurrentProfileTableIds();
    const changedProfiles = onProfilesGroupChange(movedItems, targetGroupId);

    return profilesList.map((profile) => {
      const changedProfile = changedProfiles.find(({ id }) => id === profile.id) || {};
      const profileReorderedEntity = reorderedList.find(entity => entity.id === profile.id);
      if (profileReorderedEntity && !isGroupHeader(profileReorderedEntity)) {
        return { ...profile, ...changedProfile, order: profileReorderedEntity.order };
      }

      return profile;
    }).sort((prev, next) => +(next.isPinned ?? 0) - +(prev.isPinned ?? 0) || next.order - prev.order);
  });
};
