import { API_BASE_URL } from '../../../../common/constants/constants';
import { http } from '../../../services';

export const getProxy = async () => {
  const url = `${API_BASE_URL}/users-proxies/profiles-proxies`;

  try {
    const data = await http(url, {
      method: 'GET'
    });
    return data.body;
  } catch (error) {
    throw error;
  }
};

export const checkProxyRequest = async (options: any) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/check_proxy`, {
      method: 'POST',
      body: JSON.stringify(options)
    });

    return data.body;
  } catch (error) {
    throw error;
  }
};

export const getVpnUfoCountries = async (query: string): Promise<any> => {
  const data = await http(`${API_BASE_URL}/users-proxies/mobile-countries${query}`, {
    method: 'GET',
  });

  return data.body;
};

export const getVpnUfoProxy = async (options: { countryCode: string; browserId: string }): Promise<any> => {
  const data = await http(`${API_BASE_URL}/users-proxies/mobile-proxy`, {
    method: 'POST',
    body: JSON.stringify(options),
  });

  return data.body;
};

export const getProfileGeoProxyParams = async (query: string): Promise<any> => {
  const data = await http(`${API_BASE_URL}/users-proxies/get_profile_geo_proxy_params?${query}`, {
    method: 'GET',
  });

  return data.body;
};
