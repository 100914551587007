import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const showCheckbox = (): SerializedStyles => css`
  .MuiCheckbox-root {
    opacity: 1;
  }
`;

export const CheckboxWrapper = styled('div')<{ checked?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background-color: inherit;
  border: 1px solid var(--D9D9D9-antd);
  border-color: ${(props): string => props.checked ? 'var(--00A987-profile-table)' : 'var(--CDCDCD-profile-table)'};
`;

export const IndeterminateIcon = styled('span')`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background-color: var(--CDCDCD-profile-table);
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
`;

export const CheckedIcon = styled('span')`
  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 7px;
    border-right: 2px solid var(--00A987-profile-table);
    border-bottom: 2px solid var(--00A987-profile-table);
    transform: rotate(45deg);
    top: 2px;
    left: 5px;
  }
`;

export const gologinTableCheckboxStyles = css`
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--CDCDCD-profile-table);
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--00A987-profile-table);
  }

  .ant-checkbox-wrapper .ant-checkbox-wrapper-checked {
    :hover .ant-checkbox-inner {
      border-color: var(--CDCDCD-profile-table);
    }

    :focus .ant-checkbox-inner {
      border-color: var(--00A987-profile-table);
    }
  }

  .ant-checkbox-wrapper {
    :hover .ant-checkbox-inner {
      border-color: var(--CDCDCD-profile-table);
    }

    :focus .ant-checkbox-inner {
      border-color: var(--CDCDCD-profile-table);
    }
  }

  .ant-checkbox {
    opacity: 0;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
  }

  .ant-table-row:hover .ant-checkbox,
  .ant-table-selection-column:hover .ant-checkbox {
    opacity: 1;
  }

  .ant-checkbox-indeterminate {
    opacity: 1;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);

    .ant-checkbox-inner::after {
      background-color: var(--CDCDCD-profile-table);
    }
  }

  .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
  }

  .ant-checkbox-checked::after {
    border: 1px solid var(--00A987-profile-table);
  }

  .ant-checkbox-wrapper:focus .ant-checkbox-inner {
    border-color: var(--00A987-profile-table);
  }

  .ant-checkbox-checked {
    opacity: 1;
    background-color: inherit;
    border-color: var(--00A987-profile-table);

    .ant-checkbox-inner {
      border-color: var(--00A987-profile-table);
    }

    .ant-checkbox-inner::after {
      border: 2px solid var(--00A987-profile-table);
      border-top: 0;
      border-left: 0;
    }

    .ant-checkbox-wrapper {
      :hover .ant-checkbox-inner {
        border-color: var(--00A987-profile-table);
      }

      :focus .ant-checkbox-inner {
        border-color: var(--00A987-profile-table);
      }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: var(--00A987-profile-table);
    }
  }

  .ant-checkbox {
    padding: 0;
  }

  .ant-checkbox {
    .ant-table-thead > tr > th {
      border-bottom: 0 solid var(--E8E8E7-profile-table);
    }
  }
`;

export const CheckboxContainer = styled('div')<{ hasPadding?: boolean }>`
  ${(props): SerializedStyles | '' => props.hasPadding ? css`
    padding: 16px 6px 15px 12px;
  ` : ''}

  :hover {
    ${showCheckbox()}
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: transparent;
    border: 0;
    opacity: 1;
    background-color: inherit;
    border-color: var(--00A987-profile-table);
  }

  .MuiCheckbox-root {
    opacity: 0;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
    color: transparent;
    padding: 0;
  }

  .MuiCheckbox-indeterminate {
    opacity: 1;
    background-color: inherit;
    border-color: var(--CDCDCD-profile-table);
  }

  .MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
`;
