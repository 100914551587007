import { useTranslate } from '@tolgee/react';
import React, { FC, useLayoutEffect, useRef } from 'react';

import {
  SwitchContainer,
  SwitchDivBackground,
  SwitchElem,
  SwitchSubtext,
  SwitchText,
} from './styles';

interface ISwitch {
  onChangeHandler: (checked: boolean) => void;
  checked: boolean;
}

const Switch: FC<ISwitch> = (props): JSX.Element => {
  const { onChangeHandler, checked } = props;

  const DEFAULT_EL_VALUE = null;
  const firstSwitchElementRef = useRef<HTMLDivElement | null>(DEFAULT_EL_VALUE);
  const secondSwitchElementRef = useRef<HTMLDivElement | null>(DEFAULT_EL_VALUE);
  const backgroundDivRef = useRef<HTMLDivElement | null>(DEFAULT_EL_VALUE);

  const { t: translation } = useTranslate();

  const padding = 2;

  useLayoutEffect(() => {
    if (!(firstSwitchElementRef.current && secondSwitchElementRef.current && backgroundDivRef.current)) {
      return;
    }

    let width = secondSwitchElementRef.current.clientWidth;
    let left = firstSwitchElementRef.current.clientWidth + padding;
    if (checked) {
      width = firstSwitchElementRef.current.clientWidth;
      left = padding;
    }

    backgroundDivRef.current.style.width = `${width}px`;
    backgroundDivRef.current.style.left = `${left}px`;
  }, [checked]);

  return (
    <SwitchContainer onClick={(): void => onChangeHandler(!checked)} padding={padding}>
      <SwitchElem ref={firstSwitchElementRef}>
        <SwitchText isChecked={checked}>
          {translation('pricing.buttonPayMonthly.text')}
        </SwitchText>
      </SwitchElem>
      <SwitchElem ref={secondSwitchElementRef}>
        <SwitchText isChecked={!checked}>
          {translation('pricing.buttonPayAnnually.firstText')}
        </SwitchText>
        <SwitchSubtext isChecked={checked}>
          {translation('pricing.buttonPayAnnually.secondText')}
        </SwitchSubtext>
      </SwitchElem>
      <SwitchDivBackground ref={backgroundDivRef} isChecked={checked} />
    </SwitchContainer>
  );
};

export default Switch;
