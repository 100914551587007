export const LOAD_PROXY_TRANSACTION = 'load-proxies';

export const PROXY_LOAD_OPERATION = 'http-and-ui';

export const LOAD_PROXIES_PAGES_SPAN = 'load-proxies-pages-iteratively';
export const LOAD_PROXIES_SHARED_AND_FREE_SPAN = 'load-proxies-free-and-from-shared-profiles';

export const PAGES_LOADED_COUNT_TAG = 'pages-loaded-count';
export const PROXIES_LOADED_COUNT_TAG = 'proxies-loaded-count';
export const HAS_PAGES_NOT_LOADED_TAG = 'has-pages-not-loaded';
export const SHARED_PROXIES_LOADED_COUNT_TAG = 'shared-proxies-loaded-count';
export const FREE_PROXIES_LOADED_COUNT_TAG = 'free-proxies-loaded-count';
export const PROXY_PAGE_NOT_LOADED_TAG = 'proxy-page-not-loaded-index';
export const PROXY_MODE_TAG = 'proxy-mode';
export const PROFILES_OFFSET_TAG = 'profiles-offset';
export const PROXIES_NOT_FOUND_COUNT = 'proxies-not-found-count';

export const PROXY_PAGE_LOADING_ERROR = 'proxy-page-loading-error';
export const PROXY_LOADING_DELAYED_WARNING = 'profile-proxy-loading-delayed';

export const START_APP_TO_LOAD_PROFILES_TABLE = 'start-app-to-load-profiles';
export const START_APP_TO_LOAD_SIGN_UP_PAGE = 'start-app-to-load-sign-up';
