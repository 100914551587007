import { DragOverlay } from '@dnd-kit-contextless/core';
import React, { FC } from 'react';

import { DraggedProfilesContainer } from './styles';
import { useDraggingProfilesState } from '../../../state/dragging-profiles-state.atom';

const DraggingProfilesOverlay: FC = () => {
  const { draggingProfiles, overlayElement } = useDraggingProfilesState();

  const renderContent = (): JSX.Element => {
    if (overlayElement) {
      return (
        <div ref={(node): void => {
          if (node) {
            node.replaceWith(overlayElement);
          }
        }} />
      );
    }

    let textContent = draggingProfiles[0]?.name;
    const draggingProfilesCount = draggingProfiles.length;
    if (draggingProfilesCount > 1) {
      textContent = `${draggingProfilesCount} profiles`;
    }

    return (
      <DraggedProfilesContainer>
        {textContent}
      </DraggedProfilesContainer>
    );
  };

  return (
    <DragOverlay dropAnimation={null}>
      {renderContent()}
    </DragOverlay>
  );
};

export default DraggingProfilesOverlay;
