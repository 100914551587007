import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PROXY_FOLDER_SELECTED } from '../../../../common/constants/constants';
import { PROXY_FOLDERS_DEFAULT } from '../../../features/proxy/proxy-page/constants/proxy-folders';
import { IFolder } from '../../../interfaces';

const selectedProxyFolderAtom = atom<string>(PROXY_FOLDERS_DEFAULT[0].name);
const proxyFoldersAtom = atom<IFolder[]>(PROXY_FOLDERS_DEFAULT);

export const selectedProxyFolderPersistentAtom = atom(
  (get) => get(selectedProxyFolderAtom),
  (_get, set, newSelectedFolderName: string) => {
    set(selectedProxyFolderAtom, newSelectedFolderName);
    localStorage.setItem(PROXY_FOLDER_SELECTED, newSelectedFolderName);
  },
);

export const useSelectedProxyFolder = (): string => useAtomValue(selectedProxyFolderPersistentAtom);
export const getSelectedProxyFolder = (): string => getDefaultStore().get(selectedProxyFolderPersistentAtom);
export const setSelectedProxyFolder = (newSelectedFolderName: string): void =>
  getDefaultStore().set(selectedProxyFolderPersistentAtom, newSelectedFolderName);

export const getProxyFolders = (): IFolder[] => getDefaultStore().get(proxyFoldersAtom);
export const useProxyFolders = (): IFolder[] => useAtomValue(proxyFoldersAtom);
