import moment from 'moment';

import { isNotNull } from '../../../../common/typescript/predicates';
import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { getProxyStatusParams } from '../../../features/proxy/proxy-helpers';
import { setProxyStatuses } from '../../proxies.context/api';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { getProxyList, updateProxyItem } from '../proxy-list.atom';

export const checkProxies = async (proxies: IProxy[]): Promise<void> => {
  const proxyList = getProxyList();

  const proxiesChecked: (ISetProxyStatusParams | null)[] = await Promise.all(proxies.map(async proxy => {
    if (!proxy._id) {
      return null;
    }

    const currentProxyFromList = proxyList.find(proxyFromList => proxyFromList._id === proxy._id);
    if (!currentProxyFromList || currentProxyFromList.checkDate) {
      return null;
    }

    updateProxyItem({ _id: proxy._id, checkInProgress: true });
    const statusParams = await getProxyStatusParams({ ...proxy });

    updateProxyItem({
      _id: proxy._id,
      ...statusParams,
      checkDate: moment().toDate(),
      checkInProgress: false,
    });

    if (!statusParams) {
      return null;
    }

    return {
      _id: proxy._id,
      status: statusParams.status,
      country: statusParams.country || proxy.country,
      city: statusParams.city || proxy.city,
      error: statusParams.error,
      checkDate: moment().unix(),
      lastIp: statusParams.origin,
    };
  }));

  setProxyStatuses(proxiesChecked.filter(isNotNull)).catch(() => null);
};
