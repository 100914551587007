import React from 'react';

import { NameCellEditCore } from './name-cell-edit-core';
import { IProfile } from '../../../../interfaces';
import { randomWords } from '../../../common/randomizer';
import { updateProfileName } from '../../../drawer/api';

interface IProfileEdit {
  profileName: string;
  profile: IProfile;
  setProfiles: (set: (prevProfiles: IProfile[]) => IProfile[]) => void;
  setChangeProfileName: (profileId: string) => void;
}

const ProfileEdit: React.FC<IProfileEdit> = (props) => {
  const { profileName, profile, setProfiles, setChangeProfileName } = props;

  const getTrimmedOrRandomName = (name: string): string => name.trim().replace(/\s{2,}/g, ' ') || randomWords();

  const saveProfileName = async (inputValue: string): Promise<void> => {
    const name = getTrimmedOrRandomName(inputValue);
    updateProfileName({ id: profile.id, name });

    setProfiles(profiles => profiles.map((profileFromTheList) => {
      if (profileFromTheList?.id === profile?.id) {
        return {
          ...profileFromTheList,
          name,
          defaultProps: {
            ...profileFromTheList?.defaultProps,
            profileNameIsDefault: false,
          },
        };
      }

      return profileFromTheList;
    }));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, inputValue: string): void => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    saveProfileName(inputValue);
    setChangeProfileName('id');
  };

  const handleBlur = (inputValue: string): void => {
    saveProfileName(inputValue);
    setChangeProfileName('id');
  };

  return (
    <NameCellEditCore
      inputId='profileNameId'
      initialName={profileName}
      handleKeyDown={handleKeyDown}
      handleBlur={handleBlur}
    />
  );
};

export default ProfileEdit;
