import { message } from 'antd';
import React from 'react';
import { Trans } from 'react-i18next';

import { IMultipleProxiesAddError } from './create-proxies.operations';

export const multipleProxiesAddMessageBaseKey = 'proxyImportModal.notifications';

interface ITranslationOptions {
  i18nKey: string;
  values?: { count: number };
}

export const handleMultipleProxiesAddError = (errorObject: IMultipleProxiesAddError): void => {
  const translationOptions: ITranslationOptions = {
    i18nKey: `${multipleProxiesAddMessageBaseKey}.${errorObject.message}`,
  };

  if (errorObject.isCustomError) {
    translationOptions.i18nKey = errorObject.message;
  }

  if (errorObject.count) {
    translationOptions.values = { count: errorObject.count };
  }

  message.error(<Trans {...translationOptions} />);
};
