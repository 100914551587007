import { IProfile } from '../../interfaces';
import { IGroupHeader } from '../../interfaces/group-header.interface';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { getGroupHeaderById } from '../../state/profiles-list.atom';
import { getTagById } from '../../state/tags/tags-list.atom';
import { IDeleteProfilesTagReq, addTagRequest, deleteProfilesTag } from '../tags/api';
import { IAddTagMethod } from '../tags/interfaces/tag.interface';

export const onProfilesGroupChange = (profiles: IProfile[], newGroupHeaderId: string): IProfile[] => {
  const groupHeader = getGroupHeaderById(newGroupHeaderId);
  if (!groupHeader) {
    return profiles;
  }

  const { filter: groupFilter } = groupHeader;
  switch (groupFilter.type) {
    case 'custom-status':
      return onProfileCustomStatusChange(profiles, groupHeader);
    default:
      return profiles;
  }
};

const onProfileCustomStatusChange = (profiles: IProfile[], groupHeader: IGroupHeader): IProfile[] => {
  const newCustomStatusId = groupHeader.filter.customStatusId;
  const profilesToEdit = profiles.filter((profile) => {
    const currCustomStatus = profile.tags.find((tag) => tag.field === 'custom-status');

    return newCustomStatusId !== currCustomStatus?.id;
  });

  const profileIdsToEdit = profilesToEdit.map((profile) => profile.id);
  if (!profileIdsToEdit.length) {
    return profiles;
  }

  const workspaceId = getCurrentWorkspaceId();
  const newCustomStatus = getTagById(newCustomStatusId);
  if (newCustomStatus) {
    const requestOpts: IAddTagMethod = {
      browserIds: profileIdsToEdit,
      ...newCustomStatus,
      workspace: workspaceId,
    };

    addTagRequest(requestOpts);
  } else {
    const requestsToDo = profiles.reduce<IDeleteProfilesTagReq[]>((acc, profile) => {
      const currCustomStatus = profile.tags.find((tag) => tag.field === 'custom-status');
      if (!currCustomStatus) {
        return acc;
      }

      const reqByTag = acc.find((req) => req.tagId === currCustomStatus.id);
      if (reqByTag) {
        reqByTag.profileIds.push(profile.id);

        return acc;
      }

      acc.push({ workspace: workspaceId, tagId: currCustomStatus.id, profileIds: [profile.id] });

      return acc;
    }, [] as IDeleteProfilesTagReq[]);

    requestsToDo.forEach((req) => deleteProfilesTag(req));
  }

  return profiles.map((profile) => {
    if (!profileIdsToEdit.includes(profile.id)) {
      return profile;
    }

    const newTags = profile.tags.filter((tag) => tag.field !== 'custom-status');
    if (newCustomStatus) {
      newTags.push(newCustomStatus);
    }

    return { ...profile, tags: newTags };
  });
};
