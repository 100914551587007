import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IWorkspaceFolderRole, LimitedWorkspaceRole } from '../../../../interfaces/workspaces';
import { userContext, workspaceContext } from '../../../../state';
import { GreenSubmitButton } from '../../../../ui/modern-modal';
import { filterEmailsByUser } from '../../../../utils/filterEmailsByUser';
import { sendActionAnalytics } from '../../../common/api';
import PermissionsSelect from '../../../members/permissions-select';
import showAfterInviteMessage from './after-invite-message';
import { inviteMembers } from './api';
import { EmailInput } from './email-input';
import { InviteModalSubheader } from './modal-styles';
import { InviteModal } from './style';

interface IInviteMembers {
  visible: boolean;
  onClose: () => void;
  initWithFolderName?: string;
}

const InviteMembersModal: FC<IInviteMembers> = (props) => {
  const { visible, onClose, initWithFolderName } = props;

  const [limitedAccess, setLimitedAccess] = useState<boolean>(true);
  const [workspaceRole, setWorkspaceRole] = useState<LimitedWorkspaceRole>('guest');
  const [emails, setEmails] = useState<string[]>([]);
  const [folders, setFolders] = useState<IWorkspaceFolderRole[]>([]);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [isEmailInputFocused, setIsEmailInputFocused] = useState<boolean>(true);

  const user = useContext(userContext);
  const workspace = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const currentMemberLimited = workspace.me?.limitedAccess ?? true;
  const maxMembers = workspace.isPlanUnlimited ? Number.POSITIVE_INFINITY : workspace.planMembersMax;
  const maxEmails = maxMembers - workspace.members.length;

  useEffect(() => {
    if (!initWithFolderName) {
      return;
    }

    setLimitedAccess(true);
    const foundFolder = workspace.folders.find(folder => folder.name === initWithFolderName);
    if (!foundFolder) {
      return;
    }

    setFolders([{
      name: initWithFolderName,
      role: 'guest',
      permissions: {
        manageAccess: foundFolder.permissions.manageMember,
        manageAdminAccess: foundFolder.permissions.manageAdminMember,
      },
    }]);
  }, [initWithFolderName]);

  useEffect(() => {
    if (visible) {
      sendActionAnalytics('visited add member');
    }
  }, [visible]);

  const sendInvites = async (): Promise<void> => {
    if (!emails.length) {
      if (!emailErrorText) {
        setEmailErrorText(translation('modals.inviteUserWorkspace.enterOneEmail') || '');
      }

      return;
    }

    if (emails.length > maxEmails) {
      return;
    }

    const emailsToInvite = filterEmailsByUser(emails, user.email, 'You can\'t invite yourself');
    if (!emailsToInvite.length) {
      setEmails([]);

      return;
    }

    const updatedMembers = await inviteMembers({
      workspaceId: workspace.id,
      emails: emailsToInvite,
      limitedAccess,
      role: workspaceRole,
      folders,
      workspaceName: workspace.name,
    });

    const updatedMembersEmails = updatedMembers.map(member => member.email);
    const membersToSave = workspace.members
      .filter(member => !updatedMembersEmails.includes(member.email))
      .concat(updatedMembers);

    workspace.updateWorkspace({
      members: membersToSave,
    });

    setEmails([]);
    setFolders([]);

    onClose();
    showAfterInviteMessage(emailsToInvite);
  };

  const onPermissionsChange = (
    newLimitedAccess: boolean,
    newRole: LimitedWorkspaceRole,
    newFolders: IWorkspaceFolderRole[],
  ): void => {
    setLimitedAccess(newLimitedAccess);
    setWorkspaceRole(newRole);
    setFolders(newLimitedAccess ? newFolders : []);
  };

  const closeModal = (): void => {
    onClose();
    setEmails([]);
    setFolders([]);
    setEmailErrorText('');
    setIsEmailInputFocused(true);
  };

  if (!workspace.isLoaded) {
    return null;
  }

  // desired behavior to replace 0 members to 1
  let maxMembersText = '/' + (workspace.planMembersMax || 1).toString();
  if (workspace.isPlanUnlimited) {
    maxMembersText = '';
  }

  const isInviteButtonDisabled = limitedAccess && !folders.length;

  return (
    <InviteModal
      visible={visible}
      onCancel={closeModal}
      width={496}
      title={translation('modals.inviteUserWorkspace.title')}
      footer={null}
    >
      <>
        <InviteModalSubheader>
          <span>
            {workspace.members.length}
            {maxMembersText}
            {' '}
            {translation('modals.inviteUserWorkspace.usersJoined')}
          </span>
        </InviteModalSubheader>
        <div
          style={{ marginBottom: 20 }}
          onClick={(): void => setIsEmailInputFocused(true)}
          onBlur={(): void => setIsEmailInputFocused(false)}
        >
          <EmailInput
            emails={emails}
            onChange={setEmails}
            errorText={emailErrorText}
            onErrorTextChange={setEmailErrorText}
            maxEmails={maxEmails}
            inputFocused={isEmailInputFocused}
            placeholder={translation('modals.inviteUserWorkspace.userEmail')}
            type='invite'
          />
        </div>
        <PermissionsSelect
          limitedAccess={limitedAccess}
          role={workspaceRole}
          folders={folders}
          // this whole modal only should be shown when member is admin, so only `limited` is concern here
          isCurrentMemberGlobalAdmin={!currentMemberLimited}
          onChange={onPermissionsChange}
          isRadioExtraTextShown={true}
          blockWorkspaceWideAdminSelect={!workspace.permissions.globallyManageAdminMembers}
        />
        <GreenSubmitButton
          onClick={sendInvites}
          disabled={isInviteButtonDisabled}
          style={{ margin: '32px auto 0' }}
        >
          {translation('modals.inviteUserWorkspace.sendInvite')}
        </GreenSubmitButton>
      </>
    </InviteModal>
  );
};

export default InviteMembersModal;
