import React, { ChangeEvent, FC } from 'react';

import { InputRadioEl, RadioButtonEl, RadioContainerEl, RadioLabelEl } from '../styles/radio-buttons';

interface IOption {
  value: string | number;
  label: string;
}

interface IProps {
  options: IOption[];
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtons: FC<IProps> = (props) => {
  const {
    options,
    value,
    onChange,
  } = props;

  return (
    <RadioContainerEl>
      {options.map((option) => (
        <RadioLabelEl key={option.value}>
          <InputRadioEl checked={value === option.value} onChange={onChange} type='radio' value={option.value} />
          <RadioButtonEl>
            {option.label}
          </RadioButtonEl>
        </RadioLabelEl>
      ))}
    </RadioContainerEl>
  );
};

export default RadioButtons;
