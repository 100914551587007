import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import PersonalAreaMenu from './index';
import { MenuKey } from './menu-key';
import { NEW_FEATURES } from '../../../../../feature-toggle';
import Header from '../../../ui/Header';
import { BlockShadow, ContentContainer, MainContainer } from '../styles';

declare interface ITabContainer {
  tabKey: MenuKey;
  HelmetTitle?: string;
}

export const TabWrapper: FC<ITabContainer> = ({
  children,
  tabKey,
  HelmetTitle,
}) => (
  <MainContainer newStyle={!!NEW_FEATURES.header}>
    <Header />
    <ContentContainer showScroll={!NEW_FEATURES.header}>
      <div>
        <PersonalAreaMenu selectedKey={tabKey} />
      </div>
      <BlockShadow>
        {children}
      </BlockShadow>
    </ContentContainer>
    <Helmet>
      <title>
        {HelmetTitle || `Personal area - GoLogin ${window.gologinAppVersion || ''}`}
      </title>
    </Helmet>
  </MainContainer>
);
