import React, { FC, useRef, memo } from 'react';

import ProxyForm from './proxy-form';
import ProxyPlaceholder from './proxy-placeholder';
import ProxySelectorButtons from './proxy-selector-buttons';
import { ProxyAreaEl, ProxyHeightLimitterEl, ProxyWidthLimitterEl, ProxyWrapperEl, ProxyWrapperSectionEl } from './styles';
import { NEW_FEATURES } from '../../../../../feature-toggle';
import { useProxyList } from '../../../state/proxy/proxy-list.atom';
import {
  closeProxyManager,
  openProxyManager,
  useProxyManagerState,
} from '../../../state/proxy/proxy-manager-modal-status.atom';
import { IProfileProxy } from '../components/interfaces/IProfileProxy';
import { EMPTY_PROXY } from '../proxy-helpers';
import { getProxyForProxySelector } from '../utils/find-proxy-in-list';

interface IProxySelector {
  profileId: string;
  proxy?: IProfileProxy;
  isInDrawer: boolean;
  isSharedProxy: boolean;
  proxyEnabled: boolean;
  localProxySelectorLocation: string;
}

const ProxySelector: FC<IProxySelector> = (props) => {
  const {
    profileId, proxy: propProxy, isInDrawer,
    isSharedProxy, proxyEnabled, localProxySelectorLocation,
  } = props;

  const proxyList = useProxyList();
  const { currentProfileId: proxyManagerProfileId, proxySelectorLocation } = useProxyManagerState();

  const containerElement = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const proxy = proxyEnabled ? propProxy : EMPTY_PROXY;
  const editProxyMode = !!(profileId && profileId === proxyManagerProfileId && proxySelectorLocation === localProxySelectorLocation);
  const isNoProxy = !proxy || proxy.mode === 'none';
  const shouldProxyTakeAllWidth = isInDrawer && NEW_FEATURES.drawer;

  const proxyAreaClassName = editProxyMode ? 'area-active' : '';

  const renderSelectedProxy = (): JSX.Element => {
    const proxyToRender = getProxyForProxySelector(proxyList, proxy);
    if (!proxyToRender || (isNoProxy && !editProxyMode)) {
      return <ProxyPlaceholder isInDrawer={isInDrawer} />;
    }

    return (
      <ProxyForm
        currentProxy={proxyToRender}
        profileId={profileId}
        isSharedProxy={isSharedProxy}
        editProxyMode={editProxyMode}
        isInDrawer={isInDrawer}
        localProxySelectorLocation={localProxySelectorLocation}
        selectorContainerElement={containerElement}
      />
    );
  };

  const handleContainerClick = (): void => {
    if (!profileId) {
      return;
    }

    if (proxyManagerProfileId) {
      closeProxyManager();

      return;
    }

    if (!containerElement.current) {
      console.error('No containerElement.current');

      return;
    }

    openProxyManager({
      modalView: 'proxy-list',
      currentProxy: proxy,
      containerElement: containerElement.current,
      currentProfileId: profileId,
      proxySelectorLocation: localProxySelectorLocation,
    });
  };

  const renderBtns = (): JSX.Element|null => {
    const isInTableOrNewDrawer = NEW_FEATURES.drawer || !isInDrawer;

    return (
      <ProxySelectorButtons
        isVisible={editProxyMode || !isInTableOrNewDrawer}
        profileId={profileId}
        isInDrawer={isInDrawer}
        selectorContainerElement={containerElement}
        localProxySelectorLocation={localProxySelectorLocation}
      />
    );
  };

  return (
    <ProxyWrapperSectionEl
      isInDrawer={isInDrawer}
      newStyle={!!NEW_FEATURES.header}
      newDrawer={!!NEW_FEATURES.drawer}
      editProxyMode={editProxyMode}
    >
      <ProxyWidthLimitterEl
        editProxyMode={editProxyMode || isInDrawer}
        takeAllWidth={shouldProxyTakeAllWidth}
      >
        <ProxyWrapperEl
          newStyle={!!NEW_FEATURES.header}
          newDrawer={!!NEW_FEATURES.drawer}
          ref={wrapperRef}
          editProxyMode={editProxyMode || isInDrawer}
          isNoProxy={isNoProxy}
          className='proxy-wrapper'
          isInDrawer={isInDrawer}
        >
          <ProxyHeightLimitterEl
            newStyle={!!NEW_FEATURES.header}
            isNoProxy={isNoProxy}
            ref={containerElement}
            onClick={handleContainerClick}
            takeAllWidth={shouldProxyTakeAllWidth}
          >
            <ProxyAreaEl
              className={proxyAreaClassName}
              newStyle={!!NEW_FEATURES.header}
              takeAllWidth={shouldProxyTakeAllWidth}
            >
              {renderSelectedProxy()}
              {renderBtns()}
            </ProxyAreaEl>
          </ProxyHeightLimitterEl>
        </ProxyWrapperEl>
      </ProxyWidthLimitterEl>
    </ProxyWrapperSectionEl>
  );
};

export default memo(ProxySelector);
