import { Button, Icon, Table, Tooltip } from 'antd';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerNameCol, ImportButtonDiv, LoaderDiv, TableProfile } from './styles';
import { importWorkspaceProfiles, profilesImport } from './api';
import { IRenderFunction } from './interfaces/interfaces';
import { NEW_FEATURES } from '../../../../../../feature-toggle';
import { workspaceContext } from '../../../../state';

declare interface ICookiesList {
  profilesToImport: any[];
  toggleProfileImportModalVisible: (arg: boolean) => void;
  changeCurrentWindow: (arg: 'loader'|'resultList'|'confirmation') => void;
  headerForImport: string;
  setObjectOfImportedProfiles: (arg: any) => void;
  folderName?: string;
}

const TOOLTIP_LENGTH: number = 13;

const ProfilesImportConfirmation: FC<ICookiesList> = (props) => {
  const { folderName } = props;

  const [selectedProfiles, setSelectedProfiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: workspaceId } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const getColumns = () => {
    return ['name', 'proxy', 'os', '_id'].map(field => {
      const name = translation(`importProfileModal.confirmationForm.columns.${field}`);

      const render: IRenderFunction = (value: string) => value?.length > TOOLTIP_LENGTH ? <Tooltip title={value}>{value?.slice(0, TOOLTIP_LENGTH)}...</Tooltip> : value;

      return (
        <Table.Column
          title={(
            <ContainerNameCol>
              <span>{name}</span>
            </ContainerNameCol>
          )}
          dataIndex={field}
          key={field}
          sorter={(a: any, b: any) => a[field].localeCompare(b[field])}
          render={render}
        />
      );
    });
  };

  const importProfiles = async () => {
    setIsLoading(true);
    let profilesArray: any[] = props.profilesToImport;

    if (selectedProfiles.length) {
      profilesArray = selectedProfiles;
    }

    const stringWithProfiles = profilesArray.map(shortProfile => shortProfile.fullString).join('\n');
    const profileImportTxt: string = props.headerForImport + '\n' + stringWithProfiles;

    let response;
    if (NEW_FEATURES.workspaces) {
      response = await importWorkspaceProfiles({
        browserTxt: profileImportTxt,
        workspaceId,
        folderName,
      });
    } else {
      response = await profilesImport(profileImportTxt);
    }

    props.setObjectOfImportedProfiles(response);
    setIsLoading(false);
    props.changeCurrentWindow('resultList');
  }

  const rowSelection = {
    onChange: (selectedRowKeys: string[] | number[], selectedRows: any[]) => {
      setSelectedProfiles(selectedRows);
    },
    getCheckboxProps: () => ({ disabled: isLoading }),
  };

  const getLoader = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <LoaderDiv>
        <Icon type='loading' />
      </LoaderDiv>
    );
  };

  const getImportInscription = () => {
    if (selectedProfiles.length) {
      return `${translation('importProfileModal.confirmationForm.importInscription.selected')} (${selectedProfiles.length})`;
    }

    return translation('importProfileModal.confirmationForm.importInscription.all');
  };

  return (
    <>
      <TableProfile
        pagination={false}
        rowKey='name'
        rowSelection={rowSelection}
        dataSource={props.profilesToImport}
        scroll={{ y: 'max-content' }}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'auto' }}
      >
        {getColumns()}
      </TableProfile>
      <ImportButtonDiv>
        <Button
          type='primary'
          onClick={() => importProfiles()}
          style={{ marginLeft: 'auto' }}
          disabled={isLoading}
        >
          {getImportInscription()}
          {getLoader()}
        </Button>
      </ImportButtonDiv>
    </>
  );
};

export default ProfilesImportConfirmation;
