import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { deleteProxies } from '../../../state/proxy/proxy-operations/delete-proxies.operations';
import { updateSelectedProxies, useSelectedProxies } from '../../../state/proxy/selected-proxies.atom';
import { switchConfirmBlockVisible, useConfirmBlockVisible, useIsSelectProxyModeOpened } from '../../../state/proxy-select-menu.atom';
import { deleteProxiesRequest } from '../api';
import {
  BtnDelete,
  BtnCancel,
  RemovingConfirmContentEl,
  RemovingConfirmEl,
  RemovingConfirmTitleEl,
  RemovingConfirmButtonsEl,
} from '../styles/removing-confirm';

const RemovingConfirm: FC = () => {
  const { selectedProxy, selectedProxies } = useSelectedProxies();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const confirmBlockVisible = useConfirmBlockVisible();

  const { t: translation } = useTranslation();

  if (!confirmBlockVisible) {
    return null;
  }

  const handleDelete = (): void => {
    let proxiesToDelete: string[];

    if (selectedProxy) {
      proxiesToDelete = [selectedProxy._id];
    } else {
      proxiesToDelete = selectedProxies;
    }

    deleteProxiesRequest(proxiesToDelete);
    deleteProxies(proxiesToDelete);
    switchConfirmBlockVisible(false);
  };

  const handleCancel = (): void => {
    switchConfirmBlockVisible(false);

    if (!isSelectProxyModeOpened) {
      updateSelectedProxies({
        selectedProxies: [],
      });
    }
  };

  const translateKey = (selectedProxy || selectedProxies.length === 1) ? 'proxies.deleteProxyPermanently' : 'proxies.deleteProxiesPermanently';

  return (
    <RemovingConfirmEl>
      <RemovingConfirmContentEl>
        <RemovingConfirmTitleEl>
          {translation(translateKey, {
            count: (selectedProxy) ? 1 : selectedProxies.length,
          })}
        </RemovingConfirmTitleEl>
        <RemovingConfirmButtonsEl>
          <BtnDelete onClick={handleDelete}>
            {translation('proxies.delete')}
          </BtnDelete>
          <BtnCancel onClick={handleCancel}>
            {translation('proxies.cancel')}
          </BtnCancel>
        </RemovingConfirmButtonsEl>
      </RemovingConfirmContentEl>
    </RemovingConfirmEl>
  );
};

export default RemovingConfirm;
