import { atom, getDefaultStore, useAtomValue } from 'jotai';
import moment from 'moment';

import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { getAreProxiesEqual, getProxyStatusParams } from '../../../features/proxy/proxy-helpers';
import { setProxyStatuses as setProxyStatusesRequest, setSharedProxyStatuses } from '../../proxies.context/api';
import { ISetProxyStatusParams } from '../../proxies.context/interfaces/ISetProxyStatusParams';
import { IUpdateProxyItem } from '../../proxies.context/interfaces/update-proxy-item.interface';
import { updateProxyItem } from '../proxy-list.atom';

const proxyStatusesAtom = atom<IProxy[]>([]);
const proxyStatusesProfilesIdsAtom = atom<string[]>([]);

const getProxyStatuses = (): IProxy[] => getDefaultStore().get(proxyStatusesAtom);
const setProxyStatuses = (proxies: IProxy[]): void => getDefaultStore().set(proxyStatusesAtom, proxies);
const getProxyStatusesProfilesIds = (): string[] => getDefaultStore().get(proxyStatusesProfilesIdsAtom);
const setProxyStatusesProfilesIds = (profilesIds: string[]): void => getDefaultStore().set(proxyStatusesProfilesIdsAtom, profilesIds);

const useProxyStatuses = (): IProxy[] => useAtomValue(proxyStatusesAtom);
const useProxyStatusesProfilesIds = (): string[] => useAtomValue(proxyStatusesProfilesIdsAtom);

export const useIsProxyChecking = (proxy: IProxy, profileId = ''): boolean => {
  const proxiesStatuses = useProxyStatuses();
  const proxyStatusesProfilesIds = useProxyStatusesProfilesIds();

  if (!proxy) {
    return false;
  }

  const isProxyChecking = proxiesStatuses.some(proxyChecking => getAreProxiesEqual(proxy, proxyChecking));
  const isProfileCurrentOrNoProfile = profileId && proxyStatusesProfilesIds.length ? proxyStatusesProfilesIds.includes(profileId) : true;

  return isProxyChecking && isProfileCurrentOrNoProfile;
};

export const addProxyStatuses = (proxies: IProxy[], profileIds: string[] = []): void => {
  const proxyStatuses = getProxyStatuses();
  const proxyStatusesProfileIds = getProxyStatusesProfilesIds();

  const newProxyStatuses = [...proxyStatuses, ...proxies];

  setProxyStatuses(newProxyStatuses);
  const profileIdsTruthy = profileIds.filter(Boolean);
  if (profileIdsTruthy.length) {
    const newProxyStatusesProfileIds = [...proxyStatusesProfileIds, ...profileIdsTruthy];
    setProxyStatusesProfilesIds(newProxyStatusesProfileIds);
  }
};

export const removeProxyStatuses = (proxies: IProxy[], profileIds: string[] = []): void => {
  const proxyStatuses = getProxyStatuses();
  const proxyStatusesProfileIds = getProxyStatusesProfilesIds();

  const newProxyStatuses = proxyStatuses
    .filter(proxyStatus => !proxies.some(proxy => getAreProxiesEqual(proxyStatus, proxy)));

  const newProxyStatusesProfileIds = proxyStatusesProfileIds
    .filter(proxyStatusProfileId => !profileIds.includes(proxyStatusProfileId));

  setProxyStatuses(newProxyStatuses);
  setProxyStatusesProfilesIds(newProxyStatusesProfileIds);
};

export const resetProxyStatuses = (): void => {
  setProxyStatuses([]);
  setProxyStatusesProfilesIds([]);
};

export const updateProxyStatuses = async (proxies: IProxy[], profileId = '', isSharedProxy = false): Promise<ISetProxyStatusParams|void> => {
  addProxyStatuses(proxies, [profileId]);

  const [proxyToCheck] = proxies;

  const { mode, host, port, username, password } = proxyToCheck;
  proxyToCheck._id = proxyToCheck._id || proxyToCheck.id;

  // TODO: reuse cloned parts (also, used before)
  const statusParams = await getProxyStatusParams({
    ...proxyToCheck,
    mode,
    host,
    port,
    username,
    password,
  }).catch(() => null);

  if (!statusParams) {
    return removeProxyStatuses(proxies, [profileId].filter(Boolean));
  }

  const proxyChecked: IUpdateProxyItem = {
    ...proxyToCheck,
    ...statusParams,
    checkDate: moment().toDate(),
    isInvisible: isSharedProxy,
  };

  updateProxyItem(proxyChecked);

  const checkParams: ISetProxyStatusParams = {
    _id: '',
    status: statusParams.status,
    country: statusParams.country || proxyToCheck?.country,
    city: statusParams.city || proxyToCheck?.city,
    error: statusParams.error,
    checkDate: moment().unix(),
    lastIp: statusParams.origin,
    timezone: statusParams.timezone,
    languages: statusParams.languages,
  };

  if (proxyToCheck._id) {
    checkParams._id = proxyToCheck._id;
    if (isSharedProxy) {
      setSharedProxyStatuses([{ ...checkParams, profileId }]).catch(() => null);
    } else {
      setProxyStatusesRequest([checkParams]).catch(() => null);
    }
  }

  removeProxyStatuses(proxies, [profileId].filter(Boolean));

  return checkParams;
};
