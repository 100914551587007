import { Icon, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExtensionIcon } from './extensions-icon';
import { EXTENSION_NAME_MAX_LENGTH } from './extensions-modal';
import IconExtension from '../../../../ui/icons/IconExtension';
import { sendActionAnalytics } from '../../../common/api';
import {
  addExtensionToSettings,
  removeExtensionFromSettings,
} from '../../../profileSettingsComponents/extensionsTab/api';
import {
  ActionButton,
  ActionsContainer,
  ExtensionContainerAll,
  ExtensionName,
  ExtensionNameContainer,
  FlagContainer,
  IconDiv,
} from '../../../profileSettingsComponents/extensionsTab/domElements';
import { CHROME_STORE_URL } from '../../../profileSettingsComponents/extensionsTab/extensions-tab';
import { getFlag } from '../../../profileSettingsComponents/extensionsTab/getFlag';
import {
  IAllExtensions,
  IExtensionFromDb,
  IExtensionTypeWithId,
} from '../../../profileSettingsComponents/extensionsTab/interfaces/extension.interface';

declare interface IExtensionsList {
  isLoading: boolean;
  extensions: IExtensionFromDb[];
  setUserExtensions: (extensions: IExtensionFromDb[]) => void;
  setUserExtensionsExtensionsTab?: (extensions: IExtensionFromDb[]) => void|undefined;
  updateProfileInfo?: (profileInfo: any) => void|undefined;
  setAllExtensions?: ((extensions: IExtensionFromDb[]) => void)|undefined;
  allExtensions?: IExtensionFromDb[]|undefined;
  setErrorMessage?: (arg: string) => void;
  removedExtensions?: string[]|undefined;
  setRemovedExtensions?: (arg: string[]) => void|undefined;
  isCustomExtLoading?: boolean;
  updateExtensionsMap: (extensions: IExtensionFromDb[]) => void;
}

const isElectron = !!window.require;

export const ExtensionsList: FC<IExtensionsList> = props => {
  const {
    isLoading, extensions, setUserExtensions, setUserExtensionsExtensionsTab, updateProfileInfo, setAllExtensions,
    allExtensions, setErrorMessage, removedExtensions, setRemovedExtensions, isCustomExtLoading, updateExtensionsMap,
  } = props;

  const { t: translation } = useTranslation();

  const handleLinkClick = (extName: string, extId: string): void => {
    const url: string = getExtensionUrl(extName, extId);

    if (isElectron) {
      window.require('electron').shell.openExternal(url);

      return;
    }

    window.open(url);
  };

  const getExtensionUrl = (extName: string, extId: string): string => {
    const searchName = extName.toLowerCase().split(' ').join('-');

    return `${CHROME_STORE_URL}/${searchName}/${extId}`;
  };

  const getExtensionIcon = (extension: IExtensionFromDb): JSX.Element => {
    if (extension.iconBinary || extension.urlPath) {
      return (
        <ExtensionIcon iconBinary={extension.iconBinary} urlPath={extension.urlPath} />
      );
    }

    return (
      <Icon component={IconExtension} style={{ width: '20px', height: '20px', stroke: 'black' }} />
    );
  };

  const setToNewProfilesFlag = (extension: IExtensionFromDb): void  => {
    setErrorMessage && setErrorMessage('');
    const processedExtensions: IExtensionFromDb[] = extensions.map((extensionTmp: IExtensionFromDb) => {
      if (extensionTmp.extId !== extension.extId) {
        return extensionTmp;
      }

      if (!extensionTmp.addToNewProfiles) {
        addExtensionToSettings(extensionTmp.extId, extensionTmp.type);
        sendActionAnalytics('added extension to all new profiles');
      }

      if (extensionTmp.addToNewProfiles) {
        removeExtensionFromSettings(extensionTmp.extId, extensionTmp.type);
      }

      return {
        ...extension,
        addToNewProfiles: !extensionTmp.addToNewProfiles,
      };
    });

    const processedAllExtensions: IExtensionFromDb[]|undefined =
      allExtensions && allExtensions.map((extensionTmp: IExtensionFromDb) => {
        if (extensionTmp.extId !== extension.extId) {
          return extensionTmp;
        }

        return {
          ...extension,
          addToNewProfiles: !extensionTmp.addToNewProfiles,
        };
      });

    if (processedAllExtensions) {
      setAllExtensions && setAllExtensions(processedAllExtensions);
    }

    updateExtensionsMap(processedExtensions);
    setUserExtensions(processedExtensions);
    setUserExtensionsExtensionsTab && setUserExtensionsExtensionsTab(processedExtensions);
  };

  const getFlagContainer = (extension: IExtensionFromDb): JSX.Element => (
    <Tooltip
      title={
        extension.addToNewProfiles
          ? translation('manageExtensionsModal.stopAddingToNew')
          : translation('manageExtensionsModal.startAddingToNew')
      }
    >
      <FlagContainer
        onClick={(): void|null => isCustomExtLoading ? null : setToNewProfilesFlag(extension)}
      >
        {getFlag(extension.addToNewProfiles)}
      </FlagContainer>
    </Tooltip>
  );

  const removeExtension = (event: React.MouseEvent, extension: IExtensionFromDb): void => {
    setErrorMessage && setErrorMessage('');
    event.preventDefault();
    const { extId } = extension;
    const newUserExtensions = extensions.filter((userExt: IExtensionFromDb) => userExt.extId !== extId);

    const allExtensionsTmp: IAllExtensions = getAllExtensions(newUserExtensions);

    updateProfileInfo && updateProfileInfo({
      chromeExtensions: allExtensionsTmp.chromeExtensions,
      userChromeExtensions: allExtensionsTmp.userChromeExtensions,
    });
    setUserExtensions(newUserExtensions);
    setRemovedExtensions?.([...removedExtensions as string[], extId]);
  };

  const getAllExtensions = (newUserExtensions: IExtensionTypeWithId[]): { chromeExtensions: string[]; userChromeExtensions: string[] } => (
    newUserExtensions.reduce((result: IAllExtensions, extension: IExtensionTypeWithId) => {
      if (extension.type === 'chrome') {
        result.chromeExtensions.push(extension.extId);

        return result;
      }

      result.userChromeExtensions.push(extension.extId);

      return result;
    }, { chromeExtensions: [], userChromeExtensions: [] }));

  if (isLoading) {
    return null;
  }

  const getExtensionName = (name: string): string|JSX.Element => {
    if (name.length < EXTENSION_NAME_MAX_LENGTH) {
      return name;
    }

    return (
      <Tooltip
        title={name}
      >
        {name.slice(0, EXTENSION_NAME_MAX_LENGTH)}
        ...
      </Tooltip>
    );
  };

  return (
    <>
      {extensions
        .map((extension: IExtensionFromDb) => (
          <ExtensionContainerAll
            key={extension.extId}
          >
            <ExtensionNameContainer onClick={(): void => handleLinkClick(extension.name, extension.extId)}>
              <IconDiv>
                {getExtensionIcon(extension)}
              </IconDiv>
              <ExtensionName>
                {getExtensionName(extension.name)}
              </ExtensionName>
            </ExtensionNameContainer>
            <ActionsContainer>
              {getFlagContainer(extension)}
              <ActionButton
                size='small'
                disabled={isCustomExtLoading}
                onClick={(event: React.MouseEvent): void => removeExtension(event, extension)}
              >
                {translation('manageExtensionsModal.buttons.btnRemove')}
              </ActionButton>
            </ActionsContainer>
          </ExtensionContainerAll>
        ))}
    </>
  );
};

export default ExtensionsList;
