import React, { FC, createContext, Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { useSelectedTagId } from './tags/selected-tag.atom';
import { getTagsList, setTagsList, useTagsList } from './tags/tags-list.atom';
import { IPopularTag, ITag, TagField } from '../features/tags/interfaces/tag.interface';

export interface ITagsCtx {
  tags: ITag[];
  setTags: Dispatch<SetStateAction<ITag[]>>;
  selectedTag: ITag | null;
  newAddedTag: ITag | null;
  setNewAddedTag: Dispatch<SetStateAction<ITag|null>>;
  mostPopularTags: IPopularTag[];
  setMostPopularTags: (tags: IPopularTag[]) => void;
  tagEditorVisible: boolean;
  setTagEditorVisible: (tagEditorVisible: boolean) => void;
  profileInEditTagsMode: string|null;
  setProfileInEditTagsMode: (profileInEditTagsMode: string|null) => void;
  currentTagField: TagField;
  setCurrentTagField: (tagField: TagField) => void;
}

export const tagsContext = createContext<ITagsCtx>({} as ITagsCtx);

const TagsProviderRaw: FC<{ children: React.ReactNode }> = ({ children }) => {
  const tags = useTagsList();
  const selectedTagId = useSelectedTagId();

  const [mostPopularTags, setMostPopularTags] = React.useState<IPopularTag[]>([]);
  const [newAddedTag, setNewAddedTag] = React.useState<ITag|null>(null);
  const [tagEditorVisible, setTagEditorVisible] = React.useState<boolean>(false);
  const [profileInEditTagsMode, setProfileInEditTagsMode] = React.useState<string|null>(null);
  const [currentTagField, setCurrentTagField] = React.useState<TagField>('tags');

  const selectedTag = useMemo(() => tags.find(tag => tag.id === selectedTagId) || null, [tags, selectedTagId]);

  const setOrUpdateTags: Dispatch<SetStateAction<ITag[]>> = useCallback((opt) => {
    if (typeof opt === 'function') {
      setTagsList(opt(getTagsList()));
    } else {
      setTagsList(opt);
    }
  }, [setTagsList, getTagsList]);

  const value: ITagsCtx = {
    tags,
    setTags: setOrUpdateTags,
    selectedTag,
    mostPopularTags,
    setMostPopularTags,
    newAddedTag,
    setNewAddedTag,
    tagEditorVisible,
    setTagEditorVisible,
    profileInEditTagsMode,
    setProfileInEditTagsMode,
    currentTagField,
    setCurrentTagField,
  };

  return (
    <tagsContext.Provider value={value as ITagsCtx}>
      {children}
    </tagsContext.Provider>
  );
};

export const TagsProvider = React.memo(TagsProviderRaw);
