import styled from '@emotion/styled/macro';

export const HeaderContainer = styled('div')`
  padding: 20px 24px 20px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--F9F9F9-profile-new-settings-header);
  z-index: 101;
  position: sticky;
  top: 0;
`;

export const HeaderButtonsContainer = styled('div')`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 12px;
  }
`;
