import styled from '@emotion/styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { tagsContext } from '../../../state';
import { ITag, ITagBase } from '../interfaces/tag.interface';
import TagsColors from './tag-colors';
import TagListItem from './tag-list-item';
import { setSelectedTagId } from '../../../state/tags/selected-tag.atom';

const TagInfoContainer = styled('div')`
  border-radius: 4px;
  position: absolute;
  z-index: 145;
  bottom: 8px;
  width: 250px;
  height: 32px;
  justify-content: center;
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  margin-left: -125px;
`;

const TagInfoBlock = styled('div')`
  background-color: var(--FFFFFF-tags-info);
  box-shadow: var(--box-shadow-tags-info);
  border-radius: 4px;
  position: absolute;
  padding: 8px 12px 7px;
`;

const TagInfo = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--767676-tags-info);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProps {
  tag: ITag;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeTag: () => Promise<void>;
  isAddMode: boolean;
  isLastTag: boolean;
  profilesList?: any[];
}

const Tag: FC<IProps> = (props) => {
  const {
    tag,
    updateTag,
    removeTag,
    isAddMode,
    profilesList,
  } = props;

  const { title, profilesWithTag } = tag;
  const { newAddedTag, setNewAddedTag, setProfileInEditTagsMode } = React.useContext(tagsContext);
  const { t: translation } = useTranslation();
  const [anchorColorEl, setAnchorColorEl] = React.useState<null | HTMLElement>(null);
  const [anchorInfoEl, setAnchorInfoEl] = React.useState<null | HTMLElement>(null);
  const [tagUsage, setTagUsage] = React.useState<null | number>(profilesWithTag || null);

  let colorOpen = Boolean(anchorColorEl);
  const infoOpen = Boolean(anchorInfoEl);
  const tagBlock = React.useRef(null);

  const handleClick = (): void => {
    setSelectedTagId(tag?.id);
    setProfileInEditTagsMode(null);
  };

  React.useEffect(() => {
    if (newAddedTag && newAddedTag.id === tag.id) {
      setAnchorColorEl(tagBlock.current);
      colorOpen = true;
    } else {
      setAnchorColorEl(null);
    }
  }, [tag, newAddedTag]);

  const handleClickAway = (): void => {
    setAnchorColorEl(null);
    setNewAddedTag(null);
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorInfoEl(e.currentTarget);
  };

  const handleMouseLeave = (): void => {
    setAnchorInfoEl(null);
  };

  let tagInfoText = (tagUsage === 1) ? translation('tags.viewProfile', {
    tagTitle: title,
  }) : translation('tags.viewAllProfiles', {
    tagTitle: title,
  });

  const isBulk = !!profilesList;
  if (isBulk) {
    tagInfoText = (profilesWithTag === (profilesList || []).length)
      ? translation('tags.allProfilesSelected', {
        profilesCount: (profilesList || []).length,
      })
      : translation('tags.allOfProfilesSelected', {
        profilesWithTag,
        profilesCount: (profilesList || []).length,
      });
  }

  return (
    <>
      <TagListItem
        tag={tag}
        handleClick={handleClick}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isAddMode={isAddMode}
        removeTag={removeTag}
        innerRef={tagBlock}
      />
      <Popper
        open={colorOpen ? isAddMode : false}
        anchorEl={anchorColorEl}
        style={{ zIndex: 1060 }}
        placement='bottom-start'
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <TagsColors
              allowRemove={false}
              tag={tag}
              updateTag={updateTag}
              close={handleClickAway}
            />
          </div>
        </ClickAwayListener>
      </Popper>

      <Popper
        open={infoOpen}
        anchorEl={anchorInfoEl}
        style={{ zIndex: 11000 }}
        placement='top'
      >
        <TagInfoContainer>
          <TagInfoBlock>
            <TagInfo>
              {tagInfoText}
            </TagInfo>
          </TagInfoBlock>
        </TagInfoContainer>
      </Popper>
    </>
  );
};

export default Tag;
