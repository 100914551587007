import * as Sentry from '@sentry/react';

import { UserActionKey } from './performance-observer/interfaces';
import {
  LOAD_PROXY_TRANSACTION,
  LOAD_PROXIES_PAGES_SPAN,
  LOAD_PROXIES_SHARED_AND_FREE_SPAN,
  START_APP_TO_LOAD_SIGN_UP_PAGE,
  START_APP_TO_LOAD_PROFILES_TABLE,
} from '../features/proxy/constants';
import { MODAL_USER_ACTIONS } from '../state/profiles-table-modal.atom';

const SENTRY_DSN = 'https://a32517a4990385f6cfc89c7827828aa7@sentry.amzn.pro/2';
const SENTRY_IS_DEVELOPMENT = false;

const USER_ACTIONS_TRANSACTIONS: UserActionKey[] = [
  'open-profile-settings',
  'open-tags-popup',
  'change-selected-profiles',
  'open-folders-modal',
  ...MODAL_USER_ACTIONS,
  'open-proxy-manager',
  'open-account-menu',
  'start-name-column-edit',
  'start-notes-column-edit',
  'open-profile-settings-timezone-select',
  'select-profile-settings-timezone',
  'open-profile-settings-resolution-select',
  'select-profile-settings-resolution',
  'select-proxy-manager-proxy',
];

const PROXY_TRANSACTIONS = [LOAD_PROXY_TRANSACTION, LOAD_PROXIES_PAGES_SPAN, LOAD_PROXIES_SHARED_AND_FREE_SPAN];
const APP_START_TRANSACTIONS = [START_APP_TO_LOAD_PROFILES_TABLE, START_APP_TO_LOAD_SIGN_UP_PAGE];

const getTransactionSampleRateByName = (transactionName: string): number => {
  if (['get-user', 'get-workspace-profile-list', 'load-pricing-page'].includes(transactionName)) {
    return 1.0;
  }

  if (['load-profiles-table', 'create-quick-profile', ...PROXY_TRANSACTIONS, ...APP_START_TRANSACTIONS].includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.1;
  }

  if ((USER_ACTIONS_TRANSACTIONS as string[]).includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.1;
  }

  if (['run-profile', 'prepare-mass-run', 'run-profile-full'].includes(transactionName)) {
    return SENTRY_IS_DEVELOPMENT ? 1 : 0.01;
  }

  return 0;
};

const initSentry = (rawAppVersion?: string): void => {
  const appVersion = rawAppVersion ? rawAppVersion.split(' ')[0] : 'site';
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
    ],
    tracesSampler: samplingContext => {
      const { name: transactionName } = samplingContext.transactionContext;

      return getTransactionSampleRateByName(transactionName);
    },
    environment: SENTRY_IS_DEVELOPMENT ? 'development' : 'production',
    release: appVersion,
    replaysSessionSampleRate: 1.0,
  });
};

export default initSentry;
