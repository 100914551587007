import * as Sentry from '@sentry/react';
import { Icon, Input, message } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModernModal } from '../../../../ui/modern-modal';
import { ReactError } from '../../../../utils/sentry-parameters/custom-errors';
import { LoaderDiv } from '../../../profileSettingsComponents/extensionsTab/domElements';
import {
  checkDolphinTarif,
  getProfilesDataFromDolphinApi,
  saveMigrationCookies,
  startMigrationProfilesFromDolphin,
} from './api';
import {
  ButtonsContainer,
  CancelButton,
  HelpButton,
  LeftButtonsGroup,
  RightButtonsGroup,
  SaveButton,
  PreviewText,
} from './styles';
import { getAllProfileIdsFromDolphin } from './utils';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

interface IImportDolphinProfilesModal {
  visible: boolean;
  onClose: () => void;
}

const ImportDolphinProfilesModal: React.FC<IImportDolphinProfilesModal> = (props) => {
  const { visible, onClose } = props;

  const [isPreparingToImport, setIsPreparingToImport] = useState<boolean>(false);
  const [isMigration, setIsMigration] = useState<boolean>(false);
  const [dolphinApiKey, setDolphinApiKey] = useState<string>('');

  const { t: translation } = useTranslation();

  const closeModal = (): void => {
    setDolphinApiKey('');
    setIsPreparingToImport(false);
    onClose();
  };

  const initImportProcess = async (): Promise<void> => {
    setIsMigration(true);
    const response = await getProfilesDataFromDolphinApi(dolphinApiKey).catch(() => ({}));
    const { status = 0 } = response;
    if (status === 401) {
      message.warn(translation('importDolphinProfilesModal.message.apiKeyNotValid'));
      setIsMigration(false);

      return;
    }

    if (status.toString().startsWith('2')) {
      const responseData = await response.json();
      const { total = 0, data } = responseData;
      if (!total) {
        message.warn(translation('importDolphinProfilesModal.message.profilesNotFound'));
        setIsMigration(false);

        return;
      }

      const profileId = data[0].id;
      const checkResponse = await checkDolphinTarif(dolphinApiKey, profileId).catch(() => ({}));
      const { error } = await checkResponse.json();
      if (!error) {
        startMigrationProfilesFromDolphin({ dolphinApiKey });
        message.warn(translation('importDolphinProfilesModal.message.importingProfiles'));
        setIsMigration(false);
        closeModal();

        return;
      }

      const profileIds = await getAllProfileIdsFromDolphin(dolphinApiKey).catch(() => []);
      const cookiesData = await ipcRenderer.invoke('get-dolphin-cookies', profileIds.map((id) => `${id}`));

      const batchSize = 20;
      for (let index = 0; index < cookiesData.length; index += batchSize) {
        const batch = cookiesData.slice(index, index + batchSize);
        await saveMigrationCookies({ cookiesData: batch });
      }

      startMigrationProfilesFromDolphin({ dolphinApiKey });
      message.warn(translation('importDolphinProfilesModal.message.importingProfiles'));
      setIsMigration(false);
      closeModal();

      return;
    }

    message.warn(translation('importDolphinProfilesModal.message.requestError'));
    setIsMigration(false);
    closeModal();

    Sentry.captureException(new ReactError(JSON.stringify(response)), (scope) => {
      scope.setLevel('error');
      scope.setTransactionName('import-dolphin-profiles-error');
      scope.setFingerprint(['import-dolphin-profiles-error']);

      return scope;
    });
  };

  const onClickPrepareToImport = (): void => {
    setIsPreparingToImport(true);
  };

  const previewText = (): JSX.Element => (
    <>
      {translation('importDolphinProfilesModal.preview.subtitle')}
      {!isElectron ?
        <>
          <br />
          <>
            {translation('importDolphinProfilesModal.preview.subtitleOnlyWebApp')}
          </>
          &nbsp;
          <a onClick={openDownloadAppPage}>
            {translation('importDolphinProfilesModal.preview.downloadOnlyWebApp')}
          </a>
        </>:
        null}
    </>
  );

  const getTextView = (): JSX.Element => (
    <PreviewText>
      {previewText()}
    </PreviewText>
  );

  const openDownloadAppPage = (): void =>  {
    const url = 'https://gologin.com/';
    if (isElectron) {
      window.require('electron').shell.openExternal(url);

      return;
    }

    window.open(url);
  };

  const onKeyChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setDolphinApiKey(event.target.value);
  };

  const openHelpPage = (): void =>  {
    const url = 'https://support.gologin.com/en/articles/8167434-transferring-profiles-from-dolphin-anty-to-gologin';
    if (isElectron) {
      window.require('electron').shell.openExternal(url);

      return;
    }

    window.open(url);
  };

  const getAPIKeyInput = (): JSX.Element => (
    <Input
      value={dolphinApiKey}
      placeholder={translation('importDolphinProfilesModal.credentials.apiKeyPlaceholder') || ''}
      style={{ marginTop: 20 }}
      onChange={onKeyChanged}
    />
  );

  if (isPreparingToImport) {
    return (
      <ModernModal
        visible={visible}
        onCancel={closeModal}
        width={546}
        title={translation('importDolphinProfilesModal.credentials.title')}
        footer={null}
      >
        {getAPIKeyInput()}
        <ButtonsContainer>
          <LeftButtonsGroup>
            <HelpButton
              onClick={openHelpPage}
            >
              {translation('base.help')}
            </HelpButton>
          </LeftButtonsGroup>
          <RightButtonsGroup>
            <CancelButton
              style={{ marginRight: 12 }}
              onClick={closeModal}
            >
              {translation('base.cancel')}
            </CancelButton>
            <SaveButton
              disabled={!dolphinApiKey || isMigration}
              onClick={initImportProcess}
            >
              {isMigration ?
                <LoaderDiv>
                  <Icon type='loading' />
                </LoaderDiv> :
                <>
                  {translation('base.start')}
                </>}
            </SaveButton>
          </RightButtonsGroup>
        </ButtonsContainer>
      </ModernModal>
    );
  }

  return (
    <ModernModal
      visible={visible}
      onCancel={closeModal}
      width={546}
      title={translation('importDolphinProfilesModal.preview.title')}
      footer={null}
    >
      {getTextView()}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32 }}>
        <CancelButton
          style={{ marginRight: 12 }}
          onClick={closeModal}
        >
          {translation('base.no')}
        </CancelButton>
        <SaveButton
          onClick={onClickPrepareToImport}
        >
          {translation('base.yes')}
        </SaveButton>
      </div>
    </ModernModal>
  );
};

export default ImportDolphinProfilesModal;
