import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IProxy } from '../../../features/proxy/components/interfaces/IProxy';
import { getAreProxiesEqual } from '../../../features/proxy/proxy-helpers';
import { ProxySelectorLocation } from '../proxy-manager-modal-status.atom';

export type ProxyCheckTooltipView = 'selector-profile-table'|
  'selector-profile-settings-favourite'|
  'selector-profile-settings-proxy'|
  'proxy-list-item'|
  'proxy-manager-edit-form'|
  'proxy-page-table'|
  ProxySelectorLocation.profileSettingsPage|
  '';

const proxyCheckTooltipProfileIdsAtom = atom<string[]>([]);
const proxyCheckTooltipProxiesAtom = atom<IProxy[]>([]);
const proxyCheckTooltipViewAtom = atom<ProxyCheckTooltipView>('');

const getProxyCheckTooltipProfileIds = (): string[] => getDefaultStore().get(proxyCheckTooltipProfileIdsAtom);
const getProxyCheckTooltipProxies = (): IProxy[] => getDefaultStore().get(proxyCheckTooltipProxiesAtom);

const setProxyCheckTooltipProfileIds = (profileIds: string[]): void => getDefaultStore().set(proxyCheckTooltipProfileIdsAtom, profileIds);
const setProxyCheckTooltipProxies = (proxies: IProxy[]): void => getDefaultStore().set(proxyCheckTooltipProxiesAtom, proxies);
const setProxyCheckTooltipView = (view: ProxyCheckTooltipView): void => getDefaultStore().set(proxyCheckTooltipViewAtom, view);

const useProxyCheckTooltipProfileIds = (): string[] => useAtomValue(proxyCheckTooltipProfileIdsAtom);
export const useProxyCheckTooltipProxies = (): IProxy[] => useAtomValue(proxyCheckTooltipProxiesAtom);
const useProxyCheckTooltipView = (): ProxyCheckTooltipView => useAtomValue(proxyCheckTooltipViewAtom);

export const useIsProxyCheckTooltipVisible = (proxy: IProxy, profileId = '', view: ProxyCheckTooltipView): boolean => {
  const tooltipCurrentProxies = useProxyCheckTooltipProxies();
  const tooltipCurrentView = useProxyCheckTooltipView();
  const tooltipCurrentProfilesIds = useProxyCheckTooltipProfileIds();

  const isProxyInProxySelector = tooltipCurrentView === 'selector-profile-table' && !!profileId;

  return !!view && tooltipCurrentView === view &&
    (isProxyInProxySelector ? tooltipCurrentProfilesIds.includes(profileId) : true) &&
    tooltipCurrentProxies.some(tooltipCurrentProxy => getAreProxiesEqual(tooltipCurrentProxy, proxy, true));
};

interface IProxyCheckTooltipParams {
  profileIds: string[];
  proxies: IProxy[];
  view: ProxyCheckTooltipView;
  timeout?: number;
}

export const showProxyCheckTooltip = ({
  profileIds,
  proxies,
  view,
  timeout,
}: IProxyCheckTooltipParams): void => {
  const tooltipProfileIds = getProxyCheckTooltipProfileIds();
  const tooltipProxies = getProxyCheckTooltipProxies();

  const newTooltipProfileIds = [...tooltipProfileIds, ...profileIds];
  const newTooltipProxies = [...tooltipProxies, ...proxies];

  setProxyCheckTooltipProfileIds(newTooltipProfileIds);
  setProxyCheckTooltipProxies(newTooltipProxies);
  setProxyCheckTooltipView(view);

  if (timeout) {
    setTimeout(() => hideProxyCheckTooltip(proxies, profileIds), timeout);
  }
};

export const hideProxyCheckTooltip = (proxies: IProxy[] = [], profilesIds: string[] = []): void => {
  let newTooltipProfileIds: string[] = [];
  let newTooltipProxies: IProxy[] = [];
  if (!(proxies.length || profilesIds.length)) {
    setProxyCheckTooltipProfileIds(newTooltipProfileIds);
    setProxyCheckTooltipProxies(newTooltipProxies);
    setProxyCheckTooltipView('');

    return;
  }

  const tooltipProfileIds = getProxyCheckTooltipProfileIds();
  const tooltipProxies = getProxyCheckTooltipProxies();

  if (proxies.length) {
    newTooltipProxies = tooltipProxies.filter(proxyCurrent => !proxies.some(proxy => getAreProxiesEqual(proxy, proxyCurrent)));
  }

  if (profilesIds.length) {
    newTooltipProfileIds = tooltipProfileIds.filter(profileIdCurrent => !profilesIds.includes(profileIdCurrent));
    setProxyCheckTooltipProfileIds(newTooltipProfileIds);
  }

  setProxyCheckTooltipProxies(newTooltipProxies);
  if (!newTooltipProxies.length) {
    setProxyCheckTooltipView('');
  }
};
