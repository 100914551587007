import styled from '@emotion/styled';

export const ProxyListItemProfilesCountEl = styled('div')`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: var(--CDCDCD-proxy-manager-profiles-count);
  min-width: 6px;
  height: 16px;
  padding: 2px 0;
`;

export const ProxyProfilesCountTooltipEl = styled('div')`
  color: var(--222222-proxy-manager-profiles-count);
  font-size: 12px;
  padding: 6px 8px;
  line-height: 14px;
  letter-spacing: 0.2px;
`;
