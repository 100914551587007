import { v4 as uuidv4 } from 'uuid';

import { API_BASE_URL } from '../../../common/constants/constants';
import { IProfile } from '../../interfaces';
import { http } from '../../services';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { sendReactAnalyticsError } from '../../utils/sentry.helper';
import { handleProxiesLimitError } from '../proxy/utils/handle-proxies-limit-error';

export const checkProxyRequest = async (options: any) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/check_proxy`, {
      method: 'POST',
      body: JSON.stringify(options)
    });

    return data.body;
  } catch (error) {
    throw error;
  }
}

export const requestProfileInfo = async (profileId: string) => {
  try {
    const data = await http(`${API_BASE_URL}/browser/${profileId}`, {
      method: 'GET'
    });

    return data.body;
  } catch (error) {
    throw error;
  }
};

interface IUpdateProfileOptions {
  profileId: string;
  profileData: IProfile;
  updateNoises?: boolean;
  shouldEditProxyInPlace?: boolean;
}

export const updateProfile = async ({
  profileId,
  profileData,
  updateNoises = false,
  shouldEditProxyInPlace,
}: IUpdateProfileOptions): Promise<IProfile|null> => {
  const workspaceId = getCurrentWorkspaceId();
  const url = new URL(`${API_BASE_URL}/browser/${profileId}`);
  url.searchParams.append('updateNoises', updateNoises ? 'true' : 'false');
  if (workspaceId) {
    url.searchParams.append('workspaceId', workspaceId);
  }

  if (shouldEditProxyInPlace) {
    url.searchParams.append('shouldEditProxyInPlace', 'true');
  }

  return http(url.toString(), { method: 'PUT', body: JSON.stringify(profileData) })
    .then(({ body }: { body: IProfile }) => body)
    .catch((error: { body: { message: string; currentCount: number; maxCount: number } }) => handleProxiesLimitError(error));
};

export const sendAnalytics = (profileId: string, updatedFields: any) => {
  const body = Object.keys(updatedFields).map((key: string) => ({
    field: key,
    count: updatedFields['key'],
    profile: profileId,
    type: 'fingerprint-update',
    status: 'success',
    deduplicationKey: uuidv4(),
  }));

  return http(`${API_BASE_URL}/analytics/many`, {
    method: 'POST',
    body: JSON.stringify({
      analytics: body,
    }),
  }).catch(sendReactAnalyticsError);
};

interface IUpdateFingerPrints {
  resolution: string;
  instanceIds: string[];
  template: string | null;
}

export const updateMultipleFingerprints = async (updateData: IUpdateFingerPrints): Promise<IProfile[]> => {
  const data = await http(`${API_BASE_URL}/browser/fingerprint/multi`, {
    method: 'POST',
    body: JSON.stringify(updateData),
  });

  return data.body;
};
