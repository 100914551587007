import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { SelectButtonContainer } from '../../members/role-select/styles';

const proxySavedSelectorActiveStyles = css`
  .proxy-saved-selector-arrow-down svg path {
    stroke: var(--36363D-proxy-tab-icon-active);
  }
`;

export const ProxySavedSelectorWrapper = styled(SelectButtonContainer)<{ isActive: boolean }>`
  padding: 7px 7px 7px 13px;

  ${(props): SerializedStyles|null => props.isActive ? proxySavedSelectorActiveStyles : null}

  :hover {
    ${proxySavedSelectorActiveStyles}
  }
`;
