import { MS_IN_SECOND } from './constants/constants';
import { ACCESS_TOKEN } from '../app/services/http/config';

export const wait = (delay: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, delay));

export const safeParseJSON = <T>(data: string): T|null => {
  try {
    const result = JSON.parse(data);

    return result;
  } catch (error) {
    return null;
  }
};

export const getCurrentTimestampForSentry = (): number => (performance.now() + performance.timeOrigin) / MS_IN_SECOND;

export const isUserAuthenticated = (): boolean => !!localStorage.getItem(ACCESS_TOKEN);
