import { Select } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

import { IProxy } from '../../../../interfaces';
import { ElementContainer, Subtitle } from '../domElements';

interface ITorProxyForm {
  proxy: IProxy;
  updateAutoProxyRegion: (val: string, field: 'torProxyRegion') => any;
  isDisabled: boolean;
}

export const DEFAULT_TOR_PROXY_REGION: IProxy['torProxyRegion'] = 'us';

const TorProxyForm: FC<ITorProxyForm> = (props: ITorProxyForm) => {
  const { proxy, updateAutoProxyRegion, isDisabled } = props;

  const { t: translation } = useTranslation();

  return (
    <ElementContainer>
      <Subtitle>
        {translation('base.nodeExit')}
      </Subtitle>
      <Select
        value={proxy.torProxyRegion || DEFAULT_TOR_PROXY_REGION}
        onChange={(value): void => updateAutoProxyRegion(value, 'torProxyRegion')}
        style={{ width: 278 }}
        disabled={isDisabled}
      >
        <Option key="us">
          US (
          {translation('countries.us')}
          )
        </Option>
        <Option key="uk">
          UK (
          {translation('countries.uk')}
          )
        </Option>
        <Option key="de">
          DE (
          {translation('countries.de')}
          )
        </Option>
        <Option key="fr">
          FR (
          {translation('countries.fr')}
          )
        </Option>
        <Option key="eu">
          EU (
          {translation('countries.eu')}
          )
        </Option>
      </Select>
    </ElementContainer>
  );
};

export default TorProxyForm;
