import { Button, Icon, Input, message, Modal } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userContext } from '../../../../state';
import { requestDeleteMySharedAccounts, requestGetMySharedAccounts, shareAccount } from './api';
import { Row } from './styles';

interface IShareAccountModal {
  toggleShareAccountModal: (state: boolean) => void;
  switchShareAccountModalVisible: boolean;
}

const { confirm } = Modal;

const ShareAccountModal: FC<IShareAccountModal> = (props) => {
  const [profileToShare, changeProfileToShare] = useState<string>('');
  const [sharingButtonDisable, changeSharingButtonDisable] = useState<boolean>(false);
  const [mySharedAccounts, setMySharedAccounts] = useState<any[]>([]);

  const {
    email: userEmail,
  } = useContext(userContext);

  const { t: translation } = useTranslation();

  const {
    switchShareAccountModalVisible,
    toggleShareAccountModal,
  } = props;

  useEffect(() => {
    getMySharedAccounts();
  }, []);

  const getMySharedAccounts = (): Promise<void> => requestGetMySharedAccounts().then(setMySharedAccounts);

  const ent = (event: any): void => {
    if (event.keyCode === 13) {
      shareAccountComponent();
    }
  };

  const shareAccountComponent = () => {
    const recepient = profileToShare.trim();
    if (!recepient) {
      message.error(translation('errorMessages.enterValidEmail'), 3);

      return;
    }

    if (recepient === userEmail) {
      message.error(translation('notifications.error.cannotMakeMember'), 3);

      return;
    }

    changeSharingButtonDisable(true);

    return shareAccount({ type: 'profile', recepient })
      .then(() => {
        message.success(translation('notifications.success.accountHasBeenShared'));
        changeProfileToShare('');
      })
      .finally(() => {
        changeSharingButtonDisable(false);
        getMySharedAccounts();
      });
  };

  const removeSharedAccount = (id: string, email: string) => confirm({
    title: translation('shareModal.btnSubmitRemoveMember'),
    content: email,
    onOk() {
      requestDeleteMySharedAccounts(id)
        .then(() => {
          message.success(translation('notifications.success.memberHasBeenDeleted'));
          getMySharedAccounts();
        });
    },
  });

  return (
    <Modal
      width={488}
      title={translation('shareModal.addMembers')}
      visible={switchShareAccountModalVisible}
      onCancel={() => toggleShareAccountModal(false)}
      footer={null}
    >
      <Row>
        <Input
          style={{
            width: 310,
          }}
          placeholder={translation('shareModal.form.emailInput.placeholder')}
          onKeyDown={ent}
          value={profileToShare}
          suffix={(
            <Icon
              type='close-circle'
              style={{ color: 'var(--00000073)', cursor: 'pointer' }}
              onClick={() => changeProfileToShare('')}
            />
          )}
          onChange={e => changeProfileToShare(e.target.value)}
        />
        <Button
          style={{ marginLeft: '10px' }}
          type='primary'
          onClick={() => shareAccountComponent()}
          disabled={sharingButtonDisable}
        >
          {translation('shareModal.addMembers')}
        </Button>
      </Row>
      <div style={{ marginTop: '15px' }}>
        {mySharedAccounts.map((item) => (
          <Row
            key={item.id}
            style={{
              marginBottom: 10,
            }}
          >
            {item.to.email}
            <Icon
              type='delete'
              style={{ marginLeft: '15px', color: 'var(--00000073)', cursor: 'pointer' }}
              onClick={() => removeSharedAccount(item.id, item.to.email)}
            />
          </Row>
        ))}
      </div>
    </Modal>
  );
};

export default ShareAccountModal;
