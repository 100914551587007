import { atom, getDefaultStore, useAtomValue } from 'jotai';

interface IPositionProxySelectMenuStatus {
  x: number;
  y: number;
}

const defaultPositionValue: IPositionProxySelectMenuStatus = {
  x: 0,
  y: 0,
};

const isProxyContextMenuVisible = atom(false);
const isSelectProxyModeOpenedAtom = atom(false);
const confirmBlockVisibleAtom = atom(false);
const proxySelectMenuPositionAtom = atom(defaultPositionValue);

const setIsProxyContextMenuVisible = (value: boolean): void => getDefaultStore().set(isProxyContextMenuVisible, value);
const setIsSelectProxyModeOpened = (value: boolean): void => getDefaultStore().set(isSelectProxyModeOpenedAtom, value);
const setConfirmBlockVisible = (value: boolean): void => getDefaultStore().set(confirmBlockVisibleAtom, value);
const setProxySelectMenuPosition = (value: IPositionProxySelectMenuStatus): void => getDefaultStore().set(proxySelectMenuPositionAtom, value);

export const useIsProxyContextMenuVisible = (): boolean => useAtomValue(isProxyContextMenuVisible);

export const useIsSelectProxyModeOpened = (): boolean => useAtomValue(isSelectProxyModeOpenedAtom);

export const useConfirmBlockVisible = (): boolean => useAtomValue(confirmBlockVisibleAtom);

export const useProxySelectMenuPosition = (): IPositionProxySelectMenuStatus => useAtomValue(proxySelectMenuPositionAtom);

export const openProxyContextMenu = (): void => {
  setIsProxyContextMenuVisible(true);
};

export const hideProxyContextMenu = (): void => {
  setIsProxyContextMenuVisible(false);
};

export const switchIsSelectProxyModeOpened = (value: boolean): void => {
  setIsSelectProxyModeOpened(value);
};

export const switchConfirmBlockVisible = (value: boolean): void => {
  setConfirmBlockVisible(value);
};

export const updateProxySelectMenuPosition = (value: IPositionProxySelectMenuStatus): void => {
  setProxySelectMenuPosition(value);
};
