import React, { FC } from 'react';

const IconEmptyFlag: FC = () => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="13" height="9" stroke="currentColor" />
    <path d="M0.5 0.416992L13.5 9.58366" stroke="currentColor" />
  </svg>
);

export default IconEmptyFlag;
